import { Transform } from 'class-transformer';

import { AgendaItemKind, noPostalVote, noRecommendation } from '@startuptools/common/meetings';
import { jsonParse, jsonStringify } from './utils';
import { AgendaItemDecision } from './agenda-item-decision.model';
import { GqlAgendaItem, GqlAgendaItemDecision } from '../graphql/operations';
import { AutoFields } from '.';

interface IDecisionSuggestion {
  decisionPrimaryLang: string;
  decisionSecondaryLang: string;
}

export interface AiWithIncludes {
  model: AgendaItem;
  decisions: AgendaItemDecision[];
}

export class AgendaItem implements Omit<GqlAgendaItem, 'meeting'> {
  id: string;

  order: number;
  subjectPrimaryLang: string;
  subjectSecondaryLang?: string;
  commentPrimaryLang?: string;
  commentSecondaryLang?: string;
  firstGroup: boolean;
  lastGroup: boolean;
  mandatory: boolean;
  done: boolean;
  kind: AgendaItemKind;

  @Transform(({ value }: { value: string }) => jsonParse(value), {
    toClassOnly: true,
  })
  @Transform(({ value }) => jsonStringify(value), { toPlainOnly: true })
  decisionSuggestions?: IDecisionSuggestion[];
  agendaItemDecisions: GqlAgendaItemDecision[];

  meetingId: string;

  readonly createdAt: string;
  readonly updatedAt: string;

  get canHavePostalVote() {
    return !noPostalVote.includes(this.kind);
  }

  get canHaveBoardsRecommendation() {
    return !noRecommendation.includes(this.kind);
  }

  constructor(seed?: Omit<AgendaItem, AutoFields | 'canHavePostalVote' | 'canHaveBoardsRecommendation'>) {
    Object.assign(this, seed);
  }
}
