import { Button } from '@startuptools/ui';
import { useActiveCompanySubSuspenseQuery, useCompanySuspenseQuery } from '../../graphql/react-operations';
import { useCompanyId } from '@startuptools/common/react-wrapper';
import { useNgRouter } from '../react-wrapper/AngularRouterContext';
import { Trans } from '@lingui/react';

export const CompanySubscription = () => {
  const companyId = useCompanyId();
  const { ngNavigateByUrl } = useNgRouter();
  const {
    data: { company },
  } = useCompanySuspenseQuery({ variables: { id: companyId } });
  const {
    data: {
      activeCompanySubscription: { activeProduct },
    },
  } = useActiveCompanySubSuspenseQuery({ variables: { companyId }, fetchPolicy: 'no-cache' });

  if (activeProduct || company.organizationNumber.startsWith('0')) {
    return null;
  }

  return (
    <Button onClick={() => ngNavigateByUrl('/settings/billing')}>
      <Trans id="Upgrade" />
    </Button>
  );
};
