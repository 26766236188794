import { PropsWithChildren, createContext, useContext } from 'react';

const Context = createContext<string | null | undefined>(null);

interface Props {
  companyId: string | null | undefined;
}

export const CompanyIdProvider = ({ companyId, children }: PropsWithChildren<Props>) => {
  return <Context.Provider value={companyId}>{children}</Context.Provider>;
};

export const useCompanyId = () => {
  const data = useContext(Context);
  if (!data) {
    throw new Error('useCompanyId must be used within a CompanyIdProvider');
  }
  return data;
};
