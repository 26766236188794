import { Trans } from '@lingui/react';
import { Alert, Card, Divider, Link, Stack, Tooltip, Typography } from '@startuptools/ui';

export const OldBrowser = () => {
  return (
    <Stack alignItems="center" padding={3}>
      <Card>
        <Typography level="h2">
          <Trans id="Your browser is too old" />
        </Typography>
        <Typography level="title-lg">
          <Trans id="Please upgrade your browser or switch to one of the supported versions" />
        </Typography>
        <Divider />
        <Stack direction="row" gap={3} marginY={2} justifyContent="center">
          <Tooltip title={<Trans id="Mozilla Firefox (Minimum version: 115)" />}>
            <a href="https://www.mozilla.org/firefox/new">
              <img src="./assets/browser-logos/firefox.svg" width="200px" alt="Firefox" />
            </a>
          </Tooltip>
          <Tooltip title={<Trans id="Google Chrome (Minimum version: 110)" />}>
            <a href="https://www.google.com/chrome">
              <img src="./assets/browser-logos/chrome.svg" width="200px" alt="Chrome" />
            </a>
          </Tooltip>
          <Tooltip title={<Trans id="Microsoft Edge (Minimum version: 110)" />}>
            <a href="https://www.microsoft.com/edge">
              <img src="./assets/browser-logos/edge.svg" width="200px" alt="Edge" />
            </a>
          </Tooltip>
        </Stack>
        <Alert>
          <Typography>
            <Trans
              id="If you're using Safari <link>upgrade to at least version 16.4</link>"
              components={{ link: <Link href="https://support.apple.com/102665" /> }}
            />
          </Typography>
        </Alert>
      </Card>
    </Stack>
  );
};
