import { GqlArticlesOfAssocDocument } from '../graphql/operations';

export class ArticlesOfAssocDocument implements Omit<GqlArticlesOfAssocDocument, 'articlesOfAssoc' | 'document'> {
  id: string;

  articlesOfAssocId: string;
  documentId: string;

  readonly createdAt: string;
  readonly updatedAt: string;

  constructor(seed?: Partial<ArticlesOfAssocDocument>) {
    Object.assign(this, seed);
  }
}
