import { ShareIssue } from './share-issue.model';
import { WarrantProgram } from './warrant-program.model';
import { AuthorizationGroup } from './authorization-group.model';
import { ArticlesOfAssoc } from './articles-of-assoc.model';
import type { Meeting } from './meeting.model';
import { GqlMeetingConcern } from '../graphql/operations';

export interface McWithIncludes {
  model: MeetingConcern;
  meeting: Meeting;
  warrantProgram?: WarrantProgram;
  employeeOption?: WarrantProgram;
  wise?: WarrantProgram;
  shareIssue?: ShareIssue;
  authorizationGroup?: AuthorizationGroup;
  articlesOfAssoc?: ArticlesOfAssoc;
}

export class MeetingConcern implements Omit<GqlMeetingConcern, 'meeting'> {
  id: string;

  meetingId: string;
  shareIssueId?: string;
  warrantProgramId?: string;
  articlesOfAssocId?: string;
  authorizationGroupId?: string;
  approved?: boolean;
  appendixNumber: number;

  readonly createdAt: string;
  readonly updatedAt: string;

  constructor(seed?: Partial<MeetingConcern>) {
    Object.assign(this, seed);
  }
}
