import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class VersionPromptService {
  private isActive = false;

  constructor(private snackBar: MatSnackBar) {}

  showPrompt() {
    if (!this.isActive) {
      this.isActive = true;
      setTimeout(() => {
        const snackBar = this.snackBar.open(
          $localize`Det finns en ny uppdatering av appen, vi rekommenderar att du laddar om sidan så snart du kan`,
          $localize`Ladda om`,
        );
        snackBar.onAction().subscribe(() => {
          window.location.reload();
        });
        snackBar.afterDismissed().subscribe(() => {
          this.isActive = false;
        });
      }, 60_000);
    }
  }
}
