import { Trans } from '@lingui/react';
import {
  Table,
  Select,
  Option,
  IconButton,
  Button,
  Dropdown,
  Menu,
  MenuItem,
  MenuButton,
  Box,
  Card,
  DeleteIcon,
  MoreHorizIcon,
  ListItemDecorator,
  ContentCopyIcon,
  Snackbar,
} from '@startuptools/ui';
import { Identity } from '../identity/Identity';
import { DocumentSignaturesDocument, GqlDocumentSignaturesQuery } from './DocumentSignatures.graphql';
import { isNil } from 'lodash-es';
import { useState } from 'react';
import { JuridicalKind, isSwedish } from '../../models/identity.model';
import { DocumentSignatoryStatus } from './DocumentSignatoryStatus';
import { ArrayElement } from '@startuptools/common/common';
import { EditableEmail } from '../editable-email/EditableEmail';
import {
  GqlScriveAuthMethod,
  GqlScriveDocumentStatus,
  GqlSignAllowedAction,
  GqlZignedAgreementStatus,
} from '../../graphql/base-types.graphql';

export const DocumentSignaturesTable = ({
  signAllowedAction,
  scriveStatus,
  zignedAgreementStatus,
  documentLoading,
  documentSignatures,
  setSignatoryMethod,
  setProxy,
  setEmail,
  onDelete,
}: {
  signAllowedAction: GqlSignAllowedAction;
  scriveStatus: GqlScriveDocumentStatus | null | undefined;
  zignedAgreementStatus: GqlZignedAgreementStatus | null | undefined;
  documentSignatures: GqlDocumentSignaturesQuery['documentSignatures'];
  setSignatoryMethod: (id: string, method: string) => Promise<unknown>;
  setProxy: (ds: ArrayElement<GqlDocumentSignaturesQuery['documentSignatures']>) => void;
  setEmail: (dsIs: string, email: string) => Promise<unknown>;
  documentLoading: boolean;
  onDelete: (id: string) => Promise<unknown>;
}) => {
  const [loading, setLoading] = useState(false);
  const [showCopySnackbar, setShowCopySnackbar] = useState(false);
  const [showClipboardError, setShowClipboardError] = useState(false);

  const handleSignatureMethodChange = async (id: string, newValue: string | null) => {
    if (!isNil(newValue)) {
      setLoading(true);
      const res = setSignatoryMethod(id, newValue);
      try {
        await res.finally(() => setLoading(false));
      } catch (e) {
        console.error(e);
        // setShowError(true);
      }
    }
  };

  const showActions = [GqlSignAllowedAction.Start, GqlSignAllowedAction.Restart].includes(signAllowedAction);
  const showStatus = [GqlSignAllowedAction.Stop, GqlSignAllowedAction.None].includes(signAllowedAction);
  const showSigningRoomLink = [GqlSignAllowedAction.Stop].includes(signAllowedAction);

  const mayEditEmailAndSignMethod =
    scriveStatus !== GqlScriveDocumentStatus.Closed &&
    (isNil(zignedAgreementStatus) ||
      ![GqlZignedAgreementStatus.Fulfilled, GqlZignedAgreementStatus.Pending].includes(zignedAgreementStatus));

  const handleOnDelete = async (id: string) => {
    setLoading(true);
    return onDelete(id).finally(() => setLoading(false));
  };

  const handleCopyRoomLink = async (roomLink: string | null | undefined) => {
    if (roomLink) {
      try {
        await window.navigator.clipboard.writeText(roomLink);
        setShowCopySnackbar(true);
      } catch (e) {
        if (e instanceof DOMException && e.name === 'NotAllowedError') {
          setShowClipboardError(true);
          return;
        }
        throw e;
      }
    }
  };

  const disable = loading || documentLoading;

  return (
    <Card>
      <Table noWrap sx={{ tableLayout: 'auto', '#col-actions': { width: '80px' }, '#col-email': { width: '120px' } }}>
        <thead>
          <tr>
            <th>
              <Trans id="Signatory" />
            </th>
            <th>
              <Trans id="Representative" />
            </th>
            <th>
              <Trans id="Email" />
            </th>
            <th id="col-email">
              <Box sx={{ textAlign: 'right' }}>
                <Trans id="Signature method" />
              </Box>
            </th>
            {showStatus && <th align="right"></th>}
            {showSigningRoomLink && <th />}
            {showActions && <th align="right" id="col-actions"></th>}
          </tr>
        </thead>
        <tbody>
          {documentSignatures?.map(ds => (
            <tr key={ds.id}>
              <td>
                <Identity identity={ds.signatory} editable={false} showEmail={false} />
              </td>
              <td>
                {ds.proxy && <Identity identity={ds.proxy} editable={false} showEmail={false} />}
                {!ds.proxy && ds.signatory.juridicalKind === JuridicalKind.Company && (
                  <Button variant="outlined" onClick={() => setProxy(ds)} color="warning" disabled={disable}>
                    <Trans id="Set representative" />
                  </Button>
                )}
              </td>
              <td>
                {isNil(ds.scriveSignTime) && mayEditEmailAndSignMethod ? (
                  <EditableEmail
                    onChange={async email => {
                      if (!email) {
                        return;
                      }
                      await setEmail(ds.id, email);
                    }}
                    refetchQueries={[DocumentSignaturesDocument]}
                  >
                    {ds.email}
                  </EditableEmail>
                ) : (
                  <>{ds.email}</>
                )}
              </td>
              <td style={{ maxWidth: 170 }}>
                <Select
                  disabled={disable || !mayEditEmailAndSignMethod}
                  value={ds.authMethodToSign}
                  variant="outlined"
                  onChange={(_, newValue) => handleSignatureMethodChange(ds.id, newValue)}
                >
                  {isSwedish(ds.proxy ?? ds.signatory) && (
                    <Option value={GqlScriveAuthMethod.SeBankid}>
                      {!isNil(scriveStatus) ? <>BankID</> : <Trans id="BankID or Truid" />}
                    </Option>
                  )}
                  <Option value={GqlScriveAuthMethod.Standard}>
                    {!isNil(scriveStatus) ? <Trans id="Email" /> : <Trans id="Allow email signing" />}
                  </Option>
                </Select>
              </td>
              {showStatus && (
                <>
                  <td align="right">
                    <DocumentSignatoryStatus documentSignature={ds} direction="row" />
                  </td>
                </>
              )}
              {showSigningRoomLink && (
                <td>
                  {ds.zignedParticipantSigningRoomLink && (
                    <Dropdown>
                      <MenuButton slots={{ root: IconButton }} slotProps={{ root: { variant: 'outlined' } }}>
                        <MoreHorizIcon />
                      </MenuButton>
                      <Menu>
                        <MenuItem onClick={() => handleCopyRoomLink(ds.zignedParticipantSigningRoomLink)}>
                          <ListItemDecorator>
                            <ContentCopyIcon />
                          </ListItemDecorator>
                          <Trans id="Copy signing link" />
                        </MenuItem>
                      </Menu>
                    </Dropdown>
                  )}
                </td>
              )}
              {showActions && (
                <td align="right" width="100px">
                  {ds.signatory.juridicalKind === JuridicalKind.Company && (
                    <Dropdown>
                      <MenuButton
                        slots={{ root: IconButton }}
                        slotProps={{ root: { variant: 'plain' } }}
                        disabled={disable}
                      >
                        <MoreHorizIcon />
                      </MenuButton>
                      <Menu>
                        <MenuItem onClick={() => setProxy(ds)}>
                          <Trans id="Set representative" />
                        </MenuItem>
                      </Menu>
                    </Dropdown>
                  )}
                  <IconButton variant="plain" color="danger" onClick={() => handleOnDelete(ds.id)} disabled={disable}>
                    <DeleteIcon />
                  </IconButton>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
      <Snackbar
        open={showCopySnackbar}
        color="success"
        variant="soft"
        onClose={() => setShowCopySnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Trans id="Signing room link was added to your clipboard, you can now paste it" />
      </Snackbar>
      <Snackbar
        open={showClipboardError}
        color="danger"
        variant="soft"
        onClose={() => setShowClipboardError(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Trans id="Could not copy signing room link, your browser is probably configured to not allow clipboard usage" />
      </Snackbar>
    </Card>
  );
};
