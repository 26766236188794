import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { isNil } from 'lodash-es';
import { first } from 'rxjs/operators';

interface ModifyLink {
  url: string;
  text: string;
}

export interface Data<T> {
  title: string;
  text: string;
  question: string;
  link?: ModifyLink;
  confirm: string;
  cancel: string;
  onSubmit?: (confirmed: boolean) => Observable<T>;
}

@Component({
  templateUrl: './alert-confirm-dialog.component.html',
})
export class AlertConfirmDialogComponent<T> {
  constructor(
    private dialogRef: MatDialogRef<AlertConfirmDialogComponent<T>>,
    @Inject(MAT_DIALOG_DATA) public data: Data<T>,
  ) {}

  static open<T>(dialog: MatDialog, data: Data<T>) {
    return dialog.open<AlertConfirmDialogComponent<T>, Data<T>, boolean>(AlertConfirmDialogComponent, {
      minWidth: 700,
      maxWidth: 700,
      data: data,
      disableClose: true,
      autoFocus: false,
    });
  }

  modifyText(text: string): { start?: string; end?: string } {
    if (!isNil(this.data.link)) {
      const xText: string[] = text.split('@LINK');
      return { start: xText[0], end: xText[1] };
    }
    return { start: text };
  }

  submit(confirm: boolean) {
    if (!isNil(this.data.onSubmit)) {
      this.data
        .onSubmit(confirm)
        .pipe(first())
        .subscribe(res => this.dialogRef.close(res));
      return;
    }
    this.dialogRef.close(confirm);
  }
}
