<div class="dialog" sutLoading #qLoading="Loading">
  <div class="dialog-close">
    <button mat-icon-button [mat-dialog-close]="null">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="dialog-title">
    <h4 i18n>Koppla ett ärende</h4>
  </div>

  <div mat-dialog-content class="dialog-content flex flex-col">
    <div q-message color="primary" *ngIf="(snrCases$ | async)?.length === 0; else hasCases">
      <span q-message-header>
        <span i18n>Kunde inte hitta ett relevant ärende hos bolagsverket som inkommit efter</span>
        {{ data.fromDate | dateTimeLocaleStr: DateTime.DATE_SHORT }}.

        <span i18n>Vänta på att bolagsverket har diarefört ärendet och försök igen.</span>
      </span>
    </div>

    <ng-template #hasCases>
      <table mat-table [dataSource]="snrCases$" class="w-full">
        <ng-container matColumnDef="registrationDate">
          <th mat-header-cell scope="auto" *matHeaderCellDef i18n>Registrerat</th>
          <td mat-cell *matCellDef="let row">
            {{ row.registrationDate | dateTimeToFormat: 'yyyy-MM-dd' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="arrivalDate">
          <th mat-header-cell scope="auto" *matHeaderCellDef i18n>Inkom</th>
          <td mat-cell *matCellDef="let row">
            {{ row.arrivalDate | dateTimeToFormat: 'yyyy-MM-dd' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="subjects">
          <th mat-header-cell scope="auto" *matHeaderCellDef i18n>Rubrik</th>
          <td mat-cell *matCellDef="let row">
            <span *ngFor="let sub of row.subjects; let last = last">{{ sub.value }}<br *ngIf="!last" /></span>
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell scope="auto" *matHeaderCellDef i18n>Status</th>
          <td mat-cell *matCellDef="let row">
            <span *ngFor="let status of row.status; let last = last">{{ status.value }}<br *ngIf="!last" /></span>
          </td>
        </ng-container>

        <ng-container matColumnDef="filingNumber">
          <th mat-header-cell scope="auto" *matHeaderCellDef i18n>Diarienummer</th>
          <td mat-cell *matCellDef="let row">
            <a [href]="getLink(row)" target="_blank">{{ row.filingNumber }}/{{ row.filingYear }}</a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="save(row)" clickable></tr>
      </table>
    </ng-template>
  </div>
</div>
