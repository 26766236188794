import { GqlMeetingAgendaTemplateItem } from '../graphql/operations';

interface IMeetingDecision {
  decisionPrimaryLang: string;
  decisionSecondaryLang: string;
}

export class MeetingAgendaTemplateItem implements Omit<GqlMeetingAgendaTemplateItem, 'meetingAgendaTemplate'> {
  id: string;

  order: number;
  subjectPrimaryLang: string;
  subjectSecondaryLang: string;
  commentPrimaryLang?: string;
  commentSecondaryLang?: string;
  decisionPrimaryLang?: string;
  decisionSecondaryLang?: string;
  mandatory: boolean;
  firstGroup: boolean;
  lastGroup: boolean;
  kind: number;
  decisionSuggestions?: IMeetingDecision[];
  meetingAgendaTemplateId: string;

  readonly createdAt: string;
  readonly updatedAt: string;

  constructor(seed?: Partial<MeetingAgendaTemplateItem>) {
    Object.assign(this, seed);
  }
}
