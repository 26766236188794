import { Trans } from '@lingui/react';
import { VestingKind, VestingFrequency } from '@startuptools/common/stock-options';
import * as Sentry from '@sentry/react';

export const DisplayVesting = ({ kind }: { kind: VestingKind }) => {
  switch (kind) {
    case VestingKind.Employment:
      return <Trans id="Employment" />;
    case VestingKind.BoardMembership:
      return <Trans id="Board Membership" />;
    case VestingKind.AdvisoryBoard:
      return <Trans id="Advisory Board Membership" />;
    case VestingKind.None:
      return <Trans id="No vesting" />;
    case VestingKind.Custom:
      return <Trans id="Other vesting" />;
    default:
      Sentry.captureException(new Error(`Unknown vesting kind: ${kind}`));
      return null;
  }
};

export const DisplayVestingFrequency = ({ frequency }: { frequency: VestingFrequency }) => {
  switch (frequency) {
    case VestingFrequency.Monthly:
      return <Trans id="Monthly" />;
    case VestingFrequency.Quarterly:
      return <Trans id="Quarterly" />;
    case VestingFrequency.Biyearly:
      return <Trans id="Biyearly" />;
    case VestingFrequency.Yearly:
      return <Trans id="Yearly" />;
    default:
      Sentry.captureException(new Error(`Unknown vesting frequency: ${frequency}`));
      return null;
  }
};
