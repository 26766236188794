import { createReducer, on, Action } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { map } from 'lodash-es';

import { ShareIssueDocument } from '../../models/share-issue-document.model';
import { shareIssueDocumentsActions } from '../actions/share-issue-documents.actions';

export type ShareIssueDocumentsState = EntityState<ShareIssueDocument>;

export const adapter = createEntityAdapter<ShareIssueDocument>();
export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(shareIssueDocumentsActions.manyLoaded, (state, { payload }) => {
    return adapter.addMany(
      payload,
      adapter.removeMany(
        map(payload, p => p.id),
        state,
      ),
    );
  }),
);

export function shareIssueDocumentsReducer(state: EntityState<ShareIssueDocument> | undefined, action: Action) {
  return reducer(state, action);
}
