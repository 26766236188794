import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouter } from '../../store/selectors/router.selectors';
import { values } from 'lodash-es';

import { WarrantProgramsState } from '../reducers/warrant-programs.reducers';
import { selectCompanyId } from './companies.base';
import { GqlWarrantProgramKind } from '../../graphql/operations';

export const selectWarrantProgramsState = createFeatureSelector<WarrantProgramsState>('warrantPrograms');
export const selectWarrantProgramId = createSelector(selectRouter, router => router?.state.params.warrantProgramId);
export const selectWarrantProgram = createSelector(
  selectWarrantProgramsState,
  selectWarrantProgramId,
  // @ts-expect-error TS2538
  (state, id) => state.entities[id],
);
export const selectWarrantProgramById = (id: string) =>
  createSelector(selectWarrantProgramsState, state => state.entities[id]);

export const selectWarrantPrograms = (kind: GqlWarrantProgramKind) =>
  createSelector(selectWarrantProgramsState, selectCompanyId, (state, companyId) => {
    // @ts-expect-error TS18048
    return values(state.entities).filter(m => m.warrantKind === kind && m.companyId === companyId);
  });
export const selectAllWarrantPrograms = createSelector(
  selectWarrantProgramsState,
  selectCompanyId,
  (state, companyId) => {
    // @ts-expect-error TS18048
    return values(state.entities).filter(m => m.companyId === companyId);
  },
);
