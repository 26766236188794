import { GqlAgendaItemDecision } from '../graphql/operations';

export class AgendaItemDecision implements Omit<GqlAgendaItemDecision, 'agendaItem'> {
  id: string;

  order: number;
  decisionPrimaryLang?: string;
  decisionSecondaryLang?: string;
  agendaItemId: string;

  readonly createdAt: string;
  readonly updatedAt: string;

  constructor(seed?: Partial<AgendaItemDecision>) {
    Object.assign(this, seed);
  }
}
