import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { SubSink } from 'subsink';

interface Response {
  ibanNumber: string;
  ibanBank: string;
  ibanBic: string;
  ibanAddress: string;
}

export interface InputIbanDialog {
  ibanNumber: string;
  ibanBank: string;
  ibanBic: string;
  ibanAddress: string;
  onSubmit: (response: Response) => Observable<unknown>;
}

@Component({
  templateUrl: './add-iban-dialog.component.html',
})
export class AddIbanDialogComponent implements OnDestroy {
  formControls = {
    ibanNumber: new UntypedFormControl(),
    ibanBank: new UntypedFormControl(),
    ibanBic: new UntypedFormControl(),
    ibanAddress: new UntypedFormControl(),
  };
  form = new UntypedFormGroup(this.formControls);
  subs = new SubSink();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InputIbanDialog,
    public dialogRef: MatDialogRef<AddIbanDialogComponent>,
  ) {
    this.formControls.ibanNumber.setValue(data.ibanNumber);
    this.formControls.ibanAddress.setValue(data.ibanAddress);
    this.formControls.ibanBic.setValue(data.ibanBic);
    this.formControls.ibanBank.setValue(data.ibanBank);
  }

  static open(dialog: MatDialog, data: InputIbanDialog) {
    return dialog.open<AddIbanDialogComponent, InputIbanDialog>(AddIbanDialogComponent, {
      minWidth: 550,
      disableClose: true,
      autoFocus: false,
      data: data,
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  submit() {
    if (this.form.invalid) {
      return;
    }
    const resp: Response = {
      ibanNumber: this.formControls.ibanNumber.value,
      ibanAddress: this.formControls.ibanAddress.value,
      ibanBic: this.formControls.ibanBic.value,
      ibanBank: this.formControls.ibanBank.value,
    };
    this.subs.sink = this.data
      .onSubmit(resp)
      .pipe(first())
      .subscribe(() => {
        this.dialogRef.close();
      });
  }
}
