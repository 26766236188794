import { GqlOptionsRegistrationSignatory } from '../graphql/operations';
import { AutoFields } from '.';
import { Identity, IdentityId } from './identity.model';
import { OptionsRegistrationId } from './options-registration.model';

export interface OptionsRegistrationSignatoriesWithIncludes {
  model: OptionsRegistrationSignatory;
  identity: Identity;
}

export type OptionsRegistrationSignatoryId = string;

export class OptionsRegistrationSignatory
  implements Omit<GqlOptionsRegistrationSignatory, 'optionsRegistration' | 'identity'>
{
  id: OptionsRegistrationSignatoryId;
  identityId: IdentityId;
  optionsRegistrationId: OptionsRegistrationId;

  readonly createdAt: string;
  readonly updatedAt: string;

  constructor(seed?: Omit<OptionsRegistrationSignatory, AutoFields>) {
    Object.assign(this, seed);
  }
}
