import { ReactNode, useState } from 'react';
import { JuridicalKind } from '../../models/identity.model';
import { DetailState } from './types';
import { FindIdentityDialog } from './FindIdentityDialog';
import { GqlCreateIdentityMutation, GqlIdentity, useCreateIdentityMutation } from '../../graphql/react-operations';
import { isNil } from 'lodash-es';
import { UpdateIdentityDetails } from '../identity/UpdateIdentityDetails';
import { Relations } from './InputSystem';
import { useCompanyId } from '@startuptools/common/react-wrapper';
import { identityToInput } from '../../helpers/identity-to-input';
import { InputState } from './types';
import { GqlIdentityQuery } from '../../graphql/operations';

export type AvailableInputStates = Record<InputState, boolean>;

interface Props {
  headline: ReactNode;
  juridicalKinds: JuridicalKind[];
  availableInputStates: AvailableInputStates;
  defaultInputState?: InputState;
  companyBoardOrgNumber?: string;
  identityExcludeList?: GqlIdentityQuery['identity'][];
  detailFieldsSettings?: {
    email?: DetailState;
    phone?: DetailState;
    address?: DetailState;
    countryCode?: DetailState;
  };
  relations?: Relations;
  handleCreate: (identity: GqlCreateIdentityMutation['createIdentity']) => Promise<unknown> | void;
  onClose: () => void;
  allowProtectedIdentity?: boolean;
}

export const AddIdentity = ({
  headline,
  juridicalKinds,
  availableInputStates,
  companyBoardOrgNumber,
  identityExcludeList,
  detailFieldsSettings = { email: DetailState.Required },
  relations,
  handleCreate: parentHandleCreate,
  defaultInputState,
  onClose,
  allowProtectedIdentity = true,
}: Props) => {
  const [identity, setIdentity] = useState<GqlCreateIdentityMutation['createIdentity'] | null>(null);
  const companyId = useCompanyId();
  const [doEstablish] = useCreateIdentityMutation();

  const handleCreate = async (identityInput: GqlIdentity): Promise<unknown> => {
    try {
      const { data } = await doEstablish({ variables: { companyId, input: identityToInput(identityInput) } });
      if (data) {
        const createIdentity = data.createIdentity;
        if (
          (isNil(createIdentity.email) &&
            detailFieldsSettings?.email === DetailState.Required &&
            createIdentity.juridicalKind === JuridicalKind.Person) ||
          (isNil(createIdentity.address) && detailFieldsSettings?.address === DetailState.Required) ||
          (isNil(createIdentity.countryCode) && detailFieldsSettings?.countryCode === DetailState.Required) ||
          (isNil(createIdentity.phone) && detailFieldsSettings?.phone === DetailState.Required)
        ) {
          setIdentity(createIdentity);
          return;
        }
        return parentHandleCreate(createIdentity);
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const handleUpdate = async (identity: GqlCreateIdentityMutation['createIdentity']): Promise<unknown> => {
    return parentHandleCreate(identity);
  };

  return (
    <>
      {!identity && (
        <FindIdentityDialog
          defaultInputState={defaultInputState}
          headline={headline}
          juridicalKinds={juridicalKinds}
          availableInputStates={availableInputStates}
          companyBoardOrgNumber={companyBoardOrgNumber}
          identityExcludeList={identityExcludeList}
          relations={relations}
          onClose={onClose}
          // @ts-expect-error TS2322
          handleCreate={handleCreate}
          allowProtectedIdentity={allowProtectedIdentity}
        />
      )}
      {!!identity && (
        <UpdateIdentityDetails
          identity={identity}
          fieldSettings={detailFieldsSettings}
          onClose={onClose}
          handleUpdate={handleUpdate}
        />
      )}
    </>
  );
};
