import { Directive, ElementRef, forwardRef, HostListener, Inject, Input, LOCALE_ID } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { formatNumber } from '@angular/common';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { isNumber, toNumber, isEmpty, isNil } from 'lodash-es';
import { BigNumber } from 'bignumber.js';

@Directive({
  selector: 'input[localizedNumericInput]', // eslint-disable-line @angular-eslint/directive-selector
  providers: [
    {
      provide: MAT_INPUT_VALUE_ACCESSOR,
      useExisting: LocalizedNumericInputDirective,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocalizedNumericInputDirective),
      multi: true,
    },
  ],
})
export class LocalizedNumericInputDirective implements ControlValueAccessor {
  @Input() numericIntegerOnly = false;
  private decimalMarker: string;
  private thousandSeparator: string;

  constructor(
    private element: ElementRef<HTMLInputElement>,
    @Inject(LOCALE_ID) private locale: string,
  ) {
    const [thousandSeparator, decimalMarker] = formatNumber(1000.99, this.locale).replace(/\d/g, '');
    this.thousandSeparator = thousandSeparator;
    this.decimalMarker = decimalMarker;
  }

  private _value: BigNumber | null;

  get value(): BigNumber | null {
    return this._value;
  }

  @Input()
  set value(value: BigNumber | null) {
    this.writeValue(value);
  }

  @HostListener('input', ['$event.target.value'])
  input(value: string) {
    if (isEmpty(value)) {
      this.writeValue(null);
      // @ts-expect-error TS2345
      this._onChange(this._value);
      return;
    }
    if (isNumber(value)) {
      value = `${value}`;
    }
    //Find all numerics, decimal marker(, or .) and -
    //It will delete thousandSeparator cos it's always opposite to decimal marker
    const regExp = new RegExp(`[^\\d${this.decimalMarker}-]`, 'g');
    //Separate value on before and after decimal marker
    const [integer, decimal] = value.replace(regExp, '').split(this.decimalMarker);

    let newValue: string = integer;
    if (!this.numericIntegerOnly && !isNil(decimal)) {
      //Send non localized value, with dot as decimalMarker to API
      // newValue = integer.concat('.', decimal);
      newValue += `.${decimal}`;
    }
    this._value = new BigNumber(newValue);

    // here to notify Angular Validators
    this._onChange(this._value);
  }

  @HostListener('blur')
  _onBlur() {
    /**
     * Adding thousand separators
     */
    this.formatValue(this._value);
  }

  @HostListener('focus')
  onFocus() {
    this.unFormatValue();
  }

  _onChange: (value: BigNumber) => void = () => null;

  writeValue(value: BigNumber | string | null) {
    if (isEmpty(value)) {
      this._value = null;
    } else if (value instanceof BigNumber && isNaN(value.toNumber())) {
      this._value = null;
    } else if (value instanceof BigNumber) {
      this._value = value;
    } else {
      // @ts-expect-error TS2345
      this._value = new BigNumber(value);
    }
    this.formatValue(this._value);
  }

  registerOnChange(fn: (value: BigNumber) => void) {
    this._onChange = fn;
  }

  registerOnTouched() {
    return;
  }

  isLastCharacterDecimalSeparator(value: BigNumber) {
    const strValue = value.toString();
    return isNaN(toNumber(strValue[strValue.length - 1]));
  }

  private formatValue(value: BigNumber | null) {
    if (isEmpty(value) || isNaN(value.toNumber())) {
      // @ts-expect-error TS2322
      this.element.nativeElement.value = null;
      return;
    }

    if (this.isLastCharacterDecimalSeparator(value)) {
      this.element.nativeElement.value = value.toString();
      return;
    }

    //Here value should always come with . as decimal marker thus any other behavior is bug
    const [integer, decimal] = value.toString().split('.');

    //Group every three elements, and add thousandSeparator after them
    this.element.nativeElement.value = integer.replace(/\B(?=(\d{3})+(?!\d))/g, this.thousandSeparator);

    //Add decimals and decimalMarker if any
    if (decimal) {
      this.element.nativeElement.value = this.element.nativeElement.value.concat(this.decimalMarker, decimal);
    }
  }

  private unFormatValue() {
    const value = this.element.nativeElement.value;
    const regExp = new RegExp(`[^\\d${this.decimalMarker}-]`, 'g');
    const [integer, decimal] = value.replace(regExp, '').split(this.decimalMarker);
    let newValue = integer;
    if (!isNil(decimal)) {
      newValue += `.${decimal}`;
    }
    const bnValue = new BigNumber(newValue);
    if (this.isLastCharacterDecimalSeparator(bnValue)) {
      return;
    }
    this._value = bnValue;
    {
      const [integer, decimal] = bnValue.toString().split('.');
      let newValue = integer;
      if (!isNil(decimal)) {
        newValue += `${this.decimalMarker}${decimal}`;
      }
      this.element.nativeElement.value = newValue;
    }
  }
}
