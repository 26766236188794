import { createAction, props, union } from '@ngrx/store';

import { MeetingParticipant } from '../../models/meeting-participant.model';

const prefix = '[MeetingParticipants]' as const;

export const meetingParticipantsActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: MeetingParticipant[] }>()),
  manyDeleted: createAction(prefix + ' manyDeleted', props<{ payload: string[] }>()),
  shareholdersDeleted: createAction(prefix + ' shareholdersDeleted', props<{ payload: string[] }>()),
};

const all = union(meetingParticipantsActions);

export type ActionsUnion = typeof all;
