export enum PaymentStatus {
  WaitingForPayment,
  Paid,
  NotPaid,
}

export enum PaymentKind {
  Cash,
  Offset,
  NonCash,
}
