import { GqlCreateIdentityInput } from '../graphql/operations';
import { Company } from '../models/company.model';
import { Identity, isSwedish, IDKind, JuridicalKind } from '../models/identity.model';

export function identityToInput(
  identity:
    | Pick<
        Identity,
        | 'id'
        | 'name'
        | 'juridicalKind'
        | 'identityNumber'
        | 'identityNumberKind'
        | 'address'
        | 'countryCode'
        | 'email'
        | 'phone'
      >
    | GqlCreateIdentityInput,
  email?: string | null | undefined,
  phone?: string | null | undefined,
): GqlCreateIdentityInput {
  return {
    id: identity.id,
    name: identity.name,
    juridicalKind: identity.juridicalKind,
    identityNumber: identity.identityNumber,
    identityNumberKind: identity.identityNumberKind,
    address: identity.address,
    countryCode: identity.countryCode ?? (isSwedish(identity) ? 'SE' : null),
    email: email || identity.email,
    phone: phone || identity.phone,
  };
}

export function companyToIdentityInput(
  company: Pick<Company, 'organizationNumber' | 'name' | 'email'>,
): GqlCreateIdentityInput {
  return {
    identityNumber: company.organizationNumber,
    identityNumberKind: company.organizationNumber.startsWith('0') ? IDKind.ForeignManual : IDKind.SwedishOrgNumber,
    juridicalKind: JuridicalKind.Company,
    name: company.name,
    email: company.email,
  };
}
