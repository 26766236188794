import { Transform } from 'class-transformer';
import { DateTime } from 'luxon';
import { isNil } from 'lodash-es';

import { AuthMethod } from '@startuptools/common/common';
import { GqlUser } from '../graphql/operations';

export class User implements Omit<GqlUser, 'identities' | 'companyUsers' | 'companyInvites'> {
  id: string;
  admin: boolean;
  personalId?: string;
  name: string;
  jwtEpoch: number;
  signInCount: number;
  unconfirmedEmail: string;
  email: string;
  emailMd5?: string;
  phone?: string;
  companySuggestionsSyncedDate?: string;
  optionsInShPortal: boolean;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  lastSignInAt: DateTime;
  communicationLanguage: string;

  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  readonly createdAt: DateTime;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  readonly updatedAt: DateTime;

  // Temporary and only used in Angular components
  get authMethod(): AuthMethod {
    if (!isNil(this.personalId)) {
      return AuthMethod.BankId;
    }
    return AuthMethod.Password;
  }

  constructor(seed: User) {
    Object.assign(this, seed);
  }
}
