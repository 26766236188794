import { Trans } from '@lingui/react';
import {
  Stack,
  HourglassBottomIcon,
  PendingActionsIcon,
  DoneAllIcon,
  CancelPresentationIcon,
  AlarmOffIcon,
  ThumbDownOffAltIcon,
  ErrorOutlineIcon,
  PendingIcon,
  Typography,
  HourglassEmptyIcon,
  EditIcon,
} from '@startuptools/ui';
import {
  GqlDocumentSignatureMethod,
  GqlScriveDocumentStatus,
  GqlZignedAgreementStatus,
} from '../../graphql/base-types.graphql';
import { ReactNode } from 'react';

const scriveStatusIcons: Record<GqlScriveDocumentStatus | 'none', ReactNode> = {
  [GqlScriveDocumentStatus.Preparation]: <HourglassBottomIcon color="primary" />,
  [GqlScriveDocumentStatus.Pending]: <PendingActionsIcon color="primary" />,
  [GqlScriveDocumentStatus.Closed]: <DoneAllIcon color="success" />,
  [GqlScriveDocumentStatus.Canceled]: <CancelPresentationIcon color="error" />,
  [GqlScriveDocumentStatus.Timedout]: <AlarmOffIcon color="error" />,
  [GqlScriveDocumentStatus.Rejected]: <ThumbDownOffAltIcon color="error" />,
  [GqlScriveDocumentStatus.DocumentError]: <ErrorOutlineIcon color="error" />,
  none: <PendingIcon color="warning" />,
};

const scriveSignatureStatusHuman = (input: string) => {
  switch (input) {
    case GqlScriveDocumentStatus.Preparation:
      return <Trans id="preparation" />;
    case GqlScriveDocumentStatus.Pending:
      return <Trans id="pending" />;
    case GqlScriveDocumentStatus.Closed:
      return <Trans id="signed" />;
    case GqlScriveDocumentStatus.Canceled:
      return <Trans id="canceled" />;
    case GqlScriveDocumentStatus.Timedout:
      return <Trans id="not signed in time" />;
    case GqlScriveDocumentStatus.Rejected:
      return <Trans id="rejected by a party" />;
    case GqlScriveDocumentStatus.DocumentError:
      return <Trans id="document error" />;
    case 'none':
      return <Trans id="Not sent" />;
    default:
      throw new Error(`[ScriveSignatureStatusHuman pipe] missing human readable string scrive status: ${input}`);
  }
};

const zignedStatusIcon: Record<GqlZignedAgreementStatus | 'none', ReactNode> = {
  [GqlZignedAgreementStatus.Open]: <EditIcon color="primary" />,
  [GqlZignedAgreementStatus.Draft]: <HourglassEmptyIcon color="primary" />,
  [GqlZignedAgreementStatus.Pending]: <PendingActionsIcon color="primary" />,
  [GqlZignedAgreementStatus.Cancelled]: <CancelPresentationIcon color="error" />,
  [GqlZignedAgreementStatus.Fulfilled]: <DoneAllIcon color="success" />,
  [GqlZignedAgreementStatus.FailedToGenerate]: <ErrorOutlineIcon color="error" />,
  [GqlZignedAgreementStatus.Generating]: <HourglassBottomIcon color="error" />,
  none: <PendingIcon color="warning" />,
};

const zignedSignatureStatusHuman = (input: GqlZignedAgreementStatus | 'none') => {
  switch (input) {
    case GqlZignedAgreementStatus.Draft:
      return <Trans id="draft" />;
    case GqlZignedAgreementStatus.Open:
      return <Trans id="open" />;
    case GqlZignedAgreementStatus.Fulfilled:
      return <Trans id="signed" />;
    case GqlZignedAgreementStatus.Generating:
      return <Trans id="creating signed document" />;
    case GqlZignedAgreementStatus.FailedToGenerate:
      return <Trans id="error" />;
    case GqlZignedAgreementStatus.Cancelled:
      return <Trans id="cancelled" />;
    case GqlZignedAgreementStatus.Pending:
      return <Trans id="pending" />;
    case 'none':
      return <Trans id="Not sent" />;
    default:
      throw new Error(`[ScriveSignatureStatusHuman pipe] missing human readable string scrive status: ${input}`);
  }
};
const ScriveDocumentSignatureStatus = ({
  status = 'none',
  signatureMethod = GqlDocumentSignatureMethod.Digital,
}: {
  status: GqlScriveDocumentStatus | 'none';
  signatureMethod?: GqlDocumentSignatureMethod | null | undefined;
}) => {
  const icon =
    signatureMethod === GqlDocumentSignatureMethod.Digital ? (
      scriveStatusIcons[status]
    ) : (
      <DoneAllIcon color="success" />
    );

  return (
    <Stack direction="row" gap={1} alignItems="center">
      {icon}
      <Typography level="body-xs">
        {signatureMethod === GqlDocumentSignatureMethod.Digital ? (
          scriveSignatureStatusHuman(status)
        ) : (
          <Trans id="No digital signatures" />
        )}
      </Typography>
    </Stack>
  );
};

const ZignedDocumentSignatureStatus = ({
  status = 'none',
  signatureMethod = GqlDocumentSignatureMethod.Digital,
}: {
  status: GqlZignedAgreementStatus | 'none';
  signatureMethod: GqlDocumentSignatureMethod | null | undefined;
}) => {
  const icon =
    signatureMethod === GqlDocumentSignatureMethod.Digital ? zignedStatusIcon[status] : <DoneAllIcon color="success" />;

  return (
    <Stack direction="row" gap={1} alignItems="center">
      {icon}
      <Typography level="body-xs">
        {signatureMethod === GqlDocumentSignatureMethod.Digital ? (
          zignedSignatureStatusHuman(status)
        ) : (
          <Trans id="No digital signatures" />
        )}
      </Typography>
    </Stack>
  );
};

export const DocumentSignatureStatus = ({
  scriveStatus,
  zignedAgreementStatus,
  signatureMethod,
}: {
  scriveStatus: GqlScriveDocumentStatus | null | undefined;
  zignedAgreementStatus: GqlZignedAgreementStatus | null | undefined;
  signatureMethod: GqlDocumentSignatureMethod | null | undefined;
}) => {
  if (scriveStatus) {
    return <ScriveDocumentSignatureStatus status={scriveStatus} signatureMethod={signatureMethod} />;
  }
  if (zignedAgreementStatus) {
    return <ZignedDocumentSignatureStatus status={zignedAgreementStatus} signatureMethod={signatureMethod} />;
  }
  return null;
};
