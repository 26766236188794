import { createReducer, on, Action } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { map } from 'lodash-es';

import { User } from '../../models/user.model';
import { usersActions } from '../actions/users.actions';

export interface UsersState extends EntityState<User> {
  currentUserId: string;
}

export const adapter = createEntityAdapter<User>();
export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(usersActions.manyLoaded, (state, { payload }) => {
    return adapter.addMany(
      payload,
      adapter.removeMany(
        map(payload, p => p.id),
        state,
      ),
    );
  }),
  on(usersActions.setCurrentId, (state, { payload }) => ({
    ...state,
    currentUserId: payload,
  })),
);

export function usersReducer(state: EntityState<User> | undefined, action: Action) {
  return reducer(state, action);
}
