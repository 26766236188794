import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isNumber, isNil } from 'lodash-es';

interface ColumnProperties {
  label: string;
  alignRight?: boolean;
  width?: string;
}

export interface TableData<T> {
  columnProperties: { [k in keyof T]: ColumnProperties };
  dataSource: T[];
  clickable?: {
    onClick: (row: T) => unknown;
  };
}

export interface Data<T> {
  title: string;
  text: string;
  ok: string;
  tableData?: TableData<T>;
}

@Component({
  templateUrl: './table-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableDialogComponent<T> {
  isNumber = isNumber;
  displayedColumns: string[] = !isNil(this.data.tableData?.columnProperties)
    ? Object.keys(this.data.tableData.columnProperties)
    : [];
  columnProperties: Record<string, ColumnProperties> = this.data.tableData?.columnProperties || {};
  dataSource = this.data.tableData?.dataSource || [];
  clickable = this.data.tableData?.clickable || false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Data<T>,
    public dialogRef: MatDialogRef<TableDialogComponent<T>>,
  ) {}

  static open<T>(dialog: MatDialog, data: Data<T>) {
    return dialog.open<TableDialogComponent<T>, Data<T>>(TableDialogComponent, {
      minWidth: 600,
      disableClose: true,
      data,
    });
  }

  onClick(row: T) {
    // @ts-expect-error TS2532
    this.data.tableData.clickable.onClick(row);
    this.dialogRef.close();
  }
}
