import { createAction, props, union } from '@ngrx/store';

import { ArticlesOfAssoc } from '../../models/articles-of-assoc.model';

const prefix = '[ArticlesOfAssocs]' as const;

export const articlesOfAssocsActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: ArticlesOfAssoc[] }>()),
  manyDeleted: createAction(prefix + ' manyDeleted', props<{ payload: string[] }>()),
};

const all = union(articlesOfAssocsActions);

export type ArticlesOfAssocsActionsUnion = typeof all;
