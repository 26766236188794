import { GqlShareIssueBoardMember } from '../graphql/operations';
import { Identity } from './identity.model';

export interface SibmWithIncludes {
  model: ShareIssueBoardMember;
  identity: Identity;
  proxy?: Identity;
}

export class ShareIssueBoardMember implements Omit<GqlShareIssueBoardMember, 'identity' | 'shareIssue'> {
  id: string;

  shareIssueId: string;
  identityId: string;

  readonly createdAt: string;
  readonly updatedAt: string;

  constructor(seed?: Partial<ShareIssueBoardMember>) {
    Object.assign(this, seed);
  }
}
