import { Pipe, PipeTransform } from '@angular/core';
import { isString } from 'lodash-es';

export function formatIdNumber(input: string, mask = false) {
  if (!isString(input)) {
    return;
  }
  if (input === '0000000000') {
    return;
  }
  // dash should be before the last 4 numbers
  if (input.length === 15) {
    // Sole proprietaryship (enskild firma)
    const bPoint = input.length - 9;
    return `${input.slice(0, bPoint)}-${input.slice(bPoint, bPoint + 4)}-${input.slice(10)}`;
  } else if (input.length === 12 || input.length === 10) {
    const bPoint = input.length - 4;
    return `${input.slice(0, bPoint)}-${input.length === 12 && mask ? 'XXXX' : input.slice(bPoint)}`;
  }
}

@Pipe({ name: 'idNumber' })
export class IdNumberPipe implements PipeTransform {
  transform(input: string, mask = false) {
    return formatIdNumber(input, mask);
  }
}
