import { Directive, ElementRef, Optional, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[bankCodeValidator]', // eslint-disable-line @angular-eslint/directive-selector
})
export class BankCodeValidatorDirective {
  constructor(
    private eRef: ElementRef,
    @Optional() private control: NgControl,
  ) {}

  @HostListener('input', ['$event']) onInputChange() {
    const input: string = this.eRef.nativeElement.value;
    const output: string = input.replace(/[^0-9- ]*/g, '');
    // @ts-expect-error TS2531
    this.control.control.setValue(output);
  }
}
