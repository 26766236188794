import { GqlOptionsSharesSubscriberRepresentative } from '../graphql/operations';
import { Identity } from './identity.model';
import { OptionsSharesSubscriberId } from './options-shares-subscriber.model';

export type OptionsSharesSubscriberRepresentativeId = string;

export class OptionsSharesSubscriberRepresentative
  implements Omit<GqlOptionsSharesSubscriberRepresentative, 'identity' | 'optionsSharesSubscriber'>
{
  id: OptionsSharesSubscriberRepresentativeId;
  optionsSharesSubscriberId: OptionsSharesSubscriberId;
  identityId: string;
  createdAt: string;
  updatedAt: string;

  constructor(seed?: Partial<OptionsSharesSubscriberRepresentative>) {
    Object.assign(this, seed);
  }
}

export interface OptionsSharesSubscriberRepresentativeWithIncludes {
  model: OptionsSharesSubscriberRepresentative;
  identity: Identity;
}
