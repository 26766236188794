import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import { map } from 'lodash-es';

import { AuthorizationGroup } from '../../models/authorization-group.model';
import { authorizationGroupActions } from '../../store/actions/authorization-groups.actions';

export type AuthorizationGroupsState = EntityState<AuthorizationGroup>;

export const adapter = createEntityAdapter<AuthorizationGroup>();
export const initialAuthorizationGroupsState = adapter.getInitialState({});

export const reducer = createReducer(
  initialAuthorizationGroupsState,
  on(authorizationGroupActions.manyLoaded, (state, { payload }) =>
    adapter.addMany(
      payload,
      adapter.removeMany(
        map(payload, e => e.id),
        state,
      ),
    ),
  ),
  on(authorizationGroupActions.manyDeleted, (state, { payload }) => adapter.removeMany(payload, state)),
);

export function authorizationGroupsReducer(state: EntityState<AuthorizationGroup> | undefined, action: Action) {
  return reducer(state, action);
}
