import { createAction, props, union } from '@ngrx/store';

import { SnrBond } from '../../models/snr-bond.model';

const prefix = '[SnrBonds]' as const;

export const snrBondsActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: SnrBond[] }>()),
};

export const all = union(snrBondsActions);
