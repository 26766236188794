import { Trans } from '@lingui/react';
import { ReactNode } from 'react';
import { isNil } from 'lodash-es';
import { DateTime } from 'luxon';
import {
  Tooltip,
  Stack,
  LabeledText,
  EmailIcon,
  ErrorIcon,
  DoneAllIcon,
  ThumbDownOffAltIcon,
  RemoveRedEyeIcon,
  DraftsIcon,
  MarkEmailReadIcon,
  Typography,
  HourglassTopIcon,
  HourglassEmptyIcon,
} from '@startuptools/ui';
import { GqlDocumentSignaturesQuery } from './DocumentSignatures.graphql';
import { ArrayElement } from '@startuptools/common/common';
import { GqlZignedParticipantStatus } from '../../graphql/base-types.graphql';

type DocumentSignature = Pick<
  ArrayElement<GqlDocumentSignaturesQuery['documentSignatures']>,
  | 'scriveRejectedTime'
  | 'scriveRejectionReason'
  | 'scriveSignTime'
  | 'scriveSeenTime'
  | 'scriveReadInvitationTime'
  | 'scriveEmailDeliveryStatus'
  | 'zignedParticipantStatus'
>;

export const DocumentSignatoryStatus = ({
  direction = 'column',
  documentSignature,
}: {
  direction?: 'row' | 'column';
  documentSignature: DocumentSignature;
}) => {
  const status = signatureStatus(documentSignature);
  if (!status) {
    return null;
  }

  const { icon, text, tooltip } = status;

  return (
    <Tooltip title={tooltip} followCursor>
      <Stack direction="row" gap={1} alignItems="center">
        {icon}
        {direction === 'column' && (
          <LabeledText label={<Trans id="Status" />}>
            <Typography level="body-xs">{text}</Typography>
          </LabeledText>
        )}
        {direction === 'row' && <Typography level="body-xs">{text}</Typography>}
      </Stack>
    </Tooltip>
  );
};

interface SignatureStatus {
  text: ReactNode;
  tooltip?: ReactNode | null;
  icon: ReactNode;
}

const signatureStatus = (ds: DocumentSignature): SignatureStatus | undefined => {
  if (!isNil(ds.zignedParticipantStatus)) {
    switch (ds.zignedParticipantStatus) {
      case GqlZignedParticipantStatus.Pending: {
        return {
          text: <Trans id="Pending" />,
          icon: <HourglassTopIcon />,
        };
      }
      case GqlZignedParticipantStatus.Rejected: {
        return {
          text: <Trans id="Rejected" />,
          icon: <ThumbDownOffAltIcon />,
        };
      }
      case GqlZignedParticipantStatus.Fulfilled: {
        return {
          text: <Trans id="Signed" />,
          icon: <DoneAllIcon />,
        };
      }
      case GqlZignedParticipantStatus.Initiated: {
        return {
          text: <Trans id="Signing..." />,
          icon: <HourglassTopIcon />,
        };
      }
      case GqlZignedParticipantStatus.Draft:
      case GqlZignedParticipantStatus.Processing: {
        return {
          text: <Trans id="Draft" />,
          icon: <HourglassEmptyIcon />,
        };
      }
    }
  }
  // Scrive statuses
  if (!isNil(ds.scriveRejectedTime)) {
    return {
      text: (
        <Trans
          id="Rejected at {time}"
          values={{ time: DateTime.fromISO(ds.scriveRejectedTime).toLocaleString(DateTime.DATETIME_SHORT) }}
        />
      ),
      tooltip: ds.scriveRejectionReason,
      icon: <ThumbDownOffAltIcon color="error" />,
    };
  }
  if (!isNil(ds.scriveSignTime)) {
    return {
      text: (
        <Trans
          id="Signed at {time}"
          values={{ time: DateTime.fromISO(ds.scriveSignTime).toLocaleString(DateTime.DATETIME_SHORT) }}
        />
      ),
      icon: <DoneAllIcon color="success" />,
    };
  }
  if (!isNil(ds.scriveSeenTime)) {
    return {
      text: (
        <Trans
          id="Read at {time}"
          values={{ time: DateTime.fromISO(ds.scriveSeenTime).toLocaleString(DateTime.DATETIME_SHORT) }}
        />
      ),
      icon: <RemoveRedEyeIcon />,
    };
  }
  if (!isNil(ds.scriveReadInvitationTime)) {
    return {
      text: (
        <Trans
          id="Read invite at {time}"
          values={{ time: DateTime.fromISO(ds.scriveReadInvitationTime).toLocaleString(DateTime.DATETIME_SHORT) }}
        />
      ),
      icon: <DraftsIcon />,
    };
  }
  if (!isNil(ds.scriveEmailDeliveryStatus)) {
    if (ds.scriveEmailDeliveryStatus === 'delivered') {
      return {
        text: <Trans id="Email delivered" />,
        icon: <MarkEmailReadIcon color="success" />,
      };
    }
    if (ds.scriveEmailDeliveryStatus === 'deferred') {
      return {
        text: <Trans id="Email deferred" />,
        tooltip: (
          <Trans id="This may have several causes, included but not limited to: mailbox is full, recipient's mail server didn't respond, or it was classified as spam" />
        ),
        icon: <ErrorIcon color="error" />,
      };
    }
    if (ds.scriveEmailDeliveryStatus === 'unknown') {
      return {
        text: <Trans id="Sending email" />,
        icon: <EmailIcon color="success" />,
      };
    }
    if (ds.scriveEmailDeliveryStatus === 'not_delivered') {
      return {
        text: <Trans id="Email delivery failed" />,
        icon: <ErrorIcon color="error" />,
      };
    }
  }
};
