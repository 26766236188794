'use client';
import { Alert, Button, CircularProgress, Stack, Typography } from '@startuptools/ui';
import { GqlAud } from '../../graphql/base-types.graphql';
import { useTruidTokenQuery } from './CompleteLogin.graphql';
import { isNil } from 'lodash-es';
import { AUTH_JWT_KEY } from '../constants';
import { Trans, useLingui } from '@lingui/react';
import { Navigate } from '../types';
import { useEffect } from 'react';

export const TruidCompleteLogin = ({
  aud,
  navigate,
  code,
  state,
  error,
}: {
  aud: GqlAud;
  navigate: Navigate;
  code: string | null | undefined;
  state: string | null | undefined;
  error: string | null | undefined;
}) => {
  if (!isNil(error)) {
    return (
      <Stack gap={2}>
        <Alert color="danger">
          <Trans id="Login failed, go back and try again" />
        </Alert>
        <Button onClick={() => navigate('/auth', { replace: true })}>
          <Trans id="Go Back" />
        </Button>
      </Stack>
    );
  }
  if (isNil(code) || isNil(state)) {
    return;
  }
  return <TruidCompleteLoginInner aud={aud} state={state} code={code} navigate={navigate} />;
};

const TruidCompleteLoginInner = ({
  aud,
  state,
  code,
  navigate,
}: {
  aud: GqlAud;
  state: string;
  code: string;
  navigate: Navigate;
}) => {
  const { i18n } = useLingui();
  const { data, loading, error } = useTruidTokenQuery({
    variables: { input: { state, code, aud } },
  });

  useEffect(() => {
    if (!error && data && !loading) {
      localStorage.setItem(AUTH_JWT_KEY, data.truidToken.jwt);
      const newUrl = `/`;
      navigate(newUrl, { replace: true }); // Make sure we don't save the init/poll state in the browser stack
      window.location.href = newUrl; // Remove when Angular is gone, this is a workaround to make sure angular router takes over from here
    }
  }, [error, loading, data, i18n.locale, navigate]);

  if (error) {
    return (
      <Stack gap={2}>
        <Alert color="danger">
          <Stack gap={2}>
            <Typography level="h4">
              <Trans id="An error accurred" />
            </Typography>
            {error.graphQLErrors.map(e => e.message).join('\n\n')}
          </Stack>
        </Alert>
        <Button size="lg" onClick={() => navigate('/auth/truid/init')}>
          <Trans id="Try again" />
        </Button>
      </Stack>
    );
  }

  if (loading || !data) {
    return (
      <Stack gap={2} justifyContent="center" alignItems="center">
        <CircularProgress size="lg" variant="soft" />
      </Stack>
    );
  }

  return null;
};
