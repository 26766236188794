import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { DateTime } from 'luxon';
import { isArray, isNil, orderBy } from 'lodash-es';

import { SnrCaseSubjectsKind } from '../../common/types';
import { AppState } from '../../store/reducers';
import { selectSnrCases } from '../../store/selectors/snr-cases.selectors';
import { SnrCase } from '../../models/snr-case.model';
import { buildUrl } from '../../helpers/utils';
import { GqlDispatchService } from '../../injectables/gql-dispatch.service';
import { GqlSnrCasesService } from '../../graphql/operations';
import { selectCompany } from '../../store/selectors/companies.base';

export interface ConnectSnrCaseDialogData {
  subjectsKinds?: SnrCaseSubjectsKind[];
  fromDate?: DateTime;
  saveFunction: (snrCase: SnrCase) => Observable<unknown>;
}

@Component({
  selector: 'app-snr-case-dialog',
  templateUrl: './connect-snr-case-dialog.component.html',
  styleUrls: ['connect-snr-case-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectSnrCaseDialogComponent implements OnDestroy {
  DateTime = DateTime;

  company$ = this.store.pipe(
    select(selectCompany),
    filter(e => !isNil(e)),
  );
  snrCases$ = this.store.pipe(select(selectSnrCases)).pipe(
    map(cases => {
      if (isArray(this.data.subjectsKinds)) {
        // @ts-expect-error TS18048
        return cases.filter(c => c.subjects.some(s => this.data.subjectsKinds.includes(s.kind as SnrCaseSubjectsKind)));
      }
      return cases;
    }),
    map(cases => {
      if (this.data.fromDate instanceof DateTime) {
        // @ts-expect-error TS18048
        return cases.filter(c => c.arrivalDate.startOf('day') > this.data.fromDate.startOf('day'));
      }
      return cases;
    }),
    map(cases => {
      // @ts-expect-error TS18048
      return orderBy(cases, [c => c.arrivalDate.startOf('day')], ['desc']);
    }),
  );
  subs = new SubSink();
  displayedColumns = ['registrationDate', 'arrivalDate', 'subjects', 'status', 'filingNumber'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConnectSnrCaseDialogData,
    public dialogRef: MatDialogRef<ConnectSnrCaseDialogComponent>,
    private store: Store<AppState>,
    private gqlDispatch: GqlDispatchService,
    private gqlSnrCasesService: GqlSnrCasesService,
  ) {
    this.company$.pipe(first()).subscribe(company => {
      this.gqlDispatch.query(this.gqlSnrCasesService, {
        companyId: company.id,
      });
    });
  }

  static open(dialog: MatDialog, data: ConnectSnrCaseDialogData, config: MatDialogConfig = {}) {
    return dialog.open<ConnectSnrCaseDialogComponent, ConnectSnrCaseDialogData>(ConnectSnrCaseDialogComponent, {
      minWidth: 300,
      disableClose: true,
      data: data,
      ...config,
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  save(row: SnrCase) {
    this.data.saveFunction(row).subscribe(() => {
      this.dialogRef.close(row);
    });
  }

  getLink(snrCase: SnrCase) {
    return buildUrl(
      'https://sokarende.bolagsverket.se/mia/visaarende.xhtml',
      '',
      {},
      { artal: snrCase.filingYear, id: snrCase.filingNumber },
    );
  }
}
