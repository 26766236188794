import { GqlOptionsSubscriberRepresentative } from '../graphql/operations';
import { Identity } from './identity.model';

export type OptionsSubscriberRepresentativeId = string;

export class OptionsSubscriberRepresentative
  implements Omit<GqlOptionsSubscriberRepresentative, 'identity' | 'warrantParticipant'>
{
  id: OptionsSubscriberRepresentativeId;
  warrantParticipantId: string;
  identityId: string;
  createdAt: string;
  updatedAt: string;

  constructor(seed?: Partial<OptionsSubscriberRepresentative>) {
    Object.assign(this, seed);
  }
}

export interface OptionsSubscriberRepresentativeWithIncludes {
  model: OptionsSubscriberRepresentative;
  identity: Identity;
}
