import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { interval, of, Subscription } from 'rxjs';
import { concatMap, first, withLatestFrom } from 'rxjs/operators';
import { compact, isNil } from 'lodash-es';
import { AppState } from '../store/reducers';
import { selectDocumentsPendingGen } from '../store/selectors/documents.selectors';
import { GqlDocumentsByIdsService } from '../graphql/operations';
import { GqlDispatchService } from './gql-dispatch.service';
import { selectCompany } from '../store/selectors/companies.base';

const INTERVAL = 10000;

/**
 * This will periodically poll server for object that should be asynchronically updated, but sometimes aren't
 */
@Injectable({ providedIn: 'root' })
export class AsyncPollService {
  private docPoll: Subscription;
  private company$ = this.store.pipe(select(selectCompany));

  constructor(
    private store: Store<AppState>,
    private gqlDispatch: GqlDispatchService,
    private gqlDocumentsByIds: GqlDocumentsByIdsService,
  ) {}

  startPoll() {
    // Poll generating documents
    if (isNil(this.docPoll)) {
      this.docPoll = interval(INTERVAL)
        .pipe(
          concatMap(() => this.store.pipe(select(selectDocumentsPendingGen), first())),
          withLatestFrom(this.company$),
          concatMap(([docs, company]) => {
            if (docs.length > 0 && !isNil(company)) {
              const ids = compact(docs.slice(0, 25).map(d => d?.id));
              return this.gqlDispatch.query(this.gqlDocumentsByIds, {
                companyId: company.id,
                ids,
              });
            }
            return of(null);
          }),
        )
        .subscribe();
    }
  }

  stopPoll() {
    this.docPoll?.unsubscribe();
  }
}
