import { Trans } from '@lingui/react';
import { ErrorBoundary } from '@startuptools/ui';
import { Alert, Button as MuiButton } from '@startuptools/ui';
import React, { ComponentProps } from 'react';
import { PDFPreview } from './PDFPreview';

export const PDFButton = ({
  button: Button = MuiButton,
  label,
  generatePdf,
  allowBiLingual = true,
}: {
  generatePdf: (language: {
    primaryLang: boolean;
    secondaryLang: boolean;
  }) => Promise<{ filename: string; blob: Blob }>;
  button?: (
    props: Omit<ComponentProps<typeof MuiButton>, 'children'> & { label: string; children: string },
  ) => React.ReactNode;
  label: string;
  allowBiLingual?: boolean;
}) => {
  const [show, setShow] = React.useState(false);
  return (
    <ErrorBoundary
      showModal
      fallback={() => (
        <Alert color="warning">
          <Trans id="Something went wrong while trying to generate your PDF. Try again, or contact support." />
        </Alert>
      )}
    >
      <Button
        onClick={async () => {
          setShow(true);
        }}
        label={label}
      >
        {label}
      </Button>
      {show && (
        <PDFPreview
          generatePdf={generatePdf}
          mode="generate"
          chooseLanguage
          allowBiLingual={allowBiLingual}
          onClose={() => {
            setShow(false);
          }}
        />
      )}
    </ErrorBoundary>
  );
};
