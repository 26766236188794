import { GqlUser, useUserHubspotIdentityTokenQuery } from '../../graphql/react-operations';
import { hubspotPortalId } from '../../../environments/environment';
import { useHubspotChat } from './use-hook';

const HOUR = 1000 * 60 * 60;

export const Hubspot = ({ user, jwtIsValid }: { user: GqlUser | undefined; jwtIsValid: boolean }) => {
  // A token is valid for 12 hours, let's refresh it every 6h.
  const { data, loading } = useUserHubspotIdentityTokenQuery({
    pollInterval: 6 * HOUR,
    skip: !jwtIsValid,
    fetchPolicy: 'no-cache',
  });
  if (loading) {
    return;
  }
  const token = data?.userHubspotIdentityToken;
  if (token && !user) {
    // if we got a token, then a user should also exist, so let's wait for that to become available before rendering
    return;
  }
  return <HubspotInner user={user} token={token} />;
};

const HubspotInner = ({ user, token }: { user: GqlUser | undefined; token: string | null | undefined }) => {
  useHubspotChat({ portalId: hubspotPortalId, user, token });
  return null;
};
