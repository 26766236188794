import { ShWithIncludes } from './shareholder.model';
import { Identity } from './identity.model';
import { GqlWarrantShareholder } from '../graphql/operations';

export interface WshWithIncludes {
  model: WarrantShareholder;
  shareholder: ShWithIncludes;
  proxy?: Identity;
}

export class WarrantShareholder implements Omit<GqlWarrantShareholder, 'warrantProgram' | 'shareholder'> {
  id: string;

  numberOfShares: number;
  warrantProgramId: string;
  shareholderId: string;
  proxyId?: string;

  readonly createdAt: string;
  readonly updatedAt: string;

  constructor(seed?: Partial<WarrantShareholder>) {
    Object.assign(this, seed);
  }
}
