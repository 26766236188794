import React from 'react';

export const useOnce = (handler: () => void | (() => void)) => {
  const done = React.useRef(false);

  React.useEffect(() => {
    if (done.current) {
      return;
    }
    done.current = true;
    return handler();
  }, [handler]);
};
