import { isNil, isString } from 'lodash-es';

export function jsonParse(input: string) {
  if (!isString(input)) {
    return input;
  }
  return !isNil(input) ? JSON.parse(input) : null;
}

export function jsonStringify(input: unknown) {
  if (isString(input)) {
    return input;
  }
  return !isNil(input) ? JSON.stringify(input) : null;
}
