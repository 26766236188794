import { find, isNil, isObject } from 'lodash-es';
import { GqlValidatorStatus, GqlSnrShareCapitalChange } from '../../graphql/operations';
import { stripeProducts } from '../../../environments/environment';
import { GqlActiveCompanySubQuery } from '../../graphql/react-operations';
import { GqlMeetingKind } from '../../graphql/base-types.graphql';

type ValidatorErrorsType = Record<string, GqlValidatorStatus>;

export const hasValidatorErrors = (errs: ValidatorErrorsType) => {
  return validatorErrors(errs).some(e => {
    return isObject(e) && (!e.completed || e.hasError);
  });
};

export const validatorErrors = (errs: ValidatorErrorsType) => {
  return Object.values(errs).filter(isObject);
};

export const checkNeedsPayment = (
  activeSub: GqlActiveCompanySubQuery['activeCompanySubscription'] | null,
  allMcs: { paidForAt?: unknown }[],
) => {
  // if everything is set as paid, we're all good
  if (allMcs.length > 0 && allMcs.every(e => !isNil(e.paidForAt))) {
    return false;
  }
  // if there's no plan, they need to pay
  if (isNil(activeSub?.activeProduct)) {
    return true;
  }
  // if the plan is grow and there are meeting concerns, they need to pay for those
  const hasGrow = activeSub.activeProduct.id === stripeProducts.grow;
  if (allMcs.length > 0 && hasGrow) {
    return true;
  }
  // pro (and butler) is all inclusive
  const hasProOrButler =
    activeSub.activeProduct.id === stripeProducts.pro || activeSub.activeProduct.id === stripeProducts.proButler;
  if (hasGrow || hasProOrButler) {
    return false;
  }
  return true;
};

export const getHasFoundation = (sccs: Pick<GqlSnrShareCapitalChange, 'name' | 'sharesChange'>[]): boolean => {
  const foundation = find(sccs, scc => scc.name === 'NYBILDNING');
  if (isNil(foundation)) {
    return false;
  }
  return !isNil(foundation.sharesChange);
};

export const meetingKindHuman = (input: GqlMeetingKind) => {
  switch (input) {
    case GqlMeetingKind.ShareholdersMeeting:
      return $localize`Ordinarie bolagsstämma`;
    case GqlMeetingKind.ExtraShareholdersMeeting:
      return $localize`Extra bolagsstämma`;
    case GqlMeetingKind.BoardMeeting:
      return $localize`Styrelsemöte`;
    default:
      console.warn(`[MeetingKindHumanPipe] missing human readable string for: ${input}`);
  }
};
