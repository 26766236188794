import { createReducer, on, Action } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { map } from 'lodash-es';

import { WarrantProgramParticipant } from '../../models/warrant-program-participant.model';
import { warrantParticipantsActions } from '../actions/warrant-participants.actions';

export type WarrantProgramParticipantsState = EntityState<WarrantProgramParticipant>;

export const adapter = createEntityAdapter<WarrantProgramParticipant>();
export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(warrantParticipantsActions.manyLoaded, (state, { payload }) => {
    return adapter.addMany(
      payload,
      adapter.removeMany(
        map(payload, p => p.id),
        state,
      ),
    );
  }),
  on(warrantParticipantsActions.manyDeleted, (state, { payload }) => adapter.removeMany(payload, state)),
);

export function warrantParticipantsReducer(state: EntityState<WarrantProgramParticipant> | undefined, action: Action) {
  return reducer(state, action);
}
