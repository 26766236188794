import { createAction, props, union } from '@ngrx/store';

import { WarrantBoardMember } from '../../models/warrant-board-member.model';

const prefix = '[WarrantBoardMembers]' as const;

export const warrantBoardMembersActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: WarrantBoardMember[] }>()),
  manyDeleted: createAction(prefix + ' manyDeleted', props<{ payload: string[] }>()),
};

const all = union(warrantBoardMembersActions);

export type ActionsUnion = typeof all;
