import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import { map } from 'lodash-es';

import { CompanyDirector } from '../../models/company-director.model';
import { companyDirectorsActions } from '../actions/company-directors.actions';

export type CompanyDirectorsState = EntityState<CompanyDirector>;

export const adapter = createEntityAdapter<CompanyDirector>();
export const initialCompanyDirectorsState = adapter.getInitialState({});

const reducer = createReducer(
  initialCompanyDirectorsState,
  on(companyDirectorsActions.manyLoaded, (state, { payload }) =>
    adapter.addMany(
      payload,
      adapter.removeMany(
        map(payload, p => p.id),
        state,
      ),
    ),
  ),
);

export function companyDirectorsReducer(state: EntityState<CompanyDirector> | undefined, action: Action) {
  return reducer(state, action);
}
