import { Router } from '@angular/router';
import { useCompanyId } from '@startuptools/common/react-wrapper';
import { compact } from 'lodash-es';
import { PropsWithChildren, createContext, useContext } from 'react';

const Context = createContext<{ router: Router; pathName: string } | null>(null);

interface Props {
  ngRouter: Router;
  pathName: string;
}

export const NgRouterProvider = ({ ngRouter, pathName, children }: PropsWithChildren<Props>) => {
  return <Context.Provider value={{ router: ngRouter, pathName }}>{children}</Context.Provider>;
};

export const useNgRouter = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('ngRouter must be used within a NgRouterProvider');
  }
  const { router, pathName } = context;
  const companyId = useCompanyId();

  const rootPath = `/company/${companyId}`;
  const ngNavigateByUrl = (to: string) => {
    let ngRoute = compact(to.split('/'));
    if (to.startsWith('/')) {
      ngRoute = [rootPath, ...ngRoute];
    }
    return router.navigate(ngRoute);
  };

  const changeCompany = (companyId: string) => {
    const route = ['/change-company', companyId];
    return router.navigate(route);
  };

  return {
    ngNavigateByUrl,
    changeCompany,
    pathName,
    rootPath,
    isActive: (path: string) => {
      const fullPath = `${rootPath}${path}`;
      return router.isActive(fullPath, {
        paths: 'subset',
        queryParams: 'exact',
        fragment: 'ignored',
        matrixParams: 'ignored',
      });
    },
  };
};
