import { Trans } from '@lingui/react';
import { Stepper, Step as StepUi, StepIndicator, CheckIcon, Stack, Card } from '@startuptools/ui';
import { ComponentProps, useReducer } from 'react';
import { CreateCompanyStep1 } from './CreateCompanyStep1';
import { CreateCompanyStep2 } from './CreateCompanyStep2';
import { CreateCompanyStep3 } from './CreateCompanyStep3';

type Step1 = {
  activeStep: 0;
};

type Step2 = {
  activeStep: 1;
  selectedCompany: { orgNumber: string; name: string; isCompanyDirector: boolean };
};

type Step3 = {
  activeStep: 2;
  createdCompany: { id: string; name: string };
};

type State = Step1 | Step2 | Step3;

type ResetAction = { type: 'RESET' };
type SelectedCompanyAction = {
  type: 'SELECTED_COMPANY';
  payload: { orgNumber: string; name: string; isCompanyDirector: boolean };
};
type CreatedCompanyAction = { type: 'CREATED_COMPANY'; payload: { id: string; name: string } };

type Action = ResetAction | SelectedCompanyAction | CreatedCompanyAction;

const reducer = (_: State, action: Action): State => {
  switch (action.type) {
    case 'RESET': {
      return { activeStep: 0 };
    }
    case 'SELECTED_COMPANY': {
      return { activeStep: 1, selectedCompany: action.payload };
    }
    case 'CREATED_COMPANY': {
      return { activeStep: 2, createdCompany: action.payload };
    }
  }
};

const Step = ({
  activeStep,
  step,
  children,
  immediateSuccess = false,
  ...props
}: Omit<ComponentProps<typeof StepUi>, 'indicator'> & {
  step: number;
  activeStep: number;
  immediateSuccess?: boolean;
}) => {
  return (
    <StepUi
      {...props}
      indicator={
        <StepIndicator
          variant="solid"
          color={activeStep === step && !immediateSuccess ? 'primary' : activeStep >= step ? 'success' : 'neutral'}
        >
          {activeStep > step ? <CheckIcon /> : step + 1}
        </StepIndicator>
      }
    >
      {step === activeStep && children}
    </StepUi>
  );
};

export const CreateCompanyStepper = ({
  onClose,
  navigateToCompany,
}: {
  onClose?: () => void;
  navigateToCompany: (id: string) => Promise<unknown>;
}) => {
  const [state, dispatch] = useReducer(reducer, { activeStep: 0 });

  return (
    <Stack gap={4}>
      <Card>
        <Stepper size="lg">
          <Step step={0} activeStep={state.activeStep}>
            <Trans id="Create company" />
          </Step>
          <Step step={1} activeStep={state.activeStep}>
            <Trans id="Terms of Service" />
          </Step>
          <Step step={2} activeStep={state.activeStep} immediateSuccess>
            <Trans id="Completed!" />
          </Step>
        </Stepper>
      </Card>

      {state.activeStep === 0 && (
        <CreateCompanyStep1
          onSelect={selectedCompany => {
            dispatch({ type: 'SELECTED_COMPANY', payload: selectedCompany });
          }}
        />
      )}
      {state.activeStep === 1 && (
        <CreateCompanyStep2
          company={state.selectedCompany}
          onBack={() => dispatch({ type: 'RESET' })}
          onSuccess={company => {
            dispatch({ type: 'CREATED_COMPANY', payload: company });
          }}
        />
      )}
      {state.activeStep === 2 && (
        <CreateCompanyStep3
          name={state.createdCompany.name}
          onContinue={async () => {
            await navigateToCompany(state.createdCompany.id);
            onClose?.();
          }}
        />
      )}
    </Stack>
  );
};
