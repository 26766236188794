import { Dropdown, MenuButton, Menu, MenuItem, ListItemDecorator, IconButton } from '../';
import EnFlag from './icons/flags/en_round.svg';
import SvFlag from './icons/flags/sv_round.svg';
import { Trans } from '@lingui/react';
import { Gravatar } from './Gravatar';
import React from 'react';

export const ProfileMenu = ({
  switchLang,
  children,
  lang,
  user,
  switchLangHref,
}: {
  children?: React.ReactNode;
  switchLang?: (lang: 'en' | 'sv') => void;
  switchLangHref?: string;
  lang: 'en' | 'sv';
  user: {
    name?: string | null | undefined;
    emailMd5?: string | null | undefined;
    id: string;
  };
}) => {
  return (
    <Dropdown>
      <MenuButton slots={{ root: IconButton }}>
        <Gravatar md5Hash={user?.emailMd5} size="sm" uuid={user?.id} def={user?.name?.length ? 'mp' : '404'}>
          {user?.name?.[0]}
        </Gravatar>
      </MenuButton>
      <Menu placement="top-start">
        {lang === 'sv' ? (
          <MenuItem
            onClick={switchLang ? () => switchLang('en') : undefined}
            component={switchLangHref ? 'a' : undefined}
            href={switchLangHref}
          >
            <ListItemDecorator>
              <EnFlag />
            </ListItemDecorator>
            <Trans id="Switch to English" />
          </MenuItem>
        ) : (
          <MenuItem
            onClick={switchLang ? () => switchLang('sv') : undefined}
            component={switchLangHref ? 'a' : undefined}
            href={switchLangHref}
          >
            <ListItemDecorator>
              <SvFlag />
            </ListItemDecorator>
            <Trans id="Switch to Swedish" />
          </MenuItem>
        )}
        {children}
      </Menu>
    </Dropdown>
  );
};
