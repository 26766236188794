import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { BigNumber } from 'bignumber.js';
import { isNil, toNumber } from 'lodash-es';

export function minNumberValidator(num: number | BigNumber | string | null) {
  return function (control: AbstractControl): Record<string, boolean> | null {
    if (isNil(control.value) || isNil(num)) {
      return null;
    }
    if (control.value instanceof BigNumber) {
      return control.value.isLessThan(num) ? { minNumber: true } : null;
    }
    return toNumber(control.value) < toNumber(num) ? { minNumber: true } : null;
  };
}

@Directive({
  selector: '[minNumber]', // eslint-disable-line @angular-eslint/directive-selector
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MinNumberValidatorDirective,
      multi: true,
    },
  ],
})
export class MinNumberValidatorDirective implements Validator {
  @Input('minNumber') num: number | BigNumber | string;
  validate(control: AbstractControl): Record<string, boolean> | null {
    return minNumberValidator(this.num)(control);
  }
}
