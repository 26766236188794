import { Pipe, PipeTransform } from '@angular/core';
import { meetingKindHuman } from '../company/common/utils';
import { GqlMeetingKind } from '../graphql/base-types.graphql';

@Pipe({ name: 'meetingKindHuman' })
export class MeetingKindHumanPipe implements PipeTransform {
  transform(input: GqlMeetingKind) {
    return meetingKindHuman(input);
  }
}
