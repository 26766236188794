import { createAction, props, union } from '@ngrx/store';
import { AuthorizationGroupDocument } from '../../models/authorization-group-document.model';

const prefix = '[AuthorizationGroupDocuments]' as const;

export const authorizationGroupDocumentsActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: AuthorizationGroupDocument[] }>()),
};

const all = union(authorizationGroupDocumentsActions);

export type ActionsUnion = typeof all;
