import { GqlShareIssueDocument } from '../graphql/operations';

export class ShareIssueDocument implements Omit<GqlShareIssueDocument, 'shareIssue' | 'document'> {
  id: string;

  shareIssueId: string;
  documentId: string;

  readonly createdAt: string;
  readonly updatedAt: string;

  constructor(seed?: Partial<ShareIssueDocument>) {
    Object.assign(this, seed);
  }
}
