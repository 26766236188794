<div class="dialog" sutLoading #qLoading="Loading">
  <div class="dialog-close">
    <button mat-icon-button [mat-dialog-close]="null">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="dialog-title">
    <h4>{{ data.title }}</h4>
  </div>

  <div mat-dialog-content class="dialog-content">
    <div [innerHTML]="data.text"></div>
  </div>

  <div class="dialog-actions">
    <button type="button" class="btn-secondary" [mat-dialog-close]="null">
      {{ data.cancel }}
    </button>
    <button *ngIf="data.no" type="button" class="btn-secondary" (click)="submit(false)">
      {{ data.no }}
    </button>
    <button [class]="data.confirmBtnClass" type="button" (click)="submit(true)">
      {{ data.ok }}
    </button>
  </div>
</div>
