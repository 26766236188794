'use client';
import { ApolloError } from '@apollo/client';
import { Box, Button, IconButton, Snackbar, Stack, Typography, CloseRoundedIcon } from '@startuptools/ui';
import { useState } from 'react';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { GqlAud, usePasswordConfirmRegisterMutation } from '../graphql/react-operations';
import { Trans } from '@lingui/react';
import { AUTH_JWT_KEY } from '../constants';
import { Navigate } from '../types';
import { getRedirectUrl } from '../utils/redirect';

interface FormValues {
  token: string;
}

export const RegisterConfirm = ({ navigate, aud }: { navigate: Navigate; aud: GqlAud }) => {
  const [doConfirm, { loading }] = usePasswordConfirmRegisterMutation();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [showToast, setShowToast] = useState<boolean>(false);

  const showError = (errorMsg: string) => {
    setErrorMsg(errorMsg);
    setShowToast(true);
  };

  const handleSubmit = async (values: FormValues) => {
    const { token } = values;
    try {
      const res = await doConfirm({ variables: { token, aud } });
      localStorage.setItem(AUTH_JWT_KEY, res.data?.passwordConfirmRegister?.jwt || '');
      const newUrl = `${getRedirectUrl()}`;
      navigate(newUrl, { replace: true }); // Make sure we don't save the init/poll state in the browser stack
      window.location.href = newUrl; // Remove when Angular is gone, this is a workaround to make sure angular router takes over from here
    } catch (e) {
      if (e instanceof ApolloError) {
        showError(e.message);
      }
    }
  };

  return (
    <Box>
      <FormContainer onSuccess={handleSubmit}>
        <Stack direction="column" gap={1}>
          <Typography level="h4" paddingBottom={2}>
            <Trans id="Confirm your email" />
          </Typography>
          <Trans id="An email with a token was sent to your email, paste it below to confirm your email" />
          <TextFieldElement
            label={<Trans id="Token" />}
            name="token"
            type="text"
            autoComplete="off"
            fullWidth
            required
          />
          <Stack alignItems="end">
            <Button type="submit" variant="plain" loading={loading}>
              <Trans id="Confirm" />
            </Button>
          </Stack>
        </Stack>
      </FormContainer>
      <Snackbar
        open={showToast}
        variant="solid"
        color="danger"
        onClose={() => setShowToast(false)}
        endDecorator={
          <IconButton variant="plain" size="sm" color="neutral" onClick={() => setShowToast(false)}>
            <CloseRoundedIcon />
          </IconButton>
        }
      >
        {errorMsg}
      </Snackbar>
    </Box>
  );
};
