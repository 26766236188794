import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectShareIssueId } from '../../store/selectors/share-issues.base';
import { filter } from 'lodash-es';
import { ShareIssueDocumentsState } from '../reducers/share-issue-documents.reducer';

export const selectShareIssueDocumentsState = createFeatureSelector<ShareIssueDocumentsState>('shareIssueDocuments');

export const selectShareIssueDocumentsByShareIssueId = (shareIssueId: string) =>
  createSelector(selectShareIssueDocumentsState, state => {
    // @ts-expect-error TS18048
    return filter(state.entities, m => m.shareIssueId === shareIssueId);
  });
export const selectShareIssueDocuments = createSelector(
  selectShareIssueDocumentsState,
  selectShareIssueId,
  (state, siId) => {
    // @ts-expect-error TS18048
    return filter(state.entities, m => m.shareIssueId === siId);
  },
);
