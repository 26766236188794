import { NoSsr } from '@mui/base';
import { DateTime } from 'luxon';
import React from 'react';

function dateTimeToRelative(dateString: string | DateTime, locale: string): string | undefined {
  const date = dateString instanceof DateTime ? dateString.valueOf() : new Date(dateString).getTime();
  const now = new Date().getTime();

  const diffInSeconds = Math.floor((now - date) / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  const rtf = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' });

  if (diffInDays > 1) {
    return;
  }
  if (diffInDays > 0) {
    return rtf.format(-diffInDays, 'day');
  } else if (diffInHours > 0) {
    return rtf.format(-diffInHours, 'hour');
  } else if (diffInMinutes > 0) {
    return rtf.format(-diffInMinutes, 'minute');
  } else {
    return rtf.format(-diffInSeconds, 'second');
  }
}

export const DateFormat = ({
  children = '',
  format = DateTime.DATE_SHORT,
  locale,
  readable,
  relative,
  suffix = '',
}: {
  children: string | undefined | null | DateTime;
  format?: Intl.DateTimeFormatOptions;
  locale: string;
  readable?: boolean;
  relative?: boolean;
  suffix?: string;
}) => {
  const formatted = React.useMemo(() => {
    if (!children) {
      return null;
    }
    if (relative) {
      const formatted = dateTimeToRelative(children, locale);
      if (formatted) {
        return <NoSsr>{formatted}</NoSsr>;
      }
    }
    if (readable || relative) {
      return children instanceof DateTime
        ? children.toLocaleString(DateTime.DATE_FULL, { locale })
        : DateTime.fromISO(children).toLocaleString(DateTime.DATE_FULL, { locale });
    }
    return children instanceof DateTime
      ? children.toLocaleString(format)
      : DateTime.fromISO(children).toLocaleString(format, { locale });
  }, [children, format, locale, readable, relative]);
  return (
    <span style={{ whiteSpace: 'nowrap' }}>
      {formatted}
      {suffix}
    </span>
  );
};
