import { PaymentAccountKind } from '@startuptools/common/common';
import { Transform } from 'class-transformer';
import { isNil } from 'lodash-es';
import { DateTime } from 'luxon';

import { GqlShareIssueRegistration } from '../graphql/operations';
import { Identity } from './identity.model';
import { SnrCase } from './snr-case.model';
import { ShareIssue } from './share-issue.model';
import { SipWithIncludes } from './share-issue-participant.model';

export interface ShareIssueRegistrationWithIncludes {
  model: ShareIssueRegistration;
  contact?: Identity;
  signatory?: Identity;
  participants: SipWithIncludes[];
  snrCase?: SnrCase;
  shareIssue?: ShareIssue;
}

export interface ShareIssueRegistrationIban {
  ibanNumber?: string;
  ibanBic?: string;
  ibanBank?: string;
  ibanAddress?: string;
}

export interface ShareIssueRegistrationPayment {
  paymentAccountKind?: PaymentAccountKind;
  paymentAccountNumber?: string;
  paymentName?: string;
}

export type ShareIssueRegistrationId = string;

export class ShareIssueRegistration
  implements
    ShareIssueRegistrationIban,
    ShareIssueRegistrationPayment,
    Omit<GqlShareIssueRegistration, 'participants' | 'shareIssue'>
{
  id: ShareIssueRegistrationId;
  description: string;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  paymentDueDate: DateTime;
  paymentAccountKind?: PaymentAccountKind;
  paymentAccountNumber?: string;
  paymentName?: string;
  ibanNumber?: string;
  ibanBic?: string;
  ibanBank?: string;
  ibanAddress?: string;
  contactId?: string;
  signatoryId?: string;
  snrCaseId?: string;
  shareIssueId: string;
  regFormPaymentThroughBolago: boolean;

  get payment(): ShareIssueRegistrationPayment {
    const payment = {
      paymentName: this.paymentName,
      paymentAccountKind: this.paymentAccountKind,
      paymentAccountNumber: this.paymentAccountNumber,
    };
    // @ts-expect-error TS2322
    return Object.values(payment).every(e => isNil(e)) ? null : payment;
  }

  get iban(): ShareIssueRegistrationIban {
    const iban = {
      ibanNumber: this.ibanNumber,
      ibanBic: this.ibanBic,
      ibanBank: this.ibanBank,
      ibanAddress: this.ibanAddress,
    };
    // @ts-expect-error TS2322
    return Object.values(iban).every(e => !isNil(e)) ? iban : null;
  }

  readonly createdAt: string;
  readonly updatedAt: string;

  constructor(seed?: ShareIssueRegistration) {
    Object.assign(this, seed);
  }
}
