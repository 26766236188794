import { GqlCreateIdentityInput, useRatsitCompanyBoardQuery } from '../../graphql/react-operations';
import { isNil } from 'lodash-es';
import { Identity } from '../identity/Identity';
import { ratsitIdentity2Input } from '../identity/from-ratsit';
import { Table, Tooltip } from '@startuptools/ui';
import { GqlIdentityQuery } from '../../graphql/operations';
import { Trans } from '@lingui/react';

interface Props {
  orgNumber: string;
  identityExcludeList: GqlIdentityQuery['identity'][];
  handleCreate: (identity: GqlCreateIdentityInput) => Promise<unknown>;
  allowProtectedIdentity?: boolean;
}

export const InputBoard = ({ orgNumber, handleCreate, identityExcludeList, allowProtectedIdentity = true }: Props) => {
  const { data, loading } = useRatsitCompanyBoardQuery({ variables: { orgNumber } });

  if (loading || isNil(data?.ratsitCompanyBoard)) {
    return null;
  }

  return (
    <Table sx={{ minWidth: '500px' }} hoverRow>
      <tbody>
        {data?.ratsitCompanyBoard
          ?.filter(i => !identityExcludeList.some(fi => fi.identityNumber === i.identityNumber))
          .map(bm => {
            const selectable = allowProtectedIdentity ? true : bm.name !== 'Protected identity';
            return (
              <Tooltip
                key={bm.identityNumber}
                title={!selectable ? <Trans id="Can't select person with protected identity" /> : undefined}
              >
                <tr
                  onClick={selectable ? () => handleCreate(ratsitIdentity2Input(bm)) : undefined}
                  style={selectable ? { cursor: 'pointer' } : undefined}
                >
                  <td>
                    <Identity identity={bm} showAvatar editable={false} layout="row" />
                  </td>
                  <td>{bm.functionText}</td>
                </tr>
              </Tooltip>
            );
          })}
      </tbody>
    </Table>
  );
};
