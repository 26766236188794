import { ReactNode, Suspense, useMemo, useState, useTransition } from 'react';
import { GqlCreateIdentityInput, GqlIdentityQuery, GqlSearchIdentitiesInput } from '../../graphql/react-operations';
import {
  Radio,
  RadioGroup,
  ModalClose,
  ModalDialog,
  Modal,
  Typography,
  Stack,
  Card,
  ErrorBoundary,
} from '@startuptools/ui';
import { InputState } from './types';
import { Trans } from '@lingui/react';
import { JuridicalKind } from '../../models/identity.model';
import { AvailableInputStates } from './AddIdentity';
import { InputSystem, Relations } from './InputSystem';
import { InputBoard } from './InputBoard';
import { InputSearch } from './InputSearch';
import { InputForeign } from './InputForeign';
import { IdentitySearch } from './Search';

const radioButtonLabels: Record<InputState, (kinds: JuridicalKind[]) => React.ReactNode> = {
  [InputState.System]: kinds => {
    if (kinds.includes(JuridicalKind.Company) && kinds.includes(JuridicalKind.Person)) {
      return <Trans id="Known person/company in the company" />;
    } else if (kinds.includes(JuridicalKind.Company)) {
      return <Trans id="Known company in the company" />;
    } else if (kinds.includes(JuridicalKind.Person)) {
      return <Trans id="Known person in the company" />;
    }
  },
  [InputState.Swedish]: kinds => {
    if (kinds.includes(JuridicalKind.Company) && kinds.includes(JuridicalKind.Person)) {
      return <Trans id="Has Swedish org/personal number" />;
    } else if (kinds.includes(JuridicalKind.Company)) {
      return <Trans id="Has Swedish organization number" />;
    } else if (kinds.includes(JuridicalKind.Person)) {
      return <Trans id="Has Swedish personal number" />;
    }
  },
  [InputState.Foreign]: kinds => {
    if (kinds.includes(JuridicalKind.Company) && kinds.includes(JuridicalKind.Person)) {
      return <Trans id="Does not have Swedish org/personal number" />;
    } else if (kinds.includes(JuridicalKind.Company)) {
      return <Trans id="Does not have Swedish organization number" />;
    } else if (kinds.includes(JuridicalKind.Person)) {
      return <Trans id="Does not have personal number" />;
    }
  },
  [InputState.CompanyBoard]: () => <Trans id="Board member of the company" />,
};

interface Props {
  headline: ReactNode;
  juridicalKinds: JuridicalKind[];
  availableInputStates: AvailableInputStates;
  defaultInputState?: InputState;
  companyBoardOrgNumber?: string;
  identityExcludeList?: GqlIdentityQuery['identity'][];
  relations?: Relations;
  handleCreate: (identity: GqlCreateIdentityInput) => Promise<unknown>;
  onClose: () => void;
  allowProtectedIdentity?: boolean;
}

export const FindIdentityDialog = ({
  headline,
  juridicalKinds,
  availableInputStates,
  companyBoardOrgNumber,
  identityExcludeList,
  relations,
  handleCreate,
  onClose,
  defaultInputState,
  allowProtectedIdentity,
}: Props) => {
  const usedDefaultInputState = useMemo(() => {
    if (defaultInputState) {
      return defaultInputState;
    }
    if (availableInputStates[InputState.System]) {
      return InputState.System;
    } else if (availableInputStates[InputState.CompanyBoard]) {
      return InputState.CompanyBoard;
    } else if (availableInputStates[InputState.Swedish]) {
      return InputState.Swedish;
    } else if (availableInputStates[InputState.Foreign]) {
      return InputState.Foreign;
    }
  }, [availableInputStates, defaultInputState]);
  const [inputState, setInputState] = useState(usedDefaultInputState);
  const [pending, startTransition] = useTransition();

  const [queryParams, setQueryParams] = useState<GqlSearchIdentitiesInput>({
    searchString: '',
    juridicalKinds,
    pageOffset: 0,
    pageSize: 25,
  });

  const Input = useMemo(() => {
    switch (inputState) {
      case InputState.System: {
        return InputSystem;
      }
      case InputState.CompanyBoard: {
        return InputBoard;
      }
      case InputState.Swedish: {
        return InputSearch;
      }
      case InputState.Foreign: {
        return InputForeign;
      }
      case undefined: {
        return () => null;
      }
      default: {
        throw new Error(`Missing case for InputState: ${InputState[inputState]}`);
      }
    }
  }, [inputState]);
  const showSearch = [InputState.System, InputState.Swedish].includes(inputState as InputState);
  return (
    <Modal open onClose={onClose}>
      <ModalDialog minWidth={900} sx={{ overflowY: 'auto', height: '90vh' }}>
        <ModalClose />
        <Stack margin={3} gap={3}>
          <Typography level="h2">{headline}</Typography>
          <Card>
            <RadioGroup
              value={inputState}
              onChange={event => {
                startTransition(() => {
                  setInputState(Number(event.currentTarget.value));
                });
              }}
            >
              {(Object.entries(availableInputStates) as unknown as [InputState, boolean][])
                .filter(([_key, value]) => Boolean(value))
                .map(([key]) => {
                  return <Radio key={key} value={key} label={radioButtonLabels[key](juridicalKinds)} />;
                })}
            </RadioGroup>
          </Card>
          {showSearch && (
            <IdentitySearch
              showJuridicalKindSelector={inputState === InputState.System}
              defaultRelations={relations}
              juridicalKinds={juridicalKinds}
              loading={pending}
              onSubmit={data => setQueryParams(state => ({ ...state, ...data }))}
            />
          )}
          <ErrorBoundary>
            <Suspense>
              <Input
                queryParams={queryParams}
                handleCreate={handleCreate}
                juridicalKinds={juridicalKinds}
                identityExcludeList={identityExcludeList ?? []}
                orgNumber={companyBoardOrgNumber || ''}
                allowProtectedIdentity={allowProtectedIdentity}
              />
            </Suspense>
          </ErrorBoundary>
        </Stack>
      </ModalDialog>
    </Modal>
  );
};
