import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { filter, isNil, orderBy } from 'lodash-es';

import { AppState } from '../../store/reducers';
import { selectSnrAuthorizationByKind } from '../../store/selectors/snr-authorizations.selectors';
import { Company } from '../../models/company.model';
import { SnrAuthorization, SnrAuthKind } from '../../models/snr-authorization.model';
import { GqlDispatchService } from '../../injectables/gql-dispatch.service';
import { GqlAuthorizationGroupsService } from '../../graphql/operations';

interface Data {
  company: Company;
  authorizationKind: SnrAuthKind;
  authFilter?: (auth: SnrAuthorization) => boolean;
  onSubmit: (auth: SnrAuthorization) => Observable<SnrAuthorization>;
}

@Component({
  selector: 'app-select-authorization-dialog',
  templateUrl: './select-authorization-dialog.component.html',
})
export class SelectAuthorizationDialogComponent {
  snrAuthorizations$ = this.store.pipe(
    select(selectSnrAuthorizationByKind(this.data.authorizationKind)),
    map(res => {
      if (!isNil(this.data.authFilter)) {
        // @ts-expect-error TS2722
        return filter(res, a => this.data.authFilter(a));
      }
      return res;
    }),
    // @ts-expect-error TS18048
    map(auths => orderBy(auths, [a => a.registrationDate], ['desc'])),
  );

  displayedColumns = ['registrationDate', 'kinds', 'validThru'];

  constructor(
    private dialogRef: MatDialogRef<SelectAuthorizationDialogComponent, SnrAuthorization>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
    private store: Store<AppState>,
    private gqlDispatch: GqlDispatchService,
    private gqlAuthGroups: GqlAuthorizationGroupsService,
  ) {
    this.gqlDispatch.query(this.gqlAuthGroups, {
      companyId: this.data.company.id,
    });
  }

  static open(dialog: MatDialog, data: Data) {
    return dialog.open<SelectAuthorizationDialogComponent, Data, SnrAuthorization>(SelectAuthorizationDialogComponent, {
      width: '800px',
      data: data,
    });
  }

  selectAuth(auth: SnrAuthorization) {
    this.data.onSubmit(auth).subscribe(() => {
      this.dialogRef.close(auth);
    });
  }
}
