import { createReducer, on, Action } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { map } from 'lodash-es';

import { AgendaItem } from '../../models/agenda-item.model';
import { agendaItemsActions } from '../actions/agenda-items.actions';

export type AgendaItemsState = EntityState<AgendaItem>;
export const adapter = createEntityAdapter<AgendaItem>();

export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(agendaItemsActions.manyLoaded, (state, { payload }) =>
    adapter.addMany(
      payload,
      adapter.removeMany(
        map(payload, p => p.id),
        state,
      ),
    ),
  ),
  on(agendaItemsActions.manyDeleted, (state, { payload }) => adapter.removeMany(payload, state)),
);

export function agendaItemsReducer(state: EntityState<AgendaItem> | undefined, action: Action) {
  return reducer(state, action);
}
