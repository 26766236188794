import { PropsWithChildren } from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { ThemeProvider } from '@emotion/react';
import { I18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { CompanyIdProvider } from './CompanyIdProvider';
import { UserIdProvider } from './UserIdProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { Theme as MaterialTheme } from '@mui/material/styles';
import { CssVarsProvider as JoyCssVarsProvider, Theme as JoyTheme, THEME_ID as JOY_THEME_ID } from '@mui/joy/styles';

interface Props {
  client: ApolloClient<InMemoryCache>;
  i18n: I18n;
  materialTheme: MaterialTheme;
  joyTheme: JoyTheme;
  companyId?: string;
  userId?: string;
}

export const ReactWrapper = ({
  client,
  children,
  i18n,
  materialTheme,
  joyTheme,
  userId,
  companyId,
}: PropsWithChildren<Props>) => {
  return (
    <I18nProvider i18n={i18n}>
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={i18n.locale}>
        <ApolloProvider client={client}>
          <ThemeProvider theme={materialTheme}>
            <JoyCssVarsProvider theme={{ [JOY_THEME_ID]: joyTheme }}>
              <UserIdProvider userId={userId}>
                <CompanyIdProvider companyId={companyId}>{children}</CompanyIdProvider>
              </UserIdProvider>
            </JoyCssVarsProvider>
          </ThemeProvider>
        </ApolloProvider>
      </LocalizationProvider>
    </I18nProvider>
  );
};
