import { IDKind } from '@startuptools/common/common';
import { formatIdentityNumber } from '@startuptools/common/formatters';

export const IdentityFormat = ({
  identityNumberKind,
  identityNumber,
}: {
  identityNumberKind: IDKind;
  identityNumber: string;
}) => {
  return formatIdentityNumber(identityNumberKind, identityNumber);
};
