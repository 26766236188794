import type * as Types from '../base-types.graphql';

import { gql } from '@apollo/client';
export type GqlUserFieldsFragment = { id: string, admin: boolean, personalId?: string | null, name?: string | null, jwtEpoch: number, signInCount: number, unconfirmedEmail?: string | null, email?: string | null, emailMd5?: string | null, phone?: string | null, companySuggestionsSyncedDate?: string | null, communicationLanguage: string, lastSignInAt?: string | null, hubspotObjectId?: string | null, createdAt: string, updatedAt: string, optionsInShPortal: boolean };

export const UserFieldsFragmentDoc = gql`
    fragment UserFields on User {
  id
  admin
  personalId
  name
  jwtEpoch
  signInCount
  unconfirmedEmail
  email
  emailMd5
  phone
  companySuggestionsSyncedDate
  communicationLanguage
  lastSignInAt
  hubspotObjectId
  createdAt
  updatedAt
  optionsInShPortal
}
    `;