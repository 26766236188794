import { createReducer, on, Action } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { map } from 'lodash-es';

import { OptionsSharesSubscriber } from '../../models/options-shares-subscriber.model';
import { optionsSharesSubscribersActions } from '../actions/options-shares-subscriber.actions';

export type OptionsSharesSubscribersState = EntityState<OptionsSharesSubscriber>;

export const adapter = createEntityAdapter<OptionsSharesSubscriber>();
export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(optionsSharesSubscribersActions.manyLoaded, (state, { payload }) => {
    return adapter.addMany(
      payload,
      adapter.removeMany(
        map(payload, p => p.id),
        state,
      ),
    );
  }),
  on(optionsSharesSubscribersActions.manyDeleted, (state, { payload }) => adapter.removeMany(payload, state)),
);

export function optionsSharesSubscribersReducer(
  state: EntityState<OptionsSharesSubscriber> | undefined,
  action: Action,
) {
  return reducer(state, action);
}
