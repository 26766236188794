import { createAction, props, union } from '@ngrx/store';

import { ShareIssueBoardMember } from '../../models/share-issue-board-member.model';

const prefix = '[ShareIssueBoardMembers]' as const;

export const shareIssueBoardMembersActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: ShareIssueBoardMember[] }>()),
  manyDeleted: createAction(prefix + ' manyDeleted', props<{ payload: string[] }>()),
};

const all = union(shareIssueBoardMembersActions);

export type ActionsUnion = typeof all;
