import { GqlSnrBond } from '../graphql/operations';
import { Transform } from 'class-transformer';
import { isNil } from 'lodash-es';
import { DateTime } from 'luxon';

export enum BondKind {
  Warrant = 'teckningsoptioner',
  Convertibles = 'konvertibler',
  PromissoryNote = 'skuldebrev',
}

export class SnrBond implements Omit<GqlSnrBond, 'company'> {
  id: string;
  filingYear: string;
  filingNumber: string;
  serialNumber: string;
  bondType: BondKind;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  registrationDate: DateTime;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  decisionDate?: DateTime;
  decisionBy?: string;
  partRegistration: boolean;
  shareClass?: string;
  shareCapitalCanBeIncreaseBy?: string;
  shareCapitalCanBeIncreaseByCurrency?: string;
  freetext?: string;
  warrantSubFrom?: string;
  warrantSubTo?: string;
  numberOfOptions?: string;
  numberOfSharesMax?: string;
  withoutLoan?: boolean;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  shareSubStartDate?: DateTime;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  shareSubEndDate?: DateTime;
  numberOfOptionsMax?: string;
  numberOfOptionsMin?: string;
  companyId: string;

  readonly createdAt: string;
  readonly updatedAt: string;

  get filingRef() {
    return `${this.filingNumber}/${this.filingYear}`;
  }

  constructor(seed?: Partial<SnrBond>) {
    Object.assign(this, seed);
  }
}
