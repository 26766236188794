import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouter } from '../../store/selectors/router.selectors';
import { values, filter } from 'lodash-es';

import { ShareIssuesState } from '../reducers/share-issues.reducers';
import { selectCompanyId } from './companies.base';

export const selectShareIssuesState = createFeatureSelector<ShareIssuesState>('shareIssues');

export const selectShareIssueId = createSelector(selectRouter, router => router?.state.params.shareIssueId);
export const selectShareIssue = createSelector(
  selectShareIssuesState,
  selectShareIssueId,
  // @ts-expect-error TS2538
  (state, id) => state.entities[id],
);

export const selectShareIssueById = (id: string) => createSelector(selectShareIssuesState, state => state.entities[id]);
export const selectShareIssues = createSelector(selectShareIssuesState, selectCompanyId, (state, companyId) => {
  // @ts-expect-error TS18048
  return filter(values(state.entities), si => si.companyId === companyId);
});
