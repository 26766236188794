import { createAction, props, union } from '@ngrx/store';

import { ShareIssueDocument } from '../../models/share-issue-document.model';

const prefix = '[ShareIssueDocuments]' as const;

export const shareIssueDocumentsActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: ShareIssueDocument[] }>()),
};

const all = union(shareIssueDocumentsActions);

export type ActionsUnion = typeof all;
