import { createAction, props, union } from '@ngrx/store';

import { AgendaItemDecision } from '../../models/agenda-item-decision.model';

const prefix = '[AgendaItemDecisions]' as const;

export const agendaItemDecisionsActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: AgendaItemDecision[] }>()),
  manyDeleted: createAction(prefix + ' manyDeleted', props<{ payload: string[] }>()),
};

const all = union(agendaItemDecisionsActions);

export type ActionsUnion = typeof all;
