import type * as Types from '../base-types.graphql';

import { gql } from '@apollo/client';
export type GqlDsFieldsFragment = { id: string, signed: boolean, headerPrimaryLang?: string | null, headerSecondaryLang?: string | null, authMethodToView?: Types.GqlScriveAuthMethod | null, authMethodToSign?: Types.GqlScriveAuthMethod | null, deliveryMethod?: string | null, email?: string | null, documentId: string, signatoryId: string, proxyId?: string | null, scriveSignTime?: string | null, scriveSeenTime?: string | null, scriveRejectedTime?: string | null, scriveRejectionReason?: string | null, scriveReadInvitationTime?: string | null, scriveEmailDeliveryStatus?: string | null, zignedParticipantStatus?: Types.GqlZignedParticipantStatus | null, zignedParticipantRole?: Types.GqlZignedParticipantRole | null, zignedParticipantSigningRoomLink?: string | null, createdAt: string, updatedAt: string };

export const DsFieldsFragmentDoc = gql`
    fragment DsFields on DocumentSignature {
  id
  signed
  headerPrimaryLang
  headerSecondaryLang
  authMethodToView
  authMethodToSign
  deliveryMethod
  email
  documentId
  signatoryId
  proxyId
  scriveSignTime
  scriveSeenTime
  scriveRejectedTime
  scriveRejectionReason
  scriveReadInvitationTime
  scriveEmailDeliveryStatus
  zignedParticipantStatus
  zignedParticipantRole
  zignedParticipantSigningRoomLink
  createdAt
  updatedAt
}
    `;