import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'lodash-es';

import { IAuthJson } from '../models/snr-authorization.model';

@Pipe({ name: 'authJsonKindHuman' })
export class AuthJsonKindHumanPipe implements PipeTransform {
  transform(input: IAuthJson[]): string {
    return map(input, inp => inp.kind).join(', ');
  }
}
