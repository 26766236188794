import { createReducer, on, Action } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { map } from 'lodash-es';

import { ShareIssueBoardMember } from '../../models/share-issue-board-member.model';
import { shareIssueBoardMembersActions } from '../actions/share-issue-board-members.actions';

export type ShareIssueBoardMembersState = EntityState<ShareIssueBoardMember>;

export const adapter = createEntityAdapter<ShareIssueBoardMember>();
export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(shareIssueBoardMembersActions.manyLoaded, (state, { payload }) =>
    adapter.addMany(
      payload,
      adapter.removeMany(
        map(payload, p => p.id),
        state,
      ),
    ),
  ),
  on(shareIssueBoardMembersActions.manyDeleted, (state, { payload }) => adapter.removeMany(payload, state)),
);

export function shareIssueBoardMembersReducer(state: EntityState<ShareIssueBoardMember> | undefined, action: Action) {
  return reducer(state, action);
}
