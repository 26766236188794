import { createSelector } from '@ngrx/store';
import { ShareIssueRegistrationWithIncludes } from '../../models/share-issue-registration.model';
import { SipWithIncludes } from '../../models/share-issue-participant.model';
import { values, isNil, get, isEmpty } from 'lodash-es';
import { selectIdentitiesState } from './identities.base';
import { selectSnrCasesState } from './snr-cases.selectors';
import { selectShareIssueParticipantsState } from './share-issue-participants.base';
import { selectShareIssues } from './share-issues.base';
import { selectShareIssueRegistrations } from './share-issue-registration.base';

export const selectShareIssueRegistrationsWithIncludes = createSelector(
  selectShareIssueRegistrations,
  selectIdentitiesState,
  selectShareIssueParticipantsState,
  selectSnrCasesState,
  selectShareIssues,
  (sirs, identitiesState, sipsState, snrCaseState, sis): ShareIssueRegistrationWithIncludes[] | undefined => {
    if (!isEmpty(sirs)) {
      return sirs.map(sir => {
        if (isNil(sir)) {
          throw new Error();
        }
        return {
          model: sir,
          participants: values(sipsState.entities)
            .filter(sip => sip?.shareIssueRegistrationId === sir?.id)
            .map(sip => {
              if (isNil(sip)) {
                throw new Error();
              }
              return {
                model: sip,
                identity: get(identitiesState.entities, sip.identityId)!,
                proxy: sip.proxyId ? get(identitiesState.entities, sip.proxyId)! : undefined,
              } satisfies SipWithIncludes;
            }),
          contact: sir?.contactId ? get(identitiesState.entities, sir.contactId) : undefined,
          signatory: sir?.signatoryId ? get(identitiesState.entities, sir.signatoryId) : undefined,
          snrCase: sir?.snrCaseId ? get(snrCaseState.entities, sir.snrCaseId) : undefined,
          shareIssue: sir?.shareIssueId ? sis.find(si => si?.id === sir.shareIssueId) : undefined,
        } satisfies ShareIssueRegistrationWithIncludes;
      });
    }
  },
);
