import { GqlNiceFounder } from '../graphql/operations';
import { Identity } from './identity.model';

export interface NiceFounderWithIncludes {
  model: NiceFounder;
  identity: Identity;
}

export class NiceFounder implements Omit<GqlNiceFounder, 'warrantProgram' | 'identity'> {
  id: string;

  warrantProgramId: string;
  identityId: string;

  readonly createdAt: string;
  readonly updatedAt: string;

  constructor(seed?: Partial<NiceFounder>) {
    Object.assign(this, seed);
  }
}
