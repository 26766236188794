import { createAction, props, union } from '@ngrx/store';

import { WiseDependencyBond } from '../../models/wise-dependency-bond.model';

const prefix = '[WiseDependencyBonds]' as const;

export const wiseDependencyBondsActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: WiseDependencyBond[] }>()),
  manyDeleted: createAction(prefix + ' manyDeleted', props<{ payload: string[] }>()),
};

const all = union(wiseDependencyBondsActions);

export type ActionsUnion = typeof all;
