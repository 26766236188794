import { createAction, props, union } from '@ngrx/store';

import { MeetingAgendaTemplate } from '../../models/meeting-agenda-template.model';

const prefix = '[MeetingAgendaTemplates] ' as const;

export const meetingAgendaTemplatesActions = {
  manyLoaded: createAction(prefix + 'manyLoaded', props<{ payload: MeetingAgendaTemplate[] }>()),
  manyDeleted: createAction(prefix + ' manyDeleted', props<{ payload: string[] }>()),
};

const all = union(meetingAgendaTemplatesActions);

export type ActionsUnion = typeof all;
