import { createAction, props, union } from '@ngrx/store';

import { CompanyDirector } from '../../models/company-director.model';

const prefix = '[CompanyDirectors] ' as const;

export const companyDirectorsActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: CompanyDirector[] }>()),
  manyDeleted: createAction(prefix + ' manyDeleted', props<{ payload: string[] }>()),
};

const all = union(companyDirectorsActions);

export type ActionsUnion = typeof all;
