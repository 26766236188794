import { isNil } from 'lodash-es';
import { Identity } from './identity.model';
import { GqlMeetingParticipant, GqlSummonsSimplifiedState } from '../graphql/operations';
import { GqlMpKind } from '../graphql/base-types.graphql';

export interface MpWithIncludes {
  model: MeetingParticipant;
  identity?: Identity;
  proxy?: Identity;
}

export enum JobState {
  Completed,
  Scheduled,
  Waiting,
  Failed,
  InProgress,
}

export class MeetingParticipant
  implements Omit<GqlMeetingParticipant, 'identity' | 'meeting' | 'meetingParticipantShares'>
{
  id: string;

  initials: string;
  chairman: boolean;
  secretary: boolean;
  adjuster: boolean;
  present: boolean;
  summoned: boolean;
  position: string;
  isShareholder?: boolean;
  kind: GqlMpKind;
  meetingId?: string;
  identityId: string;
  proxyId?: string;
  summonType?: number;
  emailId?: string;
  emailSummonsId?: string;
  sendgridMessageId?: string;
  summonsJobState?: JobState;
  summonsJobId?: string;
  summonsSimplifiedState: GqlSummonsSimplifiedState;

  readonly createdAt: string;
  readonly updatedAt: string;

  constructor(seed?: Partial<MeetingParticipant>) {
    Object.assign(this, seed);
  }

  get needPowerOfAttorney(): boolean {
    return Boolean(this.isShareholder && !isNil(this.proxyId) && this.proxyId !== this.identityId);
  }
}
