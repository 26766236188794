import { createAction, props, union } from '@ngrx/store';

import { MeetingConcern } from '../../models/meeting-concern.model';

const prefix = '[MeetingConcerns]' as const;

export const meetingConcernsActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: MeetingConcern[] }>()),
  manyDeleted: createAction(prefix + ' manyDeleted', props<{ payload: string[] }>()),
};

const all = union(meetingConcernsActions);

export type ActionsUnion = typeof all;
