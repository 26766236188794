import { StripeProducts } from '@startuptools/common/stripe';
import { stripeSubPricesTest, stripeProductsTest, StripePrice as StripePriceTest } from './stripe-plans-test';
// eslint-disable-next-line
import gitJson from '../../../../git.json';
export const gitBranch = gitJson.gitBranch;
export const gitRev = gitJson.gitRev;

export const environment = {
  production: true,
};

const production = window.location.hostname === 'bolago.app';

export const appTitle = (() => {
  if (production) {
    return 'Bolago | The smart way to run a company';
  } else {
    return `Bolago | ${gitJson.gitBranch}`;
  }
})();

export const host = {
  clientUrl: `https://${window.location.hostname}`,
  clientWsUrl: `wss://${window.location.hostname.replace('app', 'client')}`,
  shPortalUrl: `https://my.${window.location.hostname}`,
};

export const disableLog = true;
export const sentryDsn = 'https://ffea6658a4ef4deb9b0ccd87434f5fcb@o69954.ingest.sentry.io/163032';

export const sentryEnvironment = (() => {
  if (production) {
    return 'production';
  } else if (gitBranch === 'master') {
    return 'staging';
  }
  return gitBranch;
})();

export const hubspotPortalId: string | null = production ? '143198988' : null;

export const stripePublicKey: string = (() => {
  if (production) {
    // StartupTools stripe live
    return 'pk_live_51AgbLrFBz41BrZ6w2lNkIHglkqw8GJwEY0OsqwRurQ8xyXxaltshomreLugjb5oJKUkgbyM3DaCH6OoOGQuHnF7x00rTptuqB6';
  } else {
    // StartupTools stripe test environment
    return 'pk_test_51AgbLrFBz41BrZ6w59QRmqW7J80dcUU8eWWNcXMXTETWqI7bUuZYK0k0RMznFvXtcHlPfNt7x7brTPfNq5HnR2Kd00LwhpMnsn';
  }
})();

export const stripeProducts: StripeProducts = (() => {
  if (production) {
    // StartupTools stripe live
    return {
      // pcs
      shareIssue: 'prod_KQhy6hQIzvczSC',
      employeeOption: 'prod_KQhzW8SzcyCKpp',
      warrantProgram: 'prod_KQhytk0b0dfJrX',
      wise: 'prod_LnqMTjYSVkxbrq',
      nice: 'prod_O6qM3CRgYl5CnI',
      // subs
      shareRegistry: 'prod_KQhucx1gObPblM',
      startup: 'prod_LVSOpQEhBKYjUc',
      grow: 'prod_KQhw3UJttM70n8',
      pro: 'prod_KQhyimjAEjdgOQ',
      proButler: 'prod_MXoliZu8Tjp5PU',
    } satisfies StripeProducts;
  } else {
    // StartupTools stripe test environment
    return stripeProductsTest;
  }
})();

enum StripePriceProd {
  shareRegisterYear = 'price_1JlqV3FBz41BrZ6wPdYAXd3f',
  shareRegisterMonth = 'price_1JlqV3FBz41BrZ6wODIeVTSY',
  startup2022Year = 'price_1KoRTUFBz41BrZ6w07oGrSQI', // OLD 2 950/y
  startup2022Month = 'price_1KoRTUFBz41BrZ6woUPLyI8h', // OLD 295/m
  startup2024Year = 'price_1Oiz7OFBz41BrZ6wyt2RijUn', // 3950/y
  startup2024Month = 'price_1Oiz7BFBz41BrZ6w69u2e8TC', // 395/m
  grow2022Year = 'price_1JlqWzFBz41BrZ6wSjKnpRvH', // OLD
  grow2022Month = 'price_1JlqWzFBz41BrZ6wYEpYHq4c', // OLD
  grow2023Year = 'price_1LojBsFBz41BrZ6wfij3YHDn', // OLD 11 950/y
  grow2023Month = 'price_1LojC7FBz41BrZ6w7z1TYycT', // OLD 1 195/m
  grow2024Year = 'price_1Oix08FBz41BrZ6wahCjHQJH', // 16 950/y
  grow2024Month = 'price_1OiwzqFBz41BrZ6w2EaDjoa3', // 1 695/m
  pro2022Year = 'price_1JlqYJFBz41BrZ6wqQ7szRp6', // OLD
  pro2022Month = 'price_1JlqYJFBz41BrZ6wZqi6aauz', // OLD
  pro2023Year = 'price_1LojD6FBz41BrZ6wuFE2fKZb', // OLD 24 950/y
  pro2023Month = 'price_1LojDQFBz41BrZ6wBdtNSE8c', // OLD 2 495/m
  pro2024Year = 'price_1OiwybFBz41BrZ6whskGQgTM', // 34 950/y
  pro2024Month = 'price_1OiwzMFBz41BrZ6w7NIjCfb8', // 3 495/m
  butler2023Year = 'price_1Loj7nFBz41BrZ6wTiN7yUYC', // OLD 89 950/y
  butler2023Month = 'price_1Loj7nFBz41BrZ6w7rW7aHIe', // OLD 8 995/m
  butler2024Year = 'price_1Oiz8UFBz41BrZ6wGEVuNFdv', // 139 950/y
  butler2024Month = 'price_1Oiz83FBz41BrZ6wmSyEcOym', // 13 995/m
}

export const StripePrice = (() => {
  if (production) {
    return StripePriceProd;
  } else {
    return StripePriceTest;
  }
})();

export const stripeSubPrices: Record<string, { year: string; month: string }> = (() => {
  if (production) {
    // StartupTools stripe live
    return {
      [stripeProducts.shareRegistry]: {
        year: StripePriceProd.shareRegisterYear,
        month: StripePriceProd.shareRegisterMonth,
      },
      [stripeProducts.startup]: {
        year: StripePriceProd.startup2024Year,
        month: StripePriceProd.startup2024Month,
      },
      [stripeProducts.grow]: {
        year: StripePriceProd.grow2024Year,
        month: StripePriceProd.grow2024Month,
      },
      [stripeProducts.pro]: {
        year: StripePriceProd.pro2024Year,
        month: StripePriceProd.pro2024Month,
      },
      [stripeProducts.proButler]: {
        year: StripePriceProd.butler2024Year,
        month: StripePriceProd.butler2024Month,
      },
    } satisfies Record<string, { year: string; month: string }>;
  } else {
    // StartupTools stripe test environment
    return stripeSubPricesTest;
  }
})();

export const niceEnabled = !production;
