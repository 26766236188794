import { Trans } from '@lingui/react';
import { Box, Typography, Modal, ModalDialog, ModalClose } from '@startuptools/ui';
import { UserProfileEdit } from './UserProfileEdit';

export const UserProfileDialog = ({ onClose }: { onClose: () => void }) => {
  return (
    <Modal open onClose={onClose}>
      <ModalDialog>
        <ModalClose />
        <Box margin={3}>
          <Typography level="h2">
            <Trans id="Profile Settings" />
          </Typography>
          <UserProfileEdit onClose={onClose} />
        </Box>
      </ModalDialog>
    </Modal>
  );
};
