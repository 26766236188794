import { createReducer, on, Action } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { map } from 'lodash-es';

import { Company } from '../../models/company.model';
import { companiesActions } from '../actions/companies.actions';

export interface CompaniesState extends EntityState<Company> {
  currentCompanyId: string;
}

export const adapter = createEntityAdapter<Company>();
export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(companiesActions.manyLoaded, (state, { payload }) => {
    return adapter.addMany(
      payload,
      adapter.removeMany(
        map(payload, p => p.id),
        state,
      ),
    );
  }),
  on(companiesActions.oneDeleted, (state, { payload }) => adapter.removeOne(payload, state)),
  on(companiesActions.setCurrentId, (state, { id }) => ({
    ...state,
    currentCompanyId: id,
  })),
);

export function companiesReducer(state: EntityState<Company> | undefined, action: Action) {
  return reducer(state, action);
}
