import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription, of, timer } from 'rxjs';
import {
  filter,
  distinctUntilKeyChanged,
  bufferWhen,
  concatMap,
  share,
  first,
  delay,
  map,
  startWith,
  distinctUntilChanged,
} from 'rxjs/operators';
import { isEmpty, isNil } from 'lodash-es';
import { SubSink } from 'subsink';
import { plainToInstance } from 'class-transformer';
import { MatDialog } from '@angular/material/dialog';
import { ScrollService } from '../injectables/scroll.service';
import { AppState } from '../store/reducers';
import { selectCompany } from '../store/selectors/companies.base';
import { Document } from '../models/document.model';
import { GqlDocumentUpdatedService, GqlUserSetContactDetailsService } from '../graphql/operations';
import { documentsActions } from '../store/actions/documents.actions';
import { AsyncPollService } from '../injectables/async-poll.service';
import { selectCurrentUser } from '../store/selectors/user.selectors';
import { PromptDialogComponent, PromptInputKind } from '../components/simple-dialogs/prompt-dialog.component';
import { DateTime } from 'luxon';
import { GqlDispatchService } from '../injectables/gql-dispatch.service';
import { NavigationEnd, Router } from '@angular/router';

export enum SummonType {
  Email,
  Post,
}

export enum UserCompanyRole {
  Admin,
}

export enum ScriveDeliveryMethod {
  Email = 'email',
  Mobile = 'mobile',
  EmailMobile = 'email_mobile',
  Pad = 'pad',
  API = 'api',
}

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
})
export class CompanyComponent implements OnInit, OnDestroy {
  isOpened$ = of(true);

  subs = new SubSink();
  apolloSub: Subscription;

  user$ = this.store.pipe(
    select(selectCurrentUser),
    filter(e => !isNil(e)),
  );
  company$ = this.store.pipe(
    select(selectCompany),
    filter(e => !isNil(e)),
  );

  constructor(
    private store: Store<AppState>,
    public scrollService: ScrollService,
    private asyncPoll: AsyncPollService,
    private dialog: MatDialog,
    private gqlDispatch: GqlDispatchService,
    private gqlDocumentUpdated: GqlDocumentUpdatedService,
    private gqlUserSetContactDetails: GqlUserSetContactDetailsService,
    private router: Router,
  ) {}

  public angularPathname$ = this.router.events.pipe(
    filter(e => e instanceof NavigationEnd),
    map(e => e.url),
    startWith(this.router.url),
    distinctUntilChanged(),
  );

  pageIsResponsive$ = this.angularPathname$.pipe(map(path => path.includes('dashboard')));

  ngOnInit() {
    this.user$.pipe(first(), delay(1000)).subscribe(user => {
      if (isNil(user)) {
        return;
      }
      if (!isEmpty(user.phone)) {
        return;
      }
      const now = DateTime.now();
      if (user.createdAt.plus({ day: 1 }).startOf('day') > now) {
        return;
      }
      const resetNagDate = () => {
        localStorage.setItem('sut.next_phone_nag', DateTime.now().plus({ day: 1 }).startOf('day').toISO());
      };
      const nextPhoneNag = localStorage.getItem('sut.next_phone_nag');
      if (!nextPhoneNag) {
        resetNagDate();
        return;
      }
      const nextPhoneNagDt = DateTime.fromISO(nextPhoneNag);
      if (nextPhoneNagDt >= now) {
        return;
      }
      resetNagDate();
      const rnd = Math.random();
      const shouldPrompt = rnd <= 0.25;
      if (!shouldPrompt) {
        // Only open every 4th time the user loads a company after logging in
        return;
      }
      PromptDialogComponent.open(this.dialog, {
        title: $localize`Komplettera din profil!`,
        text: $localize`Ditt konto saknar telefonnummer, fyll i det här för att komplettera din profil`,
        ok: $localize`Spara`,
        cancel: $localize`Avbryt`,
        type: 'text',
        inputKind: PromptInputKind.Input,
        inputLabel: $localize`Telefonnr`,
        validators: [],
        modelValue: '',
        save: input => {
          return this.gqlDispatch.mutate(this.gqlUserSetContactDetails, {
            input: {
              communicationLanguage: user.communicationLanguage,
              email: user.email,
              name: user.name,
              phone: input.input,
            },
          });
        },
      });
    });

    this.asyncPoll.startPoll();

    this.subs.sink = this.company$
      .pipe(
        filter(e => !isNil(e)),
        distinctUntilKeyChanged('id'),
      )
      .subscribe(company => {
        localStorage.setItem('last_company_id', company.id);
        // Collecting updated documents
        if (isNil(this.apolloSub)) {
          const obs = this.gqlDocumentUpdated.subscribe({ channel: company.id }).pipe(share());
          this.apolloSub = obs
            .pipe(
              bufferWhen(() =>
                obs.pipe(
                  first(),
                  concatMap(() => timer(2500)),
                ),
              ),
            )
            .subscribe({
              next: payload => {
                this.store.dispatch(
                  documentsActions.manyLoaded({
                    // @ts-expect-error TS18049
                    payload: payload.map(e => plainToInstance(Document, e.data.documentUpdated)),
                  }),
                );
              },
              error: err => {
                console.error(err);
              },
            });
        }
      });
  }

  ngOnDestroy() {
    this.asyncPoll.stopPoll();
    this.subs.unsubscribe();
    this.apolloSub?.unsubscribe();
    // @ts-expect-error TS2322
    this.apolloSub = undefined;
  }
}
