import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import { map } from 'lodash-es';

import { meetingAgendaTemplateItemsActions } from '../actions/meeting-agenda-template-items.actions';
import { MeetingAgendaTemplateItem } from '../../models/meeting-agenda-template-item.model';

export type MeetingAgendaTemplateItemsState = EntityState<MeetingAgendaTemplateItem>;

export const adapter = createEntityAdapter<MeetingAgendaTemplateItem>();
export const initialMeetingAgendaTemplateItemsState = adapter.getInitialState({});

const reducer = createReducer(
  initialMeetingAgendaTemplateItemsState,
  on(meetingAgendaTemplateItemsActions.manyLoaded, (state, { payload }) =>
    adapter.addMany(
      payload,
      adapter.removeMany(
        map(payload, p => p.id),
        state,
      ),
    ),
  ),
  on(meetingAgendaTemplateItemsActions.manyDeleted, (state, { payload }) => adapter.removeMany(payload, state)),
);

export function meetingAgendaTemplateItemsReducer(
  state: EntityState<MeetingAgendaTemplateItem> | undefined,
  action: Action,
) {
  return reducer(state, action);
}
