import { createAction, props, union } from '@ngrx/store';

import { Company } from '../../models/company.model';

const prefix = '[Companies]' as const;

export const companiesActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: Company[] }>()),
  oneDeleted: createAction(prefix + ' oneDeleted', props<{ payload: string }>()),
  setCurrentId: createAction(prefix + ' setCurrentId', props<{ id: string }>()),
};

const all = union(companiesActions);

export type ActionsUnion = typeof all;
