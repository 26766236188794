import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Directive,
  DoCheck,
  ElementRef,
  HostListener,
  Injector,
  Input,
  ViewContainerRef,
} from '@angular/core';
import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { BehaviorSubject, of, map } from 'rxjs';
import { ProgressBarMode } from '@angular/material/progress-bar';
// eslint-disable-next-line no-restricted-imports
import { sample } from 'lodash-es';

export enum LoadingKind {
  Bar = 'bar',
  Circle = 'circle',
}

const Q_CIRCLE_DIAMETER = 100;

const quotes = [
  'Success is not final, failure is not fatal: it is the courage to continue that counts. - Winston Churchill',
  "I have learned over the years that when one's mind is made up, this diminishes fear. - Rosa Parks",
  'If you want to achieve greatness, stop asking for permission. - Anonymous',
  'The most courageous act is still to think for yourself. Aloud. - Coco Chanel',
  'The greatest glory in living lies not in never falling, but in rising every time we fall. - Nelson Mandela',
  "Don't let anyone rob you of your imagination, your creativity, or your curiosity. It's your place in the world; it's your life. - Dr. Mae Jemison",
  "I have not failed. I've just found 10,000 ways that won't work. - Thomas Edison",
  'Done is better than perfect. - Sheryl Sandberg',
  'The only way to do great work is to love what you do. - Steve Jobs',
  'I attribute my success to this: I never gave or took any excuse. - Florence Nightingale',
];

@Directive({
  selector: '[sutLoading]',
  exportAs: 'Loading',
})
export class LoadingDirective implements AfterViewInit, DoCheck {
  @Input() private autoStart = false;
  @Input() private mode: ProgressSpinnerMode = 'indeterminate';
  @Input() private progress = 0;
  @Input() private kind: LoadingKind = LoadingKind.Circle;

  @Input() private text: string | null;

  private portalOutlet: DomPortalOutlet;

  private component?: LoadingComponent;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private elementRef: ElementRef<HTMLElement>,
    private changeDetectorRef: ChangeDetectorRef,
    private componentFactoryResolver: ComponentFactoryResolver, // eslint-disable-line deprecation/deprecation
    private appRef: ApplicationRef,
    private injector: Injector,
  ) {}

  ngAfterViewInit() {
    if (!this.elementRef.nativeElement.style.position) {
      this.elementRef.nativeElement.style.position = 'relative';
    }
    this.portalOutlet = new DomPortalOutlet(
      this.elementRef.nativeElement,
      this.componentFactoryResolver,
      this.appRef,
      this.injector,
    );
    if (this.autoStart) {
      setTimeout(() => this.start());
    }
  }

  ngDoCheck() {
    if (this.component) {
      const { height } = this.hostSize();
      this.setDiameter(height);
      this.setMode(this.mode);
      this.setProgress(this.progress);
      this.setText(this.text);
      this.changeDetectorRef.markForCheck();
    }
  }

  public start() {
    if (this.portalOutlet?.hasAttached() === false) {
      const loadingComponentPortal = new ComponentPortal(LoadingComponent, this.viewContainerRef);
      const componentRef = this.portalOutlet.attach(loadingComponentPortal);
      this.component = componentRef.instance;
      this.setKind(this.kind);
      this.changeDetectorRef.markForCheck();
    }
  }

  public stop() {
    setTimeout(() => {
      if (this.portalOutlet?.hasAttached()) {
        this.portalOutlet.detach();
        // @ts-expect-error TS2322
        this.component = null;
        this.changeDetectorRef.markForCheck();
      }
    });
  }

  public setText(text: string | null) {
    if (this.component) {
      this.component.text = text;
    }
  }

  public setMode(mode: ProgressSpinnerMode) {
    if (this.component) {
      this.component.mode = mode;
    }
  }

  public setProgress(progress: number) {
    if (this.component) {
      this.component.progress = progress;
    }
  }

  public setKind(kind: LoadingKind) {
    if (this.component) {
      this.component.kind = kind;
    }
  }

  private hostSize(): { width: number; height: number } {
    return this.elementRef?.nativeElement?.getBoundingClientRect() ?? { width: 0, height: 0 };
  }

  private setDiameter(diameter: number) {
    // leave room for text
    if (this.component) {
      const textSize = this.text ? 24 : 0;
      const newDiameter = Math.max(diameter - textSize - 8, 24);
      if (newDiameter < Q_CIRCLE_DIAMETER) {
        this.component.diameter = newDiameter;
      } else {
        this.component.diameter = Q_CIRCLE_DIAMETER;
      }
    }
  }
}

@Component({
  selector: 'sut-loading',
  templateUrl: './loading.html',
  styleUrls: ['./loading.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent {
  randomQuote$ = of(quotes).pipe(map(q => sample(q)));

  public LoadingKind = LoadingKind;
  private _kind = LoadingKind.Circle;

  private progressSub = new BehaviorSubject<number>(0);
  public progress$ = this.progressSub.asObservable();

  private diameterSub = new BehaviorSubject<number>(1);
  public diameter$ = this.diameterSub.asObservable();

  private spinnerModeSub = new BehaviorSubject<ProgressSpinnerMode>('indeterminate');
  public spinnerMode$ = this.spinnerModeSub.asObservable();

  private barModeSub = new BehaviorSubject<ProgressBarMode>('indeterminate');
  public barMode$ = this.barModeSub.asObservable();

  private textSub = new BehaviorSubject<string | null>(null);
  text$ = this.textSub.asObservable();

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    return;
  }

  @HostListener('click', ['$event'])
  onClick($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  public set kind(kind: LoadingKind) {
    this._kind = kind;
    this.changeDetectorRef.markForCheck();
  }

  public get kind() {
    return this._kind;
  }

  public set progress(progress: number) {
    this.progressSub.next(progress);
  }

  public set diameter(diameter: number) {
    this.diameterSub.next(diameter);
  }

  public set mode(mode: ProgressSpinnerMode | ProgressBarMode) {
    if (this.kind === LoadingKind.Bar) {
      this.barModeSub.next(mode as ProgressBarMode);
    } else {
      this.spinnerModeSub.next(mode as ProgressSpinnerMode);
    }
  }

  public set text(text: string | null) {
    this.textSub.next(text);
  }
}
