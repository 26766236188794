import {
  CircularProgress,
  Modal,
  ModalDialog,
  ModalClose,
  Button,
  IconButton,
  SyncIcon,
  Input,
  Stack,
  FormControl,
  FormLabel,
  Box,
  Tooltip,
  ErrorBoundary,
  Select,
  Option,
} from '@startuptools/ui';
import { Trans, useLingui } from '@lingui/react';
import { Identity } from './Identity';
import {
  GqlUpdateIdentityInput,
  GqlUpdateIdentityMutation,
  useIdentitySuspenseQuery,
  useRefreshIdentityMutation,
  useUpdateIdentityMutation,
} from '../../graphql/react-operations';
import { SubmitHandler, useForm } from 'react-hook-form';
import { isSwedish } from '../../models/identity.model';
import { useCompanyId } from '@startuptools/common/react-wrapper';
import { FetchResult, InternalRefetchQueriesInclude } from '@apollo/client';
import { ComponentProps, Suspense, useState } from 'react';
import { countryNames } from '@startuptools/common/common';

type FormValues = Omit<GqlUpdateIdentityInput, 'id'>;

export const IdentityEditDialog = ({ onCancel, ...props }: ComponentProps<typeof IdentityEditDialogInner>) => {
  return (
    <Modal open onClose={onCancel}>
      <ModalDialog minWidth={500} sx={{ minHeight: 539 }}>
        <ModalClose />
        <Box sx={{ margin: 3 }}>
          <ErrorBoundary>
            <Suspense
              fallback={
                <Stack alignItems="center">
                  <CircularProgress size="lg" />
                </Stack>
              }
            >
              <IdentityEditDialogInner onCancel={onCancel} {...props} />
            </Suspense>
          </ErrorBoundary>
        </Box>
      </ModalDialog>
    </Modal>
  );
};

const IdentityEditDialogInner = ({
  identityId,
  onCancel,
  onConfirm,
  updateRefetchQueries,
}: {
  identityId: string;
  onCancel: () => void;
  onConfirm: () => void;
  updateRefetchQueries?:
    | ((result: FetchResult<GqlUpdateIdentityMutation>) => InternalRefetchQueriesInclude)
    | InternalRefetchQueriesInclude;
}) => {
  const companyId = useCompanyId();
  const { i18n } = useLingui();
  const [loading, setLoading] = useState(false);
  const {
    data: { identity },
  } = useIdentitySuspenseQuery({ variables: { companyId, id: identityId } });
  const { register, handleSubmit, setValue } = useForm<FormValues>({
    defaultValues: {
      name: identity.name,
      address: identity.address?.replaceAll('\n', ', ') ?? '',
      email: identity.email,
      phone: identity.phone,
    },
  });
  const [doUpdate] = useUpdateIdentityMutation({ refetchQueries: updateRefetchQueries });
  const [doRefresh, { loading: loadingRefresh }] = useRefreshIdentityMutation({
    variables: { companyId, id: identityId },
  });

  const isSwede = isSwedish(identity);

  const resetForm = (identity: FormValues) => {
    setValue('name', identity.name);
    setValue('address', identity.address?.replaceAll('\n', ', ') || '');
    setValue('email', identity.email || '');
    setValue('phone', identity.phone || '');
  };

  const onSubmit: SubmitHandler<FormValues> = async input => {
    try {
      setLoading(true);
      const res = await doUpdate({
        variables: {
          companyId,
          input: { ...input, address: input.address?.replaceAll(', ', '\n').trim() || null, id: identity.id },
        },
      });
      if (!res.data) {
        throw new Error('no data');
      }
      resetForm(res.data.updateIdentity);
      onConfirm();
    } catch (e) {
      setLoading(false);
      console.error(e);
      return;
    }
  };

  const onRefresh = async () => {
    try {
      const res = await doRefresh();
      if (res.data) {
        resetForm(res.data.refreshIdentity);
      }
    } catch (e) {
      console.error(e);
      return;
    }
  };

  /* the undefined here handles identities already saved in database, we should do a migration and remove those, then we can remove this code. */
  const editableNames = ['undefined Protected identity', 'Protected identity'];
  const disabled = isSwede && !editableNames.includes(identity.name);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={3}>
        <Identity identity={identity} editable={false} />
        <FormControl disabled={loading} required>
          <FormLabel>
            <Trans id="Name" />
          </FormLabel>
          <Input
            {...register('name')}
            readOnly={disabled}
            endDecorator={
              isSwede &&
              (loadingRefresh ? (
                <CircularProgress size="sm" />
              ) : (
                <Tooltip title={<Trans id="Refresh name and address" />}>
                  <IconButton onClick={() => onRefresh()}>
                    <SyncIcon />
                  </IconButton>
                </Tooltip>
              ))
            }
          />
        </FormControl>
        <FormControl disabled={loading}>
          <FormLabel>
            <Trans id="Address" />
          </FormLabel>
          <Input {...register('address')} disabled={disabled} />
        </FormControl>
        <FormControl disabled={loading}>
          <FormLabel>
            <Trans id="Email" />
          </FormLabel>
          <Input {...register('email')} />
        </FormControl>
        <FormControl disabled={loading}>
          <FormLabel>
            <Trans id="Phone" />
          </FormLabel>
          <Input {...register('phone')} />
        </FormControl>
        {!isSwede && (
          <FormControl required>
            <FormLabel>
              <Trans id="Country" />
            </FormLabel>
            <Select
              name="countryCode"
              placeholder="Country"
              defaultValue={identity.countryCode}
              onChange={(_event, value) => setValue('countryCode', value as unknown as string)}
              required
            >
              {countryNames[i18n.locale as 'sv' | 'en'].map(country => (
                <Option key={country.code} value={country.code}>
                  {country.name}
                </Option>
              ))}
            </Select>
          </FormControl>
        )}

        <Stack direction="row" gap={1} justifyContent="end">
          <Button variant="plain" color="neutral" onClick={onCancel} disabled={loading}>
            <Trans id="Cancel" />
          </Button>
          <Button type="submit" loading={loading}>
            <Trans id="Save" />
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};
