export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A scalar that can represent any JSON value. */
  JSON: { input: any; output: any; }
  /**
   * ISO 8601 combined date and time without timezone.
   *
   * # Examples
   *
   * * `2015-07-01T08:59:60.123`,
   */
  NaiveDateTime: { input: any; output: any; }
  /**
   * A UUID is a unique 128-bit number, stored as 16 octets. UUIDs are parsed as
   * Strings within GraphQL. UUIDs are used to assign unique identifiers to
   * entities without requiring a central allocating authority.
   *
   * # References
   *
   * * [Wikipedia: Universally Unique Identifier](http://en.wikipedia.org/wiki/Universally_unique_identifier)
   * * [RFC4122: A Universally Unique IDentifier (UUID) URN Namespace](http://tools.ietf.org/html/rfc4122)
   */
  UUID: { input: any; output: any; }
}

export enum GqlAud {
  CompanyAdmin = 'COMPANY_ADMIN',
  Stakeholder = 'STAKEHOLDER'
}

export interface GqlBankidCollectInput {
  aud: GqlAud;
  orderRef: Scalars['String']['input'];
}

export interface GqlBankidCollectResponse {
  hintCode?: Maybe<GqlHintCode>;
  status: GqlStatus;
  successfulAuthResponse?: Maybe<GqlSuccessfulAuthResponse>;
}

export interface GqlBankidInitResponse {
  autoStartToken: Scalars['String']['output'];
  orderRef: Scalars['String']['output'];
  qrStartToken: Scalars['String']['output'];
}

export interface GqlDeletedFidoKeysResponse {
  ids: Array<Scalars['UUID']['output']>;
}

export interface GqlEmptyResponse {
  ok: Scalars['Boolean']['output'];
}

export interface GqlFidoKey {
  createdAt: Scalars['NaiveDateTime']['output'];
  creds: Scalars['JSON']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  kind: Scalars['Int']['output'];
  updatedAt: Scalars['NaiveDateTime']['output'];
  userId: Scalars['ID']['output'];
}

export interface GqlFinishSecuritykeyAuthenticationInput {
  aud: GqlAud;
  publicKeyCredential: Scalars['String']['input'];
  userId: Scalars['UUID']['input'];
}

export interface GqlFinishSecuritykeyRegistrationInput {
  displayName: Scalars['String']['input'];
  registerPublicKeyCredential: Scalars['String']['input'];
}

export enum GqlHintCode {
  Cancelled = 'CANCELLED',
  CertificateErr = 'CERTIFICATE_ERR',
  ExpiredTransaction = 'EXPIRED_TRANSACTION',
  NoClient = 'NO_CLIENT',
  OutstandingTransaction = 'OUTSTANDING_TRANSACTION',
  Started = 'STARTED',
  StartFailed = 'START_FAILED',
  UserCallConfirm = 'USER_CALL_CONFIRM',
  UserCancel = 'USER_CANCEL',
  UserDeclinedCall = 'USER_DECLINED_CALL',
  UserMrtd = 'USER_MRTD',
  UserSign = 'USER_SIGN'
}

export interface GqlMutationRoot {
  bankidCancel: GqlEmptyResponse;
  bankidInit: GqlBankidInitResponse;
  deleteFidoKey: GqlDeletedFidoKeysResponse;
  finishSecuritykeyAuthentication: GqlSuccessfulAuthResponse;
  finishSecuritykeyRegistration: GqlFidoKey;
  passwordConfirmRegister: GqlSuccessfulAuthResponse;
  passwordForgot: GqlEmptyResponse;
  passwordLogin: GqlSuccessfulAuthResponse;
  passwordRegister: GqlEmptyResponse;
  passwordReset: GqlSuccessfulAuthResponse;
  startSecuritykeyAuthentication: GqlStartSecuritykeyAuthenticationResponse;
  startSecuritykeyRegistration: GqlStartSecuritykeyRegistrationResponse;
  truidLoginSession: GqlTruidConfirmResponse;
}


export interface GqlMutationRootBankidCancelArgs {
  orderRef: Scalars['String']['input'];
}


export interface GqlMutationRootDeleteFidoKeyArgs {
  id: Scalars['UUID']['input'];
}


export interface GqlMutationRootFinishSecuritykeyAuthenticationArgs {
  input: GqlFinishSecuritykeyAuthenticationInput;
}


export interface GqlMutationRootFinishSecuritykeyRegistrationArgs {
  input: GqlFinishSecuritykeyRegistrationInput;
}


export interface GqlMutationRootPasswordConfirmRegisterArgs {
  aud: GqlAud;
  token: Scalars['String']['input'];
}


export interface GqlMutationRootPasswordForgotArgs {
  email: Scalars['String']['input'];
}


export interface GqlMutationRootPasswordLoginArgs {
  input: GqlPasswordLoginInput;
}


export interface GqlMutationRootPasswordRegisterArgs {
  input: GqlPasswordRegisterInput;
}


export interface GqlMutationRootPasswordResetArgs {
  input: GqlPasswordResetInput;
}


export interface GqlMutationRootStartSecuritykeyAuthenticationArgs {
  input: GqlStartSecuritykeyAuthenticationInput;
}


export interface GqlMutationRootStartSecuritykeyRegistrationArgs {
  input: GqlStartSecuritykeyRegistrationInput;
}


export interface GqlMutationRootTruidLoginSessionArgs {
  input: GqlTruidLoginInput;
}

export interface GqlPasswordLoginInput {
  aud: GqlAud;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}

export interface GqlPasswordRegisterInput {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}

export interface GqlPasswordResetInput {
  aud: GqlAud;
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
}

export interface GqlQueryRoot {
  bankidCollect: GqlBankidCollectResponse;
  fidoKeys: Array<GqlFidoKey>;
  truidToken: GqlSuccessfulAuthResponse;
  user: GqlUser;
  users: Array<GqlUser>;
}


export interface GqlQueryRootBankidCollectArgs {
  input: GqlBankidCollectInput;
}


export interface GqlQueryRootTruidTokenArgs {
  input: GqlTruidTokenInput;
}


export interface GqlQueryRootUserArgs {
  id: Scalars['ID']['input'];
}

export interface GqlStartSecuritykeyAuthenticationInput {
  personalId: Scalars['String']['input'];
}

export interface GqlStartSecuritykeyAuthenticationResponse {
  challenge: Scalars['String']['output'];
  userId: Scalars['UUID']['output'];
}

export interface GqlStartSecuritykeyRegistrationInput {
  displayName: Scalars['String']['input'];
}

export interface GqlStartSecuritykeyRegistrationResponse {
  challenge: Scalars['String']['output'];
}

export enum GqlStatus {
  Complete = 'COMPLETE',
  Failed = 'FAILED',
  Pending = 'PENDING'
}

export interface GqlSuccessfulAuthResponse {
  jwt: Scalars['String']['output'];
  user: GqlUser;
}

export interface GqlTruidConfirmResponse {
  redirectUri: Scalars['String']['output'];
}

export interface GqlTruidLoginInput {
  aud: GqlAud;
  isSwedish: Scalars['Boolean']['input'];
}

export interface GqlTruidTokenInput {
  aud: GqlAud;
  code: Scalars['String']['input'];
  state: Scalars['String']['input'];
}

export interface GqlUser {
  admin: Scalars['Boolean']['output'];
  authMethod: Scalars['Int']['output'];
  communicationLanguage: Scalars['String']['output'];
  createdAt: Scalars['NaiveDateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  emailMd5?: Maybe<Array<Scalars['Int']['output']>>;
  id: Scalars['ID']['output'];
  intercomHmac?: Maybe<Array<Scalars['Int']['output']>>;
  name?: Maybe<Scalars['String']['output']>;
  personalId?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['NaiveDateTime']['output'];
}
