'use client';
import { useState } from 'react';
import { usePasswordForgotMutation } from '../graphql/react-operations';
import { ApolloError } from '@apollo/client';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { Button, Box, Snackbar, Stack, Typography, IconButton, CloseRoundedIcon } from '@startuptools/ui';
import { Trans } from '@lingui/react';
import { Navigate } from '../types';

interface FormValues {
  email: string;
}

export const PasswordRecover = ({ navigate }: { navigate: Navigate }) => {
  const [doRecover, { loading }] = usePasswordForgotMutation();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [showToast, setShowToast] = useState<boolean>(false);

  const showError = (errorMsg: string) => {
    setErrorMsg(errorMsg);
    setShowToast(true);
  };

  const handleSubmit = async (values: FormValues) => {
    const { email } = values;
    try {
      await doRecover({ variables: { email } });
      navigate('/auth/password/recover-confirm', { replace: true });
    } catch (e) {
      if (e instanceof ApolloError) {
        showError(e.message);
      }
    }
  };

  return (
    <Box>
      <FormContainer onSuccess={handleSubmit}>
        <Stack direction="column" gap={1} alignItems="center">
          <Typography level="h4" paddingBottom={2}>
            <Trans id="Recover password" />
          </Typography>
          <TextFieldElement
            label={<Trans id="Email" />}
            name="email"
            type="email"
            autoComplete="email"
            fullWidth
            required
          />
          <Button sx={{ alignSelf: 'end' }} loading={loading} variant="plain" type="submit">
            <Trans id="Recover" />
          </Button>

          <Button
            variant="plain"
            color="neutral"
            onClick={() => navigate('/auth/password/login', { replace: true })}
            sx={{ marginTop: 2 }}
          >
            <Trans id="Go back" />
          </Button>
        </Stack>
      </FormContainer>
      <Snackbar
        open={showToast}
        variant="solid"
        color="danger"
        onClose={() => setShowToast(false)}
        endDecorator={
          <IconButton variant="plain" size="sm" color="neutral" onClick={() => setShowToast(false)}>
            <CloseRoundedIcon />
          </IconButton>
        }
      >
        {errorMsg}
      </Snackbar>
    </Box>
  );
};
