import { ComponentProps } from 'react';
import { Snackbar as JoySnackbar } from '@mui/joy';

export const Snackbar = (props: ComponentProps<typeof JoySnackbar>) => {
  const { sx, ...restProps } = props;
  return (
    <JoySnackbar
      /* placing it on the right because otherwise the SideNav interferes with it */
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      /* this placement is in order to avoid the Chat bubble. */
      sx={{ ...sx, right: '80px !important' }}
      autoHideDuration={6000}
      {...restProps}
    />
  );
};
