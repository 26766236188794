export enum AuthMethod {
  Password,
  BankId,
  PasswordPlusSecurityKey,
  SecurityKey,
}

export enum PaymentAccountKind {
  Bankgiro,
  BankAccount,
  IBAN,
  Other,
}

export enum DecisionKind {
  BoardDecision,
  ShareHoldersMeeting,
  BoardDecisionWithShApproval,
}

export enum MeetingKind {
  BoardMeeting = 'BoardMeeting',
  ShareholdersMeeting = 'ShareholdersMeeting',
  ExtraShareholdersMeeting = 'ExtraShareholdersMeeting',
}

export enum IDKind {
  Passport,
  SwedishPersonalID,
  SwedishOrgNumber,
  ForeignCompanyDirector,
  ForeignManual,
  Fictitious,
}

export const TIME_ZONE_LUXON = 'Europe/Stockholm';
export const YEAR_FORMAT_LUXON = 'yyyy';
export const DATE_FORMAT_LUXON = `${YEAR_FORMAT_LUXON}-MM-dd`;
export const TIME_FORMAT_LUXON = 'HH:mm';
export const TIME_FORMAT_LUXON_LOC = 't';
export const DATE_TIME_FORMAT_LUXON = `${DATE_FORMAT_LUXON} ${TIME_FORMAT_LUXON}`;
