import { createAction, props, union } from '@ngrx/store';

import { AgendaItem } from '../../models/agenda-item.model';

const prefix = '[AgendaItems]' as const;

export const agendaItemsActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: AgendaItem[] }>()),
  manyDeleted: createAction(prefix + ' manyDeleted', props<{ payload: string[] }>()),
};

const all = union(agendaItemsActions);

export type ActionsUnion = typeof all;
