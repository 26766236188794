import { createAction, props, union } from '@ngrx/store';

import { SnrShareCapitalChange } from '../../models/snr-share-capital-change.model';

const prefix = '[SnrShareCapitalChange]' as const;

export const snrShareCapitalChangesActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: SnrShareCapitalChange[] }>()),
  oneLoaded: createAction(prefix + ' oneLoaded', props<{ payload: SnrShareCapitalChange }>()),
  oneDeleted: createAction(prefix + ' oneDeleted', props<{ payload: string }>()),
};

const all = union(snrShareCapitalChangesActions);

export type ActionsUnion = typeof all;
