import { Type } from '@angular/core';
import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';

import { QMessageComponent } from './message.component';
import { QMessageContainerDirective } from './message.directive';

const Q_MESSAGE: Type<unknown>[] = [QMessageComponent, QMessageContainerDirective];

@NgModule({
  imports: [CommonModule, MatDividerModule, MatIconModule],
  declarations: [Q_MESSAGE],
  exports: [Q_MESSAGE],
})
export class QMessageModule {}
