import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectMeetingId } from '../../store/selectors/meetings.base';
import { filter } from 'lodash-es';
import { MeetingDocumentsState } from '../reducers/meeting-documents.reducers';

export const selectMeetingDocumentsState = createFeatureSelector<MeetingDocumentsState>('meetingDocuments');

export const selectMeetingDocumentsByMeetingId = (meetingId: string) =>
  createSelector(selectMeetingDocumentsState, state => {
    // @ts-expect-error TS18048
    return filter(state.entities, m => m.meetingId === meetingId);
  });
export const selectMeetingDocumentsByMeeting = createSelector(
  selectMeetingDocumentsState,
  selectMeetingId,
  (state, mId) => {
    // @ts-expect-error TS18048
    return filter(state.entities, m => m.meetingId === mId);
  },
);
