import { useInterval } from '@startuptools/common/react-hooks';
import { MAINTENANCE_URL, STATUS_INTERVAL_TIME, STATUS_URL } from './consts';
import axios from 'axios';
import { useState } from 'react';
import { ErrorOutlineIcon, IconButton, Link, Stack, Tooltip, EngineeringIcon } from '@startuptools/ui';
import { Trans } from '@lingui/react';
import { MaintenancePage, StatusPage } from './types';

export const StatusNotification = () => {
  const [showNotification, setShowNotification] = useState(false);
  const [showMaintenance, setShowMaintenance] = useState(false);

  useInterval(async () => {
    const [statusRes, maintenanceRes] = await Promise.all([
      axios.get<StatusPage>(STATUS_URL).catch(() => null),
      axios.get<MaintenancePage>(MAINTENANCE_URL).catch(() => null),
    ]);
    if (statusRes) {
      setShowNotification(statusRes.data.status.indicator !== 'none');
    }
    if (maintenanceRes) {
      setShowMaintenance(maintenanceRes.data.scheduled_maintenances.some(s => s.status !== 'completed') ?? false);
    }
  }, STATUS_INTERVAL_TIME);

  return (
    <Stack direction="row" gap={1}>
      {showNotification && (
        <Tooltip title={<Trans id="Service disruptions, click for more information" />}>
          <Link href="https://bolago.statuspage.io" target="_blank">
            <IconButton>
              <ErrorOutlineIcon />
            </IconButton>
          </Link>
        </Tooltip>
      )}
      {showMaintenance && (
        <Tooltip title={<Trans id="Scheduled maintenance, click for more information" />}>
          <Link href="https://bolago.statuspage.io" target="_blank">
            <IconButton>
              <EngineeringIcon />
            </IconButton>
          </Link>
        </Tooltip>
      )}
    </Stack>
  );
};
