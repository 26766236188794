import { SVGProps } from 'react';

export const Logo = ({ color = 'black', ...rest }: { color?: string } & SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 486 165" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fill={color}
      d="M259.457 127.06C252.39 127.06 246.521 125.842 241.848 123.407C237.175 120.971 233.699 117.723 231.419 113.664C229.14 109.604 228 105.197 228 100.441C228 94.874 229.368 90.0606 232.103 86.0011C234.952 81.9416 239.112 78.81 244.583 76.6062C250.054 74.2865 256.778 73.1266 264.756 73.1266H286.468C286.468 68.4872 285.841 64.6597 284.588 61.644C283.334 58.5124 281.396 56.1927 278.775 54.6849C276.154 53.177 272.791 52.4231 268.688 52.4231C264.015 52.4231 260.026 53.525 256.721 55.7287C253.416 57.8165 251.364 61.0641 250.567 65.4716H230.393C231.077 59.2083 233.129 53.873 236.548 49.4655C239.967 44.942 244.469 41.4625 250.054 39.0268C255.752 36.4751 261.964 35.1992 268.688 35.1992C276.78 35.1992 283.676 36.649 289.374 39.5487C295.073 42.3324 299.404 46.3919 302.367 51.7272C305.445 56.9466 306.983 63.2678 306.983 70.6909V124.972H289.545L287.494 110.88C286.354 113.2 284.872 115.346 283.049 117.317C281.339 119.289 279.345 121.029 277.065 122.537C274.786 123.929 272.165 125.03 269.201 125.842C266.352 126.654 263.104 127.06 259.457 127.06ZM264.072 110.532C267.378 110.532 270.284 109.952 272.791 108.792C275.413 107.516 277.635 105.777 279.459 103.573C281.396 101.253 282.878 98.7015 283.904 95.9179C284.929 93.1342 285.613 90.1766 285.955 87.045V86.697H266.979C262.99 86.697 259.684 87.2189 257.063 88.2628C254.442 89.1907 252.561 90.5825 251.421 92.4383C250.282 94.2941 249.712 96.4398 249.712 98.8755C249.712 101.311 250.282 103.399 251.421 105.139C252.561 106.879 254.214 108.212 256.379 109.14C258.545 110.068 261.109 110.532 264.072 110.532Z"
    />
    <path fill={color} d="M195 125V0H216V125H195Z" />
    <path
      fill={color}
      d="M139.915 126C131.741 126 124.361 124.104 117.776 120.312C111.305 116.406 106.196 111.063 102.45 104.284C98.8165 97.3902 97 89.5196 97 80.6723C97 71.5953 98.8733 63.6673 102.62 56.8883C106.366 49.9943 111.475 44.6515 117.947 40.8598C124.531 36.9533 131.911 35 140.085 35C148.259 35 155.582 36.9533 162.053 40.8598C168.638 44.6515 173.747 49.9369 177.38 56.7159C181.127 63.4949 183 71.423 183 80.5C183 89.577 181.127 97.5051 177.38 104.284C173.634 111.063 168.468 116.406 161.883 120.312C155.412 124.104 148.089 126 139.915 126ZM139.915 108.248C144.002 108.248 147.692 107.214 150.984 105.146C154.39 103.078 157.115 99.9754 159.158 95.839C161.202 91.7026 162.224 86.5896 162.224 80.5C162.224 74.4104 161.202 69.3548 159.158 65.3333C157.228 61.197 154.56 58.0947 151.154 56.0265C147.862 53.9583 144.172 52.9242 140.085 52.9242C136.112 52.9242 132.422 53.9583 129.016 56.0265C125.61 58.0947 122.885 61.197 120.842 65.3333C118.798 69.3548 117.776 74.4104 117.776 80.5C117.776 86.5896 118.798 91.7026 120.842 95.839C122.885 99.9754 125.553 103.078 128.846 105.146C132.251 107.214 135.941 108.248 139.915 108.248Z"
    />
    <path
      fill={color}
      d="M442.269 127.919C434.027 127.919 426.587 126.005 419.948 122.177C413.423 118.234 408.272 112.84 404.494 105.997C400.831 99.038 399 91.093 399 82.162C399 72.9991 400.889 64.9961 404.666 58.1529C408.444 51.1938 413.595 45.8004 420.119 41.9729C426.759 38.0294 434.199 36.0576 442.441 36.0576C450.682 36.0576 458.066 38.0294 464.59 41.9729C471.23 45.8004 476.381 51.1358 480.044 57.979C483.821 64.8221 485.71 72.8252 485.71 81.9881C485.71 91.151 483.821 99.154 480.044 105.997C476.266 112.84 471.058 118.234 464.419 122.177C457.894 126.005 450.511 127.919 442.269 127.919ZM442.269 109.999C446.39 109.999 450.11 108.955 453.43 106.867C456.864 104.779 459.611 101.648 461.671 97.4722C463.732 93.2967 464.762 88.1353 464.762 81.9881C464.762 75.8408 463.732 70.7374 461.671 66.6779C459.726 62.5024 457.035 59.3708 453.601 57.2831C450.282 55.1953 446.562 54.1514 442.441 54.1514C438.434 54.1514 434.714 55.1953 431.28 57.2831C427.846 59.3708 425.099 62.5024 423.038 66.6779C420.978 70.7374 419.948 75.8408 419.948 81.9881C419.948 88.1353 420.978 93.2967 423.038 97.4722C425.099 101.648 427.789 104.779 431.108 106.867C434.542 108.955 438.263 109.999 442.269 109.999Z"
    />
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M353.586 100C349.912 100 346.492 99.6339 343.325 98.9018C342.702 100.036 342.39 101.301 342.39 102.696C342.39 107.002 346.017 109.155 353.271 109.155H367.041C373.614 109.155 379.338 110.26 384.211 112.471C389.198 114.682 393.051 117.824 395.771 121.896C398.491 125.853 399.851 130.333 399.851 135.336C399.851 144.645 395.998 151.86 388.291 156.98C380.584 162.216 369.478 164.834 354.971 164.834C344.544 164.834 336.327 163.729 330.32 161.518C324.427 159.307 320.233 156.107 317.74 151.918C315.247 147.729 314 142.376 314 135.86H333.55C333.55 139.118 334.117 141.678 335.25 143.54C336.497 145.402 338.65 146.798 341.71 147.729C344.884 148.66 349.417 149.125 355.311 149.125C363.584 149.125 369.421 148.078 372.821 145.983C376.221 143.889 377.921 140.863 377.921 136.907C377.921 133.416 376.618 130.682 374.011 128.703C371.404 126.842 367.607 125.911 362.621 125.911H349.021C340.52 125.911 334.117 124.224 329.81 120.849C325.504 117.474 323.35 113.227 323.35 108.107C323.35 104.849 324.257 101.707 326.07 98.6819C327.38 96.6252 329.004 94.7836 330.942 93.1571C327.703 90.6678 325.092 87.6885 323.109 84.2192C320.37 79.3096 319 73.9324 319 68.0877C319 62.126 320.37 56.7489 323.109 51.9562C325.849 47.1635 329.787 43.3059 334.923 40.3836C340.174 37.4612 346.395 36 353.586 36C360.777 36 366.94 37.4612 372.077 40.3836C372.143 40.4213 372.209 40.4591 372.275 40.4971C376.523 38.2609 383.452 36 393 36V52H383.916C386.639 56.7816 388 62.1442 388 68.0877C388 73.9324 386.63 79.3096 383.891 84.2192C381.151 89.0119 377.213 92.8694 372.077 95.7918C366.94 98.5973 360.777 100 353.586 100ZM353.586 83.6931C358.494 83.6931 362.432 82.3489 365.4 79.6603C368.367 76.9717 369.851 73.1142 369.851 68.0877C369.851 63.0612 368.367 59.2621 365.4 56.6904C362.432 54.0018 358.494 52.6575 353.586 52.6575C348.677 52.6575 344.682 54.0018 341.6 56.6904C338.519 59.2621 336.978 63.0612 336.978 68.0877C336.978 73.1142 338.519 76.9717 341.6 79.6603C344.682 82.3489 348.677 83.6931 353.586 83.6931Z"
    />
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6V126H49.0604C61.1254 126 70.6173 123 77.5361 117C84.512 110.943 88 102.571 88 91.8857C88 82.8 85.627 75.5428 80.8811 70.1143C77.1109 65.7492 71.8434 62.511 65.0786 60.3997C68.5267 58.4327 71.2783 55.8709 73.3333 52.7143C76.4782 47.9143 78.0507 42.0286 78.0507 35.0571C78.0507 25.5143 75.1631 18.2857 69.3879 13.3714C63.6699 8.45715 55.1215 6 43.7427 6H0ZM46.23 107.486H21.058V72.2571H46.23C52.0052 72.2571 56.4224 73.5143 60.2534 76.0286C64.0845 78.4857 66 83.0857 66 89.8286C66 96.3429 64.1702 100.914 60.5107 103.543C56.8512 106.171 52.3483 107.486 46.23 107.486ZM40.7408 53.7429H21.058V24.4286H40.7408C46.1729 24.4286 49.8525 25.3714 52.7115 27.6C55.5705 29.7714 57 33.5714 57 39C57 44.3143 55.5705 48.0857 52.7115 50.3143C49.8525 52.4857 46.1729 53.7429 40.7408 53.7429Z"
    />
  </svg>
);

export const JustST = ({ color = 'black', ...rest }: { color?: string } & SVGProps<SVGSVGElement>) => (
  <svg width="137" height="80" viewBox="0 0 137 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M71.94 52.8C71.94 26.268 20.46 36.168 20.46 21.252C20.46 19.668 22.836 15.576 34.452 15.576C42.636 15.576 46.2 17.392 46.464 22.672H70.092C70.092 6.7 52.272 0 34.32 0C8.184 0 0.132 10.56 0.132 25.212C0.132 52.668 51.612 44.352 51.612 56.1C51.612 61.908 48.18 63.888 37.488 63.888C30.36 63.888 24.156 62.404 23.496 55.672H0C2.904 73.36 19.8 79.596 37.488 79.596C63.624 79.596 71.94 68.64 71.94 52.8Z"
      fill={color}
    />
    <path d="M115 18.576H136.944V3H72V18.576H94V80H115V18.576Z" fill={color} />
  </svg>
);
