import type * as Types from '../base-types.graphql';

import { gql } from '@apollo/client';
export type GqlIdentityFieldsFragment = { id: string, identityNumberKind: number, identityNumber: string, juridicalKind: number, name: string, email?: string | null, emailMd5?: string | null, phone?: string | null, countryCode?: string | null, address?: string | null, userId?: string | null, createdAt: string, updatedAt: string, companyId: string };

export const IdentityFieldsFragmentDoc = gql`
    fragment IdentityFields on Identity {
  id
  identityNumberKind
  identityNumber
  juridicalKind
  name
  email
  emailMd5
  phone
  countryCode
  address
  userId
  createdAt
  updatedAt
  companyId
}
    `;