import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { BigNumber } from 'bignumber.js';
import { isNil, toNumber } from 'lodash-es';

export function maxNumberValidator(num: number | BigNumber | string | null) {
  return function (control: AbstractControl): Record<string, boolean> | null {
    if (isNil(control.value) || isNil(num)) {
      return null;
    }
    if (control.value instanceof BigNumber) {
      return control.value.isGreaterThan(num) ? { maxNumber: true } : null;
    }
    return toNumber(control.value) > toNumber(num) ? { maxNumber: true } : null;
  };
}

@Directive({
  selector: '[maxNumber]', // eslint-disable-line @angular-eslint/directive-selector
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MaxNumberValidatorDirective,
      multi: true,
    },
  ],
})
export class MaxNumberValidatorDirective implements Validator {
  @Input('maxNumber') num: number | BigNumber | string;
  validate(control: AbstractControl): Record<string, boolean> | null {
    return maxNumberValidator(this.num)(control);
  }
}
