import { isString, find } from 'lodash';

const data = [
  { name: 'Sveriges Riksbank', min: 1000, max: 1099 },
  { name: 'Nordea', min: 1100, max: 1199 },
  { name: 'Danske Bank', min: 1200, max: 1399 },
  { name: 'Nordea', min: 1400, max: 2099 },
  { name: 'Ålandsbanken', min: 2300, max: 2399 },
  { name: 'Danske Bank', min: 2400, max: 2499 },
  { name: 'Nordea', min: 3000, max: 3399 },
  { name: 'Länsförsäkringar Bank', min: 3400, max: 3409 },
  { name: 'Nordea', min: 3410, max: 4999 },
  { name: 'SEB', min: 5000, max: 5999 },
  { name: 'Handelsbanken', min: 6000, max: 6999 },
  { name: 'Swedbank', min: 7000, max: 8999 },
  { name: 'Länsförsäkringar Bank', min: 9020, max: 9029 },
  { name: 'Citibank', min: 9040, max: 9049 },
  { name: 'Länsförsäkringar Bank', min: 9060, max: 9069 },
  { name: 'Calyon Bank', min: 9080, max: 9089 },
  { name: 'RBS', min: 9090, max: 9099 },
  { name: 'Nordnet Bank', min: 9100, max: 9109 },
  { name: 'SEB', min: 9120, max: 9124 },
  { name: 'SEB', min: 9130, max: 9149 },
  { name: 'Skandiabanken', min: 9150, max: 9169 },
  { name: 'IKANO Banken', min: 9170, max: 9179 },
  { name: 'Danske Bank', min: 9180, max: 9189 },
  { name: 'DNB Bank', min: 9190, max: 9199 },
  { name: 'Marginalen Bank', min: 9230, max: 9239 },
  { name: 'SBAB Bank', min: 9250, max: 9259 },
  { name: 'DNB Bank', min: 9260, max: 9269 },
  { name: 'ICA Banken', min: 9270, max: 9279 },
  { name: 'Resurs Bank', min: 9280, max: 9289 },
  { name: 'Swedbank', min: 9300, max: 9349 },
  { name: 'Pareto Öhman', min: 9380, max: 9389 },
  { name: 'Landshypotek', min: 9390, max: 9399 },
  { name: 'Forex Bank', min: 9400, max: 9449 },
  { name: 'Santander Consumer Bank', min: 9460, max: 9469 },
  { name: 'Fortis Bank', min: 9470, max: 9479 },
  { name: 'Nordea', min: 9500, max: 9549 },
  { name: 'Avanza Bank', min: 9550, max: 9569 },
  { name: 'Sparbanken Syd', min: 9570, max: 9579 },
  { name: 'Exchange Finans Europe', min: 9580, max: 9589 },
  { name: 'Erik Penser Bankaktiebolag', min: 9590, max: 9599 },
  { name: 'Volvofinans Bank', min: 9610, max: 9619 },
  { name: 'Bank of China (Luxembourg)', min: 9620, max: 9629 },
  { name: 'Lån & Spar Bank', min: 9630, max: 9639 },
  { name: 'Nordax Bank', min: 9640, max: 9649 },
  { name: 'MedMera Bank', min: 9650, max: 9659 },
  { name: 'Svea Bank', min: 9660, max: 9669 },
  { name: 'JAK Medlemsbank', min: 9670, max: 9679 },
  { name: 'Bluestep Finans', min: 9680, max: 9689 },
  { name: 'Folkia', min: 9690, max: 9699 },
  { name: 'Ekobanken', min: 9700, max: 9709 },
  { name: 'Netfonds Bank (ub)', min: 9720, max: 9729 },
  { name: 'FTCS', min: 9770, max: 9779 },
  { name: 'Klarna Bank', min: 9780, max: 9789 },
  { name: 'Privatgirot', min: 9860, max: 9869 },
  { name: 'Nasdaq-OMX', min: 9870, max: 9879 },
  { name: 'Riksgälden', min: 9880, max: 9899 },
  { name: 'Teller Branch Norway', min: 9951, max: 9951 },
  { name: 'Bankernas Automatbolag', min: 9952, max: 9952 },
  { name: 'Teller Branch Sweden', min: 9953, max: 9953 },
  { name: 'Kortaccept Nordic AB', min: 9954, max: 9954 },
  { name: 'Kommuninvest', min: 9955, max: 9955 },
  { name: 'VP Securities A/S', min: 9956, max: 9956 },
  { name: 'Nordea', min: 9960, max: 9969 },
];

export function bankCode(code: number | string) {
  const c = isString(code) ? parseInt(code.replace(/\D+/g, '').substring(0, 4), 10) : code;
  return find(data, d => c >= d.min && c <= d.max);
}
