import { createAction, props, union } from '@ngrx/store';

import { Document } from '../../models/document.model';

const prefix = '[Documents]' as const;

export const documentsActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: Document[] }>()),
  manyDeleted: createAction(prefix + ' manyDeleted', props<{ payload: string[] }>()),
};

const all = union(documentsActions);

export type ActionsUnion = typeof all;
