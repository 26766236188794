import { createReducer, on, Action } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { map } from 'lodash-es';

import { OptionsSharesSubscriberRepresentative } from '../../models/options-shares-subscriber-representatives.model';
import { optionsSharesSubscriberRepresentativeActions } from '../actions/options-shares-subscriber-representative.actions';

export type OptionsSharesSubscriberRepresentativesState = EntityState<OptionsSharesSubscriberRepresentative>;

export const adapter = createEntityAdapter<OptionsSharesSubscriberRepresentative>();
export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(optionsSharesSubscriberRepresentativeActions.manyLoaded, (state, { payload }) => {
    return adapter.addMany(
      payload,
      adapter.removeMany(
        map(payload, p => p.id),
        state,
      ),
    );
  }),
  on(optionsSharesSubscriberRepresentativeActions.manyDeleted, (state, { payload }) =>
    adapter.removeMany(payload, state),
  ),
);

export function optionsSharesSubscriberRepresentativesReducer(
  state: EntityState<OptionsSharesSubscriberRepresentative> | undefined,
  action: Action,
) {
  return reducer(state, action);
}
