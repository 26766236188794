import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactWrapperComponent } from './components/react-wrapper/react-wrapper.component';
import { OldBrowser } from './OldBrowser';

@Component({
  selector: 'app-old-browser',
  template: `<app-react-wrapper [component]="OldBrowser" />`,
  standalone: true,
  imports: [ReactWrapperComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OldBrowserComponent {
  OldBrowser = OldBrowser;
}
