import {
  GqlCreateIdentityInput,
  GqlIdentityQuery,
  GqlSearchIdentitiesInput,
  useSearchIdentitiesSuspenseQuery,
} from '../../graphql/react-operations';
import { useCompanyId } from '@startuptools/common/react-wrapper';
import { Stack, Table } from '@startuptools/ui';
import { Identity } from '../identity/Identity';
import { orderBy } from 'lodash-es';
import { identityToInput } from '../../helpers/identity-to-input';

export interface Relations {
  companyDirector?: true;
  meetingParticipant?: true;
  shareholder?: true;
}

export const InputSystem = ({
  handleCreate,
  identityExcludeList,
  queryParams,
}: {
  identityExcludeList: GqlIdentityQuery['identity'][];
  handleCreate: (identity: GqlCreateIdentityInput) => Promise<unknown>;
  queryParams: GqlSearchIdentitiesInput;
}) => {
  const companyId = useCompanyId();
  const { data } = useSearchIdentitiesSuspenseQuery({
    variables: { companyId, input: queryParams },
    fetchPolicy: 'no-cache',
  });

  return (
    <Stack>
      <Table sx={{ minWidth: '500px' }} hoverRow>
        <tbody>
          {orderBy(
            data?.searchIdentities.filter(
              i => !identityExcludeList.some(fi => fi.identityNumber === i.identityNumber || fi.id === i.id),
            ),
            i => i.name,
            'asc',
          ).map(i => {
            return (
              <tr key={i.id} onClick={() => handleCreate(identityToInput(i))} style={{ cursor: 'pointer' }}>
                <td>
                  <Identity identity={i} editable={false} layout="row" />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Stack>
  );
};
