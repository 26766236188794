import { VestingFrequency, vestingFrequencyMonths } from '@startuptools/common/stock-options';
import { createDurationArray, DurationArray } from '../company/stock-options/common/utils/duration';
import { DateTime } from 'luxon';

export interface DurationSelectOptions {
  vestingDuration: DurationArray;
  cliffDuration: DurationArray;
}

export function getDurationSelectOptions(
  warrantStartDate: DateTime,
  warrantEndDate: DateTime,
  vestingFrequency: VestingFrequency,
  minDuration: number,
): DurationSelectOptions {
  const duration = Math.round(
    warrantEndDate.diff(warrantStartDate, 'months', { conversionAccuracy: 'longterm' }).months,
  );
  const durationFreq = vestingFrequencyMonths[vestingFrequency];
  return {
    vestingDuration: createDurationArray(minDuration || 1, duration + 1, false, durationFreq, warrantStartDate),
    cliffDuration: createDurationArray(minDuration || 1, duration + 1, false, undefined, warrantStartDate),
  };
}
