import { createReducer, on, Action } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { map } from 'lodash-es';

import { snrShareCapitalChangesActions } from '../actions/snr-share-capital-changes.actions';
import { SnrShareCapitalChange } from '../../models/snr-share-capital-change.model';

export interface SnrShareCapitalChangesState extends EntityState<SnrShareCapitalChange> {
  currentUserId: string;
}

export const adapter = createEntityAdapter<SnrShareCapitalChange>();
export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(snrShareCapitalChangesActions.manyLoaded, (state, { payload }) =>
    adapter.addMany(
      payload,
      adapter.removeMany(
        map(payload, p => p.id),
        state,
      ),
    ),
  ),
);

export function snrShareCapitalChangesReducer(state: EntityState<SnrShareCapitalChange> | undefined, action: Action) {
  return reducer(state, action);
}
