export enum AgendaItemKind {
  Standard,
  MeetingStart,
  MeetingEnd,
  FunctionSelect,
  AgendaApproval,
  PriorMeetingApproval,
  MeetingAssessment,
  NextMeeting,
  EmployeeOptionsProgram,
  ShareIssue,
  WarrantIssue,
  ConvertibleIssue,
  ShareIssueAuth,
  WarrantIssueAuth,
  ConvertibleIssueAuth,
  ArticlesOfAssoc,
  VotingList,
  ShareSplit,
  ShareMerge,
  ShareLimits,
  AuthorizationGroup,
  CommentOnly,
  WiseConvertible,
  NiceConvertible,
}

export const noPostalVote = [
  AgendaItemKind.MeetingStart,
  AgendaItemKind.VotingList,
  AgendaItemKind.CommentOnly,
  AgendaItemKind.MeetingEnd,
];
export const noRecommendation = [AgendaItemKind.MeetingStart, AgendaItemKind.CommentOnly, AgendaItemKind.MeetingEnd];
