import { createAction, props, union } from '@ngrx/store';

import { WarrantProgramDocument } from '../../models/warrant-program-document.model';

const prefix = '[WarrantProgramDocuments]' as const;

export const warrantProgramDocumentsActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: WarrantProgramDocument[] }>()),
};

const all = union(warrantProgramDocumentsActions);

export type ActionsUnion = typeof all;
