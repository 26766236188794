<div class="dialog" sutLoading #qLoading="Loading">
  <div class="dialog-close">
    <button mat-icon-button [mat-dialog-close]="null">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <ng-container *ngIf="data.shouldSign; else noSign">
    <mat-horizontal-stepper linear="true" #stepper>
      <mat-step [completed]="(files$ | async)!.length > 0">
        <ng-template matStepLabel i18n>Välj dokument</ng-template>
        <div class="flex flex-col gap-4 pt-4">
          <h4 i18n>Signera dokument</h4>
          <div q-message>
            <span q-message-header i18n
              >Ladda upp ett färdigt dokument och skicka ut det för signatur med BankID (om mottagaren har svenskt
              personnummer) eller via digital signering utan BankID.</span
            >
          </div>
          <div *ngTemplateOutlet="selectDocument"></div>
          <div class="flex flex-row justify-end items-center pt-6">
            <button type="button" matStepperNext [disabled]="(files$ | async)!.length === 0" class="btn" i18n>
              Nästa
            </button>
          </div>
        </div>
      </mat-step>
      <mat-step [completed]="canStartSigning$ | async">
        <ng-template matStepLabel i18n>Vilka ska signera?</ng-template>
        <div class="flex flex-col gap-4 pt-4">
          <h4 i18n>Vilka ska signera?</h4>
          <div q-message>
            <span q-message-header i18n
              >Här väljer du vilka som ska signera dokumentet. Du kan också välja ett företag men då måste en
              företrädare utses.</span
            >
          </div>
          <div class="scrollable" *ngTemplateOutlet="signing"></div>
          <div class="flex flex-row gap-2 justify-end items-center pt-6">
            <button type="button" class="btn-secondary" matStepperPrevious i18n>Tillbaka</button>
            <button type="button" class="btn" [disabled]="!(canStartSigning$ | async)" (click)="startUpload()" i18n>
              Fortsätt
            </button>
          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </ng-container>

  <ng-template #noSign>
    <div mat-dialog-content class="dialog-content">
      <h3 style="margin-top: 0px" i18n>Ladda upp ett dokument</h3>
      <div class="mt-12" *ngTemplateOutlet="selectDocument"></div>
    </div>

    <div class="dialog-actions">
      <button type="button" class="btn" (click)="startUpload()" [disabled]="(files$ | async)!.length === 0" i18n>
        Ladda upp dokumentet
      </button>
    </div>
  </ng-template>
</div>

<ng-template #selectDocument>
  <div class="pt-6">
    <ngx-file-drop
      *ngIf="(files$ | async)?.length === 0; else hasFile"
      dropZoneLabel="Dra och släpp en PDF-fil här"
      (onFileDrop)="dropped($event)"
      [multiple]="false"
      accept="application/pdf"
    >
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <div class="flex flex-col gap-4 items-center">
          <h6 class="text-gscale-100" i18n>Dra och släpp en PDF-fil här.</h6>
          <div>
            <button type="button" class="btn" (click)="openFileSelector()" i18n>Eller klicka här</button>
          </div>
        </div>
      </ng-template>
    </ngx-file-drop>
  </div>
  <ng-template #hasFile>
    <div class="flex flex-col justify-center border border-solid rounded-lg border-gscale-20 h-20">
      <div class="flex flex-row justify-between items-center px-4">
        <div class="flex flex-row items-center gap-4" *ngFor="let file of files$ | async">
          <mat-icon mat-list-icon color="primary">insert_drive_file</mat-icon>
          <div class="flex flex-col gap-1">
            <h6>{{ file.name }}</h6>
            <span class="text-gscale-60"><ng-container i18n>Filstorlek</ng-container>: {{ file.size | bytes }}</span>
          </div>
        </div>
        <div>
          <button mat-icon-button type="button" color="warn" (click)="componentStore.updateSignatories([])">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </ng-template>
  <div class="flex flex-col gap-4 pt-6" *ngIf="(files$ | async)!.length > 0 && data.showTags">
    <mat-form-field class="w-full" appearance="fill">
      <mat-label i18n>Etiketter</mat-label>
      <mat-chip-grid #chipList>
        <mat-chip-row *ngFor="let dc of userTags" focused="true" (removed)="remove(dc)">
          {{ dc }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-row>
        <input
          #tagInput
          type="text"
          i18n-placeholder
          placeholder="Ny etikett..."
          #autocompleteTrigger="matAutocompleteTrigger"
          [formControl]="userTagCtrl"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="addUserTag()"
          (click)="autocompleteTrigger.openPanel()"
        />
      </mat-chip-grid>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectUserTag($event)" panelWidth="50%">
        <mat-option *ngFor="let dc of filteredUserTags$ | async" [value]="dc"> {{ dc }} </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #signing>
  <div class="flex flex-row gap-2">
    <button
      type="button"
      class="btn-secondary"
      (click)="addCurrentCompany()"
      *ngIf="canAddCurrentCompany$ | async"
      i18n
    >
      Lägg till {{ data.company.name }}
    </button>
    <button type="button" class="btn-secondary" (click)="addSignee()" i18n>Lägg till signatör</button>
  </div>
  <div class="flex flex-col" style="overflow: auto; max-height: 300px">
    <table mat-table [dataSource]="signatories$" class="w-full">
      <ng-container matColumnDef="signatory">
        <th mat-header-cell scope="auto" *matHeaderCellDef i18n>Signatör</th>
        <td mat-cell *matCellDef="let row">
          <app-identity-wrapper [identity]="row.identity" [showAvatar]="true" [editable]="false" />
        </td>
      </ng-container>

      <ng-container matColumnDef="proxy">
        <th mat-header-cell scope="auto" *matHeaderCellDef i18n>Repr.</th>
        <td mat-cell *matCellDef="let row">
          <app-identity-wrapper *ngIf="row.proxy" [identity]="row.proxy" [showAvatar]="true" [editable]="false" />
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell scope="auto" *matHeaderCellDef i18n>Epost</th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row?.identity?.juridicalKind === JuridicalKind.Company; else showEmail">
            <ng-container *ngTemplateOutlet="!isNil(row.proxy) ? showEmail : null"></ng-container>
          </div>
          <ng-template #showEmail>
            <div *ngIf="row.documentSignature?.email; else addEmail">
              <span (click)="changeSignatureEmail(row)" clickable> {{ row.documentSignature?.email }} </span>
            </div>
            <ng-template #addEmail>
              <div class="flex flex-row items-center gap-2">
                <span (click)="changeSignatureEmail(row)" clickable i18n>ANGE EPOST</span>
                <mat-icon color="warn">warning</mat-icon>
              </div>
            </ng-template>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell scope="auto" *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <div class="flex flex-row justify-end items-center">
            <div *ngIf="row?.identity.juridicalKind === JuridicalKind.Company">
              <div *ngIf="!row?.proxy" class="flex flex-row gap-4 items-center">
                <mat-icon color="warn" matTooltip="Företag måste ha företrädare" i18n-matTooltip>warning</mat-icon>
                <button type="button" class="btn-secondary-sm tertiary" (click)="openProxyDialog(row)" i18n>
                  Lägg till företrädare
                </button>
              </div>
            </div>
            <button mat-icon-button type="button" *ngIf="row?.proxy" [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item type="button" (click)="removeProxy(row)">
                <span i18n>Ta bort företrädare</span>
              </button>
            </mat-menu>
            <button mat-icon-button type="button" color="warn" (click)="removeIdentity(row)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</ng-template>
<app-add-identity-wrapper *ngIf="open()" [openSignal]="open" [settings]="settings()" />
