import { Trans } from '@lingui/react';
import { Alert, Box, Button, Checkbox, GenericError, Link, Stack, Typography } from '@startuptools/ui';
import {
  UserCompanyInvitesDocument,
  useCompanyProvisionMutation,
  useCreateCompanyInviteMutation,
} from '../../graphql/react-operations';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

interface FormValues {
  acceptedTos: boolean;
  confirmed?: boolean;
}

export const CreateCompanyStep2 = ({
  company,
  onBack,
  onSuccess,
}: {
  company: { orgNumber: string; name: string; isCompanyDirector: boolean };
  onBack: () => void;
  onSuccess: (company: { name: string; id: string }) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [inviteSent, setInviteSent] = useState(false);
  const { register, handleSubmit } = useForm<FormValues>({ defaultValues: { acceptedTos: false } });
  const [doProvision, { error }] = useCompanyProvisionMutation();
  const [doRequestInvite, { loading: inviteLoading }] = useCreateCompanyInviteMutation({
    refetchQueries: [UserCompanyInvitesDocument],
  });

  if (inviteSent) {
    return (
      <Stack gap={3}>
        <Alert variant="outlined" color="warning">
          <Stack gap={1}>
            <Typography level="body-md">
              <Trans id="Invite request was sent, you may now go back and setup another company or wait for your request to be accepted by the company admin" />
            </Typography>
          </Stack>
        </Alert>

        <Box>
          <Button variant="outlined" color="neutral" onClick={onBack}>
            <Trans id="Back" />
          </Button>
        </Box>
      </Stack>
    );
  }

  const conflictError = error?.graphQLErrors.find(e => e.extensions?.code === '409');
  if (conflictError) {
    return (
      <Stack gap={3}>
        <Alert variant="outlined" color="warning">
          <Stack gap={1}>
            <>
              <Typography level="title-lg">
                <Trans id="{name} has already been setup by another user" values={{ name: company.name }} />
              </Typography>
              <Typography level="body-md">
                <Trans id="You can request to be invited to the company by clicking the button below" />
              </Typography>
              <Box>
                <Button
                  variant="outlined"
                  color="warning"
                  loading={inviteLoading}
                  onClick={async () => {
                    return doRequestInvite({
                      variables: { input: { companyId: conflictError.extensions!.companyId as string } },
                    }).then(() => {
                      setInviteSent(true);
                    });
                  }}
                >
                  <Trans id="Request Invite" />
                </Button>
              </Box>
            </>
          </Stack>
        </Alert>

        <Box>
          <Button variant="outlined" color="neutral" onClick={onBack} disabled={inviteLoading}>
            <Trans id="Back" />
          </Button>
        </Box>
      </Stack>
    );
  }

  const onSubmit: SubmitHandler<FormValues> = async values => {
    if (!values.acceptedTos) {
      return;
    }
    setLoading(true);
    const res = await doProvision({ variables: { input: { organizationNumber: company.orgNumber } } })
      .catch(() => null)
      .finally(() => setLoading(false));
    const id = res?.data?.companyProvision.id;
    if (!id) {
      return;
    }
    onSuccess({ id, name: company.name });
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={4}>
        {!company.isCompanyDirector && (
          <Stack gap={2}>
            <Trans
              id="Since you are not a formal representative of {name} (meaning you are not the CEO or a member of the board) you need to confirm that the company has tasked you with signing up the company {name} in StartupTools' platform"
              values={{ name: company.name }}
            />
            <Checkbox
              {...register('confirmed')}
              label={
                <Trans
                  id="I hereby confirm that I have the authority to use Bolago on behalf of {name}"
                  values={{ name: company.name }}
                />
              }
              required
            />
          </Stack>
        )}

        <Stack direction="row" alignItems="center">
          <Checkbox
            {...register('acceptedTos')}
            label={<Trans id="I accept the" comment="Context: I accept the [Terms of Service]" />}
            required
          />
          &nbsp;
          <Link href="https://bolago.com/se/villkor-for-startuptools-plattform-2024" target="_blank">
            <Trans id="Terms of Service" />
          </Link>
        </Stack>
        {error && <GenericError />}
        <Stack direction="row" alignItems="end" gap={1}>
          <Button variant="solid" type="submit" loading={loading}>
            <Trans id="Continue" />
          </Button>

          <Button variant="outlined" color="neutral" onClick={onBack} disabled={loading}>
            <Trans id="Back" />
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};
