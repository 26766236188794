<div class="flex h-screen w-screen">
  <div>
    <app-side-nav></app-side-nav>
  </div>

  <div
    class="flex flex-col w-full"
    [ngClass]="{
      'md:w-[calc(100%-220px)]': !(pageIsResponsive$ | async),
    }"
  >
    <app-toolbar class="sticky z-[1000]"></app-toolbar>
    <div
      class="p-[16px]"
      [ngClass]="{
        'overflow-y-scroll': !(pageIsResponsive$ | async),
        'overflow-x-scroll': pageIsResponsive$ | async,
      }"
    >
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
