import { Transform } from 'class-transformer';
import { DateTime } from 'luxon';
import { isNil } from 'lodash-es';

import { jsonParse, jsonStringify } from './utils';
import { GqlSnrCase } from '../graphql/operations';

export class SnrCase implements Omit<GqlSnrCase, 'company'> {
  id: string;

  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  arrivalDate: DateTime;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  registrationDate: DateTime;
  filingYear: string;
  filingNumber: string;

  @Transform(({ value }: { value: string }) => jsonParse(value), {
    toClassOnly: true,
  })
  @Transform(({ value }) => jsonStringify(value), { toPlainOnly: true })
  subjects: { kind: string; value: string }[];

  @Transform(({ value }: { value: string }) => jsonParse(value), {
    toClassOnly: true,
  })
  @Transform(({ value }) => jsonStringify(value), { toPlainOnly: true })
  status: { kod: string; value: string }[];

  companyId: string;

  readonly createdAt: string;
  readonly updatedAt: string;

  get filingRef() {
    return `${this.filingNumber}/${this.filingYear}`;
  }

  constructor(seed?: Partial<SnrCase>) {
    Object.assign(this, seed);
  }
}
