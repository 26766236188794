import { Trans } from '@lingui/react';
import * as Sentry from '@sentry/browser';
import {
  Box,
  Typography,
  DialogTitle,
  Modal,
  ModalClose,
  ModalDialog,
  Button,
  Stack,
  CircularProgress,
} from '@startuptools/ui';
import { GenericError } from '@startuptools/ui';
import React from 'react';
import { FormContainer, RadioButtonGroup } from 'react-hook-form-mui';
import { PDFModal } from './PDFModal';

type Mode = 'generate' | 'view';

type State = {
  mode: Mode;
  showLanguageChooser: boolean;
  showPreview: boolean;
  loading: boolean;
  blob: Blob | undefined;
  fileName?: string | undefined;
  src?: string | undefined;
  error: boolean;
};

type Action =
  | {
      type: 'CLOSE_LANGUAGE_CHOOSER' | 'START_GENERATION' | 'CLOSE' | 'ERROR';
      payload?: never;
    }
  | { type: 'GENERATION_COMPLETE'; payload: { fileName: string; blob: Blob } };

const reducer = (state: State, { type, payload }: Action): State => {
  switch (type) {
    case 'CLOSE_LANGUAGE_CHOOSER':
      return { ...state, showLanguageChooser: false };
    case 'START_GENERATION':
      return { ...state, error: false, loading: true, showLanguageChooser: false };
    case 'GENERATION_COMPLETE':
      return { ...state, loading: false, blob: payload.blob, fileName: payload.fileName, showPreview: true };
    case 'CLOSE':
      return {
        loading: false,
        blob: undefined,
        error: false,
        showPreview: false,
        mode: state.mode,
        showLanguageChooser: false,
      };
    case 'ERROR':
      return { ...state, error: true, loading: false };
    default:
      return state;
  }
};

type GenerateProps = {
  mode: 'generate';
  generatePdf: (language: {
    primaryLang: boolean;
    secondaryLang: boolean;
  }) => Promise<{ filename: string; blob: Blob }>;
  src?: never;
  fileName?: never;
  chooseLanguage?: boolean;
  allowBiLingual?: boolean;
  onClose: () => void;
};
type ViewProps = {
  mode: 'view';
  chooseLanguage?: never;
  allowBiLingual?: never;
  generatePdf?: never;
  src: string;
  fileName: string;
  onClose: () => void;
};

type Props = GenerateProps | ViewProps;

export const PDFPreview = ({
  chooseLanguage = false,
  allowBiLingual = true,
  src,
  mode,
  generatePdf,
  fileName,
  onClose,
}: Props) => {
  const [{ error, loading, showPreview, showLanguageChooser, ...state }, dispatch] = React.useReducer(reducer, {
    loading: false,
    blob: undefined,
    error: false,
    showPreview: false,
    mode,
    showLanguageChooser: chooseLanguage,
  });
  const usedFileName = mode === 'view' ? fileName : state.fileName;
  if (showPreview && !usedFileName) {
    throw new Error('no filename found');
  }
  const close = () => {
    onClose();
    dispatch({ type: 'CLOSE' });
  };
  const generate = React.useCallback(
    async (language?: string) => {
      dispatch({ type: 'START_GENERATION' });
      try {
        if (!generatePdf) throw new Error('generatePdf is undefined');
        const lang = {
          primaryLang: language === 'both' || language === 'swedish',
          secondaryLang: language === 'both' || language === 'english',
        };
        const { filename, blob } = await generatePdf(lang);
        dispatch({ type: 'GENERATION_COMPLETE', payload: { fileName: filename, blob } });
      } catch (error) {
        Sentry.captureException(new Error('Failed to generate PDF', { cause: error }));
        dispatch({ type: 'ERROR' });
      }
    },
    [generatePdf],
  );

  React.useEffect(() => {
    if (!chooseLanguage && mode === 'generate') {
      void generate();
    }
  }, [chooseLanguage, generate, mode]);

  const languageOptions = [
    { label: <Trans id="English" />, id: 'english' },
    { label: <Trans id="Swedish" />, id: 'swedish' },
  ];
  if (allowBiLingual) {
    languageOptions.push({ label: <Trans id="Both Swedish & English" />, id: 'both' });
  }

  return (
    <Modal open onClose={close}>
      <ModalDialog sx={showPreview || loading ? { width: '80vw', height: '80vh' } : undefined}>
        <ModalClose onClick={() => dispatch({ type: 'CLOSE_LANGUAGE_CHOOSER' })} />
        <DialogTitle
          sx={{ paddingRight: '15px' /* compensate for Joy bug where Close button overlaps title*/ }}
        ></DialogTitle>

        {showPreview && <PDFModal fileName={usedFileName!} src={src} blob={state.blob} />}

        <Stack alignItems="center" justifyContent="center">
          {loading && <CircularProgress />}
          {showLanguageChooser && (
            <Stack>
              <Typography level="h4" sx={{ textAlign: 'center' }}>
                <Trans id="In what language do you want your document?" />
              </Typography>
              <FormContainer
                defaultValues={{ language: 'english' }}
                onSuccess={async ({ language }) => {
                  void generate(language);
                }}
                onError={error => {
                  Sentry.captureException(new Error('Failed to generate PDF', { cause: error }));
                  dispatch({ type: 'ERROR' });
                }}
              >
                <RadioButtonGroup disabled={loading} name="language" options={languageOptions} />
                {error && <GenericError />}
                <Box mt={2}>
                  <Button type="submit" variant="outlined" loading={loading}>
                    <Trans id="Create" />
                  </Button>
                </Box>
              </FormContainer>
            </Stack>
          )}
        </Stack>
      </ModalDialog>
    </Modal>
  );
};
