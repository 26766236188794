import { Trans, useLingui } from '@lingui/react';
import { useDebounce } from '@startuptools/common/react-hooks';
import {
  Button,
  Card,
  CircularProgress,
  CloseIcon,
  DateFormat,
  ErrorBoundary,
  ExpandLessIcon,
  ExpandMoreIcon,
  Gravatar,
  IconButton,
  Input,
  SearchIcon,
  Sheet,
  Stack,
  Table,
} from '@startuptools/ui';
import { useForm } from 'react-hook-form';
import {
  GqlAdminUsersQuery,
  useAdminUsersSuspenseQuery,
  useUserSyncToHubspotMutation,
} from '../../graphql/react-operations';
import { ArrayElement, protectedCompanies } from '@startuptools/common/common';
import { DateTime } from 'luxon';
import { Suspense, useState } from 'react';
import { IdentityDisplay } from '../../components/identity/IdentityDisplay';
import { useNgRouter } from '../../components/react-wrapper/AngularRouterContext';

const UsersTableRow = ({
  user,
  onClose,
}: {
  user: ArrayElement<GqlAdminUsersQuery['adminUsers']>;
  onClose: () => void;
}) => {
  const { i18n } = useLingui();
  const { changeCompany } = useNgRouter();
  const [open, setOpen] = useState(false);
  const [doSyncToHubspot, { loading }] = useUserSyncToHubspotMutation({ variables: { userId: user.id } });

  const handleNavigate = (companyId: string) => {
    void changeCompany(companyId);
    onClose();
  };

  return (
    <>
      <tr>
        <td>
          <IconButton variant="outlined" onClick={() => setOpen(!open)} disabled={user.companyUsers.length === 0}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </td>
        <td style={{ maxWidth: '200px' }}>
          <IdentityDisplay
            name={user.name}
            secondRow={user.personalId}
            startDecorator={
              <Gravatar md5Hash={user.emailMd5} uuid={user.id} size="sm">
                {user.name?.[0]}
              </Gravatar>
            }
          />
        </td>
        <td>{user.email}</td>
        <td>{user.phone}</td>
        <td align="right">{user.companyUsers.length}</td>
        <td align="right">{user.signInCount}</td>
        <td>
          {user.lastSignInAt && (
            <DateFormat locale={i18n.locale} format={DateTime.DATETIME_SHORT}>
              {user.lastSignInAt}
            </DateFormat>
          )}
        </td>
        <td>
          <DateFormat locale={i18n.locale} format={DateTime.DATETIME_SHORT}>
            {user.createdAt}
          </DateFormat>
        </td>
        <td align="right">
          {user.hubspotObjectId ? (
            user.hubspotObjectId
          ) : (
            <Button variant="soft" color="neutral" size="sm" onClick={() => doSyncToHubspot()} loading={loading}>
              <Trans id="Sync to Hubspot" />
            </Button>
          )}
        </td>
      </tr>
      {open && (
        <tr>
          <td colSpan={9}>
            <Sheet variant="soft">
              <Table sx={{ tableLayout: 'auto' }}>
                <thead>
                  <tr>
                    <th>
                      <Trans id="Company" />
                    </th>
                    <th>
                      <Trans id="Created At" />
                    </th>
                    <th>HubSpot ID</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {user.companyUsers
                    .filter(c => !protectedCompanies.includes(c.company.organizationNumber))
                    .map(({ company }) => (
                      <tr key={company.id}>
                        <td>
                          <IdentityDisplay name={company.name} secondRow={company.organizationNumber} />
                        </td>
                        <td>
                          <DateFormat locale={i18n.locale} format={DateTime.DATETIME_SHORT}>
                            {company.createdAt}
                          </DateFormat>
                        </td>
                        <td align="right">{company.hubspotObjectId}</td>
                        <td align="right">
                          <Button
                            variant="outlined"
                            color="neutral"
                            size="sm"
                            onClick={() => handleNavigate(company.id)}
                          >
                            <Trans id="Visit" />
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Sheet>
          </td>
        </tr>
      )}
    </>
  );
};

const UsersTable = ({ searchString, onClose }: { searchString: string; onClose: () => void }) => {
  const {
    data: { adminUsers: users },
  } = useAdminUsersSuspenseQuery({
    variables: { input: { filter: searchString, pageOffset: 0, pageLimit: 30 } },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Card sx={{ width: '100%' }}>
      <Table sx={{ tableLayout: 'auto', width: '100%' }}>
        <thead>
          <tr>
            <th />
            <th>
              <Trans id="User" />
            </th>
            <th>
              <Trans id="Email" />
            </th>
            <th>
              <Trans id="Phone" />
            </th>
            <th align="right">
              <Trans id="Companies" />
            </th>
            <th>
              <Trans id="Login Count" />
            </th>
            <th>
              <Trans id="Last Login" />
            </th>
            <th>
              <Trans id="First Login" />
            </th>
            <th align="right">HubSpot ID</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <UsersTableRow key={user.id} user={user} onClose={onClose} />
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export const Users = ({ onClose }: { onClose: () => void }) => {
  const { i18n } = useLingui();
  const { register, watch, setValue } = useForm<{ searchString: string }>({ defaultValues: { searchString: '' } });
  const searchString = watch('searchString');
  const debouncedSearchString = useDebounce(searchString);
  return (
    <Stack gap={2} alignItems="center">
      <Input
        fullWidth
        {...register('searchString')}
        startDecorator={searchString === debouncedSearchString ? <SearchIcon /> : <CircularProgress size="sm" />}
        endDecorator={
          searchString?.length ? (
            <IconButton onClick={() => setValue('searchString', '')}>
              <CloseIcon />
            </IconButton>
          ) : undefined
        }
        type="search"
        placeholder={i18n._('Search...')}
      />
      <ErrorBoundary>
        <Suspense>
          <UsersTable searchString={debouncedSearchString} onClose={onClose} />
        </Suspense>
      </ErrorBoundary>
    </Stack>
  );
};
