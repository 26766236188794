import { GqlMeetingAgendaTemplate } from '../graphql/operations';
import { AutoFields } from '.';

export class MeetingAgendaTemplate implements Omit<GqlMeetingAgendaTemplate, 'meetingAgendaTemplateItems' | 'company'> {
  id: string;

  name: string;
  companyId: string;
  isDefault: boolean;

  readonly createdAt: string;
  readonly updatedAt: string;

  constructor(seed?: Omit<MeetingAgendaTemplate, AutoFields>) {
    Object.assign(this, seed);
  }
}
