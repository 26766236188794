import { NumberFormat } from './NumberFormat';

export const StripeCurrencyFormat = ({
  children,
  currency,
  locale,
  maximumFractionDigits = 2,
  minimumFractionDigits = 0,
}: {
  children: number;
  currency: string;
  locale: 'en' | 'sv' | string;
  maximumFractionDigits?: Intl.NumberFormatOptions['maximumFractionDigits'];
  minimumFractionDigits?: Intl.NumberFormatOptions['minimumFractionDigits'];
}) => {
  return (
    <NumberFormat
      variant="currency"
      minimumFractionDigits={minimumFractionDigits}
      maximumFractionDigits={maximumFractionDigits}
      currency={currency}
      locale={locale}
    >
      {children / 100}
    </NumberFormat>
  );
};
