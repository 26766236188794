import { Avatar } from '../';
import { isNil } from 'lodash-es';
import { PropsWithChildren, useMemo } from 'react';

export interface Props {
  md5Hash: string | null | undefined;
  size?: 'sm' | 'md' | 'lg';
  rating?: 'g' | 'pg' | 'r' | 'x';
  def?: '404' | 'mp' | 'identicon' | 'monsterid' | 'wavatar' | 'retro' | 'robohash' | 'blank';
  uuid: string | null | undefined;
}

const sizeMap = {
  sm: 32,
  md: 40,
  lg: 48,
};

const BASE_URL = 'https://www.gravatar.com/avatar/';
const DEFAULT_MD5 = 'd41d8cd98f00b204e9800998ecf8427e';

export function Gravatar({
  md5Hash,
  size = 'md',
  rating = 'g',
  def = '404',
  uuid,
  children,
}: PropsWithChildren<Props>) {
  const src = useMemo(() => {
    const url = new URL(md5Hash || DEFAULT_MD5, BASE_URL);
    url.searchParams.append('s', `${sizeMap[size]}`);
    url.searchParams.append('r', rating);
    url.searchParams.append('d', def);
    return url.href;
  }, [def, md5Hash, rating, size]);

  const bgcolor = useMemo(() => {
    return `hsl(${parseInt(uuid?.substring(0, 8) ?? '0', 16) % 360}deg ${!isNil(uuid) ? '80% 40%' : '0% 70%'})`;
  }, [uuid]);

  return (
    <Avatar
      size={size}
      sx={theme => ({ bgcolor, color: theme.palette.neutral['100'] })}
      src={src}
      slotProps={{ img: { loading: 'lazy' } }}
    >
      {children}
    </Avatar>
  );
}
