import {
  Stack,
  IconButton,
  SearchIcon,
  Input,
  Button,
  FormControl,
  FormLabel,
  CloseIcon,
  Checkbox,
} from '@startuptools/ui';
import { useTransition } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Trans, useLingui } from '@lingui/react';
import { JuridicalKind } from '../../models/identity.model';
import { GqlSearchIdentitiesInput } from '../../graphql/base-types.graphql';
import { Relations } from './InputSystem';

const searchFieldLabel = (kinds: JuridicalKind[]) => {
  if (kinds.includes(JuridicalKind.Company) && kinds.includes(JuridicalKind.Person)) {
    return <Trans id="Name or org/personal number" />;
  } else if (kinds.includes(JuridicalKind.Company)) {
    return <Trans id="Name or organization number" />;
  } else if (kinds.includes(JuridicalKind.Person)) {
    return <Trans id="Name or personal number" />;
  }
};

interface Props {
  juridicalKinds: JuridicalKind[];
  onSubmit: (data: GqlSearchIdentitiesInput) => void;
  defaultRelations?: Relations;
  showJuridicalKindSelector: boolean;
  loading: boolean;
}

export const IdentitySearch = ({
  juridicalKinds,
  showJuridicalKindSelector,
  onSubmit,
  defaultRelations = {},
  loading,
}: Props) => {
  const { i18n } = useLingui();
  const { register, handleSubmit, setValue, watch } = useForm<GqlSearchIdentitiesInput>({
    defaultValues: { searchString: '' },
  });
  const [pending, startTransition] = useTransition();
  const relations = watch('relations');

  const submitHandler: SubmitHandler<GqlSearchIdentitiesInput> = formData => {
    startTransition(() => {
      onSubmit(formData);
    });
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Stack gap={2}>
        <FormControl>
          <FormLabel>{searchFieldLabel(juridicalKinds)}</FormLabel>
          <Input
            fullWidth
            {...register('searchString')}
            startDecorator={<SearchIcon />}
            endDecorator={
              <Stack direction="row" gap={1}>
                <IconButton>
                  <CloseIcon />
                </IconButton>
                <Button variant="soft" color="neutral" type="submit" loading={loading || pending}>
                  <Trans id="Search" />
                </Button>
              </Stack>
            }
            type="search"
            placeholder={i18n._('Search...')}
          />
        </FormControl>
        {showJuridicalKindSelector && (
          <Stack direction="row" gap={2}>
            <Checkbox
              label={<Trans id="Boardmember/CEO" />}
              checked={relations?.companyDirector ?? defaultRelations.companyDirector}
              onChange={event =>
                setValue('relations', {
                  ...relations,
                  companyDirector: event.target.checked,
                })
              }
            />
            <Checkbox
              label={<Trans id="Meeting participant" />}
              checked={relations?.meetingParticipant ?? defaultRelations.meetingParticipant}
              onChange={event =>
                setValue('relations', {
                  ...relations,
                  meetingParticipant: event.target.checked,
                })
              }
            />
            <Checkbox
              label={<Trans id="Shareholder" />}
              checked={relations?.shareholder ?? defaultRelations.shareholder}
              onChange={event =>
                setValue('relations', {
                  ...relations,
                  shareholder: event.target.checked,
                })
              }
            />
          </Stack>
        )}
      </Stack>
    </form>
  );
};
