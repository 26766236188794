import { useEffect, useState } from 'react';
import { CircularProgress, Stack } from '@startuptools/ui';

const qrSrc = (qrStartToken: string, seconds: number) => {
  return `/auth-service/api/v1/qr/${qrStartToken}/${seconds}`;
};

const MAX_TIME = 30;

export const Qr = ({ qrStartToken }: { qrStartToken: string }) => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => setSeconds(s => s + 1), 1000);
    return () => clearInterval(interval);
  }, []);

  const secondsLeft = MAX_TIME - seconds;

  return (
    <Stack gap={1} justifyContent="center" alignItems="center">
      <img src={qrSrc(qrStartToken, seconds)} width="280px" height="280px" alt="BankID QR Code" />
      <CircularProgress
        determinate
        value={(secondsLeft / MAX_TIME) * 100}
        color={secondsLeft < 10 ? 'warning' : 'primary'}
      >
        {secondsLeft}s
      </CircularProgress>
    </Stack>
  );
};
