<div class="dialog">
  <div class="dialog-close">
    <button mat-icon-button [mat-dialog-close]="null">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="dialog-title">
    <h5 i18n>Välj bemyndigandet</h5>
  </div>

  <div mat-dialog-content class="dialog-content">
    <mat-list>
      <ng-container *ngIf="(snrAuthorizations$ | async)?.length > 0; else noAuths">
        <table mat-table [dataSource]="snrAuthorizations$" style="width: 100%">
          <ng-container matColumnDef="registrationDate">
            <td mat-header-cell *matHeaderCellDef i18n>Registrerat</td>
            <td mat-cell *matCellDef="let row">{{ row.registrationDate | dateTimeLocaleStr }}</td>
          </ng-container>

          <ng-container matColumnDef="kinds">
            <td mat-header-cell *matHeaderCellDef i18n>Typer</td>
            <td mat-cell *matCellDef="let row">{{ row.auths | authJsonKindHuman }}</td>
          </ng-container>

          <ng-container matColumnDef="validThru">
            <td mat-header-cell *matHeaderCellDef i18n>Giltig t.o.m.</td>
            <td mat-cell *matCellDef="let row">
              <ng-container *ngIf="row.auths[0]?.before_year"
                ><ng-container i18n>årsstämman</ng-container> {{ row.auths[0]?.before_year }}</ng-container
              >
              <ng-container *ngIf="row.auths[0]?.before">{{ row.auths[0]?.before }}</ng-container>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" clickable (click)="selectAuth(row)"></tr>
        </table>
      </ng-container>
      <ng-template #noAuths>
        <mat-list-item>
          <span matListItemTitle i18n>DET FINNS INGA BEMYNDIGANDEN.</span>
        </mat-list-item>
      </ng-template>
    </mat-list>
  </div>
</div>
