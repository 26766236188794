import { createAction, props, union } from '@ngrx/store';

import { NiceFounder } from '../../models/nice-founder.model';

const prefix = '[NiceFounders]' as const;

export const niceFoundersActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: NiceFounder[] }>()),
  manyDeleted: createAction(prefix + ' manyDeleted', props<{ payload: string[] }>()),
};

const all = union(niceFoundersActions);

export type ActionsUnion = typeof all;
