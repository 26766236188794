import { GqlAuthorizationGroupDocument } from '../graphql/operations';

export class AuthorizationGroupDocument
  implements Omit<GqlAuthorizationGroupDocument, 'authorizationGroup' | 'document'>
{
  id: string;

  authorizationGroupId: string;
  documentId: string;

  readonly createdAt: string;
  readonly updatedAt: string;

  constructor(seed?: Partial<AuthorizationGroupDocument>) {
    Object.assign(this, seed);
  }
}
