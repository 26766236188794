import { GqlCreateIdentityInput } from '../../graphql/react-operations';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { IDKind, JuridicalKind } from '../../models/identity.model';
import { Trans, useLingui } from '@lingui/react';
import { countryNames } from '@startuptools/common/common';
import { useDebounce } from '@startuptools/common/react-hooks';
import { DuplicateIdentity } from './DuplicateIdentity';
import { Button, FormControl, FormLabel, Input, Option, Radio, RadioGroup, Select, Box, Stack } from '@startuptools/ui';

type FormValues = Pick<GqlCreateIdentityInput, 'juridicalKind' | 'countryCode' | 'email' | 'name' | 'address'>;

interface Props {
  juridicalKinds: JuridicalKind[];
  handleCreate: (identity: GqlCreateIdentityInput) => Promise<unknown>;
}

export const InputForeign = ({ juridicalKinds, handleCreate }: Props) => {
  const { i18n } = useLingui();
  const { register, handleSubmit, control, setValue, watch } = useForm<FormValues>({
    defaultValues: { countryCode: 'SE', juridicalKind: juridicalKinds.length === 1 ? juridicalKinds[0] : undefined },
  });
  const name = watch('name');
  const debouncedName = useDebounce(name, 500);

  const onSubmit: SubmitHandler<FormValues> = input => {
    return handleCreate({
      ...input,
      juridicalKind: Number(input.juridicalKind),
      identityNumberKind: IDKind.ForeignManual,
      identityNumber: crypto.randomUUID(),
      email: input.email || undefined,
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column" gap={2}>
          {JuridicalKind.Person in juridicalKinds && JuridicalKind.Company in juridicalKinds && (
            <Controller
              control={control}
              name="juridicalKind"
              render={({ field }) => (
                <RadioGroup {...field} orientation="horizontal">
                  <Radio value={JuridicalKind.Person} label={<Trans id="Person" />} required />
                  <Radio value={JuridicalKind.Company} label={<Trans id="Company" />} required />
                </RadioGroup>
              )}
            />
          )}
          <FormControl>
            <FormLabel>
              <Trans id="Name" />
            </FormLabel>
            <Input {...register('name')} required />
          </FormControl>
          <FormControl>
            <FormLabel>
              <Trans id="Email" />
            </FormLabel>
            <Input {...register('email')} type="email" />
          </FormControl>
          <FormControl>
            <FormLabel>
              <Trans id="Address" />
            </FormLabel>
            <Input {...register('address')} />
          </FormControl>
          <FormControl>
            <FormLabel>
              <Trans id="Country" />
            </FormLabel>
            <Select
              name="countryCode"
              placeholder="Country"
              defaultValue={'SE'}
              onChange={(_event, value) => setValue('countryCode', value as unknown as string)}
              required
            >
              {countryNames[i18n.locale as 'sv' | 'en'].map(country => (
                <Option key={country.code} value={country.code}>
                  {country.name}
                </Option>
              ))}
            </Select>
          </FormControl>

          {(debouncedName?.length ?? 0) > 3 && (
            <DuplicateIdentity
              searchString={debouncedName}
              handleCreate={handleCreate}
              juridicalKinds={juridicalKinds}
            />
          )}

          <Box alignSelf="end">
            <Button type="submit">
              <Trans id="Save" />
            </Button>
          </Box>
        </Stack>
      </form>
    </>
  );
};
