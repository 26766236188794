import { PropsWithChildren, createContext, useContext } from 'react';

const Context = createContext<string | null | undefined>(null);

interface Props {
  userId: string | null | undefined;
}

export const UserIdProvider = ({ userId, children }: PropsWithChildren<Props>) => {
  return <Context.Provider value={userId}>{children}</Context.Provider>;
};

export const useUserId = () => useContext(Context);
