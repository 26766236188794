import { Transform } from 'class-transformer';
import { jsonParse, jsonStringify } from './utils';
import { Identity } from './identity.model';
import { BM } from '../common/types';
import { GqlCompanyDirector } from '../graphql/operations';

export interface ICompanyDirectorFunction {
  kind: string;
  code: BM;
}

export interface CdWithIncludes {
  model: CompanyDirector;
  identity: Identity;
}

export class CompanyDirector implements Omit<GqlCompanyDirector, 'identity'> {
  id: string;

  registeredAbroad: boolean;
  invited: boolean;
  companyId: string;
  identityId: string;

  @Transform(({ value }: { value: string }) => jsonParse(value), {
    toClassOnly: true,
  })
  @Transform(({ value }) => jsonStringify(value), { toPlainOnly: true })
  functions: ICompanyDirectorFunction[];

  readonly createdAt: string;
  readonly updatedAt: string;

  constructor(seed?: Partial<CompanyDirector>) {
    Object.assign(this, seed);
  }
}
