import { RefetchQueriesInclude, useApolloClient } from '@apollo/client';
import { Trans, useLingui } from '@lingui/react';
import {
  Stack,
  Tooltip,
  Input,
  Link,
  IconButton,
  CircularProgress,
  Button,
  CancelIcon,
  SaveIcon,
} from '@startuptools/ui';
import { MouseEvent, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

interface FormValues {
  email: string | null;
}

export const EditableEmail = ({
  onChange,
  refetchQueries,
  children,
}: {
  onChange: (newEmail: string | null) => Promise<void>;
  refetchQueries: RefetchQueriesInclude;
  children: string | null | undefined;
}) => {
  const { i18n } = useLingui();
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const apollo = useApolloClient();

  const { register, handleSubmit, reset, formState } = useForm<FormValues>({ defaultValues: { email: children } });

  const handleSetEditMode = (event: MouseEvent) => {
    event.stopPropagation();
    setEditMode(true);
  };

  if (!editMode) {
    return children ? (
      <Tooltip title={<Trans id="Click to edit email address" />}>
        <Link underline="hover" color="neutral" onClick={handleSetEditMode}>
          {children}
        </Link>
      </Tooltip>
    ) : (
      <Button variant="outlined" size="sm" onClick={handleSetEditMode}>
        <Trans id="Set email" />
      </Button>
    );
  }

  const handleAbort = () => {
    setEditMode(false);
    reset();
  };

  const submit: SubmitHandler<FormValues> = async data => {
    setLoading(true);
    try {
      await onChange(data.email?.trim() ?? null);
      await apollo.refetchQueries({ include: refetchQueries });
      setEditMode(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Input
        {...register('email', {
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: 'Entered value does not match email format',
          },
        })}
        placeholder={i18n._('Enter email address here')}
        type="email"
        color={formState.errors.email ? 'danger' : undefined}
        endDecorator={
          loading ? (
            <CircularProgress size="sm" />
          ) : (
            <Stack direction="row" gap={1}>
              <Tooltip title={<Trans id="Cancel" />}>
                <IconButton color="danger" onClick={handleAbort} type="button">
                  <CancelIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={<Trans id="Save" />}>
                <IconButton color="primary" type="submit">
                  <SaveIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          )
        }
      />
    </form>
  );
};
