import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import { map } from 'lodash-es';

import { Authorization } from '../../models/authorization.model';
import { authorizationsActions } from '../../store/actions/authorizations.actions';

export type AuthorizationsState = EntityState<Authorization>;

export const adapter = createEntityAdapter<Authorization>();
export const initialAuthorizationsState = adapter.getInitialState({});

export const reducer = createReducer(
  initialAuthorizationsState,
  on(authorizationsActions.manyLoaded, (state, { payload }) =>
    adapter.addMany(
      payload,
      adapter.removeMany(
        map(payload, e => e.id),
        state,
      ),
    ),
  ),
  on(authorizationsActions.manyDeleted, (state, { payload }) => adapter.removeMany(payload, state)),
);

export function authorizationsReducer(state: EntityState<Authorization> | undefined, action: Action) {
  return reducer(state, action);
}
