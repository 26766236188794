import type * as Types from '../../graphql/base-types.graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GqlTruidTokenQueryVariables = Types.Exact<{
  input: Types.GqlTruidTokenInput;
}>;


export type GqlTruidTokenQuery = { truidToken: { jwt: string } };


export const TruidTokenDocument = gql`
    query TruidToken($input: TruidTokenInput!) {
  truidToken(input: $input) {
    jwt
  }
}
    `;

/**
 * __useTruidTokenQuery__
 *
 * To run a query within a React component, call `useTruidTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useTruidTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTruidTokenQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTruidTokenQuery(baseOptions: Apollo.QueryHookOptions<GqlTruidTokenQuery, GqlTruidTokenQueryVariables> & ({ variables: GqlTruidTokenQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlTruidTokenQuery, GqlTruidTokenQueryVariables>(TruidTokenDocument, options);
      }
export function useTruidTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlTruidTokenQuery, GqlTruidTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlTruidTokenQuery, GqlTruidTokenQueryVariables>(TruidTokenDocument, options);
        }
export function useTruidTokenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GqlTruidTokenQuery, GqlTruidTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GqlTruidTokenQuery, GqlTruidTokenQueryVariables>(TruidTokenDocument, options);
        }
export type TruidTokenQueryHookResult = ReturnType<typeof useTruidTokenQuery>;
export type TruidTokenLazyQueryHookResult = ReturnType<typeof useTruidTokenLazyQuery>;
export type TruidTokenSuspenseQueryHookResult = ReturnType<typeof useTruidTokenSuspenseQuery>;
export type TruidTokenQueryResult = Apollo.QueryResult<GqlTruidTokenQuery, GqlTruidTokenQueryVariables>;