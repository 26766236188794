import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouter } from '../../store/selectors/router.selectors';

import { CompaniesState } from '../reducers/companies.reducers';

export const selectCompaniesState = createFeatureSelector<CompaniesState>('companies');

export const selectCompanyId = createSelector(selectRouter, router => {
  return router?.state.params.companyId;
});
export const selectCompany = createSelector(selectCompaniesState, selectCompanyId, (state, companyId) => {
  return companyId ? state.entities[companyId] : null;
});
