import { createReducer, on, Action } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { map } from 'lodash-es';

import { NiceFounder } from '../../models/nice-founder.model';
import { niceFoundersActions } from '../actions/nice-founders.actions';

export type NiceFoundersState = EntityState<NiceFounder>;

export const adapter = createEntityAdapter<NiceFounder>();
export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(niceFoundersActions.manyLoaded, (state, { payload }) =>
    adapter.addMany(
      payload,
      adapter.removeMany(
        map(payload, p => p.id),
        state,
      ),
    ),
  ),
  on(niceFoundersActions.manyDeleted, (state, { payload }) => adapter.removeMany(payload, state)),
);

export function niceFoundersReducer(state: EntityState<NiceFounder> | undefined, action: Action) {
  return reducer(state, action);
}
