import { enableProdMode } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import localeSv from '@angular/common/locales/sv';
import localeEn from '@angular/common/locales/en';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

registerLocaleData(localeSv, 'sv');
registerLocaleData(localeEn, 'en');

if (environment.production) {
  enableProdMode();
}

void platformBrowserDynamic().bootstrapModule(AppModule);
