import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Identity } from '../../models/identity.model';
import { DocumentSignature } from '../../models/document-signature.model';

export interface SignatoryRow {
  documentSignature: DocumentSignature;
  identity: Identity;
  proxy?: Identity;
}

@Injectable({ providedIn: 'root' })
export class DocumentUploadDialogStore extends ComponentStore<{
  files: File[];
  signatories: SignatoryRow[];
}> {
  addSignatories = this.updater((state, signatory: SignatoryRow) => {
    return {
      ...state,
      signatories: [signatory, ...state.signatories],
    };
  });

  updateSignatories = this.updater((state, signatories: SignatoryRow[]) => {
    return {
      ...state,
      signatories,
    };
  });

  updateFiles = this.updater((state, files: File[]) => {
    return {
      ...state,
      files,
    };
  });

  constructor() {
    super({
      files: [],
      signatories: [],
    });
  }
}
