'use client';
import { Button, CircularProgress, GenericError, Stack, Typography } from '@startuptools/ui';
import { useMediaQuery } from '@mui/material';
import { Trans, useLingui } from '@lingui/react';
import {
  GqlAud,
  GqlHintCode,
  GqlStatus,
  useBankidCancelMutation,
  useBankidCollectQuery,
} from '../graphql/react-operations';
import { BankidError } from './Error';
import { AUTH_JWT_KEY } from '../constants';
import { Navigate } from '../types';
import { Qr } from './Qr';
import { Suspense, useEffect } from 'react';
import { getRedirectParam, getRedirectUrl } from '../utils/redirect';

// const bankIdUri = (autoStartToken: string) => {
//   return `bankid:///?autostarttoken=${autoStartToken}&redirect=null`;
// };

interface Props {
  autoStartToken: string;
  qrStartToken: string;
  orderRef: string;
  aud: GqlAud;
  reInit: () => void;
  navigate: Navigate;
}

const usePollForBankId = (orderRef: string, aud: GqlAud) => {
  const { data, loading, stopPolling, error } = useBankidCollectQuery({
    variables: { input: { orderRef, aud } },
    fetchPolicy: 'no-cache',
    pollInterval: 2000,
  });
  const [bankidCancel, bankidCancelMut] = useBankidCancelMutation({ variables: { orderRef }, fetchPolicy: 'no-cache' });
  useEffect(() => {
    if (data?.bankidCollect.status === GqlStatus.Complete || data?.bankidCollect.status === GqlStatus.Failed || error) {
      stopPolling();
    }
  }, [data?.bankidCollect.status, stopPolling, error]);
  return {
    cancel: bankidCancel,
    status: data?.bankidCollect.status,
    hintCode: data?.bankidCollect.hintCode,
    loading,
    loadingCancel: bankidCancelMut.loading,
    jwt: data?.bankidCollect.successfulAuthResponse?.jwt ?? '',
    error,
  };
};

export const BankidPoll = ({ autoStartToken, qrStartToken, orderRef, aud, reInit, navigate }: Props) => {
  const { i18n } = useLingui();
  const { cancel, loading, loadingCancel, jwt, status, hintCode, error } = usePollForBankId(orderRef, aud);
  const isDesktop = useMediaQuery('(min-width:768px)');
  const bankIdUri = `bankid:///?autostarttoken=${autoStartToken}&redirect=null`;

  const handleCancel = async () => {
    await cancel();
    navigate(`/auth?${getRedirectParam()}`);
  };

  useEffect(() => {
    if (status === GqlStatus.Complete) {
      localStorage.setItem(AUTH_JWT_KEY, jwt);
      const newUrl = `${getRedirectUrl()}`;
      navigate(newUrl, { replace: true }); // Make sure we don't save the init/poll state in the browser stack
      window.location.href = newUrl; // Remove when Angular is gone, this is a workaround to make sure angular router takes over from here
    }
  }, [i18n.locale, jwt, navigate, status, aud]);

  if (error) {
    <Stack gap={2}>
      <GenericError />;
      <Button onClick={() => navigate('/auth', { replace: true })} sx={{ marginTop: 2 }}>
        <Trans id="Go back" />
      </Button>
    </Stack>;
  }

  if (loading) {
    return (
      <Stack gap={2} justifyContent="center" alignItems="center">
        <CircularProgress size="lg" />
      </Stack>
    );
  }

  if (status === GqlStatus.Complete) {
    return (
      <p>
        <Trans id="Login successful, redirecting" />
      </p>
    );
  }

  if (status === GqlStatus.Failed) {
    return (
      <Stack gap={2}>
        <BankidError code={hintCode} reInit={reInit} />
        <Button
          variant="outlined"
          color="neutral"
          onClick={() => navigate('/auth', { replace: true })}
          sx={{ marginTop: 2 }}
        >
          <Trans id="Go back" />
        </Button>
      </Stack>
    );
  }

  const Instruction = () => {
    if (hintCode === GqlHintCode.UserSign) {
      return (
        <Typography textAlign="center">
          <Trans id="Enter your security code in the BankID app and select Identify or Sign." />{' '}
        </Typography>
      );
    } else if (isDesktop) {
      return (
        <Typography textAlign="center">
          <Trans id="Startup your BankID app and scan the QR code" />
        </Typography>
      );
    }
    return (
      <Button fullWidth onClick={() => (window.location.href = bankIdUri)}>
        <Trans id="Start BankID" />
      </Button>
    );
  };

  return (
    <Stack gap={2} alignItems="center">
      {qrStartToken && (
        <Suspense>
          <Qr qrStartToken={qrStartToken} />
        </Suspense>
      )}
      <Instruction />
      <Button onClick={() => handleCancel()} disabled={loadingCancel} fullWidth>
        <Trans id="Cancel" />
      </Button>
    </Stack>
  );
};
