import { Directive, HostListener, Input } from '@angular/core';
import { AbstractControl, NgControl, Validator } from '@angular/forms';
import { BigNumber } from 'bignumber.js';

@Directive({
  selector: 'input[integerOnly]', // eslint-disable-line @angular-eslint/directive-selector
})
export class IntegerOnlyDirective implements Validator {
  @Input() integerOnly: boolean;

  constructor(private ngControl: NgControl) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    if (this.integerOnly) {
      // @ts-expect-error TS2531
      this.ngControl.control.patchValue(value.replace(/[^0-9]/g, ''));
    }
  }

  validate(control: AbstractControl): Record<string, unknown> | null {
    if (new BigNumber(control.value).isInteger()) {
      return null;
    }
    return { integerOnly: true };
    // return maxNumberValidator(this.num)(control);
  }
}
