import { Injectable } from '@angular/core';
import { host } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { isEmpty } from 'lodash-es';

export interface BankGiro {
  name: string;
  orgnr: string;
  bgnr: string;
}

@Injectable({ providedIn: 'root' })
export class BankGrioService {
  constructor(private http: HttpClient) {}

  getBankGiro(searchStr: string) {
    const url = new URL('lookup-service/bankgirot/search', host.clientUrl);
    searchStr = searchStr.trim();
    if (searchStr.match(/\d{6}-?\d4/)) {
      url.searchParams.append('orgnr', searchStr);
    } else if (searchStr.match(/\d{3,4}-\d{4}/)) {
      url.searchParams.append('bgnr', searchStr);
    } else {
      url.searchParams.append('company', searchStr);
    }

    return this.http.get<BankGiro[]>(url.toString()).pipe(
      map(res => {
        if (!isEmpty(res)) {
          return res;
        } else {
          return [] as BankGiro[];
        }
      }),
      catchError(err => {
        console.error(err);
        return 'error';
      }),
    );
  }
}
