import { createAction, props, union } from '@ngrx/store';

import { MeetingAgendaTemplateItem } from '../../models/meeting-agenda-template-item.model';

const prefix = '[MeetingAgendaTemplateItems] ' as const;

export const meetingAgendaTemplateItemsActions = {
  manyLoaded: createAction(prefix + 'manyLoaded', props<{ payload: MeetingAgendaTemplateItem[] }>()),
  manyDeleted: createAction(prefix + ' manyDeleted', props<{ payload: string[] }>()),
};

const all = union(meetingAgendaTemplateItemsActions);

export type ActionsUnion = typeof all;
