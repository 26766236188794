import { createFeatureSelector, createSelector } from '@ngrx/store';
import { filter, some } from 'lodash-es';
import { MeetingsState } from '../reducers/meetings.reducers';
import { selectCompanyId } from './companies.base';
import { selectRouter } from '../../store/selectors/router.selectors';
import { GqlMeetingKind } from '../../graphql/operations';

export const selectMeetingsState = createFeatureSelector<MeetingsState>('meetings');
export const selectMeetingId = createSelector(selectRouter, router => router?.state.params.meetingId);
// @ts-expect-error TS2538
export const selectMeeting = createSelector(selectMeetingsState, selectMeetingId, (state, id) => state.entities[id]);
export const selectMeetingById = (id: string) => createSelector(selectMeetingsState, state => state.entities[id]);
export const selectMeetings = (kinds: GqlMeetingKind[]) =>
  createSelector(selectMeetingsState, selectCompanyId, (state, cid) => {
    // @ts-expect-error TS18048
    return filter(state.entities, e => some(kinds, k => e.kind === k) && e.companyId === cid);
  });
