import { Button, Dropdown, IconButton, Menu, MenuButton, MenuItem, MoreVertIcon } from '@startuptools/ui';
import React, { ComponentProps, MouseEventHandler } from 'react';

export type ActionProps = {
  children: React.ReactNode;
  onClick?: MouseEventHandler<HTMLElement>;
  visible: boolean;
  show: boolean;
  variant?: ComponentProps<typeof Button>['variant'];
};

export const Action = ({ visible, variant, onClick, show, ...rest }: ActionProps) => {
  if (!show) return null;
  if (visible) {
    return <Button {...rest} variant={variant} sx={{ whiteSpace: 'nowrap' }} onClick={onClick} />;
  }
  return <MenuItem {...rest} onClick={onClick} />;
};

export const Actions = ({ children, disabled }: { children: React.ReactNode; disabled: boolean }) => {
  const actions = Array.isArray(children) ? children.filter(Boolean) : [children];

  /* apply the disabled prop to each button,
  so you can disable all buttons at once by passing `disabled` to <Actions />
  */
  const withDisabled = React.Children.map(actions, c => {
    return React.cloneElement(c, { disabled });
  }) as React.ReactElement[];

  const menuButtons = withDisabled.filter(c => !c.props.visible && c.props.show);
  const visibleButtons = withDisabled.filter(c => c.props.visible);

  return (
    <>
      {Boolean(menuButtons.length) && (
        <Dropdown>
          <MenuButton
            disabled={disabled}
            slots={{ root: IconButton }}
            slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
          >
            <MoreVertIcon />
            <Menu>{menuButtons}</Menu>
          </MenuButton>
        </Dropdown>
      )}
      {visibleButtons}
    </>
  );
};
