import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpEventType } from '@angular/common/http';
import { VersionPromptService } from '../../injectables/version-prompt.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { gitRev } from '../../../environments/environment';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class VersionCheckInterceptor implements HttpInterceptor {
  constructor(private versionPrompt: VersionPromptService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      tap(res => {
        if (res.type === HttpEventType.Response) {
          if (res.headers.has('x-qoorp-version') && res.headers.get('x-qoorp-version') !== gitRev) {
            // this.versionPrompt.showPrompt(); TODO: re-enable
          }
        }
      }),
    );
  }
}
