import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

interface ScrollCommand {
  id: string;
  scrollToOptions: ScrollToOptions;
}

@Injectable({ providedIn: 'root' })
export class ScrollService {
  private _subject = new Subject<ScrollCommand>();

  get listen() {
    return this._subject.asObservable();
  }

  public scroll(id: string, pos: ScrollToOptions) {
    this._subject.next({ id: id, scrollToOptions: pos });
  }
}
