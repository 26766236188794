import { createReducer, on, Action } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { map } from 'lodash-es';

import { snrAuthorizationsActions } from '../../store/actions/snr-authorizations.actions';
import { SnrAuthorization } from '../../models/snr-authorization.model';

export type SnrAuthorizationsState = EntityState<SnrAuthorization>;

export const adapter = createEntityAdapter<SnrAuthorization>();
export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(snrAuthorizationsActions.manyLoaded, (state, { payload }) =>
    adapter.addMany(
      payload,
      adapter.removeMany(
        map(payload, p => p.id),
        state,
      ),
    ),
  ),
);

export function snrAuthorizationsReducer(state: EntityState<SnrAuthorization> | undefined, action: Action) {
  return reducer(state, action);
}
