import { createAction, props, union } from '@ngrx/store';

import { Authorization } from '../../models/authorization.model';

const prefix = '[Authorizations] ' as const;

export const authorizationsActions = {
  manyLoaded: createAction(prefix + 'manyLoaded', props<{ payload: Authorization[] }>()),
  manyDeleted: createAction(prefix + ' manyDeleted', props<{ payload: string[] }>()),
};

const all = union(authorizationsActions);

export type ActionsUnion = typeof all;
