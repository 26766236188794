import { createAction, props, union } from '@ngrx/store';

import { Identity } from '../../models/identity.model';

const prefix = '[Identities]' as const;

export const identitiesActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: Identity[] }>()),
};

const all = union(identitiesActions);

export type ActionsUnion = typeof all;
