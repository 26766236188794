import { createAction, props, union } from '@ngrx/store';
import { FetchResult, ApolloQueryResult } from '@apollo/client/core';
import { Observable } from 'rxjs';

import { OperationId } from '../../injectables/gql-dispatch.service';

const name = (prefix: string) => `[GQL] ${prefix}`;

export interface Payload<T> {
  opId: OperationId;
  result?: ApolloQueryResult<T> | FetchResult<T>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
}

export const graphqlActions = {
  query: createAction(
    name('query'),
    props<{
      payload: {
        opId: OperationId;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        query: () => Observable<ApolloQueryResult<any>>;
      };
    }>(),
  ),
  mutate: createAction(
    name('mutate'),
    props<{
      payload: {
        opId: OperationId;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        mutation: () => Observable<FetchResult<any>>;
      };
    }>(),
  ),
  result: createAction(
    name('result'),
    props<{
      payload: {
        opId: OperationId;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        result: ApolloQueryResult<any> | FetchResult<any>;
      };
    }>(),
  ),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resultStored: createAction(name('resultStored'), props<{ payload: Payload<any> }>()),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: createAction(name('error'), props<{ payload: Payload<any> }>()),
};

const all = union(graphqlActions);

export type ActionsUnion = typeof all;
