import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectWarrantProgramId } from '../../store/selectors/warrant-programs.base';
import { filter } from 'lodash-es';
import { WarrantProgramDocumentsState } from '../reducers/warrant-program-documents.reducers';

export const selectWarrantProgramDocumentsState =
  createFeatureSelector<WarrantProgramDocumentsState>('warrantProgramDocuments');

export const selectWarrantProgramDocumentsByWpId = (wpId: string) =>
  createSelector(selectWarrantProgramDocumentsState, state => {
    // @ts-expect-error TS18048
    return filter(state.entities, m => m.warrantProgramId === wpId);
  });
export const selectWarrantProgramDocuments = createSelector(
  selectWarrantProgramDocumentsState,
  selectWarrantProgramId,
  (state, wpId) => {
    // @ts-expect-error TS18048
    return filter(state.entities, m => m.warrantProgramId === wpId);
  },
);
