import { GqlSnrAuthorization } from '../graphql/operations';
import { Transform } from 'class-transformer';
import { isNil, isString } from 'lodash-es';
import { DateTime } from 'luxon';

export enum SnrAuthKind {
  Issue = 'nyemission',
  Warrants = 'teckningsoptioner',
}

export interface IAuthJson {
  date: string;
  kind: SnrAuthKind;
  before: string;
  before_year: string;
}

export type SnrAuthorizationId = string;

export class SnrAuthorization implements Omit<GqlSnrAuthorization, 'company'> {
  id: string;

  filingYear: string;
  filingNumber: string;
  serialNumber: string;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  registrationDate: DateTime;

  @Transform(({ value }) => (isString(value) ? JSON.parse(value) : value), {
    toClassOnly: true,
  })
  @Transform(({ value }) => JSON.stringify(value), { toPlainOnly: true })
  auths: IAuthJson[];

  companyId: string;

  readonly createdAt: string;
  readonly updatedAt: string;

  get filingRef() {
    return `${this.filingNumber}/${this.filingYear}`;
  }

  constructor(seed?: Partial<SnrAuthorization>) {
    Object.assign(this, seed);
  }
}
