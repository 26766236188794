import { createAction, props, union } from '@ngrx/store';

import { OptionsRegistrationSignatory } from '../../models/options-registration-signatory.model';

const prefix = '[OptionsRegistrationSignatory]' as const;

export const optionsRegistrationSignatoryActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: OptionsRegistrationSignatory[] }>()),
  manyDeleted: createAction(prefix + ' manyDeleted', props<{ payload: string[] }>()),
};

const all = union(optionsRegistrationSignatoryActions);

export type ActionsUnion = typeof all;
