import { GqlWarrantDocument } from '../graphql/operations';

export class WarrantProgramDocument implements Omit<GqlWarrantDocument, 'warrantProgram' | 'document'> {
  id: string;

  warrantProgramId: string;
  documentId: string;

  readonly createdAt: string;
  readonly updatedAt: string;

  constructor(seed?: Partial<WarrantProgramDocument>) {
    Object.assign(this, seed);
  }
}
