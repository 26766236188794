import { ReactNode, MouseEvent } from 'react';
import { Stack } from '@mui/system';
import { Backdrop, IconButton, alpha } from '@mui/material';
import { Close } from '@mui/icons-material';
import { Box, Typography, CircularProgress } from '@mui/joy';
import {
  DialogTitleProps as MuiDialogTitleProps,
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  DialogProps,
  DialogContentProps,
  DialogActionsProps,
} from '@mui/material';

export const Dialog = ({
  children,
  sx = {},
  open = true,
  loading,
  ...other
}: Omit<DialogProps, 'open'> & { open?: boolean; loading?: boolean }) => {
  sx = { ...sx, overflow: 'hidden', borderRadius: '20px' };
  return (
    <MuiDialog sx={sx} {...other} open={open}>
      <Box sx={{ position: 'relative' }}></Box>
      <Backdrop
        sx={{
          position: 'absolute',
          zIndex: theme => theme.zIndex.drawer + 1,
          background: theme => alpha(theme.palette.background.paper, 0.87),
        }}
        open={Boolean(loading)}
      >
        <CircularProgress />
      </Backdrop>
      {children}
    </MuiDialog>
  );
};

interface DialogTitleProps extends MuiDialogTitleProps {
  actions?: ReactNode;
  onClose?: (event: MouseEvent<HTMLDivElement | HTMLAnchorElement | HTMLButtonElement | HTMLTableRowElement>) => void;
  typographyLevel?: 'h1' | 'h2' | 'h3' | 'h4';
  forceTypography?: boolean;
}

export const DialogTitle = ({ onClose, typographyLevel = 'h4', actions, children }: DialogTitleProps) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      gap={2}
      sx={{ paddingTop: '24px', paddingBottom: '4px', paddingRight: '24px', paddingLeft: '40px' }}
    >
      <Typography level={typographyLevel}>{children}</Typography>
      <Stack direction="row" flexGrow={0} gap={2} alignItems="center" justifyContent="end">
        <Box>{actions}</Box>
        {onClose && (
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};

export const DialogContent = ({ children, sx = {}, ...other }: DialogContentProps) => {
  sx = { ...sx, paddingY: '24px', paddingX: '40px' };
  return (
    <MuiDialogContent sx={sx} {...other}>
      <Stack gap={2}>{children}</Stack>
    </MuiDialogContent>
  );
};

export const DialogActions = ({ children, sx = {}, ...other }: DialogActionsProps) => {
  sx = { ...sx, padding: '24px', paddingBottom: '40px', paddingX: '40px' };
  return (
    <MuiDialogActions sx={sx} {...other}>
      {children}
    </MuiDialogActions>
  );
};
