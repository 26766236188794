import { createReducer, on, Action } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { map, filter } from 'lodash-es';

import { MeetingParticipant } from '../../models/meeting-participant.model';
import { meetingParticipantsActions } from '../actions/meeting-participants.actions';

export type MeetingParticipantsState = EntityState<MeetingParticipant>;

export const adapter = createEntityAdapter<MeetingParticipant>();
export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(meetingParticipantsActions.manyLoaded, (state, { payload }) => {
    return adapter.addMany(
      payload,
      adapter.removeMany(
        map(payload, p => p.id),
        state,
      ),
    );
  }),
  on(meetingParticipantsActions.manyDeleted, (state, { payload }) => adapter.removeMany(payload, state)),
  on(meetingParticipantsActions.shareholdersDeleted, (state, { payload }) => {
    // @ts-expect-error TS18048
    const ids = filter(state.entities, e => payload.includes(e.meetingId)).map(e => e.id);
    return adapter.removeMany(ids, state);
  }),
);

export function meetingParticipantsReducer(state: EntityState<MeetingParticipant> | undefined, action: Action) {
  return reducer(state, action);
}
