import { isNil } from 'lodash';

export const countDecimals = (value: string | number | null, clamp = Infinity): number => {
  if (isNil(value)) {
    return 0;
  }
  if (typeof value === 'number' && value % 1 === 0) {
    return 0;
  }
  const stringValue = typeof value === 'number' ? `${value}` : value;
  const decimalPart = stringValue.split('.')[1] ?? '';
  return Math.min(decimalPart.replace(/0+$/, '').length, clamp);
};
