import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
  TemplateRef,
  HostBinding,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { QMessageContainerDirective } from './message.directive';

@Component({
  selector: 'div[q-message]', // eslint-disable-line @angular-eslint/component-selector
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class QMessageComponent implements AfterViewInit {
  @Input() color: ThemePalette;
  @HostBinding('class') get classColor() {
    return this.color ? `mat-${this.color}` : undefined;
  }

  @Input() showContent = false;
  @Input() icon = 'info_outline';
  @ViewChild(QMessageContainerDirective, { static: true })
  _childElement: QMessageContainerDirective;
  @ViewChild(TemplateRef, { static: true }) _template: TemplateRef<unknown>;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    elementRef: ElementRef,
  ) {
    elementRef.nativeElement.classList.add('q-message');
  }

  ngAfterViewInit(): void {
    void Promise.resolve(undefined).then(() => {
      this._attach();
    });
  }

  private _attach(): void {
    this._childElement.viewContainer.createEmbeddedView(this._template);
    this._changeDetectorRef.markForCheck();
  }

  public toggleContent(): void {
    this.showContent = !this.showContent;
  }
}
