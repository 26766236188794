<div class="dialog" sutLoading #qLoading="Loading" i18n-text text="Söker efter befintligt bankgiro...">
  <div class="dialog-title">
    <h5 i18n>Lägg till ett betalningsalternativ</h5>
  </div>

  <div mat-dialog-content class="dialog-content">
    <div>
      <mat-form-field class="w-full" appearance="fill">
        <mat-select placeholder="Betalningsalternativ" [formControl]="formControls.paymentAccountKind" required>
          <mat-option *ngFor="let pk of paymentKinds" [value]="pk.kind">
            {{ pk.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="formControls.paymentAccountKind.value === PaymentAccountKind.Bankgiro">
      <ng-container *ngTemplateOutlet="bankGiro"></ng-container>
    </div>
    <div *ngIf="formControls.paymentAccountKind.value === PaymentAccountKind.BankAccount">
      <ng-container *ngTemplateOutlet="bankAccount"></ng-container>
    </div>
  </div>

  <div class="dialog-actions">
    <button type="button" class="btn-secondary" [mat-dialog-close]="false" i18n>Avbryt</button>
    <button type="button" class="btn" (click)="submit()" [disabled]="!submitValid()" i18n>Spara</button>
  </div>
</div>

<ng-template #bankAccount>
  <div class="flex flex-col gap-4">
    <mat-form-field class="w-full" appearance="fill">
      <mat-label>
        <span i18n>Bankkontonummer</span>
      </mat-label>
      <input matInput type="text" [formControl]="formControls.paymentAccountNumber" bankCodeValidator />
    </mat-form-field>
    <mat-form-field class="w-full" appearance="fill">
      <mat-label>
        <span i18n>Bank</span>
      </mat-label>
      <input matInput type="text" [formControl]="formControls.bankName" />
    </mat-form-field>
  </div>
</ng-template>

<ng-template #bankGiro>
  <div class="flex flex-col gap-4">
    <div>
      <mat-form-field class="w-full" appearance="fill">
        <mat-label>
          <span *ngIf="searchLabelKind() === SearchKind.None" style="color: gray" i18n
            >Bankgironummer/Organisationsnummer</span
          >
          <span *ngIf="searchLabelKind() === SearchKind.BgNumber"
            ><strong i18n>Bankgironummer</strong
            ><span style="color: gray">/<ng-container i18n>Organisationsnummer</ng-container></span></span
          >
          <span *ngIf="searchLabelKind() === SearchKind.OrgNumber"
            ><span style="color: gray"><ng-container i18n>Bankgironummer</ng-container>/</span
            ><strong i18n>Organisationsnummer</strong></span
          >
          <span *ngIf="searchLabelKind() === SearchKind.Error" i18n>Bankgironummer</span>
        </mat-label>
        <mat-icon *ngIf="searching === false && !sourceIsDown" matPrefix color="primary">search</mat-icon>
        <mat-spinner *ngIf="searching" matPrefix diameter="24"></mat-spinner>
        <input
          matInput
          type="text"
          [matAutocomplete]="auto"
          [formControl]="formControls.searchInput"
          bankCodeValidator
          (keydown.enter)="searchBankGiro()"
        />
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let bg of showbankGiro$ | async" [value]="bg.value.bgnr" (click)="selectedBG = bg.value">
            <mat-list>
              <mat-list-item>
                <span matListItemTitle>
                  {{ bg.value.name }}
                </span>
                <span matListItemLine> {{ bg.value.bgnr }}, {{ bg.value.orgnr | idNumber }} </span>
              </mat-list-item>
            </mat-list>
          </mat-option>
        </mat-autocomplete>
        <mat-hint *ngIf="!sourceIsDown"><strong i18n>starta sökning genom att trycka retur/enter</strong></mat-hint>
      </mat-form-field>
    </div>
    <div *ngIf="sourceIsDown">
      <mat-form-field class="w-full" appearance="fill">
        <mat-label i18n>Namn</mat-label>
        <input matInput type="text" [formControl]="formControls.name" />
      </mat-form-field>
    </div>
  </div>
</ng-template>
