import { Pipe, PipeTransform } from '@angular/core';
import { DateTime, DateTimeFormatOptions } from 'luxon';

@Pipe({ name: 'dateTimeLocaleStr' })
export class DateTimeLocaleStrPipe implements PipeTransform {
  // @ts-expect-error TS2366
  transform(input: DateTime, formatOptions: DateTimeFormatOptions = DateTime.DATE_SHORT): string | null {
    if (input instanceof DateTime) {
      return input.toLocaleString(formatOptions);
    }
  }
}
