'use client';
import { Button, Box, Link, Snackbar, Stack, Typography, IconButton, CloseRoundedIcon } from '@startuptools/ui';
import { useState } from 'react';
import { FormContainer, PasswordElement, PasswordRepeatElement, TextFieldElement } from 'react-hook-form-mui';
import { usePasswordRegisterMutation } from '../graphql/react-operations';
import { ApolloError } from '@apollo/client';
import { Trans } from '@lingui/react';
import { Navigate } from '../types';

interface FormValues {
  email: string;
  password: string;
  repeatPassword: string;
}

export const EmailRegister = ({ navigate }: { navigate: Navigate }) => {
  const [doRegister, { loading }] = usePasswordRegisterMutation();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [showToast, setShowToast] = useState<boolean>(false);

  const showError = (errorMsg: string) => {
    setErrorMsg(errorMsg);
    setShowToast(true);
  };

  const handleSubmit = async (values: FormValues) => {
    const { email, password } = values;
    try {
      await doRegister({ variables: { input: { email, password } } });
      navigate('/auth/password/register-confirm', { replace: true });
    } catch (e) {
      if (e instanceof ApolloError) {
        if (e.message === 'Password too short') {
          showError('Passwords must be at least 8 characters long');
          return;
        }
        showError(e.message);
      }
    }
  };

  return (
    <Box>
      <FormContainer onSuccess={handleSubmit}>
        <Stack direction="column" gap={1} alignItems="center">
          <Typography level="h4" paddingBottom={2}>
            <Trans id="Create account" />
          </Typography>
          <TextFieldElement
            label={<Trans id="Email" />}
            name="email"
            type="email"
            autoComplete="email"
            fullWidth
            required
          />
          <PasswordElement label={<Trans id="Password" />} name="password" fullWidth required />
          <PasswordRepeatElement
            name="repeatPassword"
            passwordFieldName="password"
            label={<Trans id="Repeat password" />}
            fullWidth
            required
          />
          <Button sx={{ alignSelf: 'end' }} loading={loading} variant="plain" type="submit">
            <Trans id="Next" />
          </Button>
          <Button
            variant="plain"
            color="neutral"
            onClick={() => navigate('/auth/password/login', { replace: true })}
            sx={{ marginTop: 2 }}
          >
            <Trans id="Go back" />
          </Button>
          <Typography level="body-sm">
            <Trans id="By registering you accept our" />
            &nbsp;
            <Link href="https://bolago.com/se/villkor-for-startuptools-plattform-2024" target="_blank" rel="noreferrer">
              <Trans id="terms of service" />
            </Link>
          </Typography>
        </Stack>
      </FormContainer>
      <Snackbar
        open={showToast}
        variant="solid"
        color="danger"
        onClose={() => setShowToast(false)}
        endDecorator={
          <IconButton variant="plain" size="sm" color="neutral" onClick={() => setShowToast(false)}>
            <CloseRoundedIcon />
          </IconButton>
        }
      >
        {errorMsg}
      </Snackbar>
    </Box>
  );
};
