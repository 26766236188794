import { StripeProducts } from '@startuptools/common/stripe';

export const stripeProductsTest: StripeProducts = {
  // pcs
  shareIssue: 'prod_KO8jGTLc9Y1LZB',
  employeeOption: 'prod_KO8k1wUgwG6q50',
  warrantProgram: 'prod_KO8jbihSyu7Iij',
  wise: 'prod_LnqMylgSgEdq3x',
  nice: 'prod_O6qDzRMYJ0qEkl',
  // subs
  shareRegistry: 'prod_KO8fihQQ2xPeEI',
  startup: 'prod_LV4xcR9nbJpwHC',
  grow: 'prod_KO8g6PUXXNJRDP',
  pro: 'prod_KO8ibnMEcN4CHf',
  proButler: 'prod_MVd6Te9QxBd9ko',
};

export enum StripePrice {
  shareRegisterYear = 'price_1JjMOIFBz41BrZ6wtYjmpZiB',
  shareRegisterMonth = 'price_1JjMOIFBz41BrZ6wAfKkS7iV',
  startup2022Year = 'price_1Ko4nIFBz41BrZ6wGHeN9nIh',
  startup2022Month = 'price_1Ko4nIFBz41BrZ6wpnygomVl',
  startup2024Year = 'price_1OiyyCFBz41BrZ6w8Ho8d3GE', // 3950/y
  startup2024Month = 'price_1OiyxnFBz41BrZ6wDa2if4AV', // 395/m
  grow2022Year = 'price_1JjMPYFBz41BrZ6wFpJCjSQW', // OLD 8 950/y
  grow2022Month = 'price_1JjMPYFBz41BrZ6wOXJiO1xx', // OLD 895/m
  grow2023Year = 'price_1LmYwXFBz41BrZ6watLuqBFs', // OLD 11 950/y
  grow2023Month = 'price_1LmYvsFBz41BrZ6wBg7FtIrb', // OLD 1 195/m
  grow2024Year = 'price_1Oiz0YFBz41BrZ6w44egV4KA', // 16 950/y
  grow2024Month = 'price_1Oiz0HFBz41BrZ6wy3OP35Ml', // 1 695/m
  pro2022Year = 'price_1JjMQwFBz41BrZ6wppSU1rLT', // OLD 19 950/y
  pro2022Month = 'price_1JjMQwFBz41BrZ6wdV3XCuLI', // OLD 1 995/m
  pro2023Year = 'price_1Lmb7qFBz41BrZ6wfkLnPraL', // OLD 24 950/y
  pro2023Month = 'price_1Lmb70FBz41BrZ6wBeb9D10V', // OLD 2 495/m
  pro2024Year = 'price_1Oiz1HFBz41BrZ6wJyaRLhbo', // 34 950/y
  pro2024Month = 'price_1Oiz0sFBz41BrZ6wAvF6X4sU', // 3 495/m
  butler2023Year = 'price_1Lmbq3FBz41BrZ6wwLJXHwHF', // OLD 89 950/y
  butler2023Month = 'price_1Lmbq3FBz41BrZ6wiqhQ5Xlq', // OLD 8995/m
  butler2024Year = 'price_1Oiz25FBz41BrZ6wQTf1C4Gn', // 139 950/y
  butler2024Month = 'price_1Oiz1gFBz41BrZ6wXiodYdl1', // 13 995/m
}

/**
 * current prices for test environment
 */
export const stripeSubPricesTest: Record<string, { year: StripePrice; month: StripePrice }> = {
  [stripeProductsTest.shareRegistry]: {
    year: StripePrice.shareRegisterYear,
    month: StripePrice.shareRegisterMonth,
  },
  [stripeProductsTest.startup]: {
    year: StripePrice.startup2024Year,
    month: StripePrice.startup2024Month,
  },
  [stripeProductsTest.grow]: {
    year: StripePrice.grow2024Year,
    month: StripePrice.grow2024Month,
  },
  [stripeProductsTest.pro]: {
    year: StripePrice.pro2024Year,
    month: StripePrice.pro2024Month,
  },
  [stripeProductsTest.proButler]: {
    year: StripePrice.butler2024Year,
    month: StripePrice.butler2024Month,
  },
};
