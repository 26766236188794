import { Trans } from '@lingui/react';
import { Alert, ErrorBoundary, IconButton, PictureAsPdfIcon } from '@startuptools/ui';
import React from 'react';
import { PDFPreview } from './PDFPreview';

export const PDFIconButton = ({
  generatePdf,
}: {
  generatePdf: (language: {
    primaryLang: boolean;
    secondaryLang: boolean;
  }) => Promise<{ filename: string; blob: Blob }>;
}) => {
  const [show, setShow] = React.useState(false);

  return (
    <ErrorBoundary
      showModal
      fallback={() => (
        <Alert color="warning">
          <Trans id="Something went wrong while trying to generate your PDF. Try again, or contact support." />
        </Alert>
      )}
    >
      <IconButton
        color="primary"
        onClick={async () => {
          setShow(true);
        }}
      >
        <PictureAsPdfIcon />
      </IconButton>
      {show && (
        <PDFPreview
          generatePdf={generatePdf}
          mode="generate"
          chooseLanguage
          onClose={() => {
            setShow(false);
          }}
        />
      )}
    </ErrorBoundary>
  );
};
