import { createFeatureSelector, createSelector } from '@ngrx/store';
import { filter } from 'lodash-es';

import { ArticlesOfAssocDocumentsState } from '../reducers/articles-of-assoc-documents.reducers';

export const selectArticlesOfAssocDocumentsState =
  createFeatureSelector<ArticlesOfAssocDocumentsState>('articlesOfAssocDocuments');

export const selectArticlesOfAssocDocumentsByAoaId = (aoaId: string) =>
  createSelector(selectArticlesOfAssocDocumentsState, state => {
    // @ts-expect-error TS18048
    return filter(state.entities, m => m.articlesOfAssocId === aoaId);
  });
