import { createReducer, on, Action } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { OptionsRegistrationSignatory } from '../../models/options-registration-signatory.model';
import { optionsRegistrationSignatoryActions } from '../actions/options-registration-signatory.actions';

export type OptionsRegistrationSignatoriesState = EntityState<OptionsRegistrationSignatory>;

export const adapter = createEntityAdapter<OptionsRegistrationSignatory>();
export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(optionsRegistrationSignatoryActions.manyLoaded, (state, { payload }) => {
    return adapter.addMany(
      payload,
      adapter.removeMany(
        payload.map(p => p.id),
        state,
      ),
    );
  }),
  on(optionsRegistrationSignatoryActions.manyDeleted, (state, { payload }) => adapter.removeMany(payload, state)),
);

export function optionsRegistrationSignatoriesReducer(
  state: EntityState<OptionsRegistrationSignatory> | undefined,
  action: Action,
) {
  return reducer(state, action);
}
