import { createReducer, on, Action } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { map } from 'lodash-es';

import { ArticlesOfAssoc } from '../../models/articles-of-assoc.model';
import { articlesOfAssocsActions } from '../../store/actions/articles-of-associations.actions';

export type ArticlesOfAssocsState = EntityState<ArticlesOfAssoc>;

export const adapter = createEntityAdapter<ArticlesOfAssoc>();
export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(articlesOfAssocsActions.manyLoaded, (state, { payload }) => {
    return adapter.addMany(
      payload,
      adapter.removeMany(
        map(payload, p => p.id),
        state,
      ),
    );
  }),
  on(articlesOfAssocsActions.manyDeleted, (state, { payload }) => adapter.removeMany(payload, state)),
);

export function articlesOfAssocsReducer(state: EntityState<ArticlesOfAssoc> | undefined, action: Action) {
  return reducer(state, action);
}
