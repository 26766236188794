import { createFeatureSelector, createSelector } from '@ngrx/store';
import { filter, values } from 'lodash-es';
import { SnrCasesState } from '../reducers/snr-cases.reducers';
import { selectCompanyId } from './companies.base';

export const selectSnrCasesState = createFeatureSelector<SnrCasesState>('snrCases');
export const selectSnrCaseById = (id: string) => createSelector(selectSnrCasesState, state => state.entities[id]);
export const selectSnrCases = createSelector(selectSnrCasesState, selectCompanyId, (state, companyId) => {
  // @ts-expect-error TS18048
  return filter(values(state.entities), sc => sc.companyId === companyId);
});
