'use client';
import { useState } from 'react';
import { Button, Box, Snackbar, Stack, IconButton, Typography, CloseRoundedIcon } from '@startuptools/ui';
import { FormContainer, PasswordElement, TextFieldElement } from 'react-hook-form-mui';
import { GqlAud, usePasswordLoginMutation } from '../graphql/react-operations';
import { ApolloError } from '@apollo/client';
import { Trans } from '@lingui/react';
import { AUTH_JWT_KEY } from '../constants';
import { getRedirectUrl } from '../utils/redirect';
import { Navigate } from '../types';

interface InputValues {
  email: string;
  password: string;
}

export const Password = ({ navigate, aud }: { navigate: Navigate; aud: GqlAud }) => {
  const [doLogin, { loading }] = usePasswordLoginMutation();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [showToast, setShowToast] = useState<boolean>(false);

  const handleSubmit = async (values: InputValues) => {
    const { email, password } = values;
    try {
      const res = await doLogin({ variables: { input: { email, password, aud } } });
      localStorage.setItem(AUTH_JWT_KEY, res.data?.passwordLogin?.jwt || '');
      const newUrl = `${getRedirectUrl()}`;
      navigate(newUrl, { replace: true }); // Make sure we don't save the init/poll state in the browser stack
      window.location.href = newUrl; // Remove when Angular is gone, this is a workaround to make sure angular router takes over from here
    } catch (e) {
      if (e instanceof ApolloError) {
        setErrorMsg(e.message);
        setShowToast(true);
        return;
      }
    }
  };

  return (
    <Box>
      <Typography level="h2" mb={2}>
        <Trans id="Login with email" />
      </Typography>
      <FormContainer onSuccess={handleSubmit}>
        <Stack direction="column" gap={1} alignItems="end">
          <TextFieldElement
            label={<Trans id="Email" />}
            name="email"
            type="email"
            autoComplete="email webauthn"
            fullWidth
            required
          />
          <PasswordElement label={<Trans id="Password" />} name="password" fullWidth required />
          <Button type="submit" variant="plain" loading={loading}>
            <Trans id="Log in" />
          </Button>
          <Stack direction="row" justifyItems="space-between">
            <Button variant="plain" color="neutral" onClick={() => navigate('/auth')}>
              <Trans id="Go back" />
            </Button>
            <Button type="button" variant="plain" color="neutral" onClick={() => navigate('/auth/password/recover')}>
              <Trans id="Recover password" />
            </Button>
            <Button type="button" variant="plain" color="neutral" onClick={() => navigate('/auth/password/register')}>
              <Trans id="Create account" />
            </Button>
          </Stack>
        </Stack>
      </FormContainer>
      <Snackbar
        open={showToast}
        variant="solid"
        color="danger"
        onClose={() => setShowToast(false)}
        endDecorator={
          <IconButton variant="plain" size="sm" color="neutral" onClick={() => setShowToast(false)}>
            <CloseRoundedIcon />
          </IconButton>
        }
      >
        {errorMsg}
      </Snackbar>
    </Box>
  );
};
