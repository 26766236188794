import { createReducer, on, Action } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { map } from 'lodash-es';

import { ArticlesOfAssocDocument } from '../../models/articles-of-assoc-document.model';
import { articlesOfAssocDocumentsActions } from '../actions/articles-of-assoc-documents.actions';

export type ArticlesOfAssocDocumentsState = EntityState<ArticlesOfAssocDocument>;

export const adapter = createEntityAdapter<ArticlesOfAssocDocument>();
export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(articlesOfAssocDocumentsActions.manyLoaded, (state, { payload }) =>
    adapter.addMany(
      payload,
      adapter.removeMany(
        map(payload, p => p.id),
        state,
      ),
    ),
  ),
);

export function articlesOfAssocDocumentsReducer(
  state: EntityState<ArticlesOfAssocDocument> | undefined,
  action: Action,
) {
  return reducer(state, action);
}
