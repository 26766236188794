import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { PortalModule } from '@angular/cdk/portal';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SutNavStepHeaderComponent } from './nav-step-header.component';
import { SutNavStepComponent } from './nav-step.component';
import { SutNavStepperComponent } from './nav-stepper.component';
import { SutStepComponent } from '../../modules/nav-stepper/step.component';
import { SutStepperComponent } from '../../modules/nav-stepper/stepper.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CdkStepperModule,
    PortalModule,
    MatDividerModule,
    MatIconModule,
    MatTooltipModule,
  ],
  declarations: [
    SutNavStepHeaderComponent,
    SutNavStepComponent,
    SutNavStepperComponent,
    SutStepComponent,
    SutStepperComponent,
  ],
  exports: [
    // SutNavStepHeaderComponent,
    SutNavStepComponent,
    SutNavStepperComponent,
    SutStepComponent,
    SutStepperComponent,
  ],
})
export class SutNavStepperModule {}
