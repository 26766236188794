import { GqlMeetingDocument } from '../graphql/operations';

export class MeetingDocument implements Omit<GqlMeetingDocument, 'meeting' | 'document'> {
  id: string;

  meetingId: string;
  documentId: string;

  readonly createdAt: string;
  readonly updatedAt: string;

  constructor(seed?: Partial<MeetingDocument>) {
    Object.assign(this, seed);
  }
}
