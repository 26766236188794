<form [formGroup]="form" (submit)="submit()">
  <div class="dialog">
    <div class="dialog-title">
      <h5>Lägg till IBAN-konto</h5>
    </div>
    <div mat-dialog-content class="dialog-content">
      <div class="flex flex-col gap-4">
        <mat-form-field class="w-full" appearance="fill">
          <mat-label>IBAN</mat-label>
          <input matInput type="text" [formControl]="formControls.ibanNumber" required />
          <mat-error *ngIf="formControls.ibanNumber.hasError('required')" i18n>Måste fyllas i</mat-error>
        </mat-form-field>
        <mat-form-field class="w-full" appearance="fill">
          <mat-label>Bank</mat-label>
          <input matInput type="text" [formControl]="formControls.ibanBank" required />
          <mat-error *ngIf="formControls.ibanBank.hasError('required')" i18n>Måste fyllas i</mat-error>
        </mat-form-field>
        <mat-form-field class="w-full" appearance="fill">
          <mat-label>BIC</mat-label>
          <input matInput type="text" [formControl]="formControls.ibanBic" required />
          <mat-error *ngIf="formControls.ibanBic.hasError('required')" i18n>Måste fyllas i</mat-error>
        </mat-form-field>
        <mat-form-field class="w-full" appearance="fill">
          <mat-label i18n>Adress</mat-label>
          <input matInput type="text" [formControl]="formControls.ibanAddress" required />
          <mat-error *ngIf="formControls.ibanAddress.hasError('required')" i18n>Måste fyllas i</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="dialog-actions">
      <button type="button" class="btn-secondary" [mat-dialog-close]="false" i18n>Avbryt</button>
      <button type="submit" class="btn" i18n>Lägg till</button>
    </div>
  </div>
</form>
