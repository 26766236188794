import type * as Types from '../../graphql/base-types.graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GqlTruidLoginSessionMutationVariables = Types.Exact<{
  input: Types.GqlTruidLoginInput;
}>;


export type GqlTruidLoginSessionMutation = { truidLoginSession: { redirectUri: string } };


export const TruidLoginSessionDocument = gql`
    mutation TruidLoginSession($input: TruidLoginInput!) {
  truidLoginSession(input: $input) {
    redirectUri
  }
}
    `;
export type GqlTruidLoginSessionMutationFn = Apollo.MutationFunction<GqlTruidLoginSessionMutation, GqlTruidLoginSessionMutationVariables>;

/**
 * __useTruidLoginSessionMutation__
 *
 * To run a mutation, you first call `useTruidLoginSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTruidLoginSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [truidLoginSessionMutation, { data, loading, error }] = useTruidLoginSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTruidLoginSessionMutation(baseOptions?: Apollo.MutationHookOptions<GqlTruidLoginSessionMutation, GqlTruidLoginSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlTruidLoginSessionMutation, GqlTruidLoginSessionMutationVariables>(TruidLoginSessionDocument, options);
      }
export type TruidLoginSessionMutationHookResult = ReturnType<typeof useTruidLoginSessionMutation>;
export type TruidLoginSessionMutationResult = Apollo.MutationResult<GqlTruidLoginSessionMutation>;
export type TruidLoginSessionMutationOptions = Apollo.BaseMutationOptions<GqlTruidLoginSessionMutation, GqlTruidLoginSessionMutationVariables>;