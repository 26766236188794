import { createAction, props, union } from '@ngrx/store';

import { ShareIssueRegistration } from '../../models/share-issue-registration.model';

const prefix = '[ShareIssueRegistration]' as const;

export const shareIssueRegistrationActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: ShareIssueRegistration[] }>()),
  manyDeleted: createAction(prefix + ' manyDeleted', props<{ payload: string[] }>()),
};

const all = union(shareIssueRegistrationActions);

export type ActionsUnion = typeof all;
