import { createReducer, on, Action } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { map } from 'lodash-es';

import { OptionsSubscriberRepresentative } from '../../models/options-subscriber-representatives.model';
import { optionsSubscriberRepresentativeActions } from '../../store/actions/options-subscriber-representative.actions';

export type OptionsSubscriberRepresentativesState = EntityState<OptionsSubscriberRepresentative>;

export const adapter = createEntityAdapter<OptionsSubscriberRepresentative>();
export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(optionsSubscriberRepresentativeActions.manyLoaded, (state, { payload }) => {
    return adapter.addMany(
      payload,
      adapter.removeMany(
        map(payload, p => p.id),
        state,
      ),
    );
  }),
  on(optionsSubscriberRepresentativeActions.manyDeleted, (state, { payload }) => adapter.removeMany(payload, state)),
);

export function optionsSubscriberRepresentativesReducer(
  state: EntityState<OptionsSubscriberRepresentative> | undefined,
  action: Action,
) {
  return reducer(state, action);
}
