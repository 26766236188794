import { Injectable } from '@angular/core';
import { RouterStateSerializer } from '@ngrx/router-store';
import { Params, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { isNil } from 'lodash-es';

import { RouterState } from '../store/reducers/router.reducers';

export function collectParamsFromRoot(route: ActivatedRouteSnapshot, params: Params): Params {
  if (!isNil(route.firstChild)) {
    return collectParamsFromRoot(route.firstChild, {
      ...params,
      ...route.params,
    });
  }
  return { ...params, ...route.params };
}

@Injectable()
export class RouterSerializer implements RouterStateSerializer<RouterState> {
  serialize(routerState: RouterStateSnapshot): RouterState {
    const params = collectParamsFromRoot(routerState.root, {});
    return {
      ...routerState,
      root: routerState.root,
      queryParams: routerState.root.queryParams,
      params,
    };
  }
}
