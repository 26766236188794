import { PaymentAccountKind } from '@startuptools/common/common';
import { Transform } from 'class-transformer';
import { isNil } from 'lodash-es';
import { DateTime } from 'luxon';

import { GqlOptionsRegistration } from '../graphql/operations';
import { WppWithIncludes } from './warrant-program-participant.model';
import { Identity } from './identity.model';
import { SnrCase } from './snr-case.model';
import { OptionsRegistrationSignatory } from './options-registration-signatory.model';
import { WarrantProgram } from './warrant-program.model';

export interface OptionsRegistrationWithIncludes {
  model: OptionsRegistration;
  contact?: Identity;
  participants: WppWithIncludes[];
  snrCase?: SnrCase;
  signatories?: OptionsRegistrationSignatory[];
  warrantProgram: WarrantProgram;
}

export interface OptionsRegistrationIban {
  ibanNumber?: string;
  ibanBic?: string;
  ibanBank?: string;
  ibanAddress?: string;
}

export interface OptionsRegistrationPayment {
  paymentAccountKind?: PaymentAccountKind;
  paymentAccountNumber?: string;
  paymentName?: string;
}

export type OptionsRegistrationId = string;

export class OptionsRegistration
  implements
    OptionsRegistrationIban,
    OptionsRegistrationPayment,
    Omit<GqlOptionsRegistration, 'warrantProgram' | 'orSignatories' | 'participants'>
{
  id: OptionsRegistrationId;
  description: string;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  payBeforeDate: DateTime;
  paymentAccountKind?: PaymentAccountKind;
  paymentAccountNumber?: string;
  paymentName?: string;
  ibanNumber?: string;
  ibanBic?: string;
  ibanBank?: string;
  ibanAddress?: string;
  contactId?: string;
  signatoryId?: string;
  snrCaseId?: string;
  warrantProgramId: string;
  regFormPaymentThroughBolago: boolean;

  get payment(): OptionsRegistrationPayment {
    const payment = {
      paymentName: this.paymentName,
      paymentAccountKind: this.paymentAccountKind,
      paymentAccountNumber: this.paymentAccountNumber,
    };
    // @ts-expect-error TS2322
    return Object.values(payment).every(e => isNil(e)) ? null : payment;
  }

  get iban(): OptionsRegistrationIban {
    const iban = {
      ibanNumber: this.ibanNumber,
      ibanBic: this.ibanBic,
      ibanBank: this.ibanBank,
      ibanAddress: this.ibanAddress,
    };
    // @ts-expect-error TS2322
    return Object.values(iban).every(e => !isNil(e)) ? iban : null;
  }

  readonly createdAt: string;
  readonly updatedAt: string;

  constructor(seed?: Partial<OptionsRegistration>) {
    Object.assign(this, seed);
  }
}
