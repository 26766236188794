import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { isEmpty, some } from 'lodash-es';

export function noDuplicateValidator(list: string[]) {
  return function (control: AbstractControl): Record<string, boolean> | null {
    if (isEmpty(control.value)) {
      return null;
    }
    return some(list, e => e === control.value) ? { duplicate: true } : null;
  };
}

@Directive({
  selector: '[noDuplicateValidator]', // eslint-disable-line @angular-eslint/directive-selector
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: NoDuplicateValidatorDirective,
      multi: true,
    },
  ],
})
export class NoDuplicateValidatorDirective implements Validator {
  @Input('noDuplicateValidator') list: string[];

  validate(control: AbstractControl): Record<string, boolean> | null {
    return noDuplicateValidator(this.list)(control);
  }
}
