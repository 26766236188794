<div
  *ngIf="kind === LoadingKind.Circle"
  class="circle-loading-container pad-sm flex flex-col justify-center items-center gap-4"
>
  <div><mat-progress-spinner [diameter]="diameter$ | async" [mode]="spinnerMode$ | async"></mat-progress-spinner></div>
  <ng-container *ngIf="text$ | async as text; else noText">
    <div>{{text}}</div>
  </ng-container>
  <ng-template #noText>
    <div>{{randomQuote$ | async}}</div>
  </ng-template>
</div>
<div *ngIf="kind === LoadingKind.Bar" class="bar-loading-container flex flex-col">
  <div><mat-progress-bar [mode]="barMode$ | async"></mat-progress-bar></div>
</div>
