import { NgModule } from '@angular/core';

import { BankCodeValidatorDirective } from './bank-code.directive';
import { DecimalOnlyDirective } from './decimal-only.directive';
import { IntegerOnlyDirective } from './integer-only.directive';
import { MaxNumberValidatorDirective } from './max-number.directive';
import { MinNumberValidatorDirective } from './min-number.directive';
import { NoDuplicateValidatorDirective } from './no-duplicate-validator.directive';
import { LocalizedNumericInputDirective } from './number-input.directive';
import { ScrollDirective } from './scroll.directive';
import { TrimWhitespaceDirective } from './trim-whitespace.directive';

const directives = [
  BankCodeValidatorDirective,
  DecimalOnlyDirective,
  IntegerOnlyDirective,
  MaxNumberValidatorDirective,
  MinNumberValidatorDirective,
  NoDuplicateValidatorDirective,
  LocalizedNumericInputDirective,
  ScrollDirective,
  TrimWhitespaceDirective,
];

@NgModule({
  declarations: directives,
  exports: directives,
})
export class AppDirectivesModule {}
