import { createReducer, on, Action } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { map } from 'lodash-es';

import { AuthorizationGroupDocument } from '../../models/authorization-group-document.model';
import { authorizationGroupDocumentsActions } from '../../store/actions/authorization-groups-documents.actions';

export type AuthorizationGroupDocumentsState = EntityState<AuthorizationGroupDocument>;

export const adapter = createEntityAdapter<AuthorizationGroupDocument>();
export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(authorizationGroupDocumentsActions.manyLoaded, (state, { payload }) =>
    adapter.addMany(
      payload,
      adapter.removeMany(
        map(payload, p => p.id),
        state,
      ),
    ),
  ),
);

export function authorizationGroupDocumentsReducer(
  state: EntityState<AuthorizationGroupDocument> | undefined,
  action: Action,
) {
  return reducer(state, action);
}
