import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, effect, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FunctionComponent, createElement, Suspense } from 'react';
import { createRoot, Root } from 'react-dom/client';
import { Apollo } from 'apollo-angular';
import { I18n } from '@lingui/core';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { ReactWrapper } from '@startuptools/common/react-wrapper';
import { joyTheme, materialTheme } from '../../theme';
import { ErrorBoundary } from '@startuptools/ui';
import { NavigationEnd, Router } from '@angular/router';
import { NgRouterProvider } from './AngularRouterContext';
import { ReactI18n } from '../../injectables/react-i18n.service';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-react-wrapper',
  template: '',
  styles: `
    :host {
      display: block;
      max-width: '0px';
    }

    :host.display-none {
      display: none;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReactWrapperComponent<Props extends object> implements AfterViewInit, OnDestroy {
  component = input.required<FunctionComponent<Props>>();
  i18n = input<I18n>(this.defaultI18n.i18n);
  apolloClient = input<ApolloClient<InMemoryCache>>(this.apollo.client);
  props = input<Props>();
  userId = input<string>();
  companyId = input<string>();

  private root: Root | undefined;

  public angularPathname = toSignal(
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(e => e.url),
      startWith(this.router.url),
      distinctUntilChanged(),
    ),
    { requireSync: true },
  );

  constructor(
    private elementRef: ElementRef,
    private apollo: Apollo,
    private router: Router,
    private defaultI18n: ReactI18n,
  ) {
    effect(() => {
      this.render(
        this.component(),
        this.angularPathname(),
        this.i18n(),
        this.apolloClient(),
        this.props(),
        this.companyId(),
        this.userId(),
      );
    });
  }

  render(
    component: FunctionComponent<Props>,
    angularPathname: string,
    i18n: I18n,
    apolloClient: ApolloClient<InMemoryCache>,
    props?: Props,
    companyId?: string,
    userId?: string,
  ) {
    if (!this.root) {
      return;
    }
    const children = createElement(component, props);
    this.root.render(
      <ReactWrapper
        client={apolloClient}
        i18n={i18n}
        joyTheme={joyTheme}
        materialTheme={materialTheme}
        userId={userId}
        companyId={companyId}
      >
        <NgRouterProvider ngRouter={this.router} pathName={angularPathname}>
          <Suspense>
            <ErrorBoundary>{children}</ErrorBoundary>
          </Suspense>
        </NgRouterProvider>
      </ReactWrapper>,
    );
  }

  ngAfterViewInit(): void {
    const el = this.elementRef.nativeElement;
    this.root = createRoot(el);
  }

  ngOnDestroy(): void {
    this.root?.unmount();
    this.root = undefined;
  }
}
