import { createReducer, on, Action } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { map } from 'lodash-es';

import { MeetingConcern } from '../../models/meeting-concern.model';
import { meetingConcernsActions } from '../actions/meeting-concerns.actions';

export type MeetingConcernsState = EntityState<MeetingConcern>;

export const adapter = createEntityAdapter<MeetingConcern>();
export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(meetingConcernsActions.manyLoaded, (state, { payload }) =>
    adapter.addMany(
      payload,
      adapter.removeMany(
        map(payload, p => p.id),
        state,
      ),
    ),
  ),
  on(meetingConcernsActions.manyDeleted, (state, { payload }) => adapter.removeMany(payload, state)),
);

export function meetingConcernsReducer(state: EntityState<MeetingConcern> | undefined, action: Action) {
  return reducer(state, action);
}
