import { useEffect, useState } from 'react';
import { useOnce } from '@startuptools/common/react-hooks';
import { isNil } from 'lodash-es';
import { GqlUser } from '../../graphql/react-operations';

declare global {
  interface Window {
    hsConversationsSettings: {
      loadImmediately?: boolean;
      identificationEmail?: string;
      identificationToken?: string;
    };
    hsConversationsOnReady: [() => void] | [];
    _hsq: (string | unknown)[];
    HubSpotConversations: {
      widget: {
        refresh: () => void;
        load: () => void;
        remove: () => void;
      };
    };
  }
}

export const useHubspotChat = ({
  portalId,
  token,
  user,
}: {
  portalId: string | null | undefined;
  token: string | null | undefined;
  user: GqlUser | null | undefined;
}) => {
  const [hasLoaded, setHasLoaded] = useState(false);

  useOnce(() => {
    if (isNil(portalId)) {
      return;
    }
    // Add event listener.
    window.hsConversationsOnReady = [
      () => {
        setHasLoaded(true);
      },
    ];

    const script = document.createElement('script');
    script.src = `//js-eu1.hs-scripts.com/${portalId}.js`;
    script.type = 'text/javascript';
    script.id = 'hs-script-loader';
    script.defer = true;
    script.async = true;

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
      window.hsConversationsOnReady = [];
    };
  });

  useEffect(() => {
    if (isNil(portalId)) {
      return;
    }
    if (user?.email && token) {
      window.hsConversationsSettings = {
        identificationEmail: user.email,
        identificationToken: token,
      };
    }
    // When email and/or token changes, we must reload completely
    window.HubSpotConversations?.widget?.remove?.();
    window.HubSpotConversations?.widget?.load?.();
  }, [portalId, user?.email, token]);

  return {
    hasLoaded,
  };
};
