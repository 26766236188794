import { Box, Modal, ModalClose, ModalDialog } from '@startuptools/ui';
import { CreateCompanyStepper } from './CreateCompanyStepper';
import { useNgRouter } from '../react-wrapper/AngularRouterContext';

export const CreateCompanyDialog = ({ onClose }: { onClose: () => void }) => {
  const { changeCompany } = useNgRouter();

  return (
    <Modal open onClose={onClose}>
      <ModalDialog minWidth={800} sx={{ minHeight: 560, maxHeight: '90vh' }} size="lg">
        <ModalClose />
        <Box
          margin={3}
          sx={{ position: 'absolute', overflowY: 'auto', overflowX: 'hidden', maxHeight: '80%', width: '90%' }}
        >
          <CreateCompanyStepper onClose={onClose} navigateToCompany={changeCompany} />
        </Box>
      </ModalDialog>
    </Modal>
  );
};
