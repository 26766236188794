import { useCurrentUserSuspenseQuery, useUserSetContactDetailsMutation } from '../../graphql/react-operations';
import { Trans } from '@lingui/react';
import {
  Box,
  Typography,
  Input,
  Stack,
  Gravatar,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  GenericError,
} from '@startuptools/ui';
import { GqlUserSetContactDetailsInput } from '../../graphql/operations';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ComponentProps, Suspense, useState } from 'react';
import { captureException } from '@sentry/browser';

export const UserProfileEditInner = ({ onClose }: { onClose: () => void }) => {
  const [setUserDetails, { loading: userDetailsLoading }] = useUserSetContactDetailsMutation();
  const [showError, setShowError] = useState(false);
  const {
    data: { currentUser: user },
  } = useCurrentUserSuspenseQuery();
  const { register, handleSubmit } = useForm<GqlUserSetContactDetailsInput>({
    defaultValues: {
      name: user.name ?? '',
      email: user.email ?? '',
      phone: user.phone,
      communicationLanguage: user.communicationLanguage,
    },
  });

  const onSubmit: SubmitHandler<GqlUserSetContactDetailsInput> = async input => {
    if (showError) {
      setShowError(false);
    }
    try {
      await setUserDetails({ variables: { input } });
      onClose();
    } catch (error) {
      setShowError(true);
      captureException(error);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} margin={2}>
      <Stack direction="row" gap={3} marginTop={3}>
        <Stack gap={1}>
          <Gravatar md5Hash={user?.emailMd5} size="lg" uuid={user?.id}>
            {user.name?.[0] || user.email?.[0]}
          </Gravatar>
        </Stack>
        <Stack gap={3} minWidth={600}>
          <Typography level="h4">{user.name || user.email}</Typography>
          {user.personalId && (
            <FormControl>
              <FormLabel>
                <Trans id="Email" />
              </FormLabel>
              <Input {...register('email')} type="email" required fullWidth />
            </FormControl>
          )}
          {!user.personalId && (
            <FormControl>
              <FormLabel>
                <Trans id="Name" />
              </FormLabel>
              <Input {...register('name')} fullWidth />
            </FormControl>
          )}
          <FormControl>
            <FormLabel>
              <Trans id="Phone" />
            </FormLabel>
            <Input {...register('phone')} fullWidth />
          </FormControl>
          <FormControl>
            <FormLabel>
              <Trans id="Communication Language" />
            </FormLabel>
            <RadioGroup defaultValue={user.communicationLanguage}>
              <Radio value="sv" label={<Trans id="Swedish" />} {...register('communicationLanguage')} />
              <Radio value="en" label={<Trans id="English" />} {...register('communicationLanguage')} />
            </RadioGroup>
          </FormControl>
          {showError && <GenericError />}
          <Stack direction="row" justifyContent="end">
            <Button loading={userDetailsLoading} type="submit">
              <Trans id="Save" />
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export const UserProfileEdit = (props: ComponentProps<typeof UserProfileEditInner>) => {
  return (
    <Suspense fallback={<Box minHeight={382} minWidth={600} />}>
      <UserProfileEditInner {...props} />
    </Suspense>
  );
};
