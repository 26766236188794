'use client';
import { GqlAud, useBankidInitMutation } from './../graphql/react-operations';
import { isNil } from 'lodash-es';
import { BankidPoll } from './Poll';
import { Button, CircularProgress, GenericError, Stack } from '@startuptools/ui';
import { useOnce } from '@startuptools/common/react-hooks';
import { Navigate } from '../types';
import { Trans } from '@lingui/react';

export const BankidInit = ({ navigate, aud }: { navigate: Navigate; aud: GqlAud }) => {
  const [init, { data, loading, error, called }] = useBankidInitMutation({ fetchPolicy: 'no-cache' });

  const reInit = async () => {
    try {
      await init();
    } catch (e) {
      console.error(e);
    }
  };

  useOnce(() => {
    void reInit();
  });

  if (loading || !called) {
    return (
      <Stack gap={2} justifyContent="center" alignItems="center">
        <CircularProgress size="lg" />
      </Stack>
    );
  }

  if (error) {
    return (
      <>
        <GenericError />
        <Button onClick={() => navigate('/auth', { replace: true })} sx={{ marginTop: 2 }}>
          <Trans id="Go back" />
        </Button>
      </>
    );
  }

  if (isNil(data?.bankidInit?.autoStartToken)) {
    return null;
  }

  return (
    <BankidPoll
      navigate={navigate}
      autoStartToken={data.bankidInit.autoStartToken}
      qrStartToken={data.bankidInit.qrStartToken}
      orderRef={data.bankidInit.orderRef || ''}
      aud={aud}
      reInit={reInit}
    />
  );
};
