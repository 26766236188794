import { Input, InputOwnerState } from '@mui/joy';
import { SlotProps } from '@mui/joy/utils/types';
import { ComponentProps, forwardRef, useMemo } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
const NumericFormatAdapter = forwardRef<NumericFormatProps, CustomProps>(function NumericFormatAdapter(props, ref) {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({ target: { name: props.name, value: values.value } });
      }}
      valueIsNumericString
    />
  );
});

type InputSlotProps = SlotProps<'input', object, InputOwnerState> & NumericFormatProps;
type NumericFormatInputProps = ComponentProps<typeof Input> & Pick<NumericFormatProps, 'decimalScale'>;

export const NumericFormatInput = forwardRef<InputSlotProps, NumericFormatInputProps>((props, ref) => {
  const { decimalSeparator, thousandSeparator } = useMemo(() => {
    const a = Intl.NumberFormat(props.lang);
    const parts = a.formatToParts(1000.1);
    const thousandSeparator = parts.find(p => p.type === 'group')?.value ?? ',';
    const decimalSeparator = parts.findLast(p => p.type === 'decimal')?.value ?? '.';
    return { decimalSeparator, thousandSeparator };
  }, [props.lang]);
  const { decimalScale, tabIndex, ...restProps } = props;
  return (
    <Input
      {...restProps}
      slotProps={{
        input: {
          component: NumericFormatAdapter,
          getInputRef: ref,
          decimalScale,
          decimalSeparator,
          thousandSeparator,
          tabIndex,
        } satisfies InputSlotProps,
      }}
    />
  );
});
