import { createAction, props, union } from '@ngrx/store';

import { AuthorizationGroup } from '../../models/authorization-group.model';

const prefix = '[AuthorizationGroups] ' as const;

export const authorizationGroupActions = {
  manyLoaded: createAction(prefix + 'manyLoaded', props<{ payload: AuthorizationGroup[] }>()),
  manyDeleted: createAction(prefix + ' manyDeleted', props<{ payload: string[] }>()),
};

const all = union(authorizationGroupActions);

export type ActionsUnion = typeof all;
