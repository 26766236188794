import { createAction, props, union } from '@ngrx/store';

import { MeetingDocument } from '../../models/meeting-document.model';

const prefix = '[MeetingDocuments]' as const;

export const meetingDocumentsActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: MeetingDocument[] }>()),
};

const all = union(meetingDocumentsActions);

export type ActionsUnion = typeof all;
