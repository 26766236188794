import { Transform } from 'class-transformer';
import { DateTime } from 'luxon';
import { isNumber } from 'lodash-es';
import { User } from './user.model';
import { GqlIdentity } from '../graphql/operations';
import { Company } from './company.model';

export enum JuridicalKind {
  Person,
  Company,
}

export enum IDKind {
  Passport,
  SwedishPersonalID,
  SwedishOrgNumber,
  ForeignCompanyDirector,
  ForeignManual,
  Fictitious,
}

export type IdentityId = string;

export class Identity
  implements Omit<GqlIdentity, 'meetingParticipants' | 'shareIssueParticipants' | 'capTableStakes'>
{
  id: string;

  identityNumberKind: IDKind;
  identityNumber: string;
  juridicalKind: JuridicalKind;
  name: string;
  email?: string | null | undefined;
  emailMd5?: string;
  phone?: string;
  address?: string;
  countryCode?: string;
  companyId: string;
  userId?: string;
  isCurrentUser: boolean;

  @Transform(
    ({ value }: { value: string | number }) =>
      isNumber(value) ? DateTime.fromSeconds(value) : DateTime.fromISO(value),
    { toClassOnly: true },
  )
  readonly createdAt: DateTime;

  @Transform(
    ({ value }: { value: string | number }) =>
      isNumber(value) ? DateTime.fromSeconds(value) : DateTime.fromISO(value),
    { toClassOnly: true },
  )
  readonly updatedAt: DateTime;

  constructor(seed?: Partial<Identity>) {
    Object.assign(this, seed);
  }

  static fromUser(user: User, companyId: string) {
    return new Identity({
      name: user.name,
      identityNumberKind: IDKind.SwedishPersonalID,
      identityNumber: user.personalId,
      email: user.email,
      juridicalKind: JuridicalKind.Person,
      userId: user.id,
      companyId: companyId,
    });
  }

  static fromCompany(company: Company) {
    return new Identity({
      name: company.name,
      identityNumberKind: IDKind.SwedishOrgNumber,
      identityNumber: company.organizationNumber,
      email: company.email,
      juridicalKind: JuridicalKind.Company,
    });
  }

  // This is used in autocomplete when user selects an identity
  public toString = (): string => {
    return this.name;
  };
}

export function isSwedish(
  identity: Pick<GqlIdentity, 'identityNumberKind'> | Pick<Identity, 'identityNumberKind'>,
): boolean {
  return (
    identity.identityNumberKind === IDKind.SwedishOrgNumber || identity.identityNumberKind === IDKind.SwedishPersonalID
  );
}
