import { ComponentProps, Fragment, Suspense, useMemo, useState } from 'react';
import {
  useCompaniesSuspenseQuery,
  useCompanySuggestionsSuspenseQuery,
  useRatsitCompaniesSuspenseQuery,
} from '../../graphql/react-operations';
import {
  Alert,
  Box,
  Button,
  Chip,
  Divider,
  ErrorBoundary,
  Input,
  List,
  ListItem,
  ListItemButton,
  SearchIcon,
  Sheet,
  Skeleton,
  Stack,
  Typography,
} from '@startuptools/ui';
import { Trans, useLingui } from '@lingui/react';
import { useForm } from 'react-hook-form';

const CompanySearchResultsInner = ({
  searchString,
  onSelect,
}: {
  searchString: string;
  onSelect: ({ orgNumber, name }: { orgNumber: string; name: string; isCompanyDirector: boolean }) => void;
}) => {
  const {
    data: { ratsitCompanies },
  } = useRatsitCompaniesSuspenseQuery({ variables: { name: searchString } });

  return (
    <>
      {ratsitCompanies.length === 0 ? (
        <Alert>
          <Trans id="The search returned no results, try to adjust the search term or use the company's organisation number" />
        </Alert>
      ) : (
        ratsitCompanies.slice(0, 10).map(c => (
          <>
            <ListItem key={c.identityNumber}>
              <ListItemButton
                sx={{ padding: 1.5 }}
                onClick={() => onSelect({ orgNumber: c.identityNumber, name: c.name, isCompanyDirector: false })}
              >
                <Typography level="title-md">{c.name}</Typography>
                <Chip variant="outlined" color="primary" size="sm">
                  {c.identityNumber}
                </Chip>
              </ListItemButton>
            </ListItem>
            <Divider />
          </>
        ))
      )}
    </>
  );
};

const CompanySearchResults = (props: ComponentProps<typeof CompanySearchResultsInner>) => {
  return (
    <Sheet variant="outlined" sx={{ maxHeight: 340, overflow: 'auto' }}>
      <List>
        <ErrorBoundary>
          <Suspense
            fallback={
              <>
                <ListItem>
                  <Skeleton variant="rectangular" height={50}></Skeleton>
                </ListItem>
                <ListItem>
                  <Skeleton variant="rectangular" height={50}></Skeleton>
                </ListItem>
              </>
            }
          >
            <CompanySearchResultsInner {...props} />
          </Suspense>
        </ErrorBoundary>
      </List>
    </Sheet>
  );
};

type FormData = { searchString: string };
const CreateCompanyStep1Inner = ({
  onSelect,
}: {
  onSelect: (args: { orgNumber: string; name: string; isCompanyDirector: boolean }) => void;
}) => {
  const {
    data: { companies },
  } = useCompaniesSuspenseQuery();
  const {
    data: { companySuggestions },
  } = useCompanySuggestionsSuspenseQuery();
  const { i18n } = useLingui();

  const [searchString, setSearchString] = useState<string | null>(null);

  const { register, handleSubmit, watch } = useForm<FormData>({ defaultValues: { searchString: '' } });

  const currentSearchString = watch('searchString');

  const onSearch = (data: FormData) => {
    if (!data.searchString.length) {
      return;
    }
    setSearchString(data.searchString);
  };

  const userCompaniesNotOnPlatform = useMemo(() => {
    return companySuggestions.filter(({ id }) => {
      return !companies.some(c => c.id === id);
    });
  }, [companies, companySuggestions]);

  const companiesFilteredBySearchString = useMemo(() => {
    if (!currentSearchString.length) {
      return userCompaniesNotOnPlatform;
    }
    if (userCompaniesNotOnPlatform.length === 1) {
      /* dont filter companies when user only has 1, just confusiing */
      return userCompaniesNotOnPlatform;
    }
    return userCompaniesNotOnPlatform.filter(({ name, organizationNumber }) => {
      return (
        name.toLowerCase().includes(currentSearchString.toLowerCase()) ||
        organizationNumber.includes(currentSearchString.replace('-', ''))
      );
    });
  }, [currentSearchString, userCompaniesNotOnPlatform]);

  return (
    <Stack gap={2}>
      <Typography level="title-lg">
        <Trans id="Search for a company" />
      </Typography>
      <Stack gap={2}>
        <Box component="form" onSubmit={handleSubmit(onSearch)}>
          <Input
            {...register('searchString')}
            onBlur={handleSubmit(onSearch)}
            startDecorator={<SearchIcon />}
            type="search"
            placeholder={i18n._('Search for companies...')}
            endDecorator={
              <Button variant="soft" color="neutral" type="submit">
                <Trans id="Search" />
              </Button>
            }
          />
        </Box>
        {searchString && <CompanySearchResults searchString={searchString} onSelect={onSelect} />}
      </Stack>
      <Typography level="title-md">
        {userCompaniesNotOnPlatform.length > 0 ? (
          <Trans
            id="Your {count, plural, =1 {company} other {companies}}"
            values={{ count: userCompaniesNotOnPlatform.length }}
          />
        ) : (
          <Trans id="Try Bolago with a demo company" />
        )}
      </Typography>
      <List variant="outlined">
        {companiesFilteredBySearchString.map(c => (
          <Fragment key={c.id}>
            <ListItem>
              <ListItemButton
                onClick={() => onSelect({ orgNumber: c.organizationNumber, name: c.name, isCompanyDirector: true })}
                sx={{ padding: 1.5 }}
              >
                <Stack direction="row" gap={2} alignItems="center">
                  <Typography level="title-md">{c.name}</Typography>
                  <Chip variant="outlined" color="primary" size="sm">
                    {c.organizationNumber}
                  </Chip>
                  <Stack direction="row" gap={1}>
                    {c.functions.map(f => (
                      <Chip key={f} variant="outlined" color="success" size="sm">
                        {f}
                      </Chip>
                    ))}
                  </Stack>
                </Stack>
              </ListItemButton>
            </ListItem>
            <Divider />
          </Fragment>
        ))}
        <ListItem>
          <ListItemButton
            onClick={() => onSelect({ orgNumber: '0000000000', name: 'Pied Piper AB', isCompanyDirector: true })}
            sx={{ padding: 1.5 }}
          >
            <Stack direction="row" gap={2}>
              <Typography level="title-md">Pied Piper AB</Typography>
            </Stack>
            <Chip variant="outlined" color="primary" size="sm">
              <Trans id="Demo Company" />
            </Chip>
          </ListItemButton>
        </ListItem>
      </List>
    </Stack>
  );
};

export const CreateCompanyStep1 = (props: ComponentProps<typeof CreateCompanyStep1Inner>) => {
  return (
    <Suspense>
      <CreateCompanyStep1Inner {...props} />
    </Suspense>
  );
};
