import { createSelector } from '@ngrx/store';
import { filter } from 'lodash-es';
import { selectShareIssueParticipantsState } from './share-issue-participants.base';
import { selectShareIssueRegistrationsWithIncludes } from './share-issue-registration.selectors';
import { selectShareIssueId } from './share-issues.base';

export const selectShareIssueParticipants = createSelector(
  selectShareIssueRegistrationsWithIncludes,
  selectShareIssueId,
  (sirs, siId) => {
    return sirs?.filter(e => e?.model.shareIssueId === siId).flatMap(e => e.participants.map(e => e.model)) ?? [];
  },
);
export const selectSipsWithIncludes = createSelector(selectShareIssueRegistrationsWithIncludes, sirs => {
  const sips = sirs?.flatMap(sir => sir.participants) ?? [];
  return sips;
});
export const selectShareIssueParticipantsBySiId = (siId: string) =>
  createSelector(selectShareIssueParticipantsState, state => {
    // @ts-expect-error TS18048
    return filter(state.entities, e => e.shareIssueId === siId);
  });
export const selectShareIssueParticipantById = (id: string) =>
  createSelector(selectShareIssueParticipantsState, state => state.entities[id]);
