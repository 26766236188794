import { createReducer, on, Action } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { map } from 'lodash-es';

import { Meeting } from '../../models/meeting.model';
import { meetingActions } from '../actions/meetings.actions';

export type MeetingsState = EntityState<Meeting>;

export const adapter = createEntityAdapter<Meeting>();
export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(meetingActions.manyLoaded, (state, { payload }) =>
    adapter.addMany(
      payload,
      adapter.removeMany(
        map(payload, p => p.id),
        state,
      ),
    ),
  ),
  on(meetingActions.manyDeleted, (state, { payload }) => adapter.removeMany(payload, state)),
);

export function meetingsReducer(state: EntityState<Meeting> | undefined, action: Action) {
  return reducer(state, action);
}
