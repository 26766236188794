import { Trans } from '@lingui/react';
import { BoxProps } from '@mui/joy';
import { Button, Stack, Divider, CloudDownloadIcon, Box } from '@startuptools/ui';
import React from 'react';

/**
 *  @doc this is a dumb component that renders a PDF. It can either take a Blob or a src url,
 * and render it in in iframe inside a modal
 **/
export const PDFModal = ({
  src,
  blob,
  fileName,
  showDownloadButton = true,
  ...boxProps
}: {
  blob?: Blob | undefined;
  src: string | undefined;
  fileName: string;
  showDownloadButton?: boolean;
} & BoxProps) => {
  const [blobSrc, setBlobSrc] = React.useState<string | undefined>();

  React.useEffect(() => {
    if (!blob) return;
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      setBlobSrc(base64data as string);
    };
  }, [blob]);

  const downloadUrl = React.useMemo(() => {
    if (blob) {
      return window.URL.createObjectURL(blob);
    }
    if (src) {
      return src;
    }

    throw new Error('no valid download url found');
  }, [blob, src]);

  return (
    <>
      {showDownloadButton && (
        <>
          <Stack direction="row" justifyContent="flex-end" my={3}>
            <Button
              component="a"
              startDecorator={<CloudDownloadIcon />}
              href={downloadUrl}
              download={fileName}
              target="_blank"
            >
              <Trans id="Download" />
            </Button>
          </Stack>
          <Divider />
        </>
      )}
      {/* Object doesn't work in Safari, iframe it is */}
      <Box {...boxProps} component="iframe" title={fileName} height="100%" src={src ?? blobSrc} />
    </>
  );
};
