import { GqlShareholder } from '../graphql/operations';
import { Identity } from './identity.model';
import { User } from './user.model';

export interface ShWithIncludes {
  model: Shareholder;
  identity: Identity;
  user?: User;
}

export class Shareholder implements Omit<GqlShareholder, 'company' | 'shareTransactions' | 'identity'> {
  id: string;

  companyId: string;
  identityId: string;
  ownerCompanyId?: string;
  deletable: boolean;

  readonly createdAt: string;
  readonly updatedAt: string;

  constructor(seed?: Partial<Shareholder>) {
    Object.assign(this, seed);
  }
}
