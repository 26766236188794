import { createFeatureSelector, createSelector } from '@ngrx/store';
import { get, filter, some } from 'lodash-es';
import { selectCompanyId } from './companies.base';
import { SnrAuthorizationsState } from '../reducers/snr-authorizations.reducers';
import { selectWarrantProgram } from './warrant-programs.base';
import { selectShareIssue } from './share-issues.base';
import { SnrAuthKind } from '../../models/snr-authorization.model';

export const selectSnrAuthorizationsState = createFeatureSelector<SnrAuthorizationsState>('snrAuthorizations');
export const selectSnrAuthorizationById = (id: string) =>
  createSelector(selectSnrAuthorizationsState, state => {
    return get(state.entities, id);
  });
export const selectSnrAuthorizations = createSelector(selectSnrAuthorizationsState, selectCompanyId, (state, cid) => {
  // @ts-expect-error TS18048
  return filter(state.entities, e => e.companyId === cid);
});

export const selectSnrAuthorizationByKind = (kind: SnrAuthKind) =>
  createSelector(selectSnrAuthorizations, auths => {
    // @ts-expect-error TS18048
    return filter(auths, e => some(e.auths, a => a.kind === kind));
  });

export const selectSnrAuthorizationByWp = createSelector(
  selectSnrAuthorizationsState,
  selectWarrantProgram,
  (state, warrantProgram) => {
    return get(state.entities, warrantProgram?.snrAuthorizationId);
  },
);

export const selectSnrAuthorizationBySi = createSelector(
  selectSnrAuthorizationsState,
  selectShareIssue,
  (state, shareIssue) => {
    return get(state.entities, shareIssue?.snrAuthorizationId);
  },
);
