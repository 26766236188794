import { Pipe, PipeTransform } from '@angular/core';
import { isFunction } from 'lodash-es';
import { Store, select, MemoizedSelector, State } from '@ngrx/store';
import { AppState } from '../store/reducers';

@Pipe({ name: 'selector' })
export class SelectorPipe implements PipeTransform {
  constructor(private store: Store<AppState>) {}

  transform<T>(input: MemoizedSelector<State<T>, T>) {
    if (isFunction(input)) {
      // @ts-expect-error TS2345
      return this.store.pipe(select(input));
    }
  }
}
