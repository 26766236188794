'use client';
import { useState } from 'react';
import { useNavigate, createBrowserRouter, RouterProvider, Outlet, useSearchParams } from 'react-router-dom';
import {
  Button,
  Stack,
  Box,
  Sheet,
  SvgIcon,
  Menu,
  MenuItem,
  Typography,
  Link,
  NotFoundError,
  GenericError,
  Dropdown,
  MenuButton,
  EmailIcon,
  AssignmentTurnedInIcon,
  NewspaperIcon,
  UploadFileIcon,
  ReceiptLongIcon,
  GroupsIcon,
  SettingsSuggestIcon,
  Grid,
  Alert,
} from '@startuptools/ui';
import { BankidInit } from './bankid/Init';
import { Password } from './password/Password';
import { EmailRegister } from './password/Register';
import { RegisterConfirm } from './password/RegisterConfirm';
import { PasswordRecover } from './password/Recover';
import { PasswordRecoverConfirm } from './password/RecoverConfirm';
import { Truid } from './truid/Truid';
import { TruidCompleteLogin } from './truid/CompleteLogin';
import { Trans, useLingui } from '@lingui/react';
import BankId from './bank_id_logo_white.svg';
import En from './en.svg';
import Sv from './sv.svg';
import { GqlAud } from './graphql/react-operations';
import { Navigate } from './types';
import { getRedirectParam } from './utils/redirect';

interface Props {
  imgSrc: string;
  appPage?: boolean;
  navigate?: Navigate;
  showBolagoBanner: boolean;
}

export const Start = ({
  appPage = false,
  navigate = () => new Error('not implemented'),
  showBolagoBanner,
}: Omit<Props, 'imgSrc'>) => {
  const [showError, setShowError] = useState<boolean>(false);
  return (
    <Stack direction="column" gap={3} py={3} justifyContent="space-between">
      <Typography
        sx={{
          fontFamily: 'Inter, Arial, Sans-Serif',
          fontSize: '2rem',
          fontWeight: '700',
          color: 'black',
          lineHeight: '2.0625rem',
        }}
      >
        <Trans id="Welcome to Bolago" />
      </Typography>

      {showBolagoBanner && (
        <Alert sx={{ flexDirection: 'column' }}>
          <Typography fontSize="body-sm">
            <Trans
              id="The StartupTools platform is now known as Bolago! Read more <link>here</link>, or login below as usual."
              components={{ link: <Link href="https://bolago.com/startuptools-lanserar-bolago/" /> }}
            />
          </Typography>
          <Typography fontSize="body-sm">
            <Trans id=" Rest assured, all your functions remain the same, only with improved looks and under a new name." />
          </Typography>
        </Alert>
      )}

      {/* shareholder pages */}
      {!appPage && (
        <>
          <Typography fontWeight="bold">
            <Trans id="For Shareholders" />
          </Typography>
          <Typography>
            <Trans
              id="Log in here to see your ownership in companies on the Bolago platform. Want to administer your company? Go <link>here</link> instead."
              components={{ link: <Link href="https://bolago.app" /> }}
            />
          </Typography>
        </>
      )}

      {/* main app */}
      {appPage && (
        <Stack direction="column" spacing={3}>
          <Typography
            sx={{
              fontFamily: 'Inter, Arial, Sans-Serif',
              fontSize: '1.5rem',
              fontWeight: '700',
              color: 'black',
            }}
          >
            <Trans id="Features" />
          </Typography>
          <Box>
            <Grid container spacing={2} justifyContent="space-between">
              {[
                {
                  icon: <AssignmentTurnedInIcon color="primary" />,
                  text: <Trans id="E-sign documents" />,
                },
                { icon: <NewspaperIcon color="primary" />, text: <Trans id="Issue new shares" /> },
                { icon: <UploadFileIcon color="primary" />, text: <Trans id="Document hosting" /> },
                { icon: <ReceiptLongIcon color="primary" />, text: <Trans id="Issue warrants & QESO" /> },
                { icon: <GroupsIcon color="primary" />, text: <Trans id="Board & general meetings" /> },
                { icon: <SettingsSuggestIcon color="primary" />, text: <Trans id="Formality automation" /> },
              ].map(({ icon, text }, i) => (
                <Grid xs={6} md={4} key={i}>
                  <Stack direction="row" spacing={1}>
                    {icon}
                    <Typography>{text}</Typography>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Stack>
      )}

      <Typography level="h4">
        <Trans id="Log in or create an account with..." />
      </Typography>

      <Stack gap={2}>
        <Stack direction="column" spacing={1} justifyContent="space-between">
          <Button
            color="primary"
            variant="solid"
            sx={{ fontSize: '1.1rem' }}
            size="lg"
            startDecorator={
              <SvgIcon>
                <BankId />
              </SvgIcon>
            }
            onClick={() => {
              return navigate(`/auth/bankid?${getRedirectParam()}`);
            }}
          >
            <Trans id="BankID" />
          </Button>
          <Truid aud={appPage ? GqlAud.CompanyAdmin : GqlAud.Stakeholder} setShowError={setShowError} />
          <Button
            color="neutral"
            variant="outlined"
            sx={{ fontSize: '1.1rem' }}
            size="lg"
            startDecorator={<EmailIcon />}
            onClick={() => navigate(`/auth/password/login?${getRedirectParam()}`)}
          >
            <Trans id="Email" />
          </Button>
        </Stack>
        <Box alignSelf="center">
          <Typography level="title-sm">
            <Trans id="By logging in you accept our" />
            &nbsp;
            <Link href="https://bolago.com/se/villkor-for-startuptools-plattform-2024" target="_blank" rel="noreferrer">
              <Trans id="terms of service" />
            </Link>
          </Typography>
        </Box>
        {showError && <GenericError />}
      </Stack>
    </Stack>
  );
};

export const AuthInner = ({
  imgSrc,
  appPage,
  children,
  switchLanguage,
}: Omit<Props, 'showBolagoBanner'> & { children?: React.ReactNode; switchLanguage: (lang: 'en' | 'sv') => void }) => {
  const { i18n } = useLingui();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="75%"
      minHeight="100vh"
      margin="0 auto"
    >
      <Sheet variant="outlined" sx={{ bgcolor: 'white' }}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          height={{ xs: '100vh', md: 'auto' }}
          padding={{ xs: 3, md: 0 }}
          gap={3}
        >
          <Box
            sx={{
              maxHeight: '80vh',
              overflow: 'hidden',
              borderRadius: '0.25rem 0 0 0.25rem',
            }}
            display={{ xs: 'none', md: 'block' }}
          >
            <img src={imgSrc} alt="People working" width="834px" height="1034px" style={{ display: 'block' }} />
          </Box>

          <Stack
            padding={3}
            width={{ xs: '95vw', md: '25vw' }}
            height={{ xs: '90vh', md: 'auto' }}
            minWidth={{ xs: 'auto', md: '500px' }}
            sx={{
              overflowX: 'hidden',
              overflowY: 'scroll',
            }}
            justifyContent="space-between"
          >
            <Outlet />
            {children}
            <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
              <Link href="https://bolago.com" rel="noreferrer" target="_blank" color="neutral">
                bolago.com
              </Link>
              {appPage ? (
                <Link href="https://my.bolago.app" rel="noreferrer" target="_blank" color="neutral">
                  <Trans id="For shareholders" />
                </Link>
              ) : (
                <Link href="https://bolago.app" rel="noreferrer" target="_blank" color="neutral">
                  <Trans id="For company admin" />
                </Link>
              )}
              <Dropdown>
                <MenuButton
                  variant="plain"
                  size="lg"
                  startDecorator={
                    <SvgIcon>
                      {i18n.locale === 'sv' && <Sv />}
                      {i18n.locale === 'en' && <En />}
                    </SvgIcon>
                  }
                >
                  {i18n.locale === 'sv' && <Trans id="Swedish" />}
                  {i18n.locale === 'en' && <Trans id="English" />}
                </MenuButton>
                <Menu>
                  <MenuItem onClick={() => switchLanguage('sv')}>
                    <Stack direction="row" gap={1}>
                      <SvgIcon>
                        <Sv />
                      </SvgIcon>
                      <Trans id="Swedish" />
                    </Stack>
                  </MenuItem>
                  <MenuItem onClick={() => switchLanguage('en')}>
                    <Stack direction="row" gap={1}>
                      <SvgIcon>
                        <En />
                      </SvgIcon>
                      <Trans id="English" />
                    </Stack>
                  </MenuItem>
                </Menu>
              </Dropdown>
            </Stack>
          </Stack>
        </Stack>
      </Sheet>
    </Box>
  );
};

const wrapWithRouterProps =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: Omit<P, 'navigate' | 'switchLanguage'>) => {
    const navigate = useNavigate();
    const switchLanguage = (lang: 'en' | 'sv') => {
      document.cookie = `NEXT_LOCALE=${lang};max-age=31536000;path=/`;
      window.location.replace(window.location.href.replace(/\/(sv|en)\//, `/${lang}/`));
    };
    const [params] = useSearchParams();
    return (
      <Component
        {...(props as P)}
        navigate={navigate}
        switchLanguage={switchLanguage}
        showBolagoBanner={params.get('from') === 'startuptools'}
      />
    );
  };

const wrapWithTruidSearchParams =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: Omit<P, 'code' | 'state' | 'error'>) => {
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const state = searchParams.get('state');
    const error = searchParams.get('error');
    return <Component {...(props as P)} code={code} state={state} error={error} />;
  };

const InnerWithNavigate = wrapWithRouterProps(AuthInner);
const StartWithNavigate = wrapWithRouterProps(Start);
const BankidInitWithNavigate = wrapWithRouterProps(BankidInit);
const EmailLoginWithNavigate = wrapWithRouterProps(Password);
const EmailRegisterWithNavigate = wrapWithRouterProps(EmailRegister);
const RegisterConfirmWithNavigate = wrapWithRouterProps(RegisterConfirm);
const PasswordRecoverWithNavigate = wrapWithRouterProps(PasswordRecover);
const PasswordRecoverConfirmWithNavigate = wrapWithRouterProps(PasswordRecoverConfirm);
const TruidCompleteLoginWithNavigate = wrapWithTruidSearchParams(wrapWithRouterProps(TruidCompleteLogin));

export const Auth = (props: Props) => {
  const aud = props.appPage ? GqlAud.CompanyAdmin : GqlAud.Stakeholder;
  const router = createBrowserRouter([
    {
      path: '/:lang?/auth',
      element: <InnerWithNavigate {...props} />,
      children: [
        { path: '', element: <StartWithNavigate {...props} /> },
        { path: 'bankid', element: <BankidInitWithNavigate aud={aud} /> },
        {
          path: 'password',
          element: <Outlet />,
          children: [
            { path: 'login', element: <EmailLoginWithNavigate aud={aud} /> },
            { path: 'register', element: <EmailRegisterWithNavigate /> },
            { path: 'register-confirm', element: <RegisterConfirmWithNavigate aud={aud} /> },
            { path: 'recover', element: <PasswordRecoverWithNavigate /> },
            { path: 'recover-confirm', element: <PasswordRecoverConfirmWithNavigate aud={aud} /> },
          ],
        },
        {
          path: 'truid',
          element: <Outlet />,
          children: [
            {
              path: 'v1/complete-login',
              element: <TruidCompleteLoginWithNavigate aud={aud} />,
            },
          ],
        },
        {
          path: '*',
          element: <NotFoundError />,
        },
      ],
    },
    {
      /* this is a workaround for a 404 flashing while redirecting from auth to the logged in app */
      path: '/',
    },
    {
      /* this is a workaround for a 404 flashing while redirecting from auth to the logged in app */
      path: '/sv',
    },
    {
      /* this is a workaround for a 404 flashing while redirecting from auth to the logged in app */
      path: '/en',
    },
  ]);
  return <RouterProvider router={router} />;
};
