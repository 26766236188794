export enum InputState {
  System,
  CompanyBoard,
  Swedish,
  Foreign,
}

export enum DetailState {
  Hidden,
  Required,
}

export interface DetailFieldsSettings {
  email?: DetailState;
  phone?: DetailState;
  address?: DetailState;
  countryCode?: DetailState;
}
