import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import { map } from 'lodash-es';

import { meetingAgendaTemplatesActions } from '../actions/meeting-agenda-templates.actions';
import { MeetingAgendaTemplate } from '../../models/meeting-agenda-template.model';

export type MeetingAgendaTemplatesState = EntityState<MeetingAgendaTemplate>;

export const adapter = createEntityAdapter<MeetingAgendaTemplate>();
export const initialMeetingAgendaTemplatesState = adapter.getInitialState({});

const reducer = createReducer(
  initialMeetingAgendaTemplatesState,
  on(meetingAgendaTemplatesActions.manyLoaded, (state, { payload }) =>
    adapter.addMany(
      payload,
      adapter.removeMany(
        map(payload, p => p.id),
        state,
      ),
    ),
  ),
  on(meetingAgendaTemplatesActions.manyDeleted, (state, { payload }) => adapter.removeMany(payload, state)),
);

export function meetingAgendaTemplatesReducer(state: EntityState<MeetingAgendaTemplate> | undefined, action: Action) {
  return reducer(state, action);
}
