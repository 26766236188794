import { Box, Skeleton, Stack, Typography } from '@startuptools/ui';
import { ComponentProps, ReactNode } from 'react';

export const IdentityDisplay = ({
  name,
  secondRow,
  layout = 'column',
  startDecorator,
  renderName,
}: {
  name: string | null | undefined | React.ReactNode;
  renderName?: (name: string | null | undefined | React.ReactNode) => ReactNode;
  secondRow?: string | null;
  layout?: 'row' | 'column';
  startDecorator?: ReactNode;
}) => {
  return (
    <>
      <Stack gap={2} direction="row" alignItems="center">
        {startDecorator && <Box>{startDecorator}</Box>}
        <Box
          sx={{
            whiteSpace: 'nowrap',
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
            display: 'flex',
            flexDirection: layout,
            alignItems: layout === 'row' ? 'center' : undefined,
          }}
        >
          <Typography level="body-md" noWrap>
            {renderName ? renderName(name) : name}
          </Typography>
          {secondRow && (
            <>
              {layout === 'row' && <Typography>&nbsp;</Typography>}
              <Typography level={layout === 'row' ? 'body-md' : 'body-sm'} textColor="neutral.500" noWrap>
                {secondRow}
              </Typography>
            </>
          )}
        </Box>
      </Stack>
    </>
  );
};

export const IdentityDisplaySkeleton = ({
  layout = 'column',
}: Pick<ComponentProps<typeof IdentityDisplay>, 'layout'>) => {
  return (
    <Stack gap={2} direction="row" alignItems="center">
      <Skeleton variant="circular" width={40} height={40} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: layout,
          alignItems: layout === 'row' ? 'center' : undefined,
        }}
      >
        <Skeleton variant="text" level="body-md" width={150} />
        <Skeleton variant="text" level="body-md" width={150} />
      </Box>
    </Stack>
  );
};
