import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ApolloQueryResult, FetchResult, OperationVariables } from '@apollo/client/core';
import { OperatorFunction, throwError, of } from 'rxjs';
import { first, concatMap, map } from 'rxjs/operators';
import { Mutation, Query } from 'apollo-angular';
import { graphqlActions, Payload } from '../store/actions/graphql.actions';
import { AppState } from '../store/reducers';
import { MutationOptionsAlone } from 'node_modules/apollo-angular/types.js';

export type OperationId = string;

function waitForQuery<T>(opId: OperationId): OperatorFunction<Action, Payload<T>> {
  return input$ =>
    input$.pipe(
      ofType(graphqlActions.resultStored, graphqlActions.error),
      first(action => action.payload.opId === opId),
      concatMap(action => {
        if (action.type === graphqlActions.error.type) {
          return throwError(() => action);
        }
        return of(action);
      }),
      map(action => action.payload),
    );
}

@Injectable({ providedIn: 'root' })
export class GqlDispatchService {
  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
  ) {}

  public query<T, V extends OperationVariables>(query: Query<T, V>, variables: V) {
    const opId = crypto.randomUUID();
    setTimeout(() =>
      this.store.dispatch(
        graphqlActions.query({
          payload: {
            opId,
            query: () => query.fetch(variables, { fetchPolicy: 'no-cache' }),
          },
        }),
      ),
    );
    return this.actions$.pipe(
      waitForQuery<T>(opId),
      map(res => res.result as ApolloQueryResult<T>),
    );
  }

  public mutate<T, V extends OperationVariables>(
    mutation: Mutation<T, V>,
    variables: V,
    options?: MutationOptionsAlone<T, V>,
  ) {
    const opId = crypto.randomUUID();
    setTimeout(() =>
      this.store.dispatch(
        graphqlActions.mutate({
          payload: {
            opId,
            mutation: () => mutation.mutate(variables, { fetchPolicy: 'no-cache', ...options }),
          },
        }),
      ),
    );
    return this.actions$.pipe(
      waitForQuery<T>(opId),
      map(res => res.result as FetchResult<T>),
    );
  }
}
