import { createAction, props, union } from '@ngrx/store';

import { SnrCase } from '../../models/snr-case.model';

const prefix = '[SnrCases]' as const;

export const snrCasesActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: SnrCase[] }>()),
};

export const all = union(snrCasesActions);
