import { createAction, props, union } from '@ngrx/store';

import { WarrantProgramParticipant } from '../../models/warrant-program-participant.model';

const prefix = '[WarrantProgramParticipants]' as const;

export const warrantParticipantsActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: WarrantProgramParticipant[] }>()),
  manyDeleted: createAction(prefix + ' manyDeleted', props<{ payload: string[] }>()),
};

const all = union(warrantParticipantsActions);

export type ActionsUnion = typeof all;
