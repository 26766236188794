import { GqlCreateIdentityInput, GqlIdentity, GqlUpdateIdentityMutation } from '../../graphql/react-operations';
import { Gravatar, Box } from '@startuptools/ui';
import { IdentityEditDialog } from './IdentityEditDialog';
import { useMemo, useState } from 'react';
import { isSwedish } from '../../models/identity.model';
import { formatIdNumber } from '../../pipes/id-number.pipe';
import { FetchResult, InternalRefetchQueriesInclude } from '@apollo/client';
import { IdentityDisplay } from './IdentityDisplay';
import { BoxProps } from '@mui/joy';

export type DisplayIdentity = Pick<
  GqlIdentity,
  'id' | 'name' | 'emailMd5' | 'identityNumber' | 'email' | 'phone' | 'identityNumberKind' | 'juridicalKind'
>;

export interface Props {
  identity: DisplayIdentity | GqlCreateIdentityInput;
  renderName?: (name: string | null | undefined | React.ReactNode) => React.ReactNode;
  showAvatar?: boolean;
  showEmail?: boolean;
  editable?: boolean;
  maskIdNumber?: boolean;
  layout?: 'row' | 'column';
  onChange?: () => void;
  updateRefetchQueries?:
    | ((result: FetchResult<GqlUpdateIdentityMutation>) => InternalRefetchQueriesInclude)
    | InternalRefetchQueriesInclude;
}

export function Identity({
  identity,
  showAvatar = true,
  showEmail = true,
  editable = true,
  maskIdNumber = false,
  layout = 'column',
  onChange,
  updateRefetchQueries,
  renderName,
  ...rest
}: Props & BoxProps) {
  const [open, setOpen] = useState<boolean>(false);
  const secondRow = useMemo(() => {
    if (isSwedish(identity)) {
      return formatIdNumber(identity.identityNumber, maskIdNumber);
    }
    if (showEmail) {
      return identity.email;
    }
  }, [identity, maskIdNumber, showEmail]);

  const confirmHandler = () => {
    setOpen(false);
    onChange?.();
  };

  return (
    <>
      <Box
        {...rest}
        sx={{ cursor: editable ? 'pointer' : undefined, blockSize: 'auto' }}
        onClick={() => editable && setOpen(true)}
      >
        <IdentityDisplay
          name={identity.name}
          renderName={renderName}
          secondRow={secondRow}
          layout={layout}
          startDecorator={
            showAvatar && (
              <Box>
                <Gravatar md5Hash={'emailMd5' in identity ? identity.emailMd5 : undefined} size="md" uuid={identity.id}>
                  {identity.name[0]}
                </Gravatar>
              </Box>
            )
          }
        />
      </Box>
      {open && identity?.id && (
        <IdentityEditDialog
          identityId={identity.id}
          onCancel={() => setOpen(false)}
          onConfirm={confirmHandler}
          updateRefetchQueries={updateRefetchQueries}
        />
      )}
    </>
  );
}
