import React from 'react';
import { Step, StepIndicator, Stepper, Tooltip, Typography } from '@mui/joy';
import { Pending as PendingIcon, Check as CheckIcon, Block as BlockIcon } from '@mui/icons-material';

export enum ProgressStatus {
  Done = 'done',
  Pending = 'pending',
  Error = 'error',
}
type IndicatorProps = {
  status: ProgressStatus;
};

const Indicator = ({ status }: IndicatorProps) => {
  const Icon = React.useMemo(() => {
    switch (status) {
      case ProgressStatus.Done:
        return CheckIcon;
      case ProgressStatus.Error:
        return BlockIcon;
      case ProgressStatus.Pending:
        return PendingIcon;
    }
  }, [status]);
  const color = status === ProgressStatus.Done ? 'success' : status === ProgressStatus.Error ? 'danger' : 'primary';
  return (
    <StepIndicator color={color}>
      <Icon />
    </StepIndicator>
  );
};

export const ProgressStep = ({
  status,
  label,
  tooltipText,
}: {
  tooltipText?: React.ReactNode;
  label: React.ReactNode;
} & IndicatorProps) => {
  return (
    <Tooltip title={tooltipText}>
      <Step indicator={<Indicator status={status} />}>
        <Typography fontWeight={400} fontSize="xs">
          {label}
        </Typography>
      </Step>
    </Tooltip>
  );
};

export const Progress = ({ children }: { children: React.ReactNode }) => {
  return <Stepper size="sm">{children}</Stepper>;
};
