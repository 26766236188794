<div qMessageContainer class="q-message-container">
  <ng-template>
    <div class="q-message-wrapper">
      <mat-icon class="q-message-icon">{{ icon }}</mat-icon>
      <div class="q-message-headers">
        <div class="font-normal">
          <ng-content select="[q-message-header]"></ng-content>
        </div>
        <div class="text-sm">
          <ng-content select="[q-message-subheader]"></ng-content>
        </div>
      </div>
      <div><ng-content select="[q-message-actions]"></ng-content></div>
    </div>
    <div *ngIf="showContent" class="py-4">
      <mat-divider [inset]="true"></mat-divider>
      <div class="pt-2">
        <ng-content select="[q-message-content]"></ng-content>
      </div>
    </div>
  </ng-template>
</div>
