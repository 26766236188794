'use client';
import { useState } from 'react';
import { GqlAud, usePasswordResetMutation } from '../graphql/react-operations';
import { ApolloError } from '@apollo/client';
import { FormContainer, PasswordElement, PasswordRepeatElement, TextFieldElement } from 'react-hook-form-mui';
import { Alert, Button, Box, Snackbar, Stack, Typography, IconButton, CloseRoundedIcon } from '@startuptools/ui';
import { Trans } from '@lingui/react';
import { AUTH_JWT_KEY } from '../constants';
import { Navigate } from '../types';
import { getRedirectUrl } from '../utils/redirect';

interface FormValues {
  token: string;
  password: string;
}

export const PasswordRecoverConfirm = ({ navigate, aud }: { navigate: Navigate; aud: GqlAud }) => {
  const [doReset, { loading }] = usePasswordResetMutation();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [showToast, setShowToast] = useState<boolean>(false);

  const showError = (errorMsg: string) => {
    setErrorMsg(errorMsg);
    setShowToast(true);
  };

  const handleSubmit = async (values: FormValues) => {
    const { token, password } = values;
    try {
      const res = await doReset({ variables: { input: { token, password, aud } } });
      localStorage.setItem(AUTH_JWT_KEY, res.data?.passwordReset.jwt || '');
      const newUrl = `${getRedirectUrl()}`;
      navigate(newUrl, { replace: true }); // Make sure we don't save the init/poll state in the browser stack
      window.location.href = newUrl; // Remove when Angular is gone, this is a workaround to make sure angular router takes over from here
    } catch (e) {
      if (e instanceof ApolloError) {
        showError(e.message);
      }
    }
  };

  return (
    <Box>
      <FormContainer onSuccess={handleSubmit}>
        <Stack direction="column" gap={1}>
          <Typography level="h4" paddingBottom={2}>
            <Trans id="Set your new password" />
          </Typography>
          <Alert variant="outlined" color="primary">
            <Trans id="A confirmation token was sent to your email, paste it below and set your new password" />
          </Alert>
          <TextFieldElement
            label={<Trans id="Token" />}
            name="token"
            type="text"
            autoComplete="off"
            fullWidth
            required
          />
          <PasswordElement label={<Trans id="New Password" />} name="password" fullWidth required />
          <PasswordRepeatElement
            name="repeatPassword"
            passwordFieldName="repeatPassword"
            label={<Trans id="Repeat password" />}
            fullWidth
            required
          />
          <Stack direction="column" alignItems="end">
            <Button loading={loading} variant="plain" type="submit">
              Confirm
            </Button>
          </Stack>
        </Stack>
      </FormContainer>
      <Snackbar
        open={showToast}
        variant="solid"
        color="danger"
        onClose={() => setShowToast(false)}
        endDecorator={
          <IconButton variant="plain" size="sm" color="neutral" onClick={() => setShowToast(false)}>
            <CloseRoundedIcon />
          </IconButton>
        }
      >
        {errorMsg}
      </Snackbar>
    </Box>
  );
};
