import { NgModule } from '@angular/core';

import { AuthJsonKindHumanPipe } from './auth-json-kinds-human.pipe';
import { BytesPipe } from './bytes.pipe';
import { DateTimeLocaleStrPipe } from '../pipes/date-time-locale-str.pipe';
import { MeetingKindHumanPipe } from './meeting-kind-human.pipe';
import { NameCapitalizePipe } from './name-capitalize.pipe';
import { SelectorPipe } from './selector.pipe';
import { IdNumberPipe } from './id-number.pipe';

const pipes = [
  AuthJsonKindHumanPipe,
  BytesPipe,
  DateTimeLocaleStrPipe,
  IdNumberPipe,
  MeetingKindHumanPipe,
  NameCapitalizePipe,
  SelectorPipe,
];

@NgModule({
  declarations: pipes,
  exports: pipes,
})
export class AppPipesModule {}
