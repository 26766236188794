<form [formGroup]="formGroup" (submit)="submit()">
  <div class="dialog" sutLoading #qLoading="Loading">
    <div class="dialog-close">
      <button mat-icon-button [mat-dialog-close]="null">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="dialog-title">
      <h4>{{ data.title }}</h4>
    </div>

    <div mat-dialog-content class="dialog-content">
      <div class="flex flex-col gap-4 pb-4">
        <div q-message *ngIf="data?.sublabel" color="primary">
          <div q-message-header>{{ data.sublabel }}</div>
        </div>
        <span *ngIf="data?.text">{{ data?.text }}</span>
      </div>

      <ng-container [ngSwitch]="data.inputKind">
        <ng-container *ngSwitchCase="PromptInputKind.Input">
          <mat-form-field *ngIf="data.primaryLang" class="w-full" appearance="fill">
            <mat-label *ngIf="data?.inputLabel">{{ data?.inputLabel }}</mat-label>
            <input
              matInput
              [formControl]="formControls.input"
              type="number"
              [type]="data.type"
              [integerOnly]="data.integerOnly"
              [placeholder]="data.placeholder"
              [autocomplete]="autoCompleteState"
            />
            <span *ngIf="data.suffix" matSuffix>{{ data.suffix }}</span>
            <mat-error *ngIf="formControls.input.invalid">{{ getErrorMsg() }}</mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="data.secondaryLang" class="w-full" appearance="fill">
            <mat-label *ngIf="data?.inputLabelSl">{{ data?.inputLabelSl }}</mat-label>
            <input
              matInput
              [formControl]="formControls.inputSl"
              type="number"
              [type]="data.type"
              [integerOnly]="data.integerOnly"
              [placeholder]="data.placeholderSl"
              [autocomplete]="autoCompleteState"
            />
            <span *ngIf="data.suffix" matSuffix>{{ data.suffix }}</span>
            <mat-error *ngIf="formControls.inputSl.invalid">{{ getErrorMsg() }}</mat-error>
          </mat-form-field>
        </ng-container>

        <ng-container *ngSwitchCase="PromptInputKind.InputNumerical">
          <mat-form-field *ngIf="data.primaryLang" class="w-full" appearance="fill">
            <mat-label *ngIf="data?.inputLabel">{{ data?.inputLabel }}</mat-label>
            <input
              matInput
              [formControl]="formControls.input"
              type="text"
              [type]="data.type"
              localizedNumericInput
              [numericIntegerOnly]="data.integerOnly"
              [placeholder]="data.placeholder"
              [autocomplete]="autoCompleteState"
            />
            <span *ngIf="data.suffix" matSuffix>{{ data.suffix }}</span>
            <mat-error *ngIf="formControls.input.invalid">{{ getErrorMsg() }}</mat-error>
          </mat-form-field>
        </ng-container>

        <ng-container *ngSwitchCase="PromptInputKind.TextArea">
          <mat-form-field class="w-full" appearance="fill">
            <mat-label *ngIf="data?.inputLabel">{{ data?.inputLabel }}</mat-label>
            <textarea
              matInput
              [formControl]="formControls.input"
              [type]="data.type"
              [placeholder]="data?.placeholder"
              rows="3"
            ></textarea>
            <span *ngIf="data.suffix" matSuffix>{{ data.suffix }}</span>
            <mat-error *ngIf="formControls.input.invalid">{{ getErrorMsg() }}</mat-error>
          </mat-form-field>
        </ng-container>

        <ng-container *ngSwitchCase="PromptInputKind.Date">
          <mat-form-field class="w-full" appearance="fill">
            <mat-label *ngIf="data?.inputLabel">{{ data?.inputLabel }}</mat-label>
            <input
              matInput
              type="text"
              [formControl]="formControls.input"
              [matDatepicker]="picker"
              [placeholder]="data.placeholder"
              [min]="data.dateRange?.min"
              [max]="data.dateRange?.max"
              required
            />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="PromptInputKind.RichText">
          <div>
            <quill-editor
              class="w-full h-40"
              rows="4"
              [formControl]="formControls.input"
              [placeholder]="data?.placeholder"
              i18n-placeholder
            ></quill-editor>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <mat-slide-toggle *ngIf="data.showToggle" [formControl]="formControls.toggle" color="primary" i18n
      >Teckningsoptionerna ska kopplas till kvalificerade personaloptioner</mat-slide-toggle
    >

    <div class="dialog-actions">
      <button type="button" class="btn-secondary" [mat-dialog-close]="null">{{ data.cancel }}</button>
      <button class="btn" type="submit">{{ data.ok }}</button>
    </div>
  </div>
</form>
