import { createFeatureSelector, createSelector } from '@ngrx/store';
import { map, filter, get, isNil } from 'lodash-es';
import { selectCompanyId } from './companies.base';
import { DocumentsState } from '../reducers/documents.reducers';
import { selectShareIssueDocumentsByShareIssueId, selectShareIssueDocuments } from './share-issue-documents.selectors';
import { selectMeetingDocumentsByMeeting, selectMeetingDocumentsByMeetingId } from './meeting-documents.selectors';
import { selectArticlesOfAssocDocumentsByAoaId } from './articles-of-assoc-documents.selectors';
import { selectWarrantProgramDocuments } from './warrant-program-documents.selectors';
import { selectAuthorizationGroupDocumentsByAgId } from './authorization-group-documents.selectors';
import { GqlDocGenState } from '../../graphql/base-types.graphql';

export const selectDocumentsState = createFeatureSelector<DocumentsState>('documents');

export const selectDocumentsPendingGen = createSelector(selectDocumentsState, selectCompanyId, (state, cid) => {
  if (!isNil(cid)) {
    return filter(
      state.entities,
      e =>
        !isNil(e) &&
        e.companyId === cid &&
        e.genState !== GqlDocGenState.Completed &&
        e.genState !== GqlDocGenState.Failed,
    );
  }
  return [];
});

export const selectDocumentsByAoaId = (aoaId: string) =>
  createSelector(selectDocumentsState, selectArticlesOfAssocDocumentsByAoaId(aoaId), (state, aoaDocs) => {
    return filter(
      // @ts-expect-error TS18048
      map(aoaDocs, aoaDoc => get(state.entities, aoaDoc.documentId)),
      d => !isNil(d),
    );
  });

export const selectDocumentsByAgId = (agId: string) =>
  createSelector(selectDocumentsState, selectAuthorizationGroupDocumentsByAgId(agId), (state, agDocs) => {
    return filter(
      // @ts-expect-error TS18048
      map(agDocs, agDoc => get(state.entities, agDoc.documentId)),
      d => !isNil(d),
    );
  });

export const selectDocumentsBySiId = (shareIssueId: string) =>
  createSelector(selectDocumentsState, selectShareIssueDocumentsByShareIssueId(shareIssueId), (state, siDocs) => {
    return filter(
      // @ts-expect-error TS18048
      map(siDocs, siDoc => get(state.entities, siDoc.documentId)),
      d => !isNil(d),
    );
  });
export const selectDocumentsBySi = createSelector(selectDocumentsState, selectShareIssueDocuments, (state, siDocs) => {
  return filter(
    // @ts-expect-error TS18048
    map(siDocs, siDoc => get(state.entities, siDoc.documentId)),
    d => !isNil(d),
  );
});

export const selectDocumentsByWp = createSelector(
  selectDocumentsState,
  selectWarrantProgramDocuments,
  (state, wpDocs) => {
    return filter(
      // @ts-expect-error TS18048
      map(wpDocs, wpDoc => get(state.entities, wpDoc.documentId)),
      d => !isNil(d),
    );
  },
);

export const selectDocumentsByMeetingId = (meetingId: string) =>
  createSelector(selectDocumentsState, selectMeetingDocumentsByMeetingId(meetingId), (state, mDocs) => {
    return filter(
      // @ts-expect-error TS18048
      map(mDocs, mDoc => get(state.entities, mDoc.documentId)),
      d => !isNil(d),
    );
  });
export const selectDocumentsByMeeting = createSelector(
  selectDocumentsState,
  selectMeetingDocumentsByMeeting,
  (state, mDocs) => {
    return filter(
      // @ts-expect-error TS18048
      map(mDocs, mDoc => get(state.entities, mDoc.documentId)),
      d => !isNil(d),
    );
  },
);

export const selectDocumentById = (id: string) =>
  createSelector(selectDocumentsState, state => {
    return get(state.entities, id);
  });
