import type * as Types from '../base-types.graphql';

import { gql } from '@apollo/client';
export type GqlCuFieldsFragment = { id: string, userId: string, companyId: string, role: Types.GqlCompanyUserRole, createdAt: string, updatedAt: string };

export const CuFieldsFragmentDoc = gql`
    fragment CuFields on CompanyUser {
  id
  userId
  companyId
  role
  createdAt
  updatedAt
}
    `;