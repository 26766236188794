import { createAction, props, union } from '@ngrx/store';

import { OptionsSharesSubscriber } from '../../models/options-shares-subscriber.model';

const prefix = '[OptionsSharesSubscribers]' as const;

export const optionsSharesSubscribersActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: OptionsSharesSubscriber[] }>()),
  manyDeleted: createAction(prefix + ' manyDeleted', props<{ payload: string[] }>()),
};

const all = union(optionsSharesSubscribersActions);

export type ActionsUnion = typeof all;
