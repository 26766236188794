import { GqlCreateIdentityInput, useRatsitSearchSuspenseQuery } from '../../graphql/react-operations';
import { Table } from '@startuptools/ui';
import { Trans } from '@lingui/react';
import { orderBy } from 'lodash-es';
import Fuse from 'fuse.js';
import { identityToInput } from '../../helpers/identity-to-input';
import { Identity } from '../identity/Identity';
import { GqlSearchIdentitiesInput } from '../../graphql/base-types.graphql';
import React from 'react';

type Props = {
  handleCreate: (Identity: GqlCreateIdentityInput) => Promise<unknown>;
  queryParams: GqlSearchIdentitiesInput;
};
const InputSearchInner = ({ queryParams: { searchString, juridicalKinds }, handleCreate }: Props) => {
  const {
    data: { ratsitSearch },
  } = useRatsitSearchSuspenseQuery({ variables: { searchString, juridicalKinds } });

  const ratsitIdentities = React.useMemo(() => {
    const fuse = new Fuse(ratsitSearch, {
      includeScore: true,
      threshold: 0.8,
      distance: 80,
      keys: [{ name: 'name', weight: 5 }, 'identityNumber', { name: 'email', weight: 1 }],
    });
    return orderBy(fuse.search(searchString), e => e.score).map(e => e.item);
  }, [ratsitSearch, searchString]);

  return (
    <Table hoverRow>
      <tbody>
        {ratsitIdentities.length > 0 ? (
          ratsitIdentities.map(i => {
            return (
              <tr key={i.identityNumber} onClick={() => handleCreate(identityToInput(i))} style={{ cursor: 'pointer' }}>
                <td>
                  <Identity identity={i} showAvatar editable={false} maskIdNumber layout="row" />
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td>
              <Trans id="The search gave no results, adjust your search input and try again" />
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export const InputSearch = (props: Props) => {
  return props.queryParams.searchString.length ? <InputSearchInner {...props} /> : null;
};
