import { Stack } from '@startuptools/ui';
import { CompanySelect } from '../side-nav/CompanySelect';
import { CompanySubscription } from './CompanySubscription';
import { StatusNotification } from '../status/StatusNotification';
import { Suspense } from 'react';

export const Toolbar = () => {
  return (
    <Stack
      height={64}
      maxHeight={64}
      paddingX={2}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={5}
      sx={theme => ({
        backgroundColor: 'white',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: theme.palette.neutral[200],
      })}
    >
      <CompanySelect />
      <Stack direction="row" gap={1}>
        <Suspense>
          <CompanySubscription />
        </Suspense>
        <StatusNotification />
      </Stack>
    </Stack>
  );
};
