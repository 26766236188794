import { Theme } from '@mui/joy';
import useMediaQuery from '@mui/material/useMediaQuery';
import { PieSeriesOption } from 'echarts';
import ReactECharts from 'echarts-for-react';

export const PieChart = ({
  data,
  legend = true,
}: {
  legend?: boolean;
  data: (PieSeriesOption & { value: number })[];
}) => {
  const smallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  return (
    <ReactECharts
      style={{
        display: 'flex',
        width: '100%',
      }}
      option={{
        color: ['#6464E3', '#FFCE94', '#D64045', '#3DDC97', '#FBCAEF'],
        legend: legend
          ? {
              orient: 'vertical',
              left: smallScreen ? 0 : '10%',
              top: smallScreen ? 0 : '30%',
              fontSize: 16,
            }
          : undefined,

        series: [
          {
            type: 'pie',
            radius: '60%',
            itemStyle: {
              borderRadius: 2,
              borderColor: '#fff',
              borderWidth: 2,
            },
            label: {
              show: true,
              formatter: function (params: { name: string; percent: number }) {
                return `${params.name}: ${params.percent}%`;
              },
            },

            data: data.filter(d => d.value > 0),
          },
        ],
      }}
    />
  );
};
