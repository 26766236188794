import { useState } from 'react';
import {
  GqlCreateIdentityInput,
  GqlCreateIdentityMutation,
  GqlIdentity,
  useCreateIdentityMutation,
} from '../../graphql/react-operations';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useOnce } from '@startuptools/common/react-hooks';
import { identityToInput } from '../../helpers/identity-to-input';
import { DetailFieldsSettings, DetailState } from '../add-identity-dialog/types';
import {
  Alert,
  Button,
  Modal,
  ModalDialog,
  Typography,
  Stack,
  Input,
  Select,
  Option,
  FormControl,
  FormLabel,
} from '@startuptools/ui';
import { isNil } from 'lodash-es';
import { countryNames } from '@startuptools/common/common';
import { Identity } from './Identity';
import { isSwedish } from '../../models/identity.model';
import { useCompanyId } from '@startuptools/common/react-wrapper';
import { Trans, useLingui } from '@lingui/react';

type FormValues = Pick<GqlCreateIdentityInput, 'email' | 'address' | 'phone' | 'countryCode'>;

type I = Omit<GqlIdentity, 'capTableStakes' | 'meetingParticipants' | 'shareIssueParticipants'>;

interface Props {
  onClose: () => void;
  identity: I | GqlCreateIdentityInput;
  fieldSettings: DetailFieldsSettings;
  handleUpdate: (identity: GqlCreateIdentityMutation['createIdentity']) => Promise<unknown>;
}

export const UpdateIdentityDetails = ({ identity, onClose, fieldSettings, ...props }: Props) => {
  const companyId = useCompanyId();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<FormValues>();
  const [showError, setShowError] = useState(false);
  const { i18n } = useLingui();

  const [doEstablish] = useCreateIdentityMutation();

  useOnce(() => {
    setValue('email', identity.email || '');
    setValue('phone', identity.phone || '');
    setValue('address', identity.address?.trim().replaceAll('\n', ', ').trim() || '');
    setValue('countryCode', identity.countryCode || 'SE');
  });

  const handleUpdate = async (identity: GqlCreateIdentityMutation['createIdentity']) => {
    try {
      await props.handleUpdate(identity);
    } catch (e) {
      setShowError(true);
    }
  };

  const onSubmit: SubmitHandler<FormValues> = async input => {
    const identityInput = identityToInput({
      ...identity,
      ...input,
      address: input.address?.replaceAll(', ', '\n').trim(),
    });
    try {
      const { data } = await doEstablish({ variables: { companyId, input: identityInput } });
      if (data) {
        await handleUpdate(data.createIdentity);
      }
    } catch (e) {
      console.error(e);
      setShowError(true);
      return;
    }
  };

  if (isNil(identity)) {
    return null;
  }

  return (
    <Modal open>
      <ModalDialog>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={3} margin={3} minWidth={400}>
            <Typography level="h4">
              <Trans id="Update the required details" />
            </Typography>
            <Stack direction="column" gap={3}>
              <Identity identity={identity} editable={false} showAvatar />
              {fieldSettings.email !== DetailState.Hidden && (
                <FormControl disabled={isSubmitting} required={fieldSettings.email === DetailState.Required}>
                  <FormLabel>
                    <Trans id="Email" />
                  </FormLabel>
                  <Input {...register('email')} type="email" />
                </FormControl>
              )}
              {fieldSettings.phone !== DetailState.Hidden && (
                <FormControl disabled={isSubmitting} required={fieldSettings.phone === DetailState.Required}>
                  <FormLabel>
                    <Trans id="Phone" />
                  </FormLabel>
                  <Input {...register('phone')} type="text" />
                </FormControl>
              )}
              {fieldSettings.address !== DetailState.Hidden && !isSwedish(identity) && (
                <FormControl disabled={isSubmitting} required={fieldSettings.address === DetailState.Required}>
                  <FormLabel>
                    <Trans id="Address" />
                  </FormLabel>
                  <Input {...register('address')} type="text" />
                </FormControl>
              )}
              {fieldSettings.countryCode !== DetailState.Hidden && !isSwedish(identity) && (
                <FormControl required>
                  <FormLabel>
                    <Trans id="Country" />
                  </FormLabel>
                  <Select
                    name="countryCode"
                    placeholder="Country"
                    defaultValue={identity.countryCode}
                    onChange={(_event, value) => setValue('countryCode', value as unknown as string)}
                    required
                  >
                    {countryNames[i18n.locale as 'sv' | 'en'].map(country => (
                      <Option key={country.code} value={country.code}>
                        {country.name}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Stack>
            {showError && (
              <Alert color="danger">
                <Trans id="An unexpected error happened, please try again" />
              </Alert>
            )}
            <Stack direction="row" gap={1} justifyContent="end">
              <Button type="button" disabled={isSubmitting} onClick={onClose} variant="plain" color="neutral">
                <Trans id="Cancel" />
              </Button>
              <Button type="submit" loading={isSubmitting}>
                <Trans id="Save" />
              </Button>
            </Stack>
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
};
