import { createAction, props, union } from '@ngrx/store';
import { OptionsSharesSubscriberRepresentative } from '../../models/options-shares-subscriber-representatives.model';

const prefix = '[OptionsSharesSubscriberRepresentative]' as const;

export const optionsSharesSubscriberRepresentativeActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: OptionsSharesSubscriberRepresentative[] }>()),
  manyDeleted: createAction(prefix + ' manyDeleted', props<{ payload: string[] }>()),
};

export const all = union(optionsSharesSubscriberRepresentativeActions);

export type ActionsUnion = typeof all;
