import { createReducer, on, Action } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { map } from 'lodash-es';

import { Identity } from '../../models/identity.model';
import { identitiesActions } from '../actions/identities.actions';

export type IdentitiesState = EntityState<Identity>;

export const adapter = createEntityAdapter<Identity>();
export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(identitiesActions.manyLoaded, (state, { payload }) =>
    adapter.addMany(
      payload,
      adapter.removeMany(
        map(payload, p => p.id),
        state,
      ),
    ),
  ),
);

export function identitiesReducer(state: EntityState<Identity> | undefined, action: Action) {
  return reducer(state, action);
}
