import { BigNumber } from 'bignumber.js';

/***
 ** Re-calculates claim to make it evenly divisible by the number of shares|options
 ** Also makes sure it stays within the shares limit provided.
 */
export function claimRecalc(
  claim = new BigNumber(0),
  pricePerUnit: BigNumber,
  limit: number,
): { units: BigNumber; newClaim: BigNumber } {
  let units = new BigNumber(0);
  let newClaim = new BigNumber(0);
  for (let i = 0; i < limit; i++) {
    const totPrice = newClaim.plus(pricePerUnit);
    if (totPrice.lte(claim)) {
      newClaim = totPrice;
      units = units.plus(1);
    } else {
      break;
    }
  }
  return {
    units,
    newClaim,
  };
}
