import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IdentitiesState } from '../reducers/identities.reducers';
import { get, filter } from 'lodash-es';
import { selectCompanyId } from './companies.base';

export const selectIdentitiesState = createFeatureSelector<IdentitiesState>('identities');
export const selectIdentityById = (id: string) =>
  createSelector(selectIdentitiesState, state => {
    return get(state.entities, id);
  });
export const selectIdentities = createSelector(selectIdentitiesState, selectCompanyId, (state, companyId) => {
  // @ts-expect-error TS18048
  return filter(state.entities, e => e.companyId === companyId);
});
