import { createAction, props, union } from '@ngrx/store';

import { ShareIssue } from '../../models/share-issue.model';

const prefix = '[ShareIssues]' as const;

export const shareIssueActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: ShareIssue[] }>()),
  manyDeleted: createAction(prefix + ' manyDeleted', props<{ payload: string[] }>()),
};

export const all = union(shareIssueActions);

export type ActionsUnion = typeof all;
