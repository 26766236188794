import { createAction, props, union } from '@ngrx/store';

import { WarrantProgram } from '../../models/warrant-program.model';

const prefix = '[WarrantPrograms]' as const;

export const warrantProgramsActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: WarrantProgram[] }>()),
  manyDeleted: createAction(prefix + ' manyDeleted', props<{ payload: string[] }>()),
};

const all = union(warrantProgramsActions);

export type ActionsUnion = typeof all;
