import { createReducer, on, Action } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { map } from 'lodash-es';

import { SnrCase } from '../../models/snr-case.model';
import { snrCasesActions } from '../../store/actions/snr-cases.actions';

export type SnrCasesState = EntityState<SnrCase>;

export const adapter = createEntityAdapter<SnrCase>();
export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(snrCasesActions.manyLoaded, (state, { payload }) => {
    return adapter.addMany(
      payload,
      adapter.removeMany(
        map(payload, p => p.id),
        state,
      ),
    );
  }),
);

export function snrCasesReducer(state: EntityState<SnrCase> | undefined, action: Action) {
  return reducer(state, action);
}
