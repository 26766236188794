<form [formGroup]="formGroup" (submit)="submit()">
  <div class="dialog">
    <div class="dialog-title">
      <h5 i18n>Välj aktieslag</h5>
    </div>
    <div mat-dialog-content class="dialog-content">
      <div class="flex flex-col gap-4">
        <div>
          <mat-form-field class="w-full" appearance="fill">
            <mat-label i18n>Aktieslag</mat-label>
            <mat-select
              [(ngModel)]="selectedShareClass"
              [ngModelOptions]="{ standalone: true }"
              (selectionChange)="changed()"
            >
              <mat-option [value]="{}" i18n>Skapa nytt aktieslag</mat-option>
              <mat-option *ngFor="let sc of shareClasses$ | async" [value]="sc">
                {{ sc.name }} (<ng-container i18n>röstvikt</ng-container>: {{ sc.voteWeight }})
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <ng-container *ngIf="keys(selectedShareClass).length === 0">
          <div>
            <mat-form-field class="w-full" appearance="fill">
              <mat-label i18n>Aktieslagets namn</mat-label>
              <input matInput type="text" [formControl]="formControls.shareClassName" required />
              <mat-hint i18n>Ex. PREF-A</mat-hint>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="w-full" appearance="fill">
              <mat-label i18n>Aktieslagets röstvärde</mat-label>
              <input matInput type="number" [formControl]="formControls.shareClassVoteWeight" required />
              <mat-hint i18n>antal röster per aktie</mat-hint>
            </mat-form-field>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="dialog-actions">
      <button type="button" class="btn-secondary" mat-dialog-close i18n>Avbryt</button>
      <button
        type="submit"
        class="btn"
        [disabled]="
          keys(selectedShareClass).length === 0 &&
          (formControls.shareClassName.invalid || formControls.shareClassVoteWeight.invalid)
        "
      >
        Spara
      </button>
    </div>
  </div>
</form>
