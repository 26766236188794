<div #container class="container">
  <div class="flex flex-row justify-start items-center gap-2" style="min-height: 19px">
    <div class="flex flex-col" *ngIf="value">
      <app-react-wrapper
        *ngIf="!isNil(value?.gravatarHash); else businessIcon"
        [component]="Gravatar"
        [props]="{ md5Hash: value.gravatarHash, uuid: value.uuid, size: 'sm', children: value.firstLetter }"
        [companyId]="value.companyId"
      />
      <ng-template #businessIcon>
        <mat-icon class="avatar-icon">domain</mat-icon>
      </ng-template>
    </div>
    <div class="flex flex-col app-object-value">
      <div>{{ value?.name }}</div>
      <div class="text-sm">{{ value?.sub }}</div>
    </div>
  </div>
</div>
