import { msgMap } from './messages';
import { Button, Stack, Typography, PriorityHighIcon } from '@startuptools/ui';
import { Trans } from '@lingui/react';
import { GqlHintCode } from '../graphql/react-operations';

interface Props {
  code?: GqlHintCode | null;
  reInit: () => void;
}

export const BankidError = ({ code, reInit }: Props) => {
  let msg = 'Oväntat fel';
  if (code) {
    msg = msgMap[code]?.sv || msg;
  }
  return (
    <Stack direction="column" gap={2} justifyContent="space-between" alignItems="center">
      <Stack direction="column" gap={2} alignItems="center">
        <PriorityHighIcon fontSize="large" color="warning" />
        <Typography textAlign="center">{msg}</Typography>
      </Stack>
      <Button onClick={reInit} fullWidth>
        <Trans id="Try again" />
      </Button>
    </Stack>
  );
};
