import { createAction, props, union } from '@ngrx/store';

import { WarrantShareholder } from '../../models/warrant-shareholder.model';

const prefix = '[WarrantShareholders]' as const;

export const warrantShareholdersActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: WarrantShareholder[] }>()),
  manyDeleted: createAction(prefix + ' manyDeleted', props<{ payload: string[] }>()),
};

const all = union(warrantShareholdersActions);

export type ActionsUnion = typeof all;
