<div class="dialog">
  <!-- <div class="dialog-close">
    <button mat-icon-button [mat-dialog-close]="null"><mat-icon>close</mat-icon></button>
  </div> -->

  <div mat-dialog-content class="dialog-content">
    <div q-message color="warn" icon="warning">
      <div q-message-header>
        <h5>{{ data.title }}</h5>
      </div>
      <div q-message-subheader>
        <ng-container *ngIf="!data?.link">
          <p>{{ modifyText(data.text).start }}</p>
        </ng-container>
        <ng-container *ngIf="data?.link">
          <p>
            {{ modifyText(data.text).start }}<a [href]="data.link!.url" target="_blank">{{ data.link!.text }}</a
            >{{ modifyText(data.text).end }}
          </p>
        </ng-container>
        <ng-container *ngIf="data?.question">
          <h6>{{ data.question }}</h6>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="dialog-actions">
    <mat-checkbox color="primary" #checkBox i18n>Ja, jag är säker</mat-checkbox>
    <button type="button" class="btn-warn" (click)="submit(true)" [disabled]="!checkBox.checked">
      {{ data.confirm }}
    </button>
    <button type="button" class="btn" [mat-dialog-close]="false">
      {{ data.cancel }}
    </button>
  </div>
</div>
