import { GqlAuthorization } from '../graphql/operations';
import { AuthKind } from './authorization-group.model';

export enum AllocationKind {
  OnlyWithPreferentialRights,
  OnlyWithoutPreferentialRights,
  WithOrWithoutPreferentialRights,
}

export class Authorization implements Omit<GqlAuthorization, 'authorizationGroup'> {
  id: string;
  authorizationGroupId: string;
  allocation: AllocationKind;
  kind: AuthKind;
  singleUse?: boolean;
  cashPayment?: boolean;
  offsetPayment?: boolean;
  inKindPayment?: boolean;
  maxRaiseAmount?: string;
  maxRaise?: string;
  maxRaiseByAoa?: boolean;

  readonly createdAt: string;
  readonly updatedAt: string;

  constructor(seed?: Partial<Authorization>) {
    Object.assign(this, seed);
  }
}
