<div class="dialog">
  <!-- <div class="dialog-close">
    <button mat-icon-button [mat-dialog-close]="null"><mat-icon>close</mat-icon></button>
  </div> -->

  <div class="dialog-title">
    <h4>{{ data.title }}</h4>
  </div>

  <div mat-dialog-content class="dialog-content">
    {{ data.text }}
  </div>

  <div class="dialog-actions">
    <button type="button" class="btn" mat-dialog-close>{{ data.ok }}</button>
  </div>
</div>
