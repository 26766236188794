import { Trans } from '@lingui/react';
import { Box, Button, Stack, Typography } from '@startuptools/ui';
import { useState } from 'react';

export const CreateCompanyStep3 = ({ name, onContinue }: { name: string; onContinue: () => Promise<unknown> }) => {
  const [loading, setLoading] = useState(false);

  const handleContinue = async () => {
    setLoading(true);
    await onContinue();
  };

  return (
    <Stack gap={3}>
      <Typography level="h3">
        <Trans
          id="Congratulations! <b>{name}</b> is now setup and ready!"
          values={{ name }}
          components={{ b: <Typography fontWeight={550} /> }}
        />
      </Typography>
      <Box>
        <Button onClick={handleContinue} loading={loading}>
          <Trans id="Continue to the company" />
        </Button>
      </Box>
    </Stack>
  );
};
