<div class="dialog">
  <div class="dialog-close">
    <button mat-icon-button [mat-dialog-close]="null">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="dialog-title">
    <h4>{{ data.title }}</h4>
  </div>

  <div mat-dialog-content class="dialog-content">
    {{ data.text }}

    <table mat-table [dataSource]="dataSource" class="w-full">
      <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
        <th scope="auto" mat-header-cell *matHeaderCellDef>
          <div
            class="flex flex-row justify-start items-center"
            [class.justify-end]="columnProperties[column]?.alignRight"
            [ngStyle]="{
              width: columnProperties[column]?.width ? columnProperties[column].width : 'none',
            }"
          >
            {{ columnProperties[column]?.label }}
          </div>
        </th>
        <td mat-cell *matCellDef="let row">
          <div
            class="flex flex-row justify-start items-center"
            [class.justify-end]="columnProperties[column]?.alignRight"
            [ngStyle]="{
              width: columnProperties[column]?.width ? columnProperties[column].width : 'none',
            }"
          >
            {{ row[column] }}
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{ clickable: clickable }"
        (click)="clickable ? onClick(row) : null"
      ></tr>
    </table>
  </div>
</div>
