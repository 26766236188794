import { createAction, props, union } from '@ngrx/store';

import { OptionsRegistration } from '../../models/options-registration.model';

const prefix = '[OptionsRegistration]' as const;

export const optionsRegistrationActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: OptionsRegistration[] }>()),
  manyDeleted: createAction(prefix + ' manyDeleted', props<{ payload: string[] }>()),
};

const all = union(optionsRegistrationActions);

export type ActionsUnion = typeof all;
