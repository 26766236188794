import { Pipe, PipeTransform } from '@angular/core';
import { map, some } from 'lodash-es';

export function nameCapitalize(input: string) {
  const tokens = input.split(' ');
  const capTokens = map(tokens, (token: string) => {
    const lcToken = token.toLowerCase();
    if (some(['af', 'de', 'von', 'la'], w => w === lcToken)) {
      return lcToken;
    } else {
      return lcToken.charAt(0).toUpperCase() + lcToken.substring(1);
    }
  });
  return capTokens.join(' ');
}

@Pipe({ name: 'nameCapitalize' })
export class NameCapitalizePipe implements PipeTransform {
  transform(input: string) {
    return nameCapitalize(input);
  }
}
