import { createReducer, on, Action } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { map } from 'lodash-es';

import { SnrBond } from '../../models/snr-bond.model';
import { snrBondsActions } from '../../store/actions/snr-bonds.actions';

export type SnrBondsState = EntityState<SnrBond>;

export const adapter = createEntityAdapter<SnrBond>();
export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(snrBondsActions.manyLoaded, (state, { payload }) =>
    adapter.addMany(
      payload,
      adapter.removeMany(
        map(payload, p => p.id),
        state,
      ),
    ),
  ),
);

export function snrBondsReducer(state: EntityState<SnrBond> | undefined, action: Action) {
  return reducer(state, action);
}
