import { Trans } from '@lingui/react';
import { Typography, Stack, LinearProgress, NumberFormat } from '../';
import { DateTime } from 'luxon';

const calculatePercentageOfTimePassed = (start: DateTime, end: DateTime): number => {
  const now = DateTime.local();

  const totalDuration = end.diff(start).as('seconds');
  const timePassed = now.diff(start).as('seconds');

  return Math.min(Math.round((timePassed / totalDuration) * 100), 100);
};

export const VestingProgress = ({ start, end, locale }: { start: DateTime; end: DateTime; locale: string }) => {
  const percentage = calculatePercentageOfTimePassed(start, end);
  return (
    <Stack direction="column" gap={1}>
      <Typography fontWeight={600}>
        <Trans id="Vesting" />
      </Typography>
      <Stack direction="row" gap={2}>
        <LinearProgress determinate value={percentage} />
        <Typography level="title-sm">
          <NumberFormat variant="percentage" locale={locale}>
            {percentage / 100}
          </NumberFormat>
        </Typography>
      </Stack>
    </Stack>
  );
};
