import { GqlScriveAuthMethod } from '../graphql/base-types.graphql';
import { GqlDocumentSignature } from '../graphql/operations';

export class DocumentSignature implements Omit<GqlDocumentSignature, 'signatory' | 'scriveParty' | 'document'> {
  id: string;

  signed: boolean;
  authMethodToView?: GqlScriveAuthMethod | null;
  authMethodToSign?: GqlScriveAuthMethod | null;
  deliveryMethod?: string;
  email?: string | null | undefined;

  documentId: string;
  signatoryId: string;
  proxyId: string;

  readonly createdAt: string;
  readonly updatedAt: string;

  constructor(seed?: Partial<DocumentSignature>) {
    Object.assign(this, seed);
  }
}
