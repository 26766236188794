import { Trans, useLingui } from '@lingui/react';
import { useDebounce } from '@startuptools/common/react-hooks';
import {
  Button,
  Card,
  CircularProgress,
  CloseIcon,
  DateFormat,
  ErrorBoundary,
  ExpandLessIcon,
  ExpandMoreIcon,
  Gravatar,
  IconButton,
  Input,
  SearchIcon,
  Sheet,
  Stack,
  Table,
} from '@startuptools/ui';
import { useForm } from 'react-hook-form';
import {
  AdminCompaniesDocument,
  GqlAdminCompaniesQuery,
  useAdminCompaniesSuspenseQuery,
  useCompanySyncToHubspotMutation,
} from '../../graphql/react-operations';
import { ArrayElement } from '@startuptools/common/common';
import { DateTime } from 'luxon';
import { Suspense, useState } from 'react';
import { IdentityDisplay } from '../../components/identity/IdentityDisplay';
import { useNgRouter } from '../../components/react-wrapper/AngularRouterContext';

const CompaniesTableRow = ({
  company,
  onClose,
}: {
  company: ArrayElement<GqlAdminCompaniesQuery['adminCompanies']>;
  onClose: () => void;
}) => {
  const { i18n } = useLingui();
  const { changeCompany } = useNgRouter();
  const [open, setOpen] = useState(false);
  const [doSyncToHubspot, { loading }] = useCompanySyncToHubspotMutation({
    variables: { companyId: company.id },
    refetchQueries: [AdminCompaniesDocument],
  });

  const handleNavigate = () => {
    void changeCompany(company.id);
    onClose();
  };

  return (
    <>
      <tr>
        <td>
          <IconButton variant="outlined" onClick={() => setOpen(!open)} disabled={company.companyUsers.length === 0}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </td>
        <td>
          <IdentityDisplay name={company.name} secondRow={company.organizationNumber} />
        </td>
        <td align="right">{company.companyUsers.length}</td>
        <td>
          <DateFormat locale={i18n.locale} format={DateTime.DATETIME_SHORT}>
            {company.createdAt}
          </DateFormat>
        </td>
        <td align="right">
          {company.hubspotObjectId ? (
            company.hubspotObjectId
          ) : (
            <Button
              variant="soft"
              color="neutral"
              size="sm"
              onClick={() => doSyncToHubspot()}
              loading={loading}
              disabled={company.organizationNumber.startsWith('0')}
            >
              <Trans id="Sync to Hubspot" />
            </Button>
          )}
        </td>
        <td align="right">
          <Button variant="outlined" color="neutral" size="sm" onClick={handleNavigate}>
            <Trans id="Visit" />
          </Button>
        </td>
      </tr>
      {open && (
        <tr>
          <td colSpan={6}>
            <Sheet variant="soft">
              <Table sx={{ tableLayout: 'auto' }}>
                <thead>
                  <tr>
                    <th>
                      <Trans id="User" />
                    </th>
                    <th>
                      <Trans id="Email" />
                    </th>
                    <th>
                      <Trans id="Phone" />
                    </th>
                    <th>
                      <Trans id="Created At" />
                    </th>
                    <th>HubSpot ID</th>
                  </tr>
                </thead>
                <tbody>
                  {company.companyUsers.map(({ user }) => (
                    <tr key={user.id}>
                      <td>
                        <IdentityDisplay
                          name={user.name}
                          secondRow={user.personalId}
                          startDecorator={
                            <Gravatar md5Hash={user.emailMd5} uuid={user.id} size="sm">
                              {user.name?.[0]}
                            </Gravatar>
                          }
                        />
                      </td>
                      <td>{user.email}</td>
                      <td>{user.phone}</td>
                      <td>
                        <DateFormat locale={i18n.locale} format={DateTime.DATETIME_SHORT}>
                          {user.createdAt}
                        </DateFormat>
                      </td>
                      <td align="right">{user.hubspotObjectId}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Sheet>
          </td>
        </tr>
      )}
    </>
  );
};

const CompaniesTable = ({ searchString, onClose }: { searchString: string; onClose: () => void }) => {
  const {
    data: { adminCompanies: companies },
  } = useAdminCompaniesSuspenseQuery({
    variables: { input: { filter: searchString, pageOffset: 0, pageLimit: 30 } },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Card sx={{ width: '100%' }}>
      <Table sx={{ tableLayout: 'auto', width: '100%' }}>
        <thead>
          <tr>
            <th />
            <th>
              <Trans id="Company" />
            </th>
            <th align="right">
              <Trans id="Users" />
            </th>
            <th>
              <Trans id="Created At" />
            </th>
            <th align="right">HubSpot ID</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {companies.map(company => (
            <CompaniesTableRow key={company.id} company={company} onClose={onClose} />
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export const Companies = ({ onClose }: { onClose: () => void }) => {
  const { i18n } = useLingui();
  const { register, watch, setValue } = useForm<{ searchString: string }>({ defaultValues: { searchString: '' } });
  const searchString = watch('searchString');
  const debouncedSearchString = useDebounce(searchString);

  return (
    <Stack gap={2} alignItems="center">
      <Input
        fullWidth
        {...register('searchString')}
        startDecorator={searchString === debouncedSearchString ? <SearchIcon /> : <CircularProgress size="sm" />}
        endDecorator={
          searchString?.length ? (
            <IconButton onClick={() => setValue('searchString', '')}>
              <CloseIcon />
            </IconButton>
          ) : undefined
        }
        type="search"
        placeholder={i18n._('Search...')}
      />
      <ErrorBoundary>
        <Suspense>
          <CompaniesTable searchString={debouncedSearchString} onClose={onClose} />
        </Suspense>
      </ErrorBoundary>
    </Stack>
  );
};
