export enum VestingKind {
  None,
  Employment,
  BoardMembership,
  AdvisoryBoard,
  Custom,
  // Others,
}

export enum VestingFrequency {
  Monthly,
  Quarterly,
  Biyearly,
  Yearly,
}

export enum ShareholdersAgreementKind {
  UseExistingAgreement,
  CreateAgreement,
  NoAgreement,
}

export const vestingFrequencyMonths: { [k in VestingFrequency]: number } = {
  [VestingFrequency.Monthly]: 1,
  [VestingFrequency.Quarterly]: 3,
  [VestingFrequency.Biyearly]: 6,
  [VestingFrequency.Yearly]: 12,
};
