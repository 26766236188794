export const AuthorizedSignatory = ({ input }: { input: string }) => {
  const lines = input
    .replace(/\s*$[\s/]*/m, ' ')
    .split('/')
    .filter(Boolean)
    .map(x => x.trim());

  return (
    <ul>
      {lines.map((line, index) => {
        if (line.startsWith('>')) {
          const sublines = line
            .split('>')
            .filter(Boolean)
            .map(x => x.trim());
          return (
            <ul key={index}>
              {sublines.map((sub, index) => (
                <li key={index}>{sub}</li>
              ))}
            </ul>
          );
        }
        return <li key={index}>{line}</li>;
      })}
    </ul>
  );
};
