import { createAction, props, union } from '@ngrx/store';

import { User } from '../../models/user.model';

const prefix = '[Users]' as const;

export const usersActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: User[] }>()),
  setCurrentId: createAction(prefix + ' setCurrentId', props<{ payload: string }>()),
};

const all = union(usersActions);

export type ActionsUnion = typeof all;
