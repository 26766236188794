import { Box, Tooltip } from '@mui/joy';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import React from 'react';

export const InfoTooltip = ({ children }: { children: React.ReactNode }) => {
  return (
    <Tooltip
      arrow
      variant="outlined"
      title={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 320,
            justifyContent: 'center',
            p: 1,
          }}
        >
          <Box sx={{ display: 'flex', gap: 1, width: '100%', mt: 1 }}>
            <HelpOutlineIcon color="success" />
            <Box ml={1}>{children}</Box>
          </Box>
        </Box>
      }
    >
      <HelpOutlineIcon fontSize="small" />
    </Tooltip>
  );
};
