import { createReducer, on, Action } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { map } from 'lodash-es';

import { ShareIssueRegistration } from '../../models/share-issue-registration.model';
import { shareIssueRegistrationActions } from '../actions/share-issue-registrations.actions';

export type ShareIssueRegistrationsState = EntityState<ShareIssueRegistration>;

export const adapter = createEntityAdapter<ShareIssueRegistration>();
export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(shareIssueRegistrationActions.manyLoaded, (state, { payload }) => {
    return adapter.addMany(
      payload,
      adapter.removeMany(
        map(payload, p => p.id),
        state,
      ),
    );
  }),
  on(shareIssueRegistrationActions.manyDeleted, (state, { payload }) => adapter.removeMany(payload, state)),
);

export function shareIssueRegistrationsReducer(state: EntityState<ShareIssueRegistration> | undefined, action: Action) {
  return reducer(state, action);
}
