import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[decimalOnly]', // eslint-disable-line @angular-eslint/directive-selector
})
export class DecimalOnlyDirective {
  @Input() decimalOnly: boolean;

  constructor(private ngControl: NgControl) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    if (this.decimalOnly) {
      // @ts-expect-error TS2531
      this.ngControl.control.patchValue(value.replace(/[^0-9.,]/g, ''));
    }
  }
}
