import { createAction, props, union } from '@ngrx/store';

import { ShareIssueParticipant } from '../../models/share-issue-participant.model';

const prefix = '[ShareIssueParticipants]' as const;

export const shareIssueParticipantsActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: ShareIssueParticipant[] }>()),
  manyDeleted: createAction(prefix + ' manyDeleted', props<{ payload: string[] }>()),
};

export const all = union(shareIssueParticipantsActions);

export type ActionsUnion = typeof all;
