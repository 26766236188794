import { Box } from '@mui/joy';
import React from 'react';

export const BlurOverlay = ({ show, children }: { show: boolean; children: React.ReactNode }) => {
  return (
    <Box position="relative">
      {show && (
        <Box position="absolute" height="100%" left={0} right={0} zIndex={1} sx={{ backdropFilter: 'blur(2px)' }} />
      )}
      {children}
    </Box>
  );
};
