import { Typography, Box, DateFormat, Divider } from '@startuptools/ui';
import { AuthorizedSignatory } from './AuthorizedSignatory';
import { Trans, useLingui } from '@lingui/react';
import { GqlCompany } from '../graphql/base-types.graphql';

type RequiredCompanyKeys = Pick<
  GqlCompany,
  'addressAsStr' | 'name' | 'foundationDate' | 'email' | 'authorizedSignatory'
>;
type OptionalCompanyKeys = Pick<Partial<GqlCompany>, 'fiscalYear' | 'shareholderMeetingHowtoCall'>;

type Company = RequiredCompanyKeys & OptionalCompanyKeys;
export const CompanyCard = ({ company }: { company: Company }) => {
  const { i18n } = useLingui();

  return (
    <>
      <Typography fontWeight={500}>{company.name}</Typography>
      <Box>
        <Box>
          <Trans id="Founded" />{' '}
          <DateFormat readable locale={i18n.locale}>
            {company.foundationDate}
          </DateFormat>
        </Box>
        <Box>
          <Trans id="Email" />: {company.email ?? <Trans id="Not found" />}
        </Box>
        <Box>
          <Trans id="Address" />: {company.addressAsStr}
        </Box>
        {company.fiscalYear && (
          <Box>
            <Trans id="Fiscal year" />:{' '}
            <DateFormat locale={i18n.locale} format={{ month: 'long', day: 'numeric' }}>
              {'0000' + company.fiscalYear.split('-')[0]}
            </DateFormat>
            -
            <DateFormat locale={i18n.locale} format={{ month: 'long', day: 'numeric' }}>
              {'0000' + company.fiscalYear.split('-')[1]}
            </DateFormat>
          </Box>
        )}

        <Divider sx={{ my: 2 }} />
        <Typography fontWeight={600} mt={3} textColor="neutral.500">
          <Trans id="Authority to sign on behalf of the company" />
        </Typography>
        <AuthorizedSignatory input={company.authorizedSignatory} />
        {company.shareholderMeetingHowtoCall && (
          <>
            <Typography fontWeight={600} mt={3} textColor="neutral.500">
              <Trans id="Way to summon" />
            </Typography>
            <Typography level="body-sm">
              {company.shareholderMeetingHowtoCall
                .replace(/\s*\/\s+>/g, ' ')
                .replace(/\s*\/\s+/g, '. ')
                .replace(/\.\s+/, '')}
            </Typography>
          </>
        )}
      </Box>
    </>
  );
};
