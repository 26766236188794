import { createFeatureSelector, createSelector } from '@ngrx/store';
import { filter } from 'lodash-es';

import { AuthorizationGroupDocumentsState } from '../reducers/authorization-groups-documents.reducers';

export const selectAuthorizationGroupDocumentsState =
  createFeatureSelector<AuthorizationGroupDocumentsState>('authorizationGroupDocuments');

export const selectAuthorizationGroupDocumentsByAgId = (agId: string) =>
  createSelector(selectAuthorizationGroupDocumentsState, state => {
    // @ts-expect-error TS18048
    return filter(state.entities, m => m.authorizationGroupId === agId);
  });
