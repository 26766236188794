import { createFeatureSelector, createSelector } from '@ngrx/store';
import { values } from 'lodash-es';

import { ShareIssueRegistrationsState } from '../reducers/share-issue-registrations.reducers';
import { selectShareIssueId } from './share-issues.base';

export const selectShareIssueRegistrationsState =
  createFeatureSelector<ShareIssueRegistrationsState>('shareIssueRegistrations');
export const selectShareIssueRegistrationById = (id: string) =>
  createSelector(selectShareIssueRegistrationsState, state => state.entities[id]);

export const selectShareIssueRegistrations = createSelector(
  selectShareIssueRegistrationsState,
  selectShareIssueId,
  (state, siId) => {
    return values(state.entities).filter(m => m?.shareIssueId === siId);
  },
);
