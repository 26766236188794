<!-- <app-maintenance></app-maintenance> -->
<div
  id="app-component"
  class="flex flex-col w-screen h-screen"
  sutLoading
  #qLoading="Loading"
  [autoStart]="false"
  [kind]="LoadingKind.Bar"
>
  <div id="app-main" class="flex flex-col w-screen h-screen" *ngIf="!(oldBrowser$ | async); else blockOldBrowser">
    <router-outlet></router-outlet>
  </div>
</div>
<ng-template #blockOldBrowser>
  <app-old-browser />
</ng-template>
<app-react-wrapper [component]="Hubspot" [props]="{ user: user$ | async, jwtIsValid: jwtService.isValid() }" />
