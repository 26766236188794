import { createAction, props, union } from '@ngrx/store';

import { SnrAuthorization } from '../../models/snr-authorization.model';

const prefix = '[SnrAuthorizations]' as const;

export const snrAuthorizationsActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: SnrAuthorization[] }>()),
};

export const all = union(snrAuthorizationsActions);
