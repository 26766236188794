import { range, isInteger } from 'lodash-es';
import { DateTime, Duration } from 'luxon';

export type DurationArray = {
  value: number;
  text: string;
}[];

export function createDurationArray(
  minMonths: number,
  maxMonths: number,
  onlyMonths = false,
  freqMonths = 0,
  suffixDate?: DateTime,
): DurationArray {
  const durationArray = range(minMonths, maxMonths).map(value => {
    const duration = Duration.fromObject({ months: value }, { conversionAccuracy: 'casual' });
    const years = Math.floor(duration.as('years'));
    const months = duration.minus({ years }).as('months');
    let text: string;
    if (onlyMonths) {
      text = $localize`${value} mån`;
    } else {
      if (months === 0) {
        text =
          $localize`${value} mån (${years} år` +
          (suffixDate
            ? $localize`. Slut: ` + suffixDate.plus({ months: value }).toLocaleString(DateTime.DATE_SHORT)
            : '') +
          ')';
      } else {
        text =
          $localize`${value} mån (${years} år och ${months} mån` +
          (suffixDate
            ? $localize`. Slut: ` + suffixDate.plus({ months: value }).toLocaleString(DateTime.DATE_SHORT)
            : '') +
          ')';
      }
    }
    return { value, text };
  });
  if (freqMonths > 0) {
    return durationArray.filter(da => isInteger(da.value / freqMonths));
  }
  return durationArray;
}
