import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isNil } from 'lodash-es';

export interface Data {
  title: string;
  text: string;
  ok: string;
}

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Data,
    public dialogRef: MatDialogRef<AlertDialogComponent>,
  ) {}

  static open(dialog: MatDialog, data: Data, config?: MatDialogConfig<Data>) {
    return dialog.open<AlertDialogComponent, Data>(AlertDialogComponent, {
      ...(!isNil(config) ? config : {}),
      minWidth: 600,
      disableClose: true,
      data,
    });
  }
}
