import { createAction, props, union } from '@ngrx/store';

import { ArticlesOfAssocDocument } from '../../models/articles-of-assoc-document.model';

const prefix = '[ArticlesOfAssocDocuments]' as const;

export const articlesOfAssocDocumentsActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: ArticlesOfAssocDocument[] }>()),
};

const all = union(articlesOfAssocDocumentsActions);

export type ActionsUnion = typeof all;
