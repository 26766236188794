import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A scalar that can represent any JSON value. */
  JSON: { input: any; output: any; }
  /**
   * ISO 8601 combined date and time without timezone.
   *
   * # Examples
   *
   * * `2015-07-01T08:59:60.123`,
   */
  NaiveDateTime: { input: any; output: any; }
  /**
   * A UUID is a unique 128-bit number, stored as 16 octets. UUIDs are parsed as
   * Strings within GraphQL. UUIDs are used to assign unique identifiers to
   * entities without requiring a central allocating authority.
   *
   * # References
   *
   * * [Wikipedia: Universally Unique Identifier](http://en.wikipedia.org/wiki/Universally_unique_identifier)
   * * [RFC4122: A Universally Unique IDentifier (UUID) URN Namespace](http://tools.ietf.org/html/rfc4122)
   */
  UUID: { input: any; output: any; }
}

export enum GqlAud {
  CompanyAdmin = 'COMPANY_ADMIN',
  Stakeholder = 'STAKEHOLDER'
}

export interface GqlBankidCollectInput {
  aud: GqlAud;
  orderRef: Scalars['String']['input'];
}

export interface GqlBankidCollectResponse {
  hintCode?: Maybe<GqlHintCode>;
  status: GqlStatus;
  successfulAuthResponse?: Maybe<GqlSuccessfulAuthResponse>;
}

export interface GqlBankidInitResponse {
  autoStartToken: Scalars['String']['output'];
  orderRef: Scalars['String']['output'];
  qrStartToken: Scalars['String']['output'];
}

export interface GqlDeletedFidoKeysResponse {
  ids: Array<Scalars['UUID']['output']>;
}

export interface GqlEmptyResponse {
  ok: Scalars['Boolean']['output'];
}

export interface GqlFidoKey {
  createdAt: Scalars['NaiveDateTime']['output'];
  creds: Scalars['JSON']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  kind: Scalars['Int']['output'];
  updatedAt: Scalars['NaiveDateTime']['output'];
  userId: Scalars['ID']['output'];
}

export interface GqlFinishSecuritykeyAuthenticationInput {
  aud: GqlAud;
  publicKeyCredential: Scalars['String']['input'];
  userId: Scalars['UUID']['input'];
}

export interface GqlFinishSecuritykeyRegistrationInput {
  displayName: Scalars['String']['input'];
  registerPublicKeyCredential: Scalars['String']['input'];
}

export enum GqlHintCode {
  Cancelled = 'CANCELLED',
  CertificateErr = 'CERTIFICATE_ERR',
  ExpiredTransaction = 'EXPIRED_TRANSACTION',
  NoClient = 'NO_CLIENT',
  OutstandingTransaction = 'OUTSTANDING_TRANSACTION',
  Started = 'STARTED',
  StartFailed = 'START_FAILED',
  UserCallConfirm = 'USER_CALL_CONFIRM',
  UserCancel = 'USER_CANCEL',
  UserDeclinedCall = 'USER_DECLINED_CALL',
  UserMrtd = 'USER_MRTD',
  UserSign = 'USER_SIGN'
}

export interface GqlMutationRoot {
  bankidCancel: GqlEmptyResponse;
  bankidInit: GqlBankidInitResponse;
  deleteFidoKey: GqlDeletedFidoKeysResponse;
  finishSecuritykeyAuthentication: GqlSuccessfulAuthResponse;
  finishSecuritykeyRegistration: GqlFidoKey;
  passwordConfirmRegister: GqlSuccessfulAuthResponse;
  passwordForgot: GqlEmptyResponse;
  passwordLogin: GqlSuccessfulAuthResponse;
  passwordRegister: GqlEmptyResponse;
  passwordReset: GqlSuccessfulAuthResponse;
  startSecuritykeyAuthentication: GqlStartSecuritykeyAuthenticationResponse;
  startSecuritykeyRegistration: GqlStartSecuritykeyRegistrationResponse;
  truidLoginSession: GqlTruidConfirmResponse;
}


export interface GqlMutationRootBankidCancelArgs {
  orderRef: Scalars['String']['input'];
}


export interface GqlMutationRootDeleteFidoKeyArgs {
  id: Scalars['UUID']['input'];
}


export interface GqlMutationRootFinishSecuritykeyAuthenticationArgs {
  input: GqlFinishSecuritykeyAuthenticationInput;
}


export interface GqlMutationRootFinishSecuritykeyRegistrationArgs {
  input: GqlFinishSecuritykeyRegistrationInput;
}


export interface GqlMutationRootPasswordConfirmRegisterArgs {
  aud: GqlAud;
  token: Scalars['String']['input'];
}


export interface GqlMutationRootPasswordForgotArgs {
  email: Scalars['String']['input'];
}


export interface GqlMutationRootPasswordLoginArgs {
  input: GqlPasswordLoginInput;
}


export interface GqlMutationRootPasswordRegisterArgs {
  input: GqlPasswordRegisterInput;
}


export interface GqlMutationRootPasswordResetArgs {
  input: GqlPasswordResetInput;
}


export interface GqlMutationRootStartSecuritykeyAuthenticationArgs {
  input: GqlStartSecuritykeyAuthenticationInput;
}


export interface GqlMutationRootStartSecuritykeyRegistrationArgs {
  input: GqlStartSecuritykeyRegistrationInput;
}


export interface GqlMutationRootTruidLoginSessionArgs {
  input: GqlTruidLoginInput;
}

export interface GqlPasswordLoginInput {
  aud: GqlAud;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}

export interface GqlPasswordRegisterInput {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}

export interface GqlPasswordResetInput {
  aud: GqlAud;
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
}

export interface GqlQueryRoot {
  bankidCollect: GqlBankidCollectResponse;
  fidoKeys: Array<GqlFidoKey>;
  truidToken: GqlSuccessfulAuthResponse;
  user: GqlUser;
  users: Array<GqlUser>;
}


export interface GqlQueryRootBankidCollectArgs {
  input: GqlBankidCollectInput;
}


export interface GqlQueryRootTruidTokenArgs {
  input: GqlTruidTokenInput;
}


export interface GqlQueryRootUserArgs {
  id: Scalars['ID']['input'];
}

export interface GqlStartSecuritykeyAuthenticationInput {
  personalId: Scalars['String']['input'];
}

export interface GqlStartSecuritykeyAuthenticationResponse {
  challenge: Scalars['String']['output'];
  userId: Scalars['UUID']['output'];
}

export interface GqlStartSecuritykeyRegistrationInput {
  displayName: Scalars['String']['input'];
}

export interface GqlStartSecuritykeyRegistrationResponse {
  challenge: Scalars['String']['output'];
}

export enum GqlStatus {
  Complete = 'COMPLETE',
  Failed = 'FAILED',
  Pending = 'PENDING'
}

export interface GqlSuccessfulAuthResponse {
  jwt: Scalars['String']['output'];
  user: GqlUser;
}

export interface GqlTruidConfirmResponse {
  redirectUri: Scalars['String']['output'];
}

export interface GqlTruidLoginInput {
  aud: GqlAud;
  isSwedish: Scalars['Boolean']['input'];
}

export interface GqlTruidTokenInput {
  aud: GqlAud;
  code: Scalars['String']['input'];
  state: Scalars['String']['input'];
}

export interface GqlUser {
  admin: Scalars['Boolean']['output'];
  authMethod: Scalars['Int']['output'];
  communicationLanguage: Scalars['String']['output'];
  createdAt: Scalars['NaiveDateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  emailMd5?: Maybe<Array<Scalars['Int']['output']>>;
  id: Scalars['ID']['output'];
  intercomHmac?: Maybe<Array<Scalars['Int']['output']>>;
  name?: Maybe<Scalars['String']['output']>;
  personalId?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['NaiveDateTime']['output'];
}

export type GqlBankidInitMutationVariables = Exact<{ [key: string]: never; }>;


export type GqlBankidInitMutation = { bankidInit: { orderRef: string, autoStartToken: string, qrStartToken: string } };

export type GqlBankidCollectQueryVariables = Exact<{
  input: GqlBankidCollectInput;
}>;


export type GqlBankidCollectQuery = { bankidCollect: { status: GqlStatus, hintCode?: GqlHintCode | null, successfulAuthResponse?: { jwt: string, user: { id: string, admin: boolean, personalId?: string | null, name?: string | null, email?: string | null, emailMd5?: Array<number> | null, phone?: string | null, authMethod: number, communicationLanguage: string, intercomHmac?: Array<number> | null, createdAt: any, updatedAt: any } } | null } };

export type GqlBankidCancelMutationVariables = Exact<{
  orderRef: Scalars['String']['input'];
}>;


export type GqlBankidCancelMutation = { bankidCancel: { ok: boolean } };

export type GqlFidoKeyFieldsFragment = { id: string, userId: string, displayName: string, kind: number, creds: any, createdAt: any, updatedAt: any };

export type GqlFidoKeysQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlFidoKeysQuery = { fidoKeys: Array<{ id: string, userId: string, displayName: string, kind: number, creds: any, createdAt: any, updatedAt: any }> };

export type GqlDeleteFidoKeyMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type GqlDeleteFidoKeyMutation = { deleteFidoKey: { ids: Array<any> } };

export type GqlStartSecuritykeyRegistrationMutationVariables = Exact<{
  input: GqlStartSecuritykeyRegistrationInput;
}>;


export type GqlStartSecuritykeyRegistrationMutation = { startSecuritykeyRegistration: { challenge: string } };

export type GqlFinishSecuritykeyRegistrationMutationVariables = Exact<{
  input: GqlFinishSecuritykeyRegistrationInput;
}>;


export type GqlFinishSecuritykeyRegistrationMutation = { finishSecuritykeyRegistration: { id: string, userId: string, displayName: string, kind: number, creds: any, createdAt: any, updatedAt: any } };

export type GqlStartSecuritykeyAuthenticationMutationVariables = Exact<{
  input: GqlStartSecuritykeyAuthenticationInput;
}>;


export type GqlStartSecuritykeyAuthenticationMutation = { startSecuritykeyAuthentication: { userId: any, challenge: string } };

export type GqlFinishSecuritykeyAuthenticationMutationVariables = Exact<{
  input: GqlFinishSecuritykeyAuthenticationInput;
}>;


export type GqlFinishSecuritykeyAuthenticationMutation = { finishSecuritykeyAuthentication: { jwt: string, user: { id: string, admin: boolean, personalId?: string | null, name?: string | null, email?: string | null, emailMd5?: Array<number> | null, phone?: string | null, authMethod: number, communicationLanguage: string, intercomHmac?: Array<number> | null, createdAt: any, updatedAt: any } } };

export type GqlPasswordRegisterMutationVariables = Exact<{
  input: GqlPasswordRegisterInput;
}>;


export type GqlPasswordRegisterMutation = { passwordRegister: { ok: boolean } };

export type GqlPasswordConfirmRegisterMutationVariables = Exact<{
  token: Scalars['String']['input'];
  aud: GqlAud;
}>;


export type GqlPasswordConfirmRegisterMutation = { passwordConfirmRegister: { jwt: string } };

export type GqlPasswordLoginMutationVariables = Exact<{
  input: GqlPasswordLoginInput;
}>;


export type GqlPasswordLoginMutation = { passwordLogin: { jwt: string } };

export type GqlPasswordForgotMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type GqlPasswordForgotMutation = { passwordForgot: { ok: boolean } };

export type GqlPasswordResetMutationVariables = Exact<{
  input: GqlPasswordResetInput;
}>;


export type GqlPasswordResetMutation = { passwordReset: { jwt: string } };

export type GqlUserFieldsFragment = { id: string, admin: boolean, personalId?: string | null, name?: string | null, email?: string | null, emailMd5?: Array<number> | null, phone?: string | null, authMethod: number, communicationLanguage: string, intercomHmac?: Array<number> | null, createdAt: any, updatedAt: any };

export const FidoKeyFieldsFragmentDoc = gql`
    fragment FidoKeyFields on FidoKey {
  id
  userId
  displayName
  kind
  creds
  createdAt
  updatedAt
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment UserFields on User {
  id
  admin
  personalId
  name
  email
  emailMd5
  phone
  authMethod
  communicationLanguage
  intercomHmac
  createdAt
  updatedAt
}
    `;
export const BankidInitDocument = gql`
    mutation BankidInit {
  bankidInit {
    orderRef
    autoStartToken
    qrStartToken
  }
}
    `;
export type GqlBankidInitMutationFn = Apollo.MutationFunction<GqlBankidInitMutation, GqlBankidInitMutationVariables>;

/**
 * __useBankidInitMutation__
 *
 * To run a mutation, you first call `useBankidInitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBankidInitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bankidInitMutation, { data, loading, error }] = useBankidInitMutation({
 *   variables: {
 *   },
 * });
 */
export function useBankidInitMutation(baseOptions?: Apollo.MutationHookOptions<GqlBankidInitMutation, GqlBankidInitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlBankidInitMutation, GqlBankidInitMutationVariables>(BankidInitDocument, options);
      }
export type BankidInitMutationHookResult = ReturnType<typeof useBankidInitMutation>;
export type BankidInitMutationResult = Apollo.MutationResult<GqlBankidInitMutation>;
export type BankidInitMutationOptions = Apollo.BaseMutationOptions<GqlBankidInitMutation, GqlBankidInitMutationVariables>;
export const BankidCollectDocument = gql`
    query BankidCollect($input: BankidCollectInput!) {
  bankidCollect(input: $input) {
    status
    hintCode
    successfulAuthResponse {
      jwt
      user {
        ...UserFields
      }
    }
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useBankidCollectQuery__
 *
 * To run a query within a React component, call `useBankidCollectQuery` and pass it any options that fit your needs.
 * When your component renders, `useBankidCollectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBankidCollectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBankidCollectQuery(baseOptions: Apollo.QueryHookOptions<GqlBankidCollectQuery, GqlBankidCollectQueryVariables> & ({ variables: GqlBankidCollectQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlBankidCollectQuery, GqlBankidCollectQueryVariables>(BankidCollectDocument, options);
      }
export function useBankidCollectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlBankidCollectQuery, GqlBankidCollectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlBankidCollectQuery, GqlBankidCollectQueryVariables>(BankidCollectDocument, options);
        }
export function useBankidCollectSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GqlBankidCollectQuery, GqlBankidCollectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GqlBankidCollectQuery, GqlBankidCollectQueryVariables>(BankidCollectDocument, options);
        }
export type BankidCollectQueryHookResult = ReturnType<typeof useBankidCollectQuery>;
export type BankidCollectLazyQueryHookResult = ReturnType<typeof useBankidCollectLazyQuery>;
export type BankidCollectSuspenseQueryHookResult = ReturnType<typeof useBankidCollectSuspenseQuery>;
export type BankidCollectQueryResult = Apollo.QueryResult<GqlBankidCollectQuery, GqlBankidCollectQueryVariables>;
export const BankidCancelDocument = gql`
    mutation BankidCancel($orderRef: String!) {
  bankidCancel(orderRef: $orderRef) {
    ok
  }
}
    `;
export type GqlBankidCancelMutationFn = Apollo.MutationFunction<GqlBankidCancelMutation, GqlBankidCancelMutationVariables>;

/**
 * __useBankidCancelMutation__
 *
 * To run a mutation, you first call `useBankidCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBankidCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bankidCancelMutation, { data, loading, error }] = useBankidCancelMutation({
 *   variables: {
 *      orderRef: // value for 'orderRef'
 *   },
 * });
 */
export function useBankidCancelMutation(baseOptions?: Apollo.MutationHookOptions<GqlBankidCancelMutation, GqlBankidCancelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlBankidCancelMutation, GqlBankidCancelMutationVariables>(BankidCancelDocument, options);
      }
export type BankidCancelMutationHookResult = ReturnType<typeof useBankidCancelMutation>;
export type BankidCancelMutationResult = Apollo.MutationResult<GqlBankidCancelMutation>;
export type BankidCancelMutationOptions = Apollo.BaseMutationOptions<GqlBankidCancelMutation, GqlBankidCancelMutationVariables>;
export const FidoKeysDocument = gql`
    query FidoKeys {
  fidoKeys {
    ...FidoKeyFields
  }
}
    ${FidoKeyFieldsFragmentDoc}`;

/**
 * __useFidoKeysQuery__
 *
 * To run a query within a React component, call `useFidoKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useFidoKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFidoKeysQuery({
 *   variables: {
 *   },
 * });
 */
export function useFidoKeysQuery(baseOptions?: Apollo.QueryHookOptions<GqlFidoKeysQuery, GqlFidoKeysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlFidoKeysQuery, GqlFidoKeysQueryVariables>(FidoKeysDocument, options);
      }
export function useFidoKeysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlFidoKeysQuery, GqlFidoKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlFidoKeysQuery, GqlFidoKeysQueryVariables>(FidoKeysDocument, options);
        }
export function useFidoKeysSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GqlFidoKeysQuery, GqlFidoKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GqlFidoKeysQuery, GqlFidoKeysQueryVariables>(FidoKeysDocument, options);
        }
export type FidoKeysQueryHookResult = ReturnType<typeof useFidoKeysQuery>;
export type FidoKeysLazyQueryHookResult = ReturnType<typeof useFidoKeysLazyQuery>;
export type FidoKeysSuspenseQueryHookResult = ReturnType<typeof useFidoKeysSuspenseQuery>;
export type FidoKeysQueryResult = Apollo.QueryResult<GqlFidoKeysQuery, GqlFidoKeysQueryVariables>;
export const DeleteFidoKeyDocument = gql`
    mutation DeleteFidoKey($id: UUID!) {
  deleteFidoKey(id: $id) {
    ids
  }
}
    `;
export type GqlDeleteFidoKeyMutationFn = Apollo.MutationFunction<GqlDeleteFidoKeyMutation, GqlDeleteFidoKeyMutationVariables>;

/**
 * __useDeleteFidoKeyMutation__
 *
 * To run a mutation, you first call `useDeleteFidoKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFidoKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFidoKeyMutation, { data, loading, error }] = useDeleteFidoKeyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFidoKeyMutation(baseOptions?: Apollo.MutationHookOptions<GqlDeleteFidoKeyMutation, GqlDeleteFidoKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlDeleteFidoKeyMutation, GqlDeleteFidoKeyMutationVariables>(DeleteFidoKeyDocument, options);
      }
export type DeleteFidoKeyMutationHookResult = ReturnType<typeof useDeleteFidoKeyMutation>;
export type DeleteFidoKeyMutationResult = Apollo.MutationResult<GqlDeleteFidoKeyMutation>;
export type DeleteFidoKeyMutationOptions = Apollo.BaseMutationOptions<GqlDeleteFidoKeyMutation, GqlDeleteFidoKeyMutationVariables>;
export const StartSecuritykeyRegistrationDocument = gql`
    mutation StartSecuritykeyRegistration($input: StartSecuritykeyRegistrationInput!) {
  startSecuritykeyRegistration(input: $input) {
    challenge
  }
}
    `;
export type GqlStartSecuritykeyRegistrationMutationFn = Apollo.MutationFunction<GqlStartSecuritykeyRegistrationMutation, GqlStartSecuritykeyRegistrationMutationVariables>;

/**
 * __useStartSecuritykeyRegistrationMutation__
 *
 * To run a mutation, you first call `useStartSecuritykeyRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartSecuritykeyRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startSecuritykeyRegistrationMutation, { data, loading, error }] = useStartSecuritykeyRegistrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartSecuritykeyRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<GqlStartSecuritykeyRegistrationMutation, GqlStartSecuritykeyRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlStartSecuritykeyRegistrationMutation, GqlStartSecuritykeyRegistrationMutationVariables>(StartSecuritykeyRegistrationDocument, options);
      }
export type StartSecuritykeyRegistrationMutationHookResult = ReturnType<typeof useStartSecuritykeyRegistrationMutation>;
export type StartSecuritykeyRegistrationMutationResult = Apollo.MutationResult<GqlStartSecuritykeyRegistrationMutation>;
export type StartSecuritykeyRegistrationMutationOptions = Apollo.BaseMutationOptions<GqlStartSecuritykeyRegistrationMutation, GqlStartSecuritykeyRegistrationMutationVariables>;
export const FinishSecuritykeyRegistrationDocument = gql`
    mutation FinishSecuritykeyRegistration($input: FinishSecuritykeyRegistrationInput!) {
  finishSecuritykeyRegistration(input: $input) {
    ...FidoKeyFields
  }
}
    ${FidoKeyFieldsFragmentDoc}`;
export type GqlFinishSecuritykeyRegistrationMutationFn = Apollo.MutationFunction<GqlFinishSecuritykeyRegistrationMutation, GqlFinishSecuritykeyRegistrationMutationVariables>;

/**
 * __useFinishSecuritykeyRegistrationMutation__
 *
 * To run a mutation, you first call `useFinishSecuritykeyRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishSecuritykeyRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishSecuritykeyRegistrationMutation, { data, loading, error }] = useFinishSecuritykeyRegistrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinishSecuritykeyRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<GqlFinishSecuritykeyRegistrationMutation, GqlFinishSecuritykeyRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlFinishSecuritykeyRegistrationMutation, GqlFinishSecuritykeyRegistrationMutationVariables>(FinishSecuritykeyRegistrationDocument, options);
      }
export type FinishSecuritykeyRegistrationMutationHookResult = ReturnType<typeof useFinishSecuritykeyRegistrationMutation>;
export type FinishSecuritykeyRegistrationMutationResult = Apollo.MutationResult<GqlFinishSecuritykeyRegistrationMutation>;
export type FinishSecuritykeyRegistrationMutationOptions = Apollo.BaseMutationOptions<GqlFinishSecuritykeyRegistrationMutation, GqlFinishSecuritykeyRegistrationMutationVariables>;
export const StartSecuritykeyAuthenticationDocument = gql`
    mutation StartSecuritykeyAuthentication($input: StartSecuritykeyAuthenticationInput!) {
  startSecuritykeyAuthentication(input: $input) {
    userId
    challenge
  }
}
    `;
export type GqlStartSecuritykeyAuthenticationMutationFn = Apollo.MutationFunction<GqlStartSecuritykeyAuthenticationMutation, GqlStartSecuritykeyAuthenticationMutationVariables>;

/**
 * __useStartSecuritykeyAuthenticationMutation__
 *
 * To run a mutation, you first call `useStartSecuritykeyAuthenticationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartSecuritykeyAuthenticationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startSecuritykeyAuthenticationMutation, { data, loading, error }] = useStartSecuritykeyAuthenticationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartSecuritykeyAuthenticationMutation(baseOptions?: Apollo.MutationHookOptions<GqlStartSecuritykeyAuthenticationMutation, GqlStartSecuritykeyAuthenticationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlStartSecuritykeyAuthenticationMutation, GqlStartSecuritykeyAuthenticationMutationVariables>(StartSecuritykeyAuthenticationDocument, options);
      }
export type StartSecuritykeyAuthenticationMutationHookResult = ReturnType<typeof useStartSecuritykeyAuthenticationMutation>;
export type StartSecuritykeyAuthenticationMutationResult = Apollo.MutationResult<GqlStartSecuritykeyAuthenticationMutation>;
export type StartSecuritykeyAuthenticationMutationOptions = Apollo.BaseMutationOptions<GqlStartSecuritykeyAuthenticationMutation, GqlStartSecuritykeyAuthenticationMutationVariables>;
export const FinishSecuritykeyAuthenticationDocument = gql`
    mutation FinishSecuritykeyAuthentication($input: FinishSecuritykeyAuthenticationInput!) {
  finishSecuritykeyAuthentication(input: $input) {
    jwt
    user {
      ...UserFields
    }
  }
}
    ${UserFieldsFragmentDoc}`;
export type GqlFinishSecuritykeyAuthenticationMutationFn = Apollo.MutationFunction<GqlFinishSecuritykeyAuthenticationMutation, GqlFinishSecuritykeyAuthenticationMutationVariables>;

/**
 * __useFinishSecuritykeyAuthenticationMutation__
 *
 * To run a mutation, you first call `useFinishSecuritykeyAuthenticationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishSecuritykeyAuthenticationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishSecuritykeyAuthenticationMutation, { data, loading, error }] = useFinishSecuritykeyAuthenticationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinishSecuritykeyAuthenticationMutation(baseOptions?: Apollo.MutationHookOptions<GqlFinishSecuritykeyAuthenticationMutation, GqlFinishSecuritykeyAuthenticationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlFinishSecuritykeyAuthenticationMutation, GqlFinishSecuritykeyAuthenticationMutationVariables>(FinishSecuritykeyAuthenticationDocument, options);
      }
export type FinishSecuritykeyAuthenticationMutationHookResult = ReturnType<typeof useFinishSecuritykeyAuthenticationMutation>;
export type FinishSecuritykeyAuthenticationMutationResult = Apollo.MutationResult<GqlFinishSecuritykeyAuthenticationMutation>;
export type FinishSecuritykeyAuthenticationMutationOptions = Apollo.BaseMutationOptions<GqlFinishSecuritykeyAuthenticationMutation, GqlFinishSecuritykeyAuthenticationMutationVariables>;
export const PasswordRegisterDocument = gql`
    mutation PasswordRegister($input: PasswordRegisterInput!) {
  passwordRegister(input: $input) {
    ok
  }
}
    `;
export type GqlPasswordRegisterMutationFn = Apollo.MutationFunction<GqlPasswordRegisterMutation, GqlPasswordRegisterMutationVariables>;

/**
 * __usePasswordRegisterMutation__
 *
 * To run a mutation, you first call `usePasswordRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordRegisterMutation, { data, loading, error }] = usePasswordRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePasswordRegisterMutation(baseOptions?: Apollo.MutationHookOptions<GqlPasswordRegisterMutation, GqlPasswordRegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlPasswordRegisterMutation, GqlPasswordRegisterMutationVariables>(PasswordRegisterDocument, options);
      }
export type PasswordRegisterMutationHookResult = ReturnType<typeof usePasswordRegisterMutation>;
export type PasswordRegisterMutationResult = Apollo.MutationResult<GqlPasswordRegisterMutation>;
export type PasswordRegisterMutationOptions = Apollo.BaseMutationOptions<GqlPasswordRegisterMutation, GqlPasswordRegisterMutationVariables>;
export const PasswordConfirmRegisterDocument = gql`
    mutation PasswordConfirmRegister($token: String!, $aud: Aud!) {
  passwordConfirmRegister(token: $token, aud: $aud) {
    jwt
  }
}
    `;
export type GqlPasswordConfirmRegisterMutationFn = Apollo.MutationFunction<GqlPasswordConfirmRegisterMutation, GqlPasswordConfirmRegisterMutationVariables>;

/**
 * __usePasswordConfirmRegisterMutation__
 *
 * To run a mutation, you first call `usePasswordConfirmRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordConfirmRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordConfirmRegisterMutation, { data, loading, error }] = usePasswordConfirmRegisterMutation({
 *   variables: {
 *      token: // value for 'token'
 *      aud: // value for 'aud'
 *   },
 * });
 */
export function usePasswordConfirmRegisterMutation(baseOptions?: Apollo.MutationHookOptions<GqlPasswordConfirmRegisterMutation, GqlPasswordConfirmRegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlPasswordConfirmRegisterMutation, GqlPasswordConfirmRegisterMutationVariables>(PasswordConfirmRegisterDocument, options);
      }
export type PasswordConfirmRegisterMutationHookResult = ReturnType<typeof usePasswordConfirmRegisterMutation>;
export type PasswordConfirmRegisterMutationResult = Apollo.MutationResult<GqlPasswordConfirmRegisterMutation>;
export type PasswordConfirmRegisterMutationOptions = Apollo.BaseMutationOptions<GqlPasswordConfirmRegisterMutation, GqlPasswordConfirmRegisterMutationVariables>;
export const PasswordLoginDocument = gql`
    mutation PasswordLogin($input: PasswordLoginInput!) {
  passwordLogin(input: $input) {
    jwt
  }
}
    `;
export type GqlPasswordLoginMutationFn = Apollo.MutationFunction<GqlPasswordLoginMutation, GqlPasswordLoginMutationVariables>;

/**
 * __usePasswordLoginMutation__
 *
 * To run a mutation, you first call `usePasswordLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordLoginMutation, { data, loading, error }] = usePasswordLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePasswordLoginMutation(baseOptions?: Apollo.MutationHookOptions<GqlPasswordLoginMutation, GqlPasswordLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlPasswordLoginMutation, GqlPasswordLoginMutationVariables>(PasswordLoginDocument, options);
      }
export type PasswordLoginMutationHookResult = ReturnType<typeof usePasswordLoginMutation>;
export type PasswordLoginMutationResult = Apollo.MutationResult<GqlPasswordLoginMutation>;
export type PasswordLoginMutationOptions = Apollo.BaseMutationOptions<GqlPasswordLoginMutation, GqlPasswordLoginMutationVariables>;
export const PasswordForgotDocument = gql`
    mutation PasswordForgot($email: String!) {
  passwordForgot(email: $email) {
    ok
  }
}
    `;
export type GqlPasswordForgotMutationFn = Apollo.MutationFunction<GqlPasswordForgotMutation, GqlPasswordForgotMutationVariables>;

/**
 * __usePasswordForgotMutation__
 *
 * To run a mutation, you first call `usePasswordForgotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordForgotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordForgotMutation, { data, loading, error }] = usePasswordForgotMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function usePasswordForgotMutation(baseOptions?: Apollo.MutationHookOptions<GqlPasswordForgotMutation, GqlPasswordForgotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlPasswordForgotMutation, GqlPasswordForgotMutationVariables>(PasswordForgotDocument, options);
      }
export type PasswordForgotMutationHookResult = ReturnType<typeof usePasswordForgotMutation>;
export type PasswordForgotMutationResult = Apollo.MutationResult<GqlPasswordForgotMutation>;
export type PasswordForgotMutationOptions = Apollo.BaseMutationOptions<GqlPasswordForgotMutation, GqlPasswordForgotMutationVariables>;
export const PasswordResetDocument = gql`
    mutation PasswordReset($input: PasswordResetInput!) {
  passwordReset(input: $input) {
    jwt
  }
}
    `;
export type GqlPasswordResetMutationFn = Apollo.MutationFunction<GqlPasswordResetMutation, GqlPasswordResetMutationVariables>;

/**
 * __usePasswordResetMutation__
 *
 * To run a mutation, you first call `usePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetMutation, { data, loading, error }] = usePasswordResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<GqlPasswordResetMutation, GqlPasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlPasswordResetMutation, GqlPasswordResetMutationVariables>(PasswordResetDocument, options);
      }
export type PasswordResetMutationHookResult = ReturnType<typeof usePasswordResetMutation>;
export type PasswordResetMutationResult = Apollo.MutationResult<GqlPasswordResetMutation>;
export type PasswordResetMutationOptions = Apollo.BaseMutationOptions<GqlPasswordResetMutation, GqlPasswordResetMutationVariables>;