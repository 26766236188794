import { GqlCreateIdentityInput, useSearchIdentitiesQuery } from '../../graphql/react-operations';
import { JuridicalKind } from '../../models/identity.model';
import { useCompanyId } from '@startuptools/common/react-wrapper';
import { Trans } from '@lingui/react';
import { Identity } from '../identity/Identity';
import { identityToInput } from '../../helpers/identity-to-input';
import { Alert, Button, Stack, Typography } from '@startuptools/ui';

export const DuplicateIdentity = ({
  searchString,
  juridicalKinds,
  handleCreate,
}: {
  searchString: string;
  juridicalKinds: JuridicalKind[];
  handleCreate: (identity: GqlCreateIdentityInput) => Promise<unknown>;
}) => {
  const companyId = useCompanyId();
  const { data, loading, error } = useSearchIdentitiesQuery({
    variables: { companyId, input: { searchString, juridicalKinds, pageOffset: 0, pageSize: 1, similarity: 0.3 } },
    fetchPolicy: 'no-cache',
  });

  if (loading || error) {
    return null;
  }

  const foundIdentity = data?.searchIdentities?.[0];
  return (
    <>
      {foundIdentity && (
        <Alert
          variant="outlined"
          endDecorator={
            <Button type="button" onClick={() => handleCreate(identityToInput(foundIdentity))} variant="outlined">
              <Trans id="Yes" />
            </Button>
          }
        >
          <Stack gap={1}>
            <Typography level="title-lg">
              <Trans id="We found a possible duplicate, do you want to use that one?" />
            </Typography>
            {foundIdentity && <Identity identity={foundIdentity} editable={false} showAvatar />}
          </Stack>
        </Alert>
      )}
    </>
  );
};
