import { createAction, props, union } from '@ngrx/store';

import { OptionsSubscriberRepresentative } from '../../models/options-subscriber-representatives.model';

const prefix = '[OptionsSubscriberRepresentative]' as const;

export const optionsSubscriberRepresentativeActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: OptionsSubscriberRepresentative[] }>()),
  manyDeleted: createAction(prefix + ' manyDeleted', props<{ payload: string[] }>()),
};

export const all = union(optionsSubscriberRepresentativeActions);

export type ActionsUnion = typeof all;
