import { createReducer, on, Action } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { map } from 'lodash-es';

import { WarrantBoardMember } from '../../models/warrant-board-member.model';
import { warrantBoardMembersActions } from '../actions/warrant-board-member.actions';

export type WarrantBoardMembersState = EntityState<WarrantBoardMember>;

export const adapter = createEntityAdapter<WarrantBoardMember>();
export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(warrantBoardMembersActions.manyLoaded, (state, { payload }) =>
    adapter.addMany(
      payload,
      adapter.removeMany(
        map(payload, p => p.id),
        state,
      ),
    ),
  ),
  on(warrantBoardMembersActions.manyDeleted, (state, { payload }) => adapter.removeMany(payload, state)),
);

export function warrantBoardMembersReducer(state: EntityState<WarrantBoardMember> | undefined, action: Action) {
  return reducer(state, action);
}
