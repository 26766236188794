import { alpha, createTheme } from '@mui/material/styles';
import { extendTheme } from '@startuptools/ui';
import type { ThemeOptions } from '@mui/material/styles';

export const joyTheme = extendTheme({
  typography: {
    h1: {
      fontFamily: 'Inter',
      fontWeight: 'normal',
    },
    h2: {
      fontFamily: 'Inter',
      fontWeight: 'normal',
    },
    h3: {
      fontFamily: 'Inter',
      fontWeight: 'normal',
    },
    h4: {
      fontFamily: 'Inter',
      fontWeight: 'normal',
    },
  },
  colorSchemes: {
    light: {
      palette: {
        primary: {
          '100': '#A2A2EE',
          '200': '#9494EC',
          '300': '#8383E9',
          '400': '#8383E9',
          '500': '#6464E3',
          '600': '#4848DE',
          '700': '#2D2DD9',
          '800': '#2323C2',
          '900': '#1F1FA9',
        },
        /* use to indicate anything related to the Pro subscription */
        pro: {
          background: alpha('#FFCE94', 0.4),
          dark: '#FFBC6C',
          main: '#FFCE94',
          light: '#ffd39f',
        },
      },
    },
  },
  fontFamily: {
    display: 'Inter',
    body: 'Inter',
  },
  components: {
    JoyMenu: {
      styleOverrides: {
        root: {
          /* ensure that Menu dropdowns work inside modals. */
          zIndex: 999999,
        },
      },
    },
    JoySelect: {
      styleOverrides: {
        listbox: {
          /* ensure that Select dropdowns work inside modals. */
          zIndex: 999999,
        },
      },
    },
    JoyInput: {
      styleOverrides: {
        root: ({ ownerState }) => {
          if (ownerState.readOnly) {
            return { '--_Input-focusedHighlight': 'none' };
          }
        },
      },
    },
  },
});

export const materialTheme = createTheme({
  palette: {
    primary: {
      main: '#6464E3',
      dark: '#2D2DD9',
      light: '#8383E9',
      contrastText: 'white',
    },
    secondary: {
      dark: '#A86C13',
      main: '#F09B1C',
      light: '#F3AF49',
    },
  },
  typography: {
    fontFamily: 'Inter',
    h1: {
      fontFamily: 'Inter',
    },
    h2: {
      fontFamily: 'Inter',
    },
    h3: {
      fontFamily: 'Inter',
    },
    h4: {
      fontFamily: 'Inter',
    },
    h5: {
      fontFamily: 'Inter',
    },
    h6: {
      fontFamily: 'Inter',
    },
    button: {
      fontFamily: 'inherit',
      fontSize: '1rem',
      fontWeight: 600,
      color: '#1945B4',
      lineHeight: '19px',
      textTransform: 'none',
    },
  },
} as ThemeOptions);

declare module '@mui/joy/styles' {
  interface Palette {
    pro: {
      background: string;
      dark: string;
      main: string;
      light: string;
    };
  }
}
