import { createAction, props, union } from '@ngrx/store';

import { Meeting } from '../../models/meeting.model';

const prefix = '[Meetings]' as const;

export const meetingActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: Meeting[] }>()),
  manyDeleted: createAction(prefix + ' manyDeleted', props<{ payload: string[] }>()),
};

const all = union(meetingActions);

export type ActionsUnion = typeof all;
