import { useTruidLoginSessionMutation } from './Truid.graphql';
import { isNil } from 'lodash-es';
import { Button, SvgIcon, Modal, ModalClose, ModalDialog } from '@startuptools/ui';
import { DialogTitle, DialogContent, DialogActions, Box } from '@mui/joy'; // Because we can't re-export before removing custom from ui lib
import { Trans } from '@lingui/react';
import TruidIcon from '../truid_logo_black.svg';
import { GqlAud } from '../../graphql/base-types.graphql';
import { Dispatch, SetStateAction, useState } from 'react';

export const Truid = ({ aud, setShowError }: { aud: GqlAud; setShowError: Dispatch<SetStateAction<boolean>> }) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [login] = useTruidLoginSessionMutation();

  const handleLogin = async (isSwedish: boolean) => {
    setShowError(false);
    setLoading(true);
    setShowModal(true);
    try {
      const { data } = await login({ variables: { input: { aud, isSwedish } } });
      if (!isNil(data)) {
        window.location.replace(data.truidLoginSession.redirectUri);
      } else {
        setShowError(true);
        setLoading(false);
      }
    } catch {
      setShowError(true);
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        color="neutral"
        variant="outlined"
        sx={{ fontSize: '1.1rem' }}
        size="lg"
        startDecorator={
          <SvgIcon>
            <TruidIcon />
          </SvgIcon>
        }
        onClick={() => setShowModal(true)}
        loading={loading}
      >
        <Trans id="Truid" />
      </Button>
      {showModal && (
        <Modal open onClose={() => setShowModal(false)}>
          <ModalDialog size="sm">
            <ModalClose />
            <Box margin={3}>
              <DialogTitle>
                <Trans id="Do you have a Swedish Personal Number?" />
              </DialogTitle>
              {aud === GqlAud.Stakeholder && (
                <DialogContent sx={{ mt: 2 }}>
                  <Trans id="If you do not have a Swedish personal number, make sure you use the same email in Truid as the shareholder that was added by the company" />
                </DialogContent>
              )}
              <DialogActions>
                <Button variant="outlined" onClick={() => handleLogin(true)}>
                  <Trans id="Yes" />
                </Button>
                <Button variant="outlined" onClick={() => handleLogin(false)} color="neutral">
                  <Trans id="No" />
                </Button>
                <Button variant="plain" color="neutral" onClick={() => setShowModal(false)}>
                  <Trans id="Cancel" />
                </Button>
              </DialogActions>
            </Box>
          </ModalDialog>
        </Modal>
      )}
    </>
  );
};
