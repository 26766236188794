import { GqlWarrantBoardMember } from '../graphql/operations';
import { Identity } from './identity.model';

export interface WpbmWithIncludes {
  model: WarrantBoardMember;
  identity: Identity;
}

export class WarrantBoardMember implements Omit<GqlWarrantBoardMember, 'warrantProgram'> {
  id: string;

  warrantProgramId: string;
  identityId: string;

  readonly createdAt: string;
  readonly updatedAt: string;

  constructor(seed?: Partial<WarrantBoardMember>) {
    Object.assign(this, seed);
  }
}
